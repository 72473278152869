import { createReducer, createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import { Analytic } from '../../App/Analytic';
import { IsCanvasEditor, PROJECT_CONST } from '../../data/config';
import { LoadLanguages } from '../../data/LanguageHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { StoreType } from '../../store/store.type';
import { API } from '../../utils/API';
import { history, ROUTE_CONST } from '../../utils/history';
import { GetProjectCreationParamsFromSession } from '../../utils/projectHelper';
import { alertActions } from '../alert/alert';
import { backgroundActions } from '../backgrounds/background.store';
import { clipartActions } from '../cliparts/cliparts';
import {
  layoutListActions,
  layoutListSelectors,
} from '../layouts/layout.store';
import { overlayerActions, overlayerSelectors } from '../overlayers/overlayers';
import { photoListSelector } from '../photoList/photo.selector';
import { photoListActions } from '../photoList/photo.store';
import { pricingActions, pricingSelectors } from '../pricing/pricing';

/** **********************************
// ACTIONS TYPES
************************************ */

const CHECK_SESS_START = 'AUTH/CHECKSESS_START';
const CHECK_SESS_FAIL = 'AUTH/CHECK_SESS_FAIL';
const CHECK_SESS_SUCCESS = 'AUTH/CHECK_SESS_SUCCESS';

const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE';

const REGISTER_REQUEST = 'AUTH/REGISTER_REQUEST';
const REGISTER_SUCCESS = 'AUTH/REGISTER_SUCCESS';
const REGISTER_FAILURE = 'AUTH/REGISTER_FAILURE';

const LANGUAGE_LOADED = 'LANG/LANGUAGE_LOADED';
const LOGOUT = 'AUTH/LOGOUT';

/** **********************************
// REDUCERS
************************************ */

/** @type {ISession} */
let sessionStore = null;

if (DebugFlags.USE_SESSION_LOCAL_STORAGE) {
  // try recover old session
  try {
    sessionStore = JSON.parse(localStorage.getItem('session'));
  } catch (e) {
    localStorage.removeItem('session');
  }
}

type RootState = StoreType;
type AuthState = StoreType['authentication'];

const initialState: AuthState = {
  initializing: true, // when app is initializing (checking current params and session)
  languageLoaded: false, // languages are loaded

  loggingIn: false, // currently login
  registering: false, // currently registering
  loggedIn: false, // is user loggedin
  lastSessionCheck: Date.now(), // last date the session was checked

  error: null,

  session: sessionStore, // or just user is ok, not mandatory to use session:session
  rebranding: null,
  user: sessionStore
    ? { id: sessionStore.user_id, email: sessionStore.email }
    : null,
  projectCreationParams: null,
};

const reducer = createReducer(initialState, {
  // --------------------- session check ------------------------

  [CHECK_SESS_START]: (state) => {
    state.initializing = true;
    state.loggedIn = false;
    state.session = null;
    state.user = null;
  },
  [CHECK_SESS_FAIL]: (state, action) => {
    state.initializing = false;
    state.error = action.error;
  },
  [CHECK_SESS_SUCCESS]: (state, action) => {
    state.initializing = false;
    updateSession(state, action.session);
  },

  // --------------------- login ------------------------

  [LOGIN_REQUEST]: (state, action) => {
    state.error = null;
    state.loggingIn = true;
    state.loggedIn = false;
    state.user = { email: action.username };
  },

  [LOGIN_SUCCESS]: (state, action) => {
    state.error = null;
    state.loggingIn = false;
    updateSession(state, action.session);
  },

  [LOGIN_FAILURE]: (state, action) => {
    state.error = action.error;
    state.loggingIn = false;
    state.loggedIn = false;
  },

  // --------------------- register ------------------------

  [REGISTER_REQUEST]: (state, action) => {
    state.error = null;
    state.registering = true;
    state.user = { email: action.username };
  },

  [REGISTER_SUCCESS]: (state, action) => {
    state.error = null;
    state.registering = false;
    updateSession(state, action.session);
  },

  [REGISTER_FAILURE]: (state, action) => {
    state.error = action.error;
    state.registering = false;
  },

  // --------------------- language ------------------------

  [LANGUAGE_LOADED]: (state, action) => {
    state.languageLoaded = action.isLoaded;
  },

  // --------------------- logout ------------------------

  [LOGOUT]: (state) => {
    state.error = null;
    state.loggingIn = false;
    state.loggedIn = false;
  },
});

/** **********************************
// Helpers
************************************ */

/**
 *
 * @param {*} state
 * @param {ISession} session
 */
function updateSession(state, session) {
  if (!session) return;

  state.session = session;

  // [HACK]
  if (
    state.session.recent_project_class &&
    state.session.recent_project_class === 'calendar'
  ) {
    state.session.recent_project_class = 'calendars';
  }

  localStorage.setItem('session', JSON.stringify(session));

  // case we have a user_id, we are logged in!
  if (session.user_id !== 0) {
    state.user = { id: session.user_id, email: session.email };
    state.loggedIn = true;
    localStorage.setItem('loggedIn', '1');

    // sentry set user
    Sentry.setUser({ email: session.email, id: session.user_id });
  }

  // project creation based on session parameters
  if (DebugFlags.SIMULATE_SESSION_PRODUCT) {
    // USE THIS TO CREATE REAL FAKE SESSION  (do this in sale browser session as the testing one!)
    // https://www.tictacphoto.com/en/tictacphoto/redirect?prod=album&clicked=1&cover=Custom+Cover&corners=none&size=medium&type=contemporary&product=201&pagePaperQuality=250&varnish=1&flyleaf=white&inserts=0&style=simple&loadeditor=1
    // AND VERIFY AFTER HERE :
    // https://editor2dev.tictacphoto.com/json/chksess.php

    // ---- ALBUMS ----
    // common (ONLY MANDATORY!)
    session.return_page = 'albums';
    session.product_id = '30201'; // product code
    session.product_inserts = '0';
    session.product_pagePaperQuality = '250';
    session.product_page_count = '40';

    session.product_flyleaf = 'white';
    session.product_style = 'simple';

    // session.cover_name="Custom Cover";
    session.cover_name = 'Linen Red'; // (Mostly this is 'Custom Cover', except on classic it can be 'Leather Black' and the others)
    session.cover_corners = 'roundsilver';
    // session.cover_name="Leather Black"; //(Mostly this is 'Custom Cover', except on classic it can be 'Leather Black' and the others)
    // session.cover_corners= "none"; //(defaults to none, can be roundgold, roundsilver, squaregold, squaresilver)

    // ---- CALENDARS ----
    // session.return_page="calendars";
    // session.product_id = "17"
    // session.product_coated = "false"
    // session.year = "2030";
    // session.month = "0";
    // session.weekstart = "1";

    // ---- CARDS ----
    // session.return_page = "cards";
    // session.product_id = "3203"; // anouncement card portrait 2X5
    // // session.product_id = "3549"; // panoramic landscape 1X1
    // session.envelope = "light_blue";

    // ---- CANVAS ----
    // session.return_page="canvas";
    // // session.product_id = "518"; // portrait 100x150
    // // session.product_id = "599"; // Free format
    // session.product_id = "7616"; // kadapak Portrait 70*105
    // // for free
    // session.width = "134";
    // session.height = "46";
    // // rows (for multi canvas)
    // // cols (for multi canvas)
    // // gap (for multi canvas)
    // session.frame_color = "blue"; // (for kadapak)

    // canvas multiple
    session.classname = 'canvas';
    session.height = '66';
    session.id = 'io4lkjt8qrqusctj0dbeufe5cv';
    session.lang = 'fr';
    session.product_id = '7899';
    session.product_style = 'simple';
    session.server_name = 'editor.tictacphoto.com';
    session.user_id = 0;
    session.vendor = '1';
    session.vendor_url = 'www.tictacphoto.com';
    session.width = '100';
  }

  // Unset projectCreationParams if a project id is in the session
  const hasSessionProjectId = state.session.recent_project_id;

  // project creation params
  if (!hasSessionProjectId && session.product_id) {
    state.projectCreationParams = GetProjectCreationParamsFromSession(session);
  } else {
    state.projectCreationParams = null;
  }

  // session has been updated
  state.lastSessionCheck = Date.now();

  if (session.rebranding) {
    state.rebranding = session.rebranding;
  }
}

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

/*
export function requestLogin() {
  return { type: LOAD };
}

export function createWidget(widget) {
  return { type: CREATE, widget };
}

export function updateWidget(widget) {
  return { type: UPDATE, widget };
}

export function removeWidget(widget) {
  return { type: REMOVE, widget };
}
*/

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function checkCurrentSession() {
  return (dispatch) => {
    // be sure to remove loggedin cookie
    localStorage.removeItem('loggedIn');

    // update store by telling we are "requesting"
    dispatch(request());

    // once we start login/check session, we want to clear the whole resources so we force a reloading after login
    dispatch(clearUserResources());

    // call service
    API.checkSession().then(
      (session) => {
        dispatch(success(session)); // user is the same as session actualY.. TODO: maybe renamte this

        // rebranding hook
        if (session.rebranding) {
          document.title = session.rebranding.title;
          // force update theme
          document.documentElement.style.setProperty(
            `--primary_light`,
            session.rebranding.primary_light
          );
          document.documentElement.style.setProperty(
            `--primary`,
            session.rebranding.primary
          );
          document.documentElement.style.setProperty(
            `--selectionColor`,
            session.rebranding.selectionColor
          );
          document.documentElement.style.setProperty(
            `--selectionColor_text`,
            session.rebranding.selectionColor_text
          );
        } else {
          document.title = 'TicTacPhoto';
        }

        if (session.user_id === 0 && DebugFlags.AUTO_FILL_LOGIN) {
          dispatch(
            login(
              DebugFlags.AUTO_FILL_LOGIN.user,
              DebugFlags.AUTO_FILL_LOGIN.pass
            )
          );
        } else {
          dispatch(loadUserResources());
        } // load resources
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: CHECK_SESS_START };
  }
  function failure(error) {
    return { type: CHECK_SESS_FAIL, error };
  }
  function success(session) {
    return { type: CHECK_SESS_SUCCESS, session };
  }
}

function login(username, password) {
  return (dispatch) => {
    // update store by telling we are "requesting"
    dispatch(request({ username }));

    // once we start login, we want to clear the whole resources so we force a reloading after login
    dispatch(clearUserResources());

    // call service
    API.login(username, password).then(
      (session) => {
        dispatch(success(session));
        dispatch(loadUserResources());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(username) {
    return { type: LOGIN_REQUEST, username };
  }
  function success(session) {
    return { type: LOGIN_SUCCESS, session };
  }
  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
}

/**
 *
 * @param {Array<String>} languages
 * @param {string} spreadSheetID
 * @returns
 */
function loadLanguages(languages, spreadSheetID) {
  return (dispatch) => {
    dispatch({ type: LANGUAGE_LOADED, isLoaded: false });
    LoadLanguages(languages, spreadSheetID).then(() => {
      // ok language is loaded, notify the store
      dispatch({ type: LANGUAGE_LOADED, isLoaded: true });
    });
  };

  // TODO: should we handle an error in loading here?
}

/**
 * Clear user resources
 */
function clearUserResources() {
  return (dispatch) => {
    dispatch(photoListActions.clearPhotos());
    dispatch(layoutListActions.clearLayouts());
  };
}

/**
 * Load all needed resources
 */
function loadUserResources() {
  return (dispatch) => {
    // return ( dispatch, getState ) =>{

    // let projectClassname = editionSelectors.GetProjectClassName(getState());
    const projectClass = PROJECT_CONST.project_class;
    // TODO: maybe later, we would need to import layouts and backgrounds only when we know the project type
    // --- photos
    dispatch(photoListActions.getAll());
    // --- layouts
    dispatch(layoutListActions.getAll(projectClass));
    // --- backgrounds
    dispatch(backgroundActions.getAll(projectClass));
    // --- cliparts
    dispatch(clipartActions.getAll(projectClass));
    // --- overlayers
    dispatch(overlayerActions.getAll(projectClass));
    // --- pricing
    dispatch(pricingActions.GetAll());
  };
}

function register(username, password) {
  return (dispatch) => {
    // update store by telling we are "requesting"
    dispatch(request({ username }));

    // call service
    API.register(username, password).then(
      (session) => {
        dispatch(success(session));
        Analytic.TrackCustomEvent('register', 'register_success');
        history.push(ROUTE_CONST.SELECT_PROJECT_CLASS); // go to homepage!
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(username) {
    return { type: REGISTER_REQUEST, username };
  }
  function success(session) {
    return { type: REGISTER_SUCCESS, session };
  }
  function failure(error) {
    return { type: REGISTER_FAILURE, error };
  }
}

function logout() {
  /*
  return ( dispatch ) => {
    dispatch(editionActions.ClearProject())
    API.logout(); // notify api
    dispatch( return { type: LOGOUT }; // push action
    // remove user from local storage to log user out
    localStorage.removeItem('loggedIn');
    //ClearPhpSession();

  }
  */

  API.logout(); // notify api

  // remove user from local storage to log user out
  localStorage.removeItem('loggedIn');

  return { type: LOGOUT }; // push action
}

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

const ResourcesAreLoading = createSelector(
  (state) => state.photos.isLoading,
  (state) => state.layouts.isLoading,
  (state) => state.backgrounds.isLoading,
  (state) => state.cliparts.isLoading,
  (state) => state.overlayers.isLoading,
  (
    photosAreLoading,
    layoutsAreLoading,
    backgroundsAreLoading,
    clipartsAreLoading,
    overlayersAreLoading
  ) =>
    photosAreLoading ||
    layoutsAreLoading ||
    backgroundsAreLoading ||
    clipartsAreLoading ||
    overlayersAreLoading
);

const ResourcesAreLoaded = createSelector(
  // state => !isEmpty(state.photos.backendPhotosByID),
  (state) => state.photos && state.photos.isLoaded, //
  (state) => !isEmpty(layoutListSelectors.getLayoutsByID(state)),
  (state) => !isEmpty(state.backgrounds.byID) || IsCanvasEditor(), // no backgrouds for canvas
  (state) => !isEmpty(state.cliparts.byID),
  (state) => !isEmpty(overlayerSelectors.getAllOverlayersByID(state)),
  (state) => state.authentication.languageLoaded,
  (state) => pricingSelectors.IsPricingLoaded(state),
  (
    photosAreLoaded,
    layoutsAreLoaded,
    backgroundsAreLoaded,
    clipartsAreLoaded,
    overlayersAreLoaded,
    languageLoaded,
    pricingLoaded
  ) =>
    photosAreLoaded &&
    layoutsAreLoaded &&
    backgroundsAreLoaded &&
    clipartsAreLoaded &&
    overlayersAreLoaded &&
    languageLoaded &&
    pricingLoaded
);

const GetSession = (state: RootState) => state.authentication.session;

const NeedSessionCheck = createSelector(
  (state) => state.authentication.lastSessionCheck,
  (state) => photoListSelector.hasPhotosStillImporting(state),
  () => Date.now(),
  (lastSessionDate, photosStillImporting, now) => {
    const elapsedTime = now - lastSessionDate;
    return !photosStillImporting && elapsedTime > 6 * 60 * 60 * 1000; // 6H without check session?
  }
);

const IsLoggedIn = (state: RootState) => state.authentication.loggedIn;

const GetUserID = createSelector(
  (state) => state.authentication.user,
  (user) => (user ? user.id : null)
);

const GetUserEmail = createSelector(
  (state) => state.authentication.user,
  (user) => (user ? user.email : null)
);

const GetSessionID = createSelector(
  (state) => state.authentication.session,
  (session) => (session ? session.id : null)
);

const GetVendorID = createSelector(
  (state) => state.authentication.session,
  (session) => (session ? (session.vendor ? session.vendor : '1') : '1')
);

const GetProjectCreationParams = (state) =>
  state.authentication.projectCreationParams;

const GetRebranding = (state: RootState) => state.authentication.rebranding;

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export default reducer;

export const authSelectors = {
  IsLoggedIn,
  GetSession,
  NeedSessionCheck,
  GetSessionID,
  GetUserEmail,
  GetUser: (state: RootState) => state.authentication.user,
  GetUserID,
  GetVendorID,
  GetProjectCreationParams,
  ResourcesAreLoading,
  ResourcesAreLoaded,
  GetRebranding,
};

export const authActions = {
  checkCurrentSession,
  loadLanguages,
  login,
  register,
  logout,
};
