import { LayoutStore } from '../../store/store.type';
import { Project } from '../../types/project';
import { IPage } from '../../types/types';
import { RandomArrayElement } from '../../utils/MathUtils';
import { CreatePage } from '../../utils/pageHelper';
import { GetDoc } from '../../utils/ProductHelper';
import { isLayflat, mergeLayflatPageList } from '../layflat/layflatHelpers';
import { applyLayoutToPage } from '../layouts/helpers/applyLayoutToPage';
import { LAYOUT_TYPE } from '../layouts/layout.type';
import {
  cleanLayoutBeyondEdgeFrames,
  GetForcedLayoutsForPage,
} from '../layouts/layoutHelper';

/**
 * Create a new pages for the project,
 * NOTE: this do not change the project
 */
export function CreateProjectPages(
  project: Project,
  numPages: number,
  pagesStartIndex: number,
  layoutStore: LayoutStore
): IPage[] {
  const newPages = [];
  const layoutList = layoutStore.filtered[LAYOUT_TYPE.DEFAULT].all;
  const layoutsByID = layoutStore.byID;
  const isLayflatProject = isLayflat(project.docID);

  const doc = GetDoc(project.docID);
  // default layout list for this docID
  const defaultLayoutList = doc.layouts_default;

  // create pages
  for (
    let index = pagesStartIndex;
    index < pagesStartIndex + numPages;
    index += 1
  ) {
    const page = CreatePage(index, project.width, project.height);

    let newLayout = null;

    // check if we have a default page for this index
    // if(defaultLayoutList && defaultLayoutList.length > index && defaultLayoutList[index]) // Before, if there were non element at index in default, we would go back to random list, now we loop..
    if (defaultLayoutList && defaultLayoutList.length > 0) {
      //
      newLayout =
        layoutsByID[defaultLayoutList[index % defaultLayoutList.length]];
    } else {
      // list of available layout for this docID
      const forcedLayoutList = GetForcedLayoutsForPage(project.docID, index);
      newLayout =
        layoutsByID[RandomArrayElement(forcedLayoutList || layoutList)];
    }

    // in case of layflats, we need to be sure layouts do not have frames that go beyond the page bounds (to avoid overlap on merged pages)
    if (isLayflatProject) {
      newLayout = cleanLayoutBeyondEdgeFrames(newLayout);
    }

    applyLayoutToPage(project, page, newLayout, null, null);
    newPages.push(page);
  }

  // if layflat, we merge layflat pages
  if (isLayflatProject)
    return mergeLayflatPageList(newPages, pagesStartIndex === 1);

  // otherwise we return pages as is
  return newPages;
}
