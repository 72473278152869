import { Component } from 'react';
import { CirclePicker } from 'react-color';
import { batch, connect } from 'react-redux';
import { Button, Popover } from 'antd';
import { cloneDeep } from 'lodash';
import { Colors } from '../../../data/Colors';
import { GetText } from '../../../data/LanguageHelper';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { ProjectOptions } from '../../../types/project';
import type { ICalendarColorOptions } from '../../../types/types';

/**
 * Photo item
 */
class CalendarColorsModifierPopover extends Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {};
  }

  // --------------------- react ------------------------

  // componentDidUpdate(nextProps, nextState) {
  //     if (nextProps.pageList.length !==this.props.pageList.length ) {
  //       this.setState({
  //           numPages :this.props.pageList.length-1
  //       })
  //     }
  //   }

  // --------------------- Methods ------------------------

  handleMonthColorChange = (newColorValue) => {
    this.updateCurrentPageColor({ monthAndYearColor: newColorValue.hex });
  };

  handleYearColorChange = (newColorValue) => {
    this.updateCurrentPageColor({ firstPageYearColor: newColorValue.hex });
  };

  handleStrokeColorChange = (newColorValue) => {
    this.updateCurrentPageColor({ strokeColor: newColorValue.hex });
  };

  handleDayColorChange = (newColorValue) => {
    this.updateCurrentPageColor({ dayColor: newColorValue.hex });
  };

  handleWeekendColorChange = (newColorValue) => {
    this.updateCurrentPageColor({ weekendColor: newColorValue.hex });
  };

  handleOffsetColorChange = (newColorValue) => {
    this.updateCurrentPageColor({ prevNextMonthColor: newColorValue.hex });
  };

  // ---- Retreive curent page colors ----
  getCurrentPageColorOptions = () => {
    const { calendarColors, currentPage } = this.props;
    // global colors
    let colorOptions: ICalendarColorOptions = cloneDeep(
      this.props.calendarColors
    );
    // if page already has color options, we use those
    if (currentPage.calendarColorOptions)
      colorOptions = cloneDeep(currentPage.calendarColorOptions);
    return colorOptions;
  };

  // ---- Update current page option ----
  updateCurrentPageColor = (newValues) => {
    let calendarOptions = this.getCurrentPageColorOptions();
    // merge
    calendarOptions = { ...calendarOptions, ...newValues };
    // get page
    const pageUpdated = cloneDeep(this.props.currentPage);
    // update page options
    pageUpdated.calendarColorOptions = calendarOptions;
    // save chages
    this.props.dispatch(editionActions.UpdatePage(pageUpdated));
  };

  // ---- Update all pages ----
  updateAllPagesColors = () => {
    const calendarOptions = this.getCurrentPageColorOptions();
    // retrieve project options
    const newProjectOptions: ProjectOptions = cloneDeep(
      this.props.projectOptions
    );
    // update projectOptions
    newProjectOptions.calendarColorOptions = calendarOptions;
    // save changes
    batch(() => {
      // clear existing "per pages" calendar color options
      this.props.dispatch(editionActions.ClearPerPageCalendarOptions());
      // update project options
      this.props.dispatch(
        editionActions.UpdateProjectOptions(newProjectOptions)
      );
    });
  };

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////
  render() {
    const colorOptions: ICalendarColorOptions =
      this.getCurrentPageColorOptions();

    const colorsArr = [
      {
        ref: colorOptions.monthAndYearColor,
        label: 'edition.toolbar.calendar.color.label.month',
        handler: this.handleMonthColorChange,
      },
      {
        ref: colorOptions.firstPageYearColor,
        label: 'edition.toolbar.calendar.color.label.year',
        handler: this.handleYearColorChange,
      },
      {
        ref: colorOptions.strokeColor,
        label: 'edition.toolbar.calendar.color.label.border',
        handler: this.handleStrokeColorChange,
      },
      {
        ref: colorOptions.dayColor,
        label: 'edition.toolbar.calendar.color.label.weekDay',
        handler: this.handleDayColorChange,
      },
      {
        ref: colorOptions.weekendColor,
        label: 'edition.toolbar.calendar.color.label.weekEndDay',
        handler: this.handleWeekendColorChange,
      },
      {
        ref: colorOptions.prevNextMonthColor,
        label: 'edition.toolbar.calendar.color.label.offSetDay',
        handler: this.handleOffsetColorChange,
      },
    ];

    return (
      <Popover
        trigger="click"
        content={
          <div // className="pic"
            className=""
          >
            <h4>{GetText('edition.toolbar.calendar.color.title')}</h4>

            {colorsArr.map((value, index, arr) => (
              <Popover
                key={value.label}
                content={
                  <CirclePicker
                    width={230}
                    circleSpacing={5}
                    circleSize={15}
                    colors={Colors.displayColors}
                    color={value.ref}
                    onChange={value.handler}
                  />
                }
                title="Change Color"
                trigger="focus"
              >
                <div
                  style={{
                    paddingBottom: '6px',
                  }}
                >
                  <Button
                    type="default"
                    // icon="bg-colors"
                    className="button"
                    onClick={(e) => e.target.focus()}
                    style={{
                      backgroundColor: value.ref,
                      // Due to antd change empty buttons are hidden
                      visibility: 'visible',
                    }}
                  />
                  <span
                    style={{
                      // height:"100%",
                      fontSize: '80%',
                      // verticalAlign:"middle",
                      paddingLeft: '10px',
                      paddingTop: '20px',
                    }}
                  >
                    {GetText(value.label)}
                  </span>
                </div>
              </Popover>
            ))}
            <div style={{ paddingTop: '10px' }}>
              <Button
                type="primary"
                onClick={() => {
                  this.updateAllPagesColors();
                }}
              >
                {GetText('lefttab.backgrounds.applytoall')}
              </Button>
            </div>
          </div>
        }
      >
        {this.props.children}
      </Popover>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  const { project } = state.edition;

  return {
    docID: project.docID,
    calendarColors: project.calendarColorOptions,
    currentPage: editionSelectors.GetSelectedPage(state),
    projectOptions: editionSelectors.GetProjectOptionsSelector(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(CalendarColorsModifierPopover);
export { reduxConnected as CalendarColorsModifierPopover };
