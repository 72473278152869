import {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { check } from 'prettier';
import { on } from 'stream';
import ImgIcon from '../../../_components/ImgIcon';
import { DebugFlags } from '../../../debug/DebugFlags';
import { FRAME_TYPE } from '../../../feature/frame/frame.types';
import {
  frameCanCrop,
  frameCanInsideMove,
  frameCanMove,
  frameCanRotate,
  frameCanScale,
} from '../../../feature/frame/frameHelper';
import { EmbedIcons } from '../../../images/EmbedIcons';
import {
  Frame,
  IPage,
  Photo,
  Point,
  SnappingLines,
} from '../../../types/types';
import {
  DistanceBetweenPoints,
  MidPointsBetweenPoints,
  rotatePointAround,
  SnapNumberTo,
  SnapNumberToRange,
} from '../../../utils/MathUtils';
import { mmToPoint, pixelToCm } from '../../../utils/MeasureUtils';

export enum TRANFORM_TOOL_ACTION {
  MOVE = 'MOVE',
  ROTATE = 'ROTATE',
  SCALE = 'SCALE',
  CROP = 'CROP',
  INSIDE_MOVE = 'INSIDE_MOVE',
  ZOOM = 'ZOOM',
}

enum DRAG_POINT {
  TOP_LEFT = 'tl',
  TOP_RIGHT = 'tr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_RIGHT = 'br',

  TOP = 't',
  RIGHT = 'r',
  BOTTOM = 'b',
  LEFT = 'l',
}

type Props = {
  pagePosX: number;
  pagePosY: number;
  editedFrame: Frame;
  editedPage: IPage;
  editionScale: number;

  snappingLines: SnappingLines;

  // callbacks
  getPhotosById: (photoId: string) => Photo;
  onDoubleClick: (frame: Frame) => void;
  onFrameUpdateStart: (newFrameState: Frame, editAction: string) => void;
  onFrameUpdate: (newFrameState: Frame, refreshContent?: boolean) => void;
  onFrameUpdateCompleted: (newFrameState: Frame) => void;
  onItemDropped: (dropEvent: Event) => void;
};

type StateType = {
  textEditing: boolean;
  action: TRANFORM_TOOL_ACTION | undefined;

  startTransform: Frame; // original transformation
  dragDetail: {
    startX?: number; // start drag pos
    startY?: number;
    dragPoint?: DRAG_POINT;
    startPoint?: Point;
    oppositePoint?: Point;
    scaleFactor?: number;
  };

  transformOrigin: string; // "50% 50%"
};

export type TransformToolRef = {
  transformTool: HTMLDivElement;
  handleMoveStart: (e: MouseEvent) => void;
};

const TransformTool = forwardRef<TransformToolRef, Props>(
  (
    {
      editedFrame,
      editedPage,
      editionScale,
      pagePosX,
      pagePosY,
      onFrameUpdateStart,
      onFrameUpdate,
      onFrameUpdateCompleted,
      onItemDropped,
      onDoubleClick,
      getPhotosById,
      snappingLines,
    }: Props,
    ref
  ) => {
    // TODO: all content should be centered so origin should not be at 0,0 but at 50%,50%
    // this would allow us to follow same conventions as current online and offline editor.

    // refs
    // explanation : https://stackoverflow.com/questions/65876809/property-current-does-not-exist-on-type-instance-htmldivelement-null
    // const transformRef = createRef<HTMLDivElement>();
    // const transformRef = ref.transformToolRef;
    // // const transformRef = useRef<HTMLDivElement>(null);
    // useImperativeHandle(ref.handleMoveStartRef, () => ({ handleMoveStart }));

    const transformRef = createRef<HTMLDivElement>();

    // UPDATE 2024 : we need to scale the UI to have a better user experience
    const uiTransform = { transform: `scale(${1 / editionScale})` };

    useImperativeHandle(ref, () => ({
      transformTool: transformRef.current!,
      handleMoveStart: handleMoveStart,
    }));

    // useImperativeHandle(ref, () => transformRef.current!, []);

    const frame = editedFrame;

    // corner refs
    const cornerRefs = {};
    cornerRefs[DRAG_POINT.TOP_LEFT] = createRef();
    cornerRefs[DRAG_POINT.TOP_RIGHT] = createRef();
    cornerRefs[DRAG_POINT.BOTTOM_LEFT] = createRef();
    cornerRefs[DRAG_POINT.BOTTOM_RIGHT] = createRef();

    cornerRefs[DRAG_POINT.TOP] = createRef();
    cornerRefs[DRAG_POINT.RIGHT] = createRef();
    cornerRefs[DRAG_POINT.BOTTOM] = createRef();
    cornerRefs[DRAG_POINT.LEFT] = createRef();

    // state
    // const startTransform = { ...frame }; // make copy of transform, otherwise it is just a ref we are using and will be updated each time we update the frame...

    // initial state
    const [state, setState] = useState<StateType>({
      textEditing: editedFrame.type === FRAME_TYPE.TEXT, // if we are editing some text
      action: undefined,
      startTransform: { ...editedFrame }, // original transformation
      dragDetail: {
        startX: 0, // start drag pos
        startY: 0,
      },
      transformOrigin: '50% 50%',
    });

    const { transformOrigin, action } = state;
    const moving = state?.action === TRANFORM_TOOL_ACTION.MOVE;
    const rotating = state?.action === TRANFORM_TOOL_ACTION.ROTATE;
    const scaling = state?.action === TRANFORM_TOOL_ACTION.SCALE;
    const cropping = state?.action === TRANFORM_TOOL_ACTION.CROP;
    const insideMoving = state?.action === TRANFORM_TOOL_ACTION.INSIDE_MOVE;

    // --------------------- Methods ------------------------

    const calculatePosX = () => {
      const theFrame = frame;
      return theFrame.x;
    };

    const calculatePosY = () => {
      const theFrame = frame;
      return theFrame.y;
    };

    // ********************************
    // Handle Move
    // ********************************

    /**
     * Handle Move Start
     */
    const handleMoveStart = ({ clientX, clientY }) => {
      // security
      checkEndCurrentAction(TRANFORM_TOOL_ACTION.MOVE);
      setState({
        ...state,
        action: TRANFORM_TOOL_ACTION.MOVE,
        startTransform: { ...frame }, // make a copy of edited frame
        dragDetail: {
          startX: clientX,
          startY: clientY,
        },
      });

      onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.MOVE);
    };

    const handleMoveUpdate = ({ clientX, clientY }) => {
      const t: Frame = state.startTransform;

      // calculate position
      let newX = t.x + (clientX - state.dragDetail.startX) / editionScale;
      let newY = t.y + (clientY - state.dragDetail.startY) / editionScale;

      // snap x to origin points
      if (snappingLines) {
        const snapTreshold = mmToPoint(2); // 5mm was too much.. (2 => range of 4mm to snap)
        snappingLines.h.forEach((posY) => {
          newY = SnapNumberTo(newY, posY, snapTreshold);
          if (t.rotation === 0) {
            newY = SnapNumberTo(newY + t.height * 0.5, posY, snapTreshold);
            newY = SnapNumberTo(newY - t.height * 0.5, posY, snapTreshold);
          }
        });
        snappingLines.v.forEach((posX) => {
          newX = SnapNumberTo(newX, posX, snapTreshold);
          if (t.rotation === 0) {
            newX = SnapNumberTo(newX + t.width * 0.5, posX, snapTreshold);
            newX = SnapNumberTo(newX - t.width * 0.5, posX, snapTreshold);
          }
        });
      }
      // newX = SnapNumberTo(newX, t.x, 5);
      // newY = SnapNumberTo(newY, t.y, 5);

      // we can directly modify the frame from props
      frame.x = newX;
      frame.y = newY;
      onFrameUpdate(frame);

      // let updatedFrame = {...frame};
      // updatedFrame.x = newX;
      // updatedFrame.y = newY;

      // // TODO: should we prevent default?
      // //e.preventDefault();
      // setState(prevState => ({
      //     ...prevState,
      //     moving:true, // still dragging
      // }),

      // // on state updated callback
      // ()=>{
      //     // nofity parent
      //     // onFrameUpdate( frame );
      //     onFrameUpdate( updatedFrame );
      //    // console.log("frame moved to: " + frame.transform.x + " - " + frame.transform.y);
      // });
    };

    // const handleMoveEnd = ({ clientX, clientY }) => {
    //   endAction();
    //   onFrameUpdateCompleted(frame);
    // };

    // ********************************
    // Handle rotation
    // ********************************

    const handleRotateStart = ({ clientX, clientY }) => {
      console.log(`start rotating:${clientX}`);

      // security
      checkEndCurrentAction(TRANFORM_TOOL_ACTION.ROTATE);
      const bounds = transformRef.current.getBoundingClientRect();
      // let c = t.current;
      // let b = c.getBoundingClientRect();
      // //let bounds = transformRef.current.getBoundingClientRect();
      // console.log(b);

      // these are relative to the viewport
      /*
        var rotatingCenter = viewportOffset.top;
        var left = viewportOffset.left;

        var rotatingCenter
        */

      // e.preventDefault();
      setState((current) => ({
        ...current,
        action: TRANFORM_TOOL_ACTION.ROTATE,
        startTransform: { ...frame }, // make a copy, not a ref.
        dragDetail: {
          startX: bounds.x + bounds.width / 2,
          startY: bounds.y + bounds.height / 2,
        },
      }));

      onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.ROTATE);
    };

    const handleRotateUpdate = ({ clientX, clientY }) => {
      const p2 = { x: clientX, y: clientY };
      const p1 = {
        x: state.dragDetail.startX,
        y: state.dragDetail.startY,
      };

      // angle in radians
      const angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
      // angle in degrees
      const angleDeg = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
      // const newAngle = (startTransform.angle + angleDeg) % 360  + 90 ; // -90 because the rotation icon is at 90°
      let newAngle = angleDeg + 90; // -90 because the rotation icon is at 90°

      // snap angles 45°
      newAngle = SnapNumberToRange(newAngle, 45, 5);
      newAngle = SnapNumberTo(newAngle, 0, 5);

      // direct modify frame
      frame.rotation = newAngle;
      onFrameUpdate(frame);
    };

    // ********************************
    // Handle scale
    // ********************************

    // handleScaleStart = ( dragPoint, {clientX, clientY}) =>
    const handleScaleStart = (dragPoint, e) => {
      console.log(`----> scale START`);
      const { clientX, clientY } = e;

      console.log(`HandleScaleStart( ${dragPoint} ) :${clientX}`);

      // security
      checkEndCurrentAction(TRANFORM_TOOL_ACTION.SCALE);

      // get client corner points and scale factor!
      const startPointRect = e.currentTarget.getBoundingClientRect();
      const startPoint = {
        x: startPointRect.left + startPointRect.width / 2,
        y: startPointRect.top + startPointRect.height / 2,
      };
      const oppositeRef =
        dragPoint === DRAG_POINT.TOP_LEFT
          ? cornerRefs[DRAG_POINT.BOTTOM_RIGHT]
          : dragPoint === DRAG_POINT.TOP_RIGHT
            ? cornerRefs[DRAG_POINT.BOTTOM_LEFT]
            : dragPoint === DRAG_POINT.BOTTOM_LEFT
              ? cornerRefs[DRAG_POINT.TOP_RIGHT]
              : cornerRefs[DRAG_POINT.TOP_LEFT];

      const oppositePointRect = oppositeRef.current.getBoundingClientRect();
      const oppositePoint = {
        x: oppositePointRect.left + oppositePointRect.width / 2,
        y: oppositePointRect.top + oppositePointRect.height / 2,
      };

      const distanceTool = DistanceBetweenPoints(
        startPoint.x,
        startPoint.y,
        oppositePoint.x,
        oppositePoint.y
      );
      const distanceFrame = Math.hypot(frame.width, frame.height);
      const scaleFactor = distanceTool / distanceFrame;
      console.log(`scaleFactor:${scaleFactor} --> distance: ${distanceTool}`);

      // e.preventDefault();
      setState((current) => ({
        ...current,
        action: TRANFORM_TOOL_ACTION.SCALE,
        startTransform: { ...frame }, // make a copy, not a ref.
        dragDetail: {
          scaleFactor,
          startPoint,
          oppositePoint,
        },
      }));

      onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.SCALE);
    };

    const handleScaleUpdate = ({ clientX, clientY }) => {
      console.log(`----> scale UPDATE`);
      const startFrame: Frame = state.startTransform;
      const { startPoint, scaleFactor, oppositePoint } = state.dragDetail;

      // to get correct new width and height for a rotated object,
      // we need to make some matrix calculation and get back with an angle of zero
      let zeroRotationPoint = rotatePointAround(
        clientX,
        clientY,
        oppositePoint.x,
        oppositePoint.y,
        -startFrame.rotation
      );

      /// /////////////////////////////////////////////////////////////
      // Keep ratio?
      /// /////////////////////////////////////////////////////////////
      const keepRatio = !frame.text; //frame.overlayer || frame.clipart ;
      if (keepRatio) {
        let ratioW = (zeroRotationPoint.x - oppositePoint.x) / startFrame.width;
        let ratioH =
          (zeroRotationPoint.y - oppositePoint.y) / startFrame.height;
        // keep biggest ratio as reference
        const useRatio =
          Math.abs(ratioW) > Math.abs(ratioH)
            ? Math.abs(ratioW)
            : Math.abs(ratioH);
        // update ratios by keeping negative vs positive
        ratioW = (ratioW / Math.abs(ratioW)) * useRatio;
        ratioH = (ratioH / Math.abs(ratioH)) * useRatio;
        // update zero point position
        zeroRotationPoint = {
          x: oppositePoint.x + ratioW * startFrame.width,
          y: oppositePoint.y + ratioH * startFrame.height,
        };
        // rotate back to update clientx and clienty for later positioning
        const newRotatedPoint = rotatePointAround(
          zeroRotationPoint.x,
          zeroRotationPoint.y,
          oppositePoint.x,
          oppositePoint.y,
          startFrame.rotation
        );
        clientX = newRotatedPoint.x;
        clientY = newRotatedPoint.y;
      }
      /// /////////////////////////////////////////////////////////////

      // calculate new width
      const newWidth =
        Math.abs(oppositePoint.x - zeroRotationPoint.x) / scaleFactor;
      const newHeight =
        Math.abs(oppositePoint.y - zeroRotationPoint.y) / scaleFactor;

      // now find the correct pos x and y
      const centerPoint = MidPointsBetweenPoints(
        oppositePoint.x,
        oppositePoint.y,
        startPoint.x,
        startPoint.y
      );
      const newMidPoint = MidPointsBetweenPoints(
        oppositePoint.x,
        oppositePoint.y,
        clientX,
        clientY
      );
      const xDiff = centerPoint.x - newMidPoint.x;
      const yDiff = centerPoint.y - newMidPoint.y;

      const newX = startFrame.x - xDiff / scaleFactor;
      const newY = startFrame.y - yDiff / scaleFactor;

      // added to have a nice smooth system for scaling
      if (frame.photo) {
        // ---- USING SCALING ----
        // EDIT results are not really great.. better to use a crop system also than a scale system..
        const scaleX = newWidth / startFrame.width;
        const scaleY = newHeight / startFrame.height;
        let newZoomValue =
          startFrame.zoom * (scaleX > scaleY ? scaleX : scaleY);

        // security, to avoid issue with the slider (that has a 2 max limit for zoom)
        if (newZoomValue > 2) {
          newZoomValue = 2;
        }
        const pic: Photo = getPhotosById(frame.photo);
        frame.zoom = newZoomValue;

        // try to keep crop?
        // const diffX = (pic.width*startFrame.zoom-startFrame.width);
        // const diffY = pic.height*newZoomValue - pic.height*startFrame.zoom;
        // frame.cLeft = startFrame.cLeft - diffX/2;
        // frame.cTop = startFrame.cTop - diffY/2;

        // we need to keep center point!
        const startCenterX =
          (startFrame.cLeft + startFrame.width / 2) / startFrame.zoom;
        const startCenterY =
          (startFrame.cTop + startFrame.height / 2) / startFrame.zoom;
        frame.cLeft = startCenterX * newZoomValue - newWidth / 2;
        frame.cTop = startCenterY * newZoomValue - newHeight / 2;

        // ---- using simple crops ----
        // // always put back the start frame options, the system will correct it when using "updateFrame"
        // frame.zoom = startFrame.zoom;
        // frame.cLeft = startFrame.cLeft;
        // frame.cTop = startFrame.cTop;
      }

      // direct modify frame
      frame.width = newWidth;
      frame.height = newHeight;
      frame.x = newX;
      frame.y = newY;

      // use the "true" value to force limit check of the "injectIntoFrame"
      onFrameUpdate(frame, true);
    };

    /**
     * handle drag and drop events on this
     */
    const handleDragOver = (event) => {
      event.preventDefault(); // prevent default to allow drop
    };

    const handleItemDrop = (dropEvent) => {
      onItemDropped(dropEvent);
    };

    // --------------------- HANDLE CROP ------------------------

    const handleCropStart = (dragPoint, e) => {
      console.log(`HandleCropStart( ${dragPoint} )`);

      // security
      checkEndCurrentAction(TRANFORM_TOOL_ACTION.CROP);

      // get client corner points and scale factor!
      const startPointRect = e.currentTarget.getBoundingClientRect();
      const startPoint = {
        x: startPointRect.left + startPointRect.width / 2,
        y: startPointRect.top + startPointRect.height / 2,
      };
      const oppositeRef =
        dragPoint === DRAG_POINT.TOP
          ? cornerRefs[DRAG_POINT.BOTTOM]
          : dragPoint === DRAG_POINT.LEFT
            ? cornerRefs[DRAG_POINT.RIGHT]
            : dragPoint === DRAG_POINT.BOTTOM
              ? cornerRefs[DRAG_POINT.TOP]
              : cornerRefs[DRAG_POINT.LEFT];

      // setState({transformOrigin:"0% 50%"});

      const oppositePointRect = oppositeRef.current.getBoundingClientRect();
      const oppositePoint = {
        x: oppositePointRect.left + oppositePointRect.width / 2,
        y: oppositePointRect.top + oppositePointRect.height / 2,
      };

      // get the scale factor between client and frame
      const distanceTool = DistanceBetweenPoints(
        startPoint.x,
        startPoint.y,
        oppositePoint.x,
        oppositePoint.y
      );
      // let distanceFrame = Math.hypot( frame.width, frame.height );
      const distanceFrame =
        dragPoint === DRAG_POINT.LEFT || dragPoint === DRAG_POINT.RIGHT
          ? frame.width
          : frame.height;
      const scaleFactor = distanceTool / distanceFrame;

      // e.preventDefault();
      setState((current) => ({
        ...current,
        action: TRANFORM_TOOL_ACTION.CROP,
        startTransform: { ...frame }, // make a copy, not a ref.
        dragDetail: {
          dragPoint,
          scaleFactor,
          startPoint,
          oppositePoint,
        },
      }));

      onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.CROP);
    };

    const handleCropUpdate = ({ clientX, clientY }) => {
      const startFrame: Frame = state.startTransform;
      const { startPoint, scaleFactor, oppositePoint, dragPoint } =
        state.dragDetail;

      // to get correct new width and height for a rotated object,
      // we need to make some matrix calculation and get back with an angle of zero
      // let zeroRotOppositePoint = rotatePointAround(clientX, clientY,oppositePoint.x, oppositePoint.y, -startFrame.rotation);
      const zeroRotClientPoint = rotatePointAround(
        clientX,
        clientY,
        oppositePoint.x,
        oppositePoint.y,
        -startFrame.rotation
      );

      const cropWidth =
        dragPoint === DRAG_POINT.LEFT || dragPoint === DRAG_POINT.RIGHT;
      const newWidth = cropWidth
        ? Math.abs(oppositePoint.x - zeroRotClientPoint.x)
        : startFrame.width * scaleFactor;
      const newHeight = cropWidth
        ? startFrame.height * scaleFactor
        : Math.abs(oppositePoint.y - zeroRotClientPoint.y);
      // console.log(`width:${newWidth} and height:${newHeight}`);

      // we don't want to scale the frame, we want to crop it
      frame.width = newWidth / scaleFactor;
      frame.height = newHeight / scaleFactor;

      // check max width / height
      if (frame.photo) {
        const photo = getPhotosById(frame.photo);
        if (!photo) {
          // NOTE this happens when swithcing from temp photo to photo after an upload success..
          // console.warn('No photo found for frame', frame);
        } else {
          // UPDATE 2024
          // we reset the origin zoom, cleft and ctop during crop so the crop system is reset to origin values when cropping back
          frame.zoom = startFrame.zoom;
          frame.cLeft = startFrame.cLeft;
          frame.cTop = startFrame.cTop;

          // check max size (as we don't want to zoom anymore on the image using the crop system)
          const maxWidth = photo.width * frame.zoom;
          const maxHeight = photo.height * frame.zoom;

          // Security to avoid scaling on crop
          // See https://app.clickup.com/t/86bxvw1j5
          // UPDATE 2025: manu wants again that we can crop and scale the image at the same time, so we comment this part
          // if (frame.width > maxWidth) {
          //   frame.width = maxWidth;
          //   newWidth = maxWidth * scaleFactor;
          // }
          // if (frame.height > maxHeight) {
          //   frame.height = maxHeight;
          //   newHeight = maxHeight * scaleFactor;
          // }

          // UPDATE 2024
          // case bigger than image, we zoom on image
          if (frame.width > maxWidth) {
            frame.zoom = frame.width / photo.width;
            const zoomDiff = startFrame.zoom / frame.zoom;
            frame.cTop = frame.cTop / zoomDiff;
          }
          if (frame.height > maxHeight) {
            frame.zoom = frame.height / photo.height;
            const zoomDiff = startFrame.zoom / frame.zoom;
            frame.cLeft = frame.cLeft / zoomDiff;
          }

          // check crop values
          const maxLeft = photo.width * frame.zoom - frame.width;
          const maxTop = photo.height * frame.zoom - frame.height;
          const diffX = frame.width - startFrame.width;
          const diffY = frame.height - startFrame.height;

          if (dragPoint === DRAG_POINT.LEFT) {
            frame.cLeft = startFrame.cLeft - diffX;
          }
          if (frame.cLeft > maxLeft) frame.cLeft = maxLeft;
          if (frame.cLeft < 0) frame.cLeft = 0;

          if (dragPoint === DRAG_POINT.TOP) {
            frame.cTop = startFrame.cTop - diffY;
          }
          if (frame.cTop > maxTop) frame.cTop = maxTop;
          if (frame.cTop < 0) frame.cTop = 0;
        }
      }

      const projectedClientPoint = cropWidth
        ? rotatePointAround(
            oppositePoint.x +
              (dragPoint === DRAG_POINT.LEFT ? -newWidth : newWidth),
            oppositePoint.y,
            oppositePoint.x,
            oppositePoint.y,
            startFrame.rotation
          )
        : rotatePointAround(
            oppositePoint.x,
            oppositePoint.y +
              (dragPoint === DRAG_POINT.TOP ? -newHeight : newHeight),
            oppositePoint.x,
            oppositePoint.y,
            startFrame.rotation
          );

      // now find the correct pos x and y
      const centerPoint = MidPointsBetweenPoints(
        oppositePoint.x,
        oppositePoint.y,
        startPoint.x,
        startPoint.y
      );
      const newMidPoint = MidPointsBetweenPoints(
        oppositePoint.x,
        oppositePoint.y,
        projectedClientPoint.x,
        projectedClientPoint.y
      );
      // let newMidPoint = MidPointsBetweenPoints( oppositePoint.x, oppositePoint.y, clientX, clientY);
      const xDiff = centerPoint.x - newMidPoint.x;
      const yDiff = centerPoint.y - newMidPoint.y;

      const newX = startFrame.x - xDiff / scaleFactor;
      const newY = startFrame.y - yDiff / scaleFactor;

      frame.x = newX;
      frame.y = newY;
      onFrameUpdate(frame, false);
    };

    const checkEndCurrentAction = (newAction: TRANFORM_TOOL_ACTION) => {
      if (newAction && action && newAction !== action) {
        console.warn(
          `Starting new action '${newAction}' but '${action}' action still pending`
        );
        endAction();
      }
    };

    /** **********************************
    // Inside Move
    ************************************ */

    const handleInsideMoveStart = ({ clientX, clientY }) => {
      console.log(`handkeInsideMoveStart:${clientX}`);

      // security
      checkEndCurrentAction(TRANFORM_TOOL_ACTION.INSIDE_MOVE);

      // [TODO:] little hack for now as when swithhing from photo to background..
      if (!frame.photo) return;

      // e.preventDefault();
      setState((current) => ({
        ...current,
        action: TRANFORM_TOOL_ACTION.INSIDE_MOVE,
        startTransform: { ...frame }, // make a copy of frame
        dragDetail: {
          startX: clientX,
          startY: clientY,
        },
      }));

      onFrameUpdateStart(frame, TRANFORM_TOOL_ACTION.INSIDE_MOVE);
    };

    const handleInsideMoveUpdate = ({ clientX, clientY }) => {
      const t: Frame = state.startTransform;

      // calculate position
      const diffX = (clientX - state.dragDetail.startX) / editionScale;
      const diffY = (clientY - state.dragDetail.startY) / editionScale;

      // snap x to origin points
      // newX = SnapNumberTo(newX, t.x, 5);
      // newY = SnapNumberTo(newY, t.y, 5);

      // we can directly modify the frame from props
      frame.cLeft = t.cLeft - diffX;
      frame.cTop = t.cTop - diffY;

      // verify ctop & cleft
      // TODO: put this verification in a helper! and check everywhere
      if (frame.cLeft < 0) frame.cLeft = 0;
      if (frame.cTop < 0) frame.cTop = 0;
      const photo: Photo = getPhotosById(frame.photo);
      if (photo) {
        const maxLeft = photo.width * frame.zoom - frame.width;
        const maxTop = photo.height * frame.zoom - frame.height;
        if (frame.cLeft > maxLeft) frame.cLeft = maxLeft;
        if (frame.cTop > maxTop) frame.cTop = maxTop;
      } else {
        // console.warn("No more photo here...");
      }

      onFrameUpdate(frame);
    };

    // --------------------- mouse move and mouse up actions ----------------------------

    const handleMouseMoveAction = useCallback(
      (e) => {
        if (action === TRANFORM_TOOL_ACTION.MOVE) {
          handleMoveUpdate(e);
        } else if (action === TRANFORM_TOOL_ACTION.ROTATE) {
          handleRotateUpdate(e);
        } else if (action === TRANFORM_TOOL_ACTION.SCALE) {
          handleScaleUpdate(e);
        } else if (action === TRANFORM_TOOL_ACTION.CROP) {
          handleCropUpdate(e);
        } else if (action === TRANFORM_TOOL_ACTION.INSIDE_MOVE) {
          handleInsideMoveUpdate(e);
        }
      },
      [handleCropUpdate, action]
    );

    // end a current transform action
    const endAction = useCallback(() => {
      setState((current) => ({
        ...current,
        action: undefined,
        dragDetail: {},
      }));
      onFrameUpdateCompleted(frame);
    }, [onFrameUpdateCompleted, frame]);

    // mouse up action actually just ends current action
    const handleMouseUpAction = useCallback(() => {
      if (action) endAction();
    }, [endAction, action]);

    // --------------------- effects ----------------------------

    // when action change, change the event listeners
    useEffect(() => {
      if (state.action) {
        window.addEventListener('mousemove', handleMouseMoveAction);
        window.addEventListener('mouseup', handleMouseUpAction);
      }
      return () => {
        window.removeEventListener('mousemove', handleMouseMoveAction);
        window.removeEventListener('mouseup', handleMouseUpAction);
      };
    }, [state, handleMouseMoveAction, handleMouseUpAction]);

    //-------------------------- render ----------------------------

    return (
      // To be sure to not have to have to transform all element, we use a parent that simulate exactly the page placement
      <div
        key="pageTransformEmulated"
        style={{
          left: pagePosX,
          top: pagePosY,
          width: '100%',
          height: '100%',
          // overflow: 'hidden',
          transformOrigin: '0% 0%',
          transform: `scale(${editionScale})`,
          // backgroundColor: '#ffff0044',
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        {
          // // TODO: DEBUGGING, remove
          // <pre style={{ background: '#ff000099', color: 'yellow' }}>
          //   {JSON.stringify(state, null, 2)}
          //   <br />
          //   {JSON.stringify(frame, null, 2)}
          //   <br />
          //   {editedPage ? 'EDITED PAGE AVAILABLE' : '=> no edited page'}
          //   <br />
          //   {frame.photo
          //     ? JSON.stringify(getPhotosById(frame.photo), null, 2)
          //     : '=> no photo'}
          // </pre>
        }

        <div
          className="selection-box"
          ref={transformRef}
          onDragOver={(e) => {
            handleDragOver(e);
          }}
          onDrop={(e) => {
            handleItemDrop(e);
          }}
          onDoubleClick={() => {
            onDoubleClick(frame);
          }}
          style={{
            width: `${frame.width}px`,
            height: `${frame.height}px`,
            zIndex: -1,
            cursor: 'move',
            display: 'inline-block',
            left: calculatePosX(),
            top: calculatePosY(),

            transformOrigin: transformOrigin,
            transform: `translateX( ${-frame.width / 2}px) translateY( ${
              -frame.height / 2
            }px) rotate(${frame.rotation}deg)`,
          }}
        >
          {
            /* // --------------------- frame size detail ------------------------  */
            frameCanMove(frame) && !moving && (
              <div>
                <div className="selection-box-size-width">
                  <span
                    className="label"
                    style={{ fontSize: 10 / editionScale }}
                  >
                    {` ${pixelToCm(frame.width).toFixed(1)} cm `}
                  </span>
                </div>
                <div className="selection-box-size-height">
                  <span
                    className="label"
                    style={{ fontSize: 10 / editionScale }}
                  >
                    {` ${pixelToCm(frame.height).toFixed(1)} cm `}
                  </span>
                </div>
              </div>
            )
          }

          <div className="selection-box--border">
            {
              // // --------------------- MOVE ------------------------
              // NOTE: actually, we cannot do this inside the Transform tool because it will block all actions on frames (buttons to upload, add texts etc..)
              // frameCanMove(frame) && (
              //   <div
              //     onMouseDown={(e) => {
              //       handleMoveStart(e);
              //     }}
              //     style={{
              //       opacity: 0,
              //       backgroundColor: 'blue',
              //       zIndex: -20,
              //       position: 'absolute',
              //       width: '100%',
              //       height: '100%',
              //       pointerEvents: 'auto',
              //       cursor: 'move',
              //     }}
              //   />
              // )
            }

            {
              // --------------------- SCALING ------------------------
              frameCanScale(frame) && !editedPage && (
                <div>
                  <div
                    role="button"
                    tabIndex={0}
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--topleft"
                    ref={cornerRefs[DRAG_POINT.TOP_LEFT]}
                    style={uiTransform}
                    onMouseDown={(e) => {
                      handleScaleStart(DRAG_POINT.TOP_LEFT, e);
                    }}
                  />
                  <div
                    role="button"
                    tabIndex={0}
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--topright"
                    ref={cornerRefs[DRAG_POINT.TOP_RIGHT]}
                    style={uiTransform}
                    onMouseDown={(e) => {
                      handleScaleStart(DRAG_POINT.TOP_RIGHT, e);
                    }}
                  />
                  <div
                    role="button"
                    tabIndex={0}
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--bottomleft"
                    ref={cornerRefs[DRAG_POINT.BOTTOM_LEFT]}
                    style={uiTransform}
                    onMouseDown={(e) => {
                      handleScaleStart(DRAG_POINT.BOTTOM_LEFT, e);
                    }}
                  />
                  <div
                    role="button"
                    tabIndex={0}
                    aria-label="cornerDrag"
                    className="resize-hitzone resize-hitzone--bottomright"
                    ref={cornerRefs[DRAG_POINT.BOTTOM_RIGHT]}
                    style={uiTransform}
                    onMouseDown={(e) => {
                      handleScaleStart(DRAG_POINT.BOTTOM_RIGHT, e);
                    }}
                  />
                </div>
              )
            }

            {
              // --------------------- CROP ------------------------
              (frameCanCrop(frame) || frameCanInsideMove(frame)) && (
                <div>
                  {frameCanCrop(frame) && !editedPage && (
                    <div>
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--top"
                        style={uiTransform}
                        ref={cornerRefs[DRAG_POINT.TOP]}
                        onMouseDown={(e) => {
                          handleCropStart(DRAG_POINT.TOP, e);
                        }}
                      />
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--right"
                        style={uiTransform}
                        ref={cornerRefs[DRAG_POINT.RIGHT]}
                        onMouseDown={(e) => {
                          handleCropStart(DRAG_POINT.RIGHT, e);
                        }}
                      />
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--bottom"
                        style={uiTransform}
                        ref={cornerRefs[DRAG_POINT.BOTTOM]}
                        onMouseDown={(e) => {
                          handleCropStart(DRAG_POINT.BOTTOM, e);
                        }}
                      />
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="cornerDrag"
                        className="crop-hitzone crop-hitzone--left"
                        style={uiTransform}
                        ref={cornerRefs[DRAG_POINT.LEFT]}
                        onMouseDown={(e) => {
                          handleCropStart(DRAG_POINT.LEFT, e);
                        }}
                      />
                    </div>
                  )}
                  {frameCanInsideMove(frame) && !editedPage && (
                    <div
                      role="button"
                      tabIndex={0}
                      aria-label="insidemove"
                      className="insideMove unselectable"
                      style={uiTransform}
                      onMouseDown={(e) => {
                        handleInsideMoveStart(e);
                      }}
                    >
                      <ImgIcon icon={EmbedIcons.GrabIcon} className="icon" />
                    </div>
                  )}
                </div>
              )
            }

            {
              // --------------------- ROTATE ------------------------
              frameCanRotate(frame) && (
                <div
                  role="button"
                  tabIndex={0}
                  aria-label="rotate"
                  className="selection-box--rotate-icon"
                  style={uiTransform}
                  onMouseDown={(e) => {
                    handleRotateStart(e);
                  }}
                >
                  <RedoOutlined className="icon" />

                  {/*
                                // TODO: REMEMBER THIS :: when using a button from antd inside a SVG, it leads to weird async position behaviors...
                                <Button type="primary" shape="circle" icon="reload" size="large" />
                                <Icon type="reload" spin theme="filled" style={{ fontSize: '50px', color: '#ffffff' }}  />
                            */}
                </div>
              )
            }

            {
              // ---- ROTATING DEBUG ----
              DebugFlags.USE_TRANSFORM_TOOL_DEBUG && rotating && (
                <div
                  className="rotation debug"
                  style={{
                    width: 10,
                    height: 10,
                    position: 'absolute',
                    left: `${frame.width / 2 - 5}px`,
                    top: `${frame.height / 2 - 5}px`,
                    backgroundColor: '#fff000',
                  }}
                />
              )
            }

            {
              // ---- SCALING DEBUG ----
              DebugFlags.USE_TRANSFORM_TOOL_DEBUG && cropping && (
                <div
                  className="scaling debug"
                  style={{
                    width: 10,
                    height: 10,
                    position: 'absolute',
                    left: `${Math.abs(
                      state.dragDetail.oppositePoint.x -
                        state.dragDetail.startPoint.x
                    )}px`,
                    top: `${Math.abs(
                      state.dragDetail.oppositePoint.y -
                        state.dragDetail.startPoint.y
                    )}px`,
                    backgroundColor: '#fff000',
                  }}
                />
              )
            }

            {/* {   // --------------------- Frame text Edition ------------------------
                        (frame.type === FRAME_TYPE.TEXT) &&

                            <div style={{ width:"100%", height:"100%", position:"absolute", top:"0px"}}>
                                <Input ref={ textEditRef } style={{ width:"100%", height:"100%"}} placeholder="edit text" />
                            </div>

                    } */}
          </div>
        </div>
      </div>
    );
  }
);

export default TransformTool;
