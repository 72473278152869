import { PROJECT_CLASS } from '../data/Constants';
import classicCoverCorners_none from './classicCoverCorners/none.jpg';
import classicCoverCorners_roundgold from './classicCoverCorners/roundgold.jpg';
import classicCoverCorners_roundsilver from './classicCoverCorners/roundsilver.jpg';
import classicCoverCorners_squaregold from './classicCoverCorners/squaregold.jpg';
import classicCoverCorners_squaresilver from './classicCoverCorners/squaresilver.jpg';
import BackgroundWall from './preview/wall.jpg';
import BackgroundWood from './preview/wood_desk.jpg';
import ClassAlbumImage from './products/albums.png';
import ClassCalendarImage from './products/calendars.png';
import ClassCanvasImage from './products/canvas.png';
import ClassCardImage from './products/cards.png';
import ProductFaceMask from './products/face_mask.jpg';
import FunProductImage from './products/fun.png';
import ProductPrints from './products/prints.jpg';
import Logo from './logo.png';
import LogoMobileScreen from './logo_mobile_screen.png';

export const EmbedAssets = {
  Logo,
  LogoMobileScreen,
  FunProductImage,
  ProductFaceMask,
  ProductPrints,
  BackgroundWall,
  BackgroundWood,
  ClassicCoverCorner: (key) => {
    if (key === 'roundgold') return classicCoverCorners_roundgold;
    if (key === 'roundsilver') return classicCoverCorners_roundsilver;
    if (key === 'squaregold') return classicCoverCorners_squaregold;
    if (key === 'squaresilver') return classicCoverCorners_squaresilver;
    return classicCoverCorners_none;
  },
  classNameImage: (classname) => {
    if (classname === PROJECT_CLASS.CALENDAR) return ClassCalendarImage;
    if (classname === PROJECT_CLASS.CANVAS) return ClassCanvasImage;
    if (classname === PROJECT_CLASS.CARD) return ClassCardImage;
    return ClassAlbumImage;
  },
};
