import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentClient } from '@sentry/browser';
import { Button, Empty, Modal, Popover, Select, Spin } from 'antd';
import { cloneDeep } from 'lodash';
import ColorPicker from '../../../_components/ColorPicker';
import { Colors } from '../../../data/Colors';
import { IsCanvasEditor } from '../../../data/config';
import { GetText } from '../../../data/LanguageHelper';
import { DebugFlags } from '../../../debug/DebugFlags';
import { backgroundSelectors } from '../../../feature/backgrounds/background.store';
import {
  ApplyBackgroundFillToPage,
  applyBackgroundToPage,
} from '../../../feature/backgrounds/backgroundHelper';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { StoreType } from '../../../store/store.type';
import type { Background, IPage } from '../../../types/types';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';
import { TABS_ENUM } from './TABS_ENUM';

const { Option } = Select;

export const BackgroundsArea = () => {
  // --------------------- redux ------------------------

  const dispatch = useDispatch();

  // we need user, project and layouts
  const project = useSelector(editionSelectors.GetProjectSelector);
  const selectedFrameID = useSelector(editionSelectors.GetSelectedFrameID);
  // const selectedTab = useSelector(ui.selectedTab);
  // const selectedPageIndex = useSelector( edition.selectedPage);
  const selectedPageObject = useSelector(editionSelectors.GetSelectedPage);
  const isBackgroundsLoading = useSelector(
    (state: StoreType) => state.backgrounds.isLoading
  );
  const backgrounds = useSelector(
    backgroundSelectors.getBackgroundsByCategories
  );
  const backgroundCategories = useSelector(
    backgroundSelectors.getBackgroundCategories
  );

  // --------------------- state ------------------------
  const [currentCategory, setCurrentCategory] = useState(null);

  const pageRatio = project ? project.width / project.height : -1;
  const selectedPage: IPage = selectedPageObject;
  const currentBackgroundColor =
    selectedPage &&
    !selectedPage.coverClassicOptions &&
    selectedPage.frames[0].fillColor
      ? selectedPage.frames[0].fillColor
      : '#ffffff';
  console.log(`WE RENDER BACKGROUNDS NOW (category:${currentCategory})`);

  // TODO: change this rule later with other products
  const pageHasBackgrounds = !selectedPage.coverClassicOptions;

  // --------------------- methods ------------------------

  const handleCategoryChange = (newCategory) => {
    setCurrentCategory(newCategory);
  };

  const handleItemClick = (item) => {
    if (!project) {
      alert('TODO: no project selected');
      return;
    }

    // get a copy of page to work with
    // const pageCopy:IPage = cloneDeep( project.pageList[ selectedPage ] );
    const pageCopy: IPage = cloneDeep(selectedPageObject);

    // security, do not automatically apply background for merged pages. As it could be applied to the wrong page area (left or right)
    // if (pageCopy.merged) return;

    // apply to page
    applyBackgroundToPage(pageCopy, item);

    // dispatch page update
    dispatch(editionActions.UpdatePage(pageCopy));

    // layout applied, allow undoable action
    dispatch(editionActions.AddUndoableAction());
  };

  const handleApplyToAll = (item) => {
    dispatch(editionActions.ApplyBackgroundToAllPages(item.id));
  };

  const handleItemDragStart = (evt, item) => {
    AddDragData(evt, DRAG_TYPE.BACKGROUND, item.id);
  };

  const handleBackgroundColorSelect = (color) => {
    // alert("new color selected:" + JSON.stringify(color));

    // for canvas do not add apply to all selection.
    if (IsCanvasEditor()) {
      applyBackgroundColor(color, false);
      return;
    }

    // select for all pages or only this page
    Modal.confirm({
      title: GetText('popup.background.applytoall.title'),
      okText: GetText('popup.background.applytoall.yes'),
      cancelText: GetText('popup.background.applytoall.no'),
      width: 500,
      onOk: () => {
        applyBackgroundColor(color, true);
      },
      onCancel: () => {
        applyBackgroundColor(color, false);
      },
    });
  };

  const applyBackgroundColor = (color, allPages: boolean) => {
    if (!project) {
      alert('TODO: no project selected');
      return;
    }

    if (allPages) {
      dispatch(editionActions.ApplyBackgroundToAllPages(null, color.hex));
    }
    // only selected Page
    else {
      // get a copy of page to work with
      const pageCopy: IPage = cloneDeep(selectedPageObject);
      // if page is a merged page, we need to know which area to apply the color
      if (pageCopy.merged && selectedFrameID) {
        const frame = pageCopy.frames.find(
          (frame) => frame.id === selectedFrameID
        );
        const mergedPageArea = frame.x < pageCopy.width / 2 ? 'left' : 'right';
        ApplyBackgroundFillToPage(pageCopy, color.hex, mergedPageArea);
      } else {
        // apply to default page
        ApplyBackgroundFillToPage(pageCopy, color.hex);
      }

      // dispatch page update
      dispatch(editionActions.UpdatePage(pageCopy));
      // layout applied, allow undoable action
      dispatch(editionActions.AddUndoableAction());
    }
  };

  // --------------------- effect ------------------------

  // componentDidMount() {
  //   if (
  //     backgroundCategories &&
  //     backgroundCategories.length > 0
  //   )
  //     handleCategoryChange(backgroundCategories[0]);
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // const differentTitle = title !== nextProps.title;
  //   // const differentDone = done !== nextProps.done
  //   // return differentTitle || differentDone;
  //   const { props } = this;

  //   // we should update layouts only if layouts did change, or if we switch from cover to page
  //   const tabActive = nextProps.selectedTab === TABS_ENUM.BACKGROUND;
  //   const tabBecameActive =
  //     tabActive && props.selectedTab !== TABS_ENUM.BACKGROUND;
  //   const categoryDidChange =
  //     nextState.currentCategory !== state.currentCategory;

  //   // set default category
  //   if (
  //     !nextState.currentCategory &&
  //     nextProps.backgroundCategories &&
  //     nextProps.backgroundCategories.length > 0
  //   ) {
  //     handleCategoryChange(nextProps.backgroundCategories[0]);
  //     return true;
  //   }
  //   const coverChange =
  //     props.selectedPageIndex !== nextProps.selectedPageIndex &&
  //     (props.selectedPageIndex === 0 || nextProps.selectedPageIndex === 0);

  //   const fillColorChange =
  //     props.selectedPageObject &&
  //     nextProps.selectedPageObject &&
  //     props.selectedPageObject.frames.length > 0 &&
  //     nextProps.selectedPageObject.frames.length > 0 &&
  //     props.selectedPageObject.frames[0].fillColor !==
  //       nextProps.selectedPageObject.frames[0].fillColor;

  //   // Conditions to re render this component, only if the layout list is different!
  //   const different = backgrounds !== nextProps.backgrounds;
  //   return (
  //     tabActive &&
  //     (different ||
  //       coverChange ||
  //       categoryDidChange ||
  //       fillColorChange ||
  //       tabBecameActive)
  //   );
  // }

  useEffect(() => {
    if (!currentCategory) {
      setCurrentCategory(backgroundCategories[0]);
    }
  }, [backgroundCategories]);

  // --------------------- render ------------------------

  /// ///////////////////////////////////////////////////
  // Render
  /// ///////////////////////////////////////////////////

  return (
    <div className="tabContent backgroundsArea">
      <h3>{GetText('lefttab.backgrounds')}</h3>

      {
        // --------------------- CASE PAGE HAS BACKGROUND ------------------------
        pageHasBackgrounds && (
          <Spin spinning={isBackgroundsLoading}>
            <h4>{GetText('lefttab.backgrounds.colors')}</h4>
            <ColorPicker
              colors={Colors.displayColors}
              selected={currentBackgroundColor}
              onChange={handleBackgroundColorSelect}
              itemSize={15}
              borderRadius={8}
              spacing={2}
            />

            {/* <span className="spacer"/> */}

            {/* <CirclePicker width="100%"
                    key="colorpicker"
                    circleSpacing={2}
                    circleSize={15}
                    colors={Colors.displayColors } color={currentBackgroundColor}
                    onChange={handleBackgroundColorSelect} />  */}

            {/* <h4>{GetText("lefttab.backgrounds.select.category")}</h4> */}

            {
              // --------------------- background category filter ------------------------
              backgroundCategories && backgroundCategories.length > 0 && (
                <Select
                  value={currentCategory}
                  placeholder="select a category"
                  onChange={(value) => {
                    handleCategoryChange(value);
                  }}
                  className="dropdown"
                >
                  {backgroundCategories.map((category) => (
                    <Option key={category} value={category}>
                      {GetText(`lefttab.category.${category}`)}{' '}
                      {`(${backgrounds[category].length})`}
                    </Option>
                  ))}
                </Select>
              )
            }

            <div
              style={{
                width: '100%',
                display: 'grid',
                padding: '10px 0px',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: 'auto',
              }}
            >
              {backgrounds &&
                backgrounds[currentCategory] &&
                backgrounds[currentCategory].map((Background, key) => {
                  // const item:Layout = layoutsByID[layoutID];
                  const item: Background = Background;
                  const overContent = (
                    <div className="BackgroundOverlay">
                      <b>{item.id}</b>
                      {DebugFlags.DEBUGGING ? JSON.stringify(item) : ''}
                      <Button className="button" type="primary">
                        {GetText('lefttab.backgrounds.apply')}
                      </Button>
                      <Button
                        className="button"
                        type="primary"
                        onClick={() => handleApplyToAll(item)}
                      >
                        {GetText('lefttab.backgrounds.applytoall')}
                      </Button>
                    </div>
                  );
                  const overContentDelay = DebugFlags.DEBUGGING ? 0.5 : 0.5;

                  const pageRatio = item.width / item.height;
                  const contentW = 90;
                  const contentH = contentW / pageRatio;

                  return (
                    // TODO:check to use antd Grid here with list item card

                    <div
                      role="button"
                      tabIndex={0}
                      className="photoItem"
                      style={{
                        width: contentW,
                        textAlign: 'center',
                        // height:contentH,
                      }}
                      key={item.id}
                      onDragStart={(e) => handleItemDragStart(e, item)}
                      onClick={(e) => handleItemClick(item)}
                      draggable
                    >
                      {
                        // see doc of popover that extends the tooltip
                        // https://gary-shen.github.io/ant-design/components/tooltip/
                      }
                      {/* <Tooltip placement="right" title={"id:"+item.id + "\nname:"+item.name}> */}
                      <Popover
                        content={overContent} // title={"id:" + item.id}
                        placement="right"
                        trigger="hover"
                        mouseEnterDelay={overContentDelay}
                      >
                        <LazyLoadImage // className="photoPreview"
                          // style={{margin: "0px auto 0px auto"}}
                          // key={item.id}
                          threshold={600}
                          effect="opacity"
                          style={{
                            width: '100%',
                            maxHeight: '70px',
                            height: '70px',
                            objectFit: 'contain',
                          }}
                          draggable={false}
                          src={item.thumb_url}
                        />
                      </Popover>
                    </div>
                  );
                })}
            </div>
          </Spin>
        )
      }

      {
        // --------------------- CASE EMPTY ------------------------
        !pageHasBackgrounds && (
          <Empty
            style={{ width: '100%' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={GetText('lefttab.background.empty.label')}
          />
        )
      }
    </div>
  );
};
