/* eslint-disable max-len */
export const CATALOGUE_CARD = {
  // // --------------------- POSTCARDS ------------------------

  // PC  : postcard --LANDSCAPE-- 14,8 cm * 10,5 cm
  // PX  : XL Postcard --LANDSCAPE-- 21 cm * 14,9 cm

  // // --------------------- ANONOUCEMENT CARDS / FLAT CARD ------------------------

  // -[new]-// ACMP  : announcement card MEDIUM --PORTRAIT-- 7.55 cm * 11.5 cm
  // -[new]-// ACML  : announcement card MEDIUM --LANDSCAPE-- 11.5 cm * 7.55 cm
  // ACP  : announcement card --PORTRAIT-- 10,5 cm * 14,8 cm
  // ACL  : announcement card --LANDSCAPE-- 14,8 cm * 10,5 cm
  // AXP  : announcement card XL --PORTRAIT-- 14,8 cm * 21 cm
  // AXL  : announcement card XL --LANDSCAPE-- 21 cm * 14,8 cm
  // AUP  : panoramic announcement card --PORTRAIT-- 10,5 cm * 21 cm
  // AUL  : panoramic announcement card --LANDSCAPE-- 21 cm * 10,5 cm
  // AQP  : square announcement card --SQUARE-- 16 cm * 16 cm
  // -[new]-  // ABP  : Bookmark announcement card --PORTRAIT-- 2 cm * 20 cm

  // // --------------------- GREETING CARDS / FOLDED CARDS ------------------------

  // -[new]-// GCSP  : Small Greeting card --PORTRAIT-- 5.5 cm * 8.5 cm
  // -[new]-// GCSL  : Small Greeting card --LANDSCAPE-- 8.5 cm * 5.5 cm
  // -[new]-// GCMP  : Medium Greeting card --PORTRAIT-- 7.55 cm * 11.5 cm
  // -[new]-// GCML  : Medium Greeting card --LANDSCAPE-- 11.5 * 7.55 cm
  // FCP  : Large Greeting card --PORTRAIT-- 10,8 cm * 16,5 cm
  // FCL  : Large Greeting card --LANDSCAPE-- 16,5 cm * 10,8 cm
  // GCP  : XL Greeting card --PORTRAIT-- 14,7 cm * 22,4 cm
  // GCL  : XL Greeting card --LANDSCAPE-- 22,4 cm * 14,7 cm
  // FUP  : panormic Greeting card --PORTRAIT-- 10,5 cm * 21 cm
  // FUL  : panoramic Greeting card --LANDSCAPE-- 21 cm * 10,5 cm
  // FQP  : panoramic Greeting card --SQUARE-- 16 cm * 16 cm

  // -- landscape short fold --

  // -[new]-// GCSLSF  : ShortFold:  Small Greeting card --LANDSCAPE-- 8.5 cm * 5.5 cm
  // -[new]-// GCMLSF  : ShortFold: Medium Greeting card --LANDSCAPE-- 11.5 * 7.55 cm
  // -[new]-// FCLSF  : ShortFold: Large Greeting card --LANDSCAPE-- 16,5 cm * 10,8 cm
  // -[new]-// GCLSF  : ShortFold: XL Greeting card --LANDSCAPE-- 22,4 cm * 14,7 cm

  // // --------------------- MAGNET CARDS ------------------------

  // MACP  : magnet announcement card  --PORTRAIT-- 10,5 cm * 14,8 cm
  // MACL  : magnet announcement card  --LANDSCAPE-- 14,8 cm * 10,5 cm
  // MAXP  : magnet XL announcement card  --PORTRAIT-- 14,8 cm * 21 cm
  // MAXL  : magnet XL announcement card  --LANDSCAPE-- 21 cm * 14,8 cm
  // MAUP  : magnet (US) panoramic announcement card  --PORTRAIT-- 10,5 cm * 21 cm
  // MAUL  : magnet (US) panoramic announcement card  --LANDSCAPE-- 21 cm * 10,5 cm
  // MAQP  : magnet square announcement card  --SQUARE-- 15 cm * 15 cm

  products: {
    postcard: {
      formats: {
        land: { docs: ['PC', 'PX'], default: 'PC' },
      },
      default: 'land',
    },
    // = Flat cards
    announcement: {
      formats: {
        port: { docs: ['ACMP', 'ACP', 'AXP', 'AUP'], default: 'ACP' },
        land: { docs: ['ACML', 'ACL', 'AXL', 'AUL'], default: 'ACL' },
        pano: { docs: ['AUP', 'AUL'], default: 'AUP' },
        square: { docs: ['AQP'], default: 'AQP' },
        // bookmark: { docs: ['ABP'], default: 'ABP' },
      },
      default: 'port',
    },
    // = Folded cards
    greeting: {
      formats: {
        port: { docs: [/*GCSP*/ 'GCMP', 'FCP', 'GCP'], default: 'FCP' },
        land_lf: { docs: [/*'GCSL'*/ 'GCML', 'FCL', 'GCL'], default: 'FCL' },
        land_sf: {
          docs: [/*'GCSLSF', */ 'GCMLSF', 'FCLSF', 'GCLSF'],
          default: 'FCLSF',
        },
        pano: { docs: ['FUP', 'FUL'], default: 'FUP' },
        square: { docs: ['FQP'], default: 'FQP' },
      },
      default: 'port',
    },
    magnet: {
      docs: ['MACP', 'MACL', 'MAXP', 'MAXL', 'MAUP', 'MAUL', 'MAQP'],
      formats: {
        port: { docs: ['MACP', 'MAXP'], default: 'MACP' },
        land: { docs: ['MACL', 'MAXL'], default: 'MACL' },
        pano: { docs: ['MAUP', 'MAUL'], default: 'MAUL' },
        square: { docs: ['MAQP'], default: 'MAQP' },
      },
      default: 'port',
    },
  },

  envelopes: [
    { color: '#000000', id: 'none' },
    { color: '#ffffff', id: 'white' },
    { color: '#d4fd93', id: 'lime_green' },
    { color: '#000000', id: 'black' },
    { color: '#634100', id: 'brown' },
    { color: '#0068a0', id: 'blue' },
    { color: '#bdbdbd', id: 'gray' },
    { color: '#006500', id: 'green' },
    { color: '#b5e0f8', id: 'light_blue' },
    { color: '#0068a0', id: 'navy_blue' },
    // { "color":"#ff8a00" , "id":"orange" stock="outofstock"},
    { color: '#d1d3c2', id: 'pearl_gray' },
    { color: '#7a4653', id: 'bordeau' },
    // { "color":"#000000" , "id":"chalk" stock="outofstock"},
    { color: '#ff0000', id: 'red' },
    { color: '#f3ea00', id: 'yellow' },
  ],

  upgrades: [
    // Postcards
    ['PC', 'PX'],

    // Announcement
    ['ACMP', 'ACP', 'AXP', 'AUP', 'ACML', 'ACL', 'AXL', 'AUL', 'AQP'],
    // ['ACMP', 'ACP', 'AXP', 'AUP', 'ACML', 'ACL', 'AXL', 'AUL', 'AQP', 'ABP'],

    // Greetings // flopped cards
    [
      // 'GCSP',
      'GCMP',
      'FCP',
      'GCP',
      // 'GCSL',
      'GCML',
      'FCL',
      'GCL',
      'FUP',
      'FUL',
      // 'GCSLSF',
      'GCMLSF',
      'FCLSF',
      'GCLSF',
    ],

    // magnets
    ['MACP', 'MAXP', 'MACL', 'MAXL', 'MAUP', 'MAUL', 'MAQP'],
  ],

  docs: {
    // // --------------------- POSTCARDS ------------------------

    // PC  : postcard --LANDSCAPE-- 14,8 cm * 10,5 cm
    PC: {
      codes: {
        '1X1': 3049,
        '1X10': 3001,
        // "2X5" : 3002,
        // "5X2" : 3003,
        '10X1': 3004,
      },
      width: 148,
      height: 105,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none'],
      envelopes_default: 'none',

      layouts_default: [3006, 3000],
      // layouts_cover: [542],
      layouts_all: [
        3000, 3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023,
        3024, 3025, 3026,
      ],
    },

    // PX  : XL Postcard --LANDSCAPE-- 21 cm * 14,9 cm
    PX: {
      codes: {
        '1X1': 3099,
        '1X10': 3051,
        // "2X5" : 3052,
        // "5X2" : 3053,
        '10X1': 3054,
      },
      width: 148,
      height: 105,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 4.0222,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none'],
      envelopes_default: 'none',

      layouts_default: [3006, 3000],
      // layouts_cover: [542],
      layouts_all: [
        3000, 3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023,
        3024, 3025, 3026,
      ],
    },

    // // --------------------- ANONOUCEMENT CARDS = FLAT PHOTO CARDS ------------------------

    // -[new]-// ACMP  : announcement card MEDIUM --PORTRAIT-- 7.55 cm * 11.5 cm
    ACMP: {
      codes: {
        '1X1': 6149,
        '1X10': 6101,
        // "2X5" : 3202,
        // "5X2" : 3203,
        '10X1': 6104,
      },
      width: 75.5,
      height: 115,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'none',

      layouts_default: [3201, 3213],
      // layouts_cover: [542],
      layouts_all: [
        3201, 3202, 3226, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210, 3211,
        3212, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223,
        3224, 3225,
      ],
    },

    // -[new]-// ACML  : announcement card MEDIUM --LANDSCAPE-- 11.5 cm * 7.55 cm
    ACML: {
      codes: {
        '1X1': 6049,
        '1X10': 6001,
        // "2X5" : 3402,
        // "5X2" : 3403,
        '10X1': 6004,
      },
      width: 115,
      height: 75.5,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'none',

      layouts_default: [3004, 3001],
      // layouts_cover: [542],
      layouts_all: [
        3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023, 3024,
        3025, 3026,
      ],
    },

    // ACP  : announcement card --PORTRAIT-- 10,5 cm * 14,8 cm
    ACP: {
      codes: {
        '1X1': 3249,
        '1X10': 3201,
        // "2X5" : 3202,
        // "5X2" : 3203,
        '10X1': 3204,
      },
      width: 105,
      height: 148,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'black',
        'blue',
        'light_blue',
        'red',
        'yellow',
      ],
      envelopes_default: 'none',

      layouts_default: [3201, 3213],
      // layouts_cover: [542],
      layouts_all: [
        3201, 3202, 3226, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210, 3211,
        3212, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223,
        3224, 3225,
      ],
    },

    // ACL  : announcement card --LANDSCAPE-- 14,8 cm * 10,5 cm
    ACL: {
      codes: {
        '1X1': 3449,
        '1X10': 3401,
        // "2X5" : 3402,
        // "5X2" : 3403,
        '10X1': 3404,
      },
      width: 148,
      height: 105,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'black',
        'blue',
        'light_blue',
        'red',
        'yellow',
      ],
      envelopes_default: 'none',

      layouts_default: [3004, 3001],
      // layouts_cover: [542],
      layouts_all: [
        3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023, 3024,
        3025, 3026,
      ],
    },

    // AXP  : announcement card --PORTRAIT-- 14,8 cm * 21 cm
    AXP: {
      codes: {
        '1X1': 3299,
        '1X10': 3251,
        // "2X5" : 3252,
        // "5X2" : 3253,
        '10X1': 3254,
      },
      width: 105,
      height: 148,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 4.0222,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'none',

      layouts_default: [3201, 3213],
      // layouts_cover: [542],
      layouts_all: [
        3201, 3202, 3226, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210, 3211,
        3212, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223,
        3224, 3225,
      ],
    },

    // AXL  : announcement card --LANDSCAPE-- 21 cm * 14,8 cm
    AXL: {
      codes: {
        '1X1': 3499,
        '1X10': 3451,
        // "2X5" : 3452,
        // "5X2" : 3453,
        '10X1': 3454,
      },
      width: 148,
      height: 105,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 4.0222,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'none',

      layouts_default: [3004, 3001],
      // layouts_cover: [542],
      layouts_all: [
        3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023, 3024,
        3025, 3026,
      ],
    },

    // AUP  : panoramic announcement card --PORTRAIT-- 10,5 cm * 21 cm
    AUP: {
      codes: {
        '1X1': 3349,
        '1X10': 3301,
        // "2X5" : 3302,
        // "5X2" : 3303,
        '10X1': 3304,
      },
      width: 105,
      height: 210,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'none',

      layouts_default: [4101, 4117],
      // layouts_cover: [542],
      layouts_all: [
        4101, 4102, 4132, 4103, 4104, 4105, 4106, 4107, 4108, 4109, 4110, 4111,
        4112, 4113, 4114, 4115, 4116, 4117, 4118, 4119, 4120, 4121, 4122, 4123,
        4124, 4125, 4126, 4127, 4128, 4129, 4130, 4131,
      ],
    },

    // AUL  : panoramic announcement card --LANDSCAPE-- 21 cm * 10,5 cm
    AUL: {
      codes: {
        '1X1': 3549,
        '1X10': 3501,
        // "2X5" : 3502,
        // "5X2" : 3503,
        '10X1': 3504,
      },
      width: 210,
      height: 105,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'none',

      layouts_default: [4151, 4162],
      // layouts_cover: [542],
      layouts_all: [
        4151, 4152, 4175, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160, 4161,
        4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172, 4173,
        4174,
      ],
    },
    // AQP  : square announcement card --SQUARE-- 16 cm * 16 cm
    AQP: {
      codes: {
        '1X1': 3399,
        '1X10': 3351,
        // "2X5" : 3352,
        // "5X2" : 3353,
        '10X1': 3354,
      },
      width: 160,
      height: 160,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'blue',
        'bordeau',
        'yellow',
        'black',
        'red',
        'green',
      ],
      envelopes_default: 'none',

      layouts_default: [3351],
      // layouts_cover: [542],
      layouts_all: [
        3351, 3352, 3375, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361,
        3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373,
        3374,
      ],
    },

    // ABP  : Bookmark announcement card --PORTRAIT-- 6 cm * 20 cm
    ABP: {
      codes: {
        '1X1': 6249,
        '1X10': 6201,
        // "2X5" : 9999,
        // "5X2" : 9999,
        '10X1': 6204,
      },
      width: 60,
      height: 200,

      pages_per_group: 2,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'none',

      layouts_default: [3351],
      // layouts_cover: [542],
      layouts_all: [
        3351, 3352, 3375, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361,
        3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373,
        3374,
      ],
    },

    // // --------------------- GREETING CARDS === FOLDED CARDS ------------------------

    // GCSP  : Small Greeting card --PORTRAIT-- 5.5 cm * 8.5 cm
    GCSP: {
      codes: {
        '1X1': 5949,
        '1X10': 5901,
        // "2X5" : 9999,
        // "5X2" : 9999,
        '10X1': 5904,
      },
      width: 55,
      height: 85,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'white',

      layouts_default: [4002, 4001, 4001, 4000],
      layouts_all: [
        4000, 4001, 4002, 4003, 4004, 4005, 4006, 4008, 4009, 4010, 4011, 4012,
        4020, 4021, 4022, 4023, 4024, 4026, 4027, 4028, 4030, 4031,
      ],
    },

    // GCSL  : Small Greeting card --LANDSCAPE-- 8.5 cm * 5.5 cm
    GCSL: {
      codes: {
        '1X1': 5749,
        '1X10': 5701,
        // "2X5" : 5002,
        // "5X2" : 5003,
        '10X1': 5704,
      },
      width: 85,
      height: 55,

      pages_per_group: 4,
      pages_group_orientation: 'vertical',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // GCMP  : Medium Greeting card --PORTRAIT-- 7.55 cm * 11.5 cm
    GCMP: {
      codes: {
        '1X1': 5849,
        '1X10': 5801,
        // "2X5" : 9999,
        // "5X2" : 9999,
        '10X1': 5804,
      },
      width: 75.5,
      height: 115,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'white',

      layouts_default: [4002, 4001, 4001, 4000],
      layouts_all: [
        4000, 4001, 4002, 4003, 4004, 4005, 4006, 4008, 4009, 4010, 4011, 4012,
        4020, 4021, 4022, 4023, 4024, 4026, 4027, 4028, 4030, 4031,
      ],
    },

    // GCML  : Medium Greeting card --LANDSCAPE-- 11.5 * 7.55 cm
    GCML: {
      codes: {
        '1X1': 5649,
        '1X10': 5601,
        // "2X5" : 5002,
        // "5X2" : 5003,
        '10X1': 5604,
      },
      width: 115,
      height: 75.5,

      pages_per_group: 4,
      pages_group_orientation: 'vertical',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // FCP  : Greeting card --PORTRAIT-- 10,8 cm * 16,5 cm
    FCP: {
      codes: {
        '1X1': 4049,
        '1X10': 4001,
        // "2X5" : 4002,
        // "5X2" : 4003,
        '10X1': 4004,
      },
      width: 115,
      height: 175,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.672666,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['white', 'black', 'blue', 'light_blue', 'red', 'yellow'],
      envelopes_default: 'white',

      layouts_default: [4002, 4001, 4001, 4000],
      // layouts_cover: [542],
      layouts_all: [
        4000, 4001, 4002, 4003, 4004, 4005, 4006, 4008, 4009, 4010, 4011, 4012,
        4020, 4021, 4022, 4023, 4024, 4026, 4027, 4028, 4030, 4031,
      ],
    },

    // FCL  : Greeting card --LANDSCAPE-- 16,5 cm * 10,8 cm
    FCL: {
      codes: {
        '1X1': 5049,
        '1X10': 5001,
        // "2X5" : 5002,
        // "5X2" : 5003,
        '10X1': 5004,
      },
      width: 175,
      height: 115,

      pages_per_group: 4,
      pages_group_orientation: 'vertical',

      multiplier: 2.672666,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['white', 'black', 'blue', 'light_blue', 'red', 'yellow'],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      // layouts_cover: [542],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // GCP  : XL Greeting card --PORTRAIT-- 14,7 cm * 22,4 cm
    GCP: {
      codes: {
        '1X1': 4149,
        '1X10': 4101,
        // "2X5" : 4102,
        // "5X2" : 4103,
        '10X1': 4104,
      },
      width: 115,
      height: 175,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 3.628346,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [4002, 4001, 4001, 4000],
      // layouts_cover: [542],
      layouts_all: [
        4000, 4001, 4002, 4003, 4004, 4005, 4006, 4008, 4009, 4010, 4011, 4012,
        4020, 4021, 4022, 4023, 4024, 4026, 4027, 4028, 4030, 4031,
      ],
    },

    // GCL  : XL Greeting card --LANDSCAPE-- 22,4 cm * 14,7 cm
    GCL: {
      codes: {
        '1X1': 5149,
        '1X10': 5101,
        // "2X5" : 5102,
        // "5X2" : 5103,
        '10X1': 5104,
      },
      width: 175,
      height: 115,

      pages_per_group: 4,
      pages_group_orientation: 'vertical',

      multiplier: 3.628346,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      // layouts_cover: [542],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // FUP  : panormic Greeting card --PORTRAIT-- 10,5 cm * 21 cm
    FUP: {
      codes: {
        '1X1': 4099,
        '1X10': 4051,
        // "2X5" : 4052,
        // "5X2" : 4053,
        '10X1': 4054,
      },
      width: 105,
      height: 210,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [4101, 4131, 4131, 4100],
      // layouts_cover: [542],
      layouts_all: [
        4100, 4101, 4102, 4132, 4103, 4104, 4105, 4106, 4107, 4108, 4109, 4110,
        4111, 4112, 4113, 4114, 4115, 4116, 4117, 4118, 4119, 4120, 4121, 4122,
        4123, 4124, 4125, 4126, 4127, 4128, 4129, 4130, 4131,
      ],
    },
    // FUL  : panoramic Greeting card --LANDSCAPE-- 21 cm * 10,5 cm
    FUL: {
      codes: {
        '1X1': 5099,
        '1X10': 5051,
        // "2X5" : 5052,
        // "5X2" : 5053,
        '10X1': 5054,
      },
      width: 210,
      height: 105,

      pages_per_group: 4,
      pages_group_orientation: 'vertical',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [4151, 4174, 4174, 4150],
      // layouts_cover: [542],
      layouts_all: [
        4150, 4151, 4152, 4175, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160,
        4161, 4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172,
        4173, 4174,
      ],
    },

    // FQP  : panoramic Greeting card --SQUARE-- 16 cm * 16 cm
    FQP: {
      codes: {
        '1X1': 4199,
        '1X10': 4151,
        // "2X5" : 4152,
        // "5X2" : 4153,
        '10X1': 4154,
      },
      width: 160,
      height: 160,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'none',
        'white',
        'blue',
        'bordeau',
        'yellow',
        'black',
        'red',
        'green',
      ],
      envelopes_default: 'white',

      layouts_default: [3351, 3374, 3374, 3350],
      // layouts_cover: [542],
      layouts_all: [
        3350, 3351, 3352, 3375, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360,
        3361, 3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372,
        3373, 3374,
      ],
    },

    // -- Greetings, short fold landdscape --

    // -[new]-// GCSLSF  : ShortFold: Small Greeting card --LANDSCAPE-- 8.5 cm * 5.5 cm
    GCSLSF: {
      codes: {
        '1X1': 5549,
        '1X10': 5501,
        '10X1': 5504,
      },
      width: 85,
      height: 55,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // -[new]-// GCMLSF : ShortFold: Medium Greeting card --LANDSCAPE-- 11.5 * 7.55 cm
    GCMLSF: {
      codes: {
        '1X1': 5449,
        '1X10': 5401,
        '10X1': 5404,
      },
      width: 115,
      height: 75.5,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834645,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['none', 'white'],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // -[new]-// FCLSF : ShortFold: Greeting card --LANDSCAPE-- 16,5 cm * 10,8 cm
    FCLSF: {
      codes: {
        '1X1': 5349,
        '1X10': 5301,
        '10X1': 5304,
      },
      width: 175,
      height: 115,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 2.672666,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: ['white', 'black', 'blue', 'light_blue', 'red', 'yellow'],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      // layouts_cover: [542],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // -[new]-// GCLSF : ShortFold: XL Greeting card --LANDSCAPE-- 22,4 cm * 14,7 cm
    GCLSF: {
      codes: {
        '1X1': 5249,
        '1X10': 5201,
        '10X1': 5204,
      },
      width: 175,
      height: 115,

      pages_per_group: 4,
      pages_group_orientation: 'horizontal',

      multiplier: 3.628346,
      cutBorder: 12.5,

      finish: ['none', 'coating', 'mate'],
      finish_default: ['coating'],

      packs_default: '1X10',

      envelopes: [
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [5002, 5001, 5001, 5000],
      // layouts_cover: [542],
      layouts_all: [
        5000, 5001, 5002, 5003, 5004, 5005, 5006, 5008, 5009, 5010, 5011, 5012,
        5013, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
      ],
    },

    // // --------------------- MAGNET CARDS ------------------------

    // MACP  : magnet announcement card  --PORTRAIT-- 10,5 cm * 14,8 cm
    MACP: {
      codes: {
        '1X1': 3649,
        '1X8': 3601,
        // "2X4" : 3602,
        // "4X2" : 3603,
        '8X1': 3604,
      },
      width: 105,
      height: 148,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'black',
        'blue',
        'light_blue',
        'red',
        'yellow',
      ],
      envelopes_default: 'black',

      layouts_default: [3201],
      layouts_all: [
        3201, 3202, 3226, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210, 3211,
        3212, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223,
        3224, 3225,
      ],
    },

    // MACL  : magnet announcement card  --LANDSCAPE-- 14,8 cm * 10,5 cm
    MACL: {
      codes: {
        '1X1': 3849,
        '1X8': 3801,
        // "2X4" : 3802,
        // "4X2" : 3803,
        '8X1': 3804,
      },
      width: 148,
      height: 105,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'black',
        'blue',
        'light_blue',
        'red',
        'yellow',
      ],
      envelopes_default: 'white',

      layouts_default: [3004],
      layouts_all: [
        3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023, 3024,
        3025, 3026,
      ],
    },

    // MAXP  : magnet XL announcement card  --PORTRAIT-- 14,8 cm * 21 cm
    MAXP: {
      codes: {
        '1X1': 3699,
        '1X8': 3651,
        // "2X4" : 3652,
        // "4X2" : 3653,
        '8X1': 3654,
      },
      width: 105,
      height: 148,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 4.0222,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [3201],
      // layouts_cover: [542],
      layouts_all: [
        3201, 3202, 3226, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210, 3211,
        3212, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223,
        3224, 3225,
      ],
    },

    // MAXL  : magnet XL announcement card  --LANDSCAPE-- 21 cm * 14,8 cm
    MAXL: {
      codes: {
        '1X1': 3899,
        '1X8': 3851,
        // "2X4" : 3852,
        // "4X2" : 3853,
        '8X1': 3854,
      },
      width: 148,
      height: 105,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 4.0222,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [3004],
      layouts_all: [
        3001, 3003, 3004, 3005, 3006, 3007, 3009, 3020, 3021, 3022, 3023, 3024,
        3025, 3026,
      ],
    },

    // MAUP  : magnet panoramic announcement card  --PORTRAIT-- 10,5 cm * 21 cm
    MAUP: {
      codes: {
        '1X1': 3749,
        '1X8': 3701,
        // "2X4" : 3702,
        // "4X2" : 3703,
        '8X1': 3704,
      },
      width: 105,
      height: 210,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'light_blue',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [4101],
      layouts_all: [
        4101, 4102, 4132, 4103, 4104, 4105, 4106, 4107, 4108, 4109, 4110, 4111,
        4112, 4113, 4114, 4115, 4116, 4117, 4118, 4119, 4120, 4121, 4122, 4123,
        4124, 4125, 4126, 4127, 4128, 4129, 4130, 4131,
      ],
    },

    // MAUL  : magnet panoramic announcement card  --LANDSCAPE-- 21 cm * 10,5 cm
    MAUL: {
      codes: {
        '1X1': 3949,
        '1X8': 3901,
        // "2X4" : 3902,
        // "4X2" : 3903,
        '8X1': 3904,
      },
      width: 210,
      height: 105,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'lime_green',
        'black',
        'brown',
        'gray',
        'navy_blue',
        'pearl_gray',
      ],
      envelopes_default: 'white',

      layouts_default: [4151],
      layouts_all: [
        4151, 4152, 4175, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160, 4161,
        4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172, 4173,
        4174,
      ],
    },

    // MAQP  : magnet square announcement card  --SQUARE-- 15 cm * 15 cm
    MAQP: {
      codes: {
        '1X1': 3799,
        '1X8': 3751,
        // "2X4" : 3752,
        // "4X2" : 3753,
        '8X1': 3754,
      },
      width: 160,
      height: 160,

      pages_per_group: 1,
      pages_group_orientation: 'horizontal',

      multiplier: 2.834691,
      cutBorder: 12.5,

      finish: ['none'],
      finish_default: ['none'],

      packs_default: '1X8',

      envelopes: [
        'none',
        'white',
        'blue',
        'bordeau',
        'yellow',
        'black',
        'red',
        'green',
      ],
      envelopes_default: 'white',

      layouts_default: [3351],
      layouts_all: [
        3351, 3352, 3375, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361,
        3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373,
        3374,
      ],
    },
  },
};
