import { Component } from 'react';
import { connect } from 'react-redux';
import { FormOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import { GetText } from '../../../../data/LanguageHelper';
import { editionActions } from '../../../../feature/edition/edition';
import { editionSelectors } from '../../../../feature/edition/edition.selector';
import { pricingSelectors } from '../../../../feature/pricing/pricing';
import { Project, ProjectOptions } from '../../../../types/project';
import type { IDocument } from '../../../../types/types';
import { CanvasHelper } from '../../../../utils/canvas/CanvasHelper';
import { GetDoc, GetProjectSizeCM } from '../../../../utils/ProductHelper';
import { CanvasProjectCreationView } from '../../projectCreation/CanvasProjectCreationView';

const { Paragraph } = Typography;

class CanvasProjectArea extends Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      upgradePopupVisible: false,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {}

  // --------------------- methods ------------------------

  handleProjectNameChange(newName) {
    this.props.dispatch(editionActions.UpdateProjectName(newName));
  }

  handleKadapakColorChange = (colorID: string) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.canvasFrameColor = colorID;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  // --------------------- render ------------------------

  render() {
    const currentProject: Project = this.props.project;
    const projectSizeInCM: string = currentProject
      ? GetProjectSizeCM(currentProject)
      : null;
    let doc: IDocument;
    let previewURL: string;
    if (currentProject) {
      doc = GetDoc(currentProject.docID);
      previewURL = CanvasHelper.GetPreviewUrl(
        currentProject.type,
        currentProject.canvasFormat
      );
    }

    console.log('RENDER CANVAS PROJECT AREA AREA');

    return (
      <div className="tabContent projectArea test-height-b">
        {this.state.upgradePopupVisible && (
          <CanvasProjectCreationView
            isUpgrade="true"
            onCloseCallback={() => {
              this.setState({ upgradePopupVisible: false });
            }}
          />
        )}

        <h3>{GetText('lefttab.project')}</h3>

        {currentProject && (
          <div>
            <Paragraph
              editable={{
                onChange: (str) => {
                  this.handleProjectNameChange(str);
                },
              }}
            >
              {currentProject.name}
            </Paragraph>

            <h4>{GetText('lefttab.project.canvas.title')}</h4>
            <ul style={{ paddingLeft: '20px', listStyleType: 'circle' }}>
              <li>{GetText(`canvas.type.${currentProject.type}_types`)}</li>
              <li>
                {GetText(
                  `canvas.orientation.types_canvas_${currentProject.canvasFormat}`
                )}
              </li>

              <li>{projectSizeInCM}</li>
              {/* <li>{GetText("lefttab.canvas.numTotal")}: {numCanvass}</li> */}
            </ul>

            {
              // ALLOW UPGRADE?
              <Button
                type="primary"
                size="default"
                icon={<FormOutlined />}
                onClick={() => {
                  this.setState({ upgradePopupVisible: true });
                }}
              >
                {GetText('common.modify')}
              </Button>
            }

            {/* // --------------------- PREVIEW ------------------------ */}

            <Row className="preview">
              <img
                src={previewURL}
                style={{
                  backgroundColor: this.props.project.canvasFrameColor
                    ? CanvasHelper.GetFrameColorByID(
                        this.props.project.canvasFrameColor
                      )
                    : null,
                }}
              />
            </Row>
          </div>
        )}
      </div>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, edition } = state;
  const { project } = edition;

  return {
    project,
    docID: project ? project.docID : null,
    projectName: editionSelectors.GetProjectName(state),
    projectOptions: editionSelectors.GetProjectOptionsSelector(state),
    projectOptionsPrice: pricingSelectors.GetProjectOptionsPrice(state),
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(CanvasProjectArea);
export { reduxConnected as CanvasProjectArea };
