import { Component } from 'react';
import { batch, connect } from 'react-redux';
import { FormOutlined } from '@ant-design/icons';
import { Button, Checkbox, Row, Select, Tooltip, Typography } from 'antd';
import { Colors } from '../../../../data/Colors';
import { PAPER_FINISH } from '../../../../data/Constants';
import { GetText } from '../../../../data/LanguageHelper';
import { editionActions } from '../../../../feature/edition/edition';
import { editionSelectors } from '../../../../feature/edition/edition.selector';
import { pricingSelectors } from '../../../../feature/pricing/pricing';
import { Project, ProjectOptions } from '../../../../types/project';
import { IDocument } from '../../../../types/types';
import { CardHelper } from '../../../../utils/card/CardHelper';
import {
  GetDoc,
  GetPossibleUpgrades,
  GetProjectDocSizeCM,
} from '../../../../utils/ProductHelper';
import { CardProjectCreationView } from '../../projectCreation/CardProjectCreationView';

const { Paragraph } = Typography;
const { Option } = Select;

class CardProjectArea extends Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      upgradePopupVisible: false,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {}

  componentDidUpdate() {
    // security for matte/ coating!
    if (this.props.project && this.props.projectOptionsPrice) {
      const proj: Project = this.props.project;
      if (proj.coated && Number.isNaN(this.props.projectOptionsPrice.coated))
        this.handlePaperFinishChange(PAPER_FINISH.COATED, false);
      else if (proj.matte && Number.isNaN(this.props.projectOptionsPrice.matte))
        this.handlePaperFinishChange(PAPER_FINISH.MATTE, false);
    }
  }

  // --------------------- methods ------------------------

  handleProjectNameChange(newName) {
    this.props.dispatch(editionActions.UpdateProjectName(newName));
  }

  handleCardPackChange = (cardPack: string) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };

    // if num models change, we need to upgrade proejct to new page amount
    const currentPages = CardHelper.GetTotalNumberOfPages(
      this.props.docID,
      this.props.projectOptions.cardPack
    );
    const newPages = CardHelper.GetTotalNumberOfPages(
      this.props.docID,
      cardPack
    );

    // update options
    newProjectOptions.cardPack = cardPack;
    batch(() => {
      if (currentPages !== newPages) {
        const proj: Project = this.props.project;
        this.props.dispatch(
          editionActions.UpgradeCurrentProject({
            type: proj.type,
            docID: proj.docID,
            numPages: newPages,
          })
        );
      }
      this.props.dispatch(
        editionActions.UpdateProjectOptions(newProjectOptions)
      );
    });
  };

  handleEnvelopeChange = (envelopeID: string) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.envelope = envelopeID;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  handlePaperFinishChange = (paperFinish: string, flag) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    if (paperFinish === PAPER_FINISH.COATED) {
      newProjectOptions.coated = flag;
      if (flag) newProjectOptions.matte = false; // be sure to force false to avoid having both options
    } else {
      newProjectOptions.matte = flag;
      if (flag) newProjectOptions.coated = false; // be sure to force false to avoid having both options
    }
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  // --------------------- render ------------------------

  render() {
    const currentProject: Project = this.props.project;
    const projectSizeInCM: string = currentProject
      ? GetProjectDocSizeCM(currentProject.docID)
      : null;

    let doc: IDocument;
    let allowInserts: boolean;
    let pagePaperQualityOptions: Array;
    if (currentProject) doc = GetDoc(currentProject.docID);

    console.log('RENDER CARD PROJECT AREA AREA');
    const previewURL = currentProject
      ? `/images/ui/menu/lefttabs/project/${currentProject.classname}/types_${currentProject.classname}_${currentProject.type}_${currentProject.docID}.jpg`
      : null;
    const numCards: number = currentProject
      ? CardHelper.GetTotalCardNum(currentProject.cardPack)
      : 0;

    return (
      <div className="tabContent projectArea">
        {this.state.upgradePopupVisible && (
          <CardProjectCreationView
            isUpgrade="true"
            onCloseCallback={() => {
              this.setState({ upgradePopupVisible: false });
            }}
          />
        )}

        <h3>{GetText('lefttab.project')}</h3>

        {currentProject && (
          <div>
            <Paragraph
              editable={{
                onChange: (str) => {
                  this.handleProjectNameChange(str);
                },
              }}
            >
              {currentProject.name}
            </Paragraph>

            <h4>{GetText('card.type')}</h4>
            <ul style={{ paddingLeft: '20px', listStyleType: 'circle' }}>
              <li>{GetText(`card.prefix.${currentProject.docID}`)}</li>
              <li>{projectSizeInCM}</li>
              <li>
                {GetText('lefttab.card.numTotal')}: {numCards}
              </li>
            </ul>

            {
              // ALLOW UPGRADE?
              GetPossibleUpgrades(currentProject.docID).length > 0 && (
                <Button
                  type="primary"
                  size="default"
                  icon={<FormOutlined />}
                  onClick={() => {
                    this.setState({ upgradePopupVisible: true });
                  }}
                >
                  {GetText('common.modify')}
                </Button>
              )
            }

            {/* // --------------------- CARDS ONLY ------------------------ */}

            <div>
              {/* // ---- pack/model select ---- */}
              <h5 style={{ paddingTop: '12px' }}>
                {GetText('cards.configurator.model.label')}
              </h5>
              <Select
                style={{ width: '100%' }}
                value={currentProject.cardPack}
                size="small"
                onChange={(cardPack) => this.handleCardPackChange(cardPack)}
              >
                {Object.keys(doc.codes).map((val, index) => (
                  <Option key={val} value={val}>
                    {CardHelper.GetPackDisplayName(val)}
                  </Option>
                ))}
              </Select>
            </div>

            {
              /* // ---- ENVELOPPE SELECT ---- */
              this.props.availableEnvelopes &&
                Object.keys(this.props.availableEnvelopes).length > 0 && (
                  <div>
                    <h5 style={{ paddingTop: '12px' }}>
                      {GetText('cards.configurator.envelope.label')}
                    </h5>
                    <Select
                      style={{ width: '75%' }}
                      value={currentProject.envelope}
                      size="small"
                      onChange={(envelopeID) =>
                        this.handleEnvelopeChange(envelopeID)
                      }
                    >
                      {
                        // none option, only if white is not free or not there is no white.
                        (!this.props.availableEnvelopes.white ||
                          parseFloat(
                            this.props.availableEnvelopes.white.price
                          ) !== 0) && (
                          <Option key="none" value="none">
                            {GetText('cards.configurator.envelope.none')}
                          </Option>
                        )
                      }
                      {Object.keys(this.props.availableEnvelopes).map(
                        (id, index) => (
                          <Option key={id} value={id}>{`${GetText(
                            `cards.configurator.envelope.${id}`
                          )} (${
                            this.props.availableEnvelopes[id].price
                          }€)`}</Option>
                        )
                      )}
                    </Select>

                    {
                      // enveloppe
                      currentProject && currentProject.envelope !== 'none' && (
                        <svg
                          style={{
                            float: 'right',
                            marginLeft: '5px',
                            marginTop: '0px',
                            width: '20%',
                            height: '25px',
                          }}
                        >
                          <g>
                            {/* <circle fill={Colors.BACKGROUND_COLOR}
                        cx={holeCenterPos} cy={positionFromEdge}
                        r={holeSize/2}
                        /> */}

                            {/*  --- adress lines ---*/}
                            <line
                              x1={0}
                              y1={0}
                              x2="50%"
                              y2="60%"
                              style={{
                                pointerEvents: 'none',
                                stroke: Colors.BLACK,
                                strokeWidth: '1px',
                              }}
                            />
                            <line
                              x1="50%"
                              y1="60%"
                              x2="100%"
                              y2="0%"
                              style={{
                                pointerEvents: 'none',
                                stroke: Colors.BLACK,
                                strokeWidth: '1px',
                              }}
                            />

                            <rect
                              fill={CardHelper.GetEnvelopeColorByID(
                                currentProject.envelope
                              )}
                              fillOpacity={0.8}
                              // x={holeCenterPos -spiralMargin*.5 - spiralSize}
                              style={{
                                pointerEvents: 'none',
                                stroke: Colors.GREY,
                                strokeWidth: '1px',
                              }}
                              // y={0}
                              width="100%"
                              height="100%"
                            />
                            {/* <rect fill={Colors.BLACK} fillOpacity={0.8}
                        x={holeCenterPos -spiralMargin*.5}
                        y={0}
                        width={spiralSize} height={positionFromEdge}
                        />  */}
                          </g>
                        </svg>
                      )
                    }
                  </div>
                )
            }

            {/* <div className="spacerV" /> */}
            <div className="spacerV" />
            <h4>{GetText('lefttab.card.option')}</h4>

            {
              /* // ---- COATING ---- */
              doc &&
                doc.finish.includes('coating') &&
                !Number.isNaN(this.props.projectOptionsPrice.coated) && (
                  <div>
                    <Tooltip
                      title={GetText('infos.lefttab.cards.coating')}
                      placement="right"
                    >
                      <Checkbox
                        checked={currentProject.coated}
                        onChange={(e) =>
                          this.handlePaperFinishChange(
                            PAPER_FINISH.COATED,
                            e.target.checked
                          )
                        }
                      >
                        {GetText('lefttab.project.cards.coated')}{' '}
                        {`(${this.props.projectOptionsPrice.coated}€)`}
                      </Checkbox>
                    </Tooltip>
                  </div>
                )
            }

            {
              /* // ---- MATTE ---- */
              doc &&
                doc.finish.includes('mate') &&
                !Number.isNaN(this.props.projectOptionsPrice.matte) && (
                  <div>
                    <Tooltip
                      title={GetText('infos.lefttab.cards.matte')}
                      placement="right"
                    >
                      <Checkbox
                        checked={currentProject.matte}
                        onChange={(e) =>
                          this.handlePaperFinishChange(
                            PAPER_FINISH.MATTE,
                            e.target.checked
                          )
                        }
                        style={{
                          display: currentProject.matte
                            ? 'inline-flex'
                            : 'none',
                        }}
                      >
                        {GetText('lefttab.project.cards.matte')}{' '}
                        {`(${this.props.projectOptionsPrice.matte}€)`}
                      </Checkbox>
                    </Tooltip>
                  </div>
                )
            }

            {/* // --------------------- PREVIEW ------------------------ */}

            <Row className="preview">
              <img src={previewURL} />
            </Row>
          </div>
        )}
      </div>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, edition } = state;
  const { project } = edition;

  return {
    project,
    docID: project ? project.docID : null,
    availableEnvelopes:
      pricingSelectors.GetAvailableEnvelopeStockAndPrice(state),
    projectName: editionSelectors.GetProjectName(state),
    projectOptions: editionSelectors.GetProjectOptionsSelector(state),
    projectOptionsPrice: pricingSelectors.GetProjectOptionsPrice(state),
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(CardProjectArea);
export { reduxConnected as CardProjectArea };
