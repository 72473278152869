import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureException } from '@sentry/browser';
import { cloneDeep } from 'lodash';
import { editionActions } from '../feature/edition/edition';
import { editionSelectors } from '../feature/edition/edition.selector';
import { Frame, IPage } from '../types/types';
import { useTempPhotoReplace } from './useTempPhotoReplace';

const notifyError = (message: string, details?: object) => {
  console.warn('usePageEditing:' + message, details || 'no details');
  // TODO: fix this
  //  for now we don't capture the error anymore because it's too frequent in sentryand it's not really an error
  // captureException(new Error('usePageEditing:' + message));
  return;
};

export const usePageEditing = () => {
  const dispatch = useDispatch();
  const project = useSelector(editionSelectors.GetProjectSelector);
  const { pageList } = project;
  const selectedPageIndex = useSelector(editionSelectors.GetSelectedPageIndex);
  const selectedFrameId = useSelector(editionSelectors.GetSelectedFrameID);
  const { checkReplaceTempPhotoID } = useTempPhotoReplace();

  const [status, setStatus] = useState<
    'no-editing' | 'editing-in-progress' | 'waiting-for-publish'
  >('no-editing');

  // TODO: later check if we need to use a context for this to be sure all componetns have access to the same state.
  // for now we use it only in editionArea and mainToolbar, but this might change in the future
  const [editedPage, setEditedPage] = useState<IPage | undefined>(undefined);
  const [editAction, setEditAction] = useState<string | undefined>(undefined);

  // --------------------- methods ------------------------

  // after an enditin, we reset the pageEditing state
  const reset = () => {
    setEditedPage(undefined);
    setEditAction(undefined);
    setStatus('no-editing');
  };

  /**
   * Starts the page editing process
   * it clones the page and frame so we can edit them without changing the store yet
   */
  const start = (action?: string | undefined) => {
    if (editedPage)
      return notifyError(
        'we should never start a new page edition without ending the previous one...',
        { editedPage }
      );

    // we clone the page so we can edit it without changing the store yet
    const pageCopy = cloneDeep(pageList[selectedPageIndex]);
    if (!pageCopy) return notifyError('Page not found');

    setEditedPage(pageCopy);
    setEditAction(action);
    setStatus('editing-in-progress');
  };

  /**
   * Modifies the edited page
   * it updates the edited page with the new one
   */
  const modifyFrame = (modifyCallback: { (frame: Frame): Frame }) => {
    // update edited frame
    setEditedPage((page) => {
      if (!page) {
        notifyError(
          'we should never modify a frame without starting the edition...'
        );
        return undefined;
      }

      // each time we modify a frame, we ensure the frame is part of the current edited page (which is rendererd during edition)
      const frame = page.frames.find((f) => f.id === selectedFrameId);
      if (!frame) notifyError('Frame not found');
      return {
        ...page,
        frames: page.frames.map((f) =>
          f.id === selectedFrameId ? modifyCallback(f) : f
        ),
      };
    });
  };

  const modifyPage = (modifyCallback: {
    (page: IPage, frame: Frame): IPage;
  }) => {
    // update edited frame
    setEditedPage((page) => {
      if (!page) {
        notifyError(
          'we should never modify a Page without starting the edition...'
        );
        return undefined;
      }

      // be sure while editing page that the editedFrame is still in the page
      const frame = page.frames.find((f) => f.id === selectedFrameId);
      return modifyCallback(page, frame);
    });
  };

  /**
   * Ends the page editing process
   * it saves the page on the global store and adds an undoable action
   */
  const end = () => {
    setStatus('waiting-for-publish');
  };

  //--------------------- effect ------------------------

  /**
   * Effect: if we are not editing anymore but we have an edited page, we publish it.
   **/
  useEffect(() => {
    if (status === 'waiting-for-publish') {
      // console.log('endPageEdit => effect');
      // security check
      if (!editedPage) {
        const msg = 'we should never endPageEdition with no edited page...';
        console.warn(msg);
        captureException(new Error(msg));
        return;
      }

      const clonedPage = cloneDeep(editedPage);
      // each time we end a page editing, we verify the photosID
      clonedPage.frames.forEach((frame) => {
        if (frame.photo) {
          frame.photo = checkReplaceTempPhotoID(frame.photo);
        }
      });
      // save the page on global store
      dispatch(editionActions.UpdatePage(clonedPage));

      // editing over, add undoable action
      dispatch(editionActions.AddUndoableAction());

      // reset the state
      reset();
    }
  }, [status, dispatch, editedPage, checkReplaceTempPhotoID]);

  //--------------------- return ------------------------

  return {
    page: editedPage,
    action: editAction,

    reset, // this allows to reset state (stop the editing)
    start,
    modifyFrame,
    modifyPage,
    end,
  };
};
