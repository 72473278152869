import { Fragment, useEffect, useRef, useState } from 'react';
import { FaFileImport } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseCircleOutlined,
  EditOutlined,
  FileImageFilled,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, ButtonProps, Input, Modal, Popover, Radio } from 'antd';
import { motion } from 'framer-motion';
import { GetText } from '../../data/LanguageHelper';
import { editionSelectors } from '../../feature/edition/edition.selector';
import { photoListSelector } from '../../feature/photoList/photo.selector';
import { photoListActions } from '../../feature/photoList/photo.store';
import { PHOTO_FILTER_OPTIONS } from '../../feature/photoList/photo.type';
import { projectListSelectors } from '../../feature/projectList/projectList';
import { usePhotoUploader } from '../../feature/upload/PhotoUploaderProvider';
import PhotoItem from '../../pages/homePage/leftArea/PhotoItem';
import {
  getPhotoCategoriesSelector,
  getProjectCategoryIdSelector,
} from '../../store/customSelectors/selectors';
import { Category, ProjectListVo } from '../../types/types';
import { API } from '../../utils/API';
import { AddDragData, DRAG_TYPE } from '../../utils/DragUtils';
import { sortCategories } from '../../utils/folder/folderHelper';
import { usePagination } from '../../utils/usePagination';
import { PhotoDetailPopover } from '../popover/PhotoDetailPopover';
import { DragGhostImage } from './_components/DragGhostImage';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { SPECIAL_CATEGORIES } from './types';

type Props = {
  onClose: () => void;
};

const MAX_PHOTOS_WITHOUT_PAGINATION = 200;
const leftPaneWidth = 230;

export const PhotoManager = ({ onClose }: Props) => {
  //-------------------- redux ---------------------
  const dispatch = useDispatch();
  const categoriesSiderRef = useRef<HTMLDivElement>(null);
  const projectCategoryId = useSelector(getProjectCategoryIdSelector);
  const photoFilter = useSelector(photoListSelector.sortFilterSelector);
  const photosByCategory = useSelector(photoListSelector.getPhotosByCategories);
  const allProjects = useSelector(projectListSelectors.getAllProjects);
  const photoUsed = useSelector(editionSelectors.GetPhotosUsedSelector);
  const backendCategories = useSelector(getPhotoCategoriesSelector);
  const { startImportImages } = usePhotoUploader();

  //-------------------- state ---------------------

  const [currentCategory, setCurrentCategory] = useState<Category>(
    backendCategories[0]
  );

  const [createdCategories, setCreatedCategories] = useState<string[]>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [currentDropFolder, setCurrentDropFolder] = useState<string>();
  const [isDragging, setIsDragging] = useState(false);

  const canEditCategoryName =
    !currentCategory.isProject &&
    !(
      [SPECIAL_CATEGORIES.DELETED, SPECIAL_CATEGORIES.EDITOR2] as string[]
    ).includes(currentCategory.id);

  // all possible categories
  // const photoCategoriesRaw = useMemo(() => {
  //   return [...new Set(allPhotos.map((photo) => photo.cat))];
  // }, [allPhotos]);

  // map project ids with categories
  const projectMap = {};
  allProjects?.forEach((project: ProjectListVo) => {
    projectMap[project.id] = project.name;
  });
  // const photoCategories: string[] =
  //   projectMap && photoCategoriesRaw
  //     ? photoCategoriesRaw
  //         // NOTE: for now we don't display the project id categories, just remove this line to show them or add an option in the filter
  //         .filter((category) => !category.includes('@@'))
  //         .map((category) =>
  //           category.includes('@@')
  //             ? projectMap[category.split('@@').join('')]
  //             : category
  //         )
  //     : [];

  const allCategories: Category[] = [
    ...new Set([
      ...createdCategories
        // be sure the created category has not yet been created on backend, if so, we don't display it
        .filter((cat) => !backendCategories.some((bc) => bc.id === cat))
        // created categoris are just string, we need to convert them to Category
        .map((c) => ({ id: c, label: c, isProject: false })),
      ...backendCategories,
    ]),
  ].sort(sortCategories);

  // const getCategoryById = (id: string) => {
  //   return allCategories.find((cat) => cat.id === id);
  // };

  const projectCategory = allCategories.find(
    (cat) => cat.id === projectCategoryId
  );

  const filteredPhotos = photosByCategory[currentCategory.id] || [];

  // const filteredPhotos = useMemo(() => {
  //   let photos = allPhotos;
  //   if (!currentCategory || currentCategory === SPECIAL_CATEGORIES.PROJECT)
  //     photos = sessionPhotos;
  //   else if (currentCategory !== SPECIAL_CATEGORIES.ALL)
  //     photos = allPhotos.filter((photo) => photo.cat === currentCategory);
  //   // we want photos from the more recent to the oldest
  //   photos.sort(
  //     (a, b) => Number(b.modification_date) - Number(a.modification_date)
  //   );

  //   return photos;
  // }, [currentCategory, sessionPhotos, allPhotos]);

  const numCols = Math.floor((window.innerWidth - 300) / 92);
  const numRows = Math.floor((window.innerHeight - 320) / 91);
  const photosPerPage =
    numCols * numRows > MAX_PHOTOS_WITHOUT_PAGINATION
      ? MAX_PHOTOS_WITHOUT_PAGINATION
      : numCols * numRows;

  // in case of more than 200 pictures, we will use pagination
  const totalPages =
    filteredPhotos.length > MAX_PHOTOS_WITHOUT_PAGINATION
      ? Math.ceil(filteredPhotos.length / photosPerPage)
      : 1;
  const { pageIndex, setPageIndex, nextPage, previousPage } = usePagination(
    totalPages,
    0
  );

  //-------------------- methods ---------------------

  const scrollToCategory = (categoryId: string) => {
    const element = document.getElementById(categoryId);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const handleCreateNewFolder = () => {
    let inputValue = '';
    const handleNewFolderConfirm = () => {
      if (inputValue === '') return;
      const existingCategory = allCategories.find(
        (cat) => cat.id === inputValue
      );
      if (existingCategory) {
        setCurrentCategory(existingCategory);
        scrollToCategory(existingCategory.id);
        return;
      }
      // categoriesSiderRef.current.scrollIntoView({ behavior: 'smooth' });
      setCreatedCategories([...createdCategories, inputValue]);
      setCurrentCategory({
        id: inputValue,
        label: inputValue,
        isProject: false,
      });
      scrollToCategory(inputValue);

      /*
      TODO: 2024
      NOTE: is this really needed? I think we set the category of an existing picture.. and this is what creates a new category..
      */
      //API.createCategory(inputValue);
    };
    Modal.confirm({
      title: GetText('popup.photomanager.new.folder.title'),
      content: (
        <Input
          defaultValue={inputValue}
          onChange={(e) => {
            inputValue = e.target.value;
          }}
        />
      ),
      onOk: () => handleNewFolderConfirm(),
    });
  };

  const handleRenameFolder = () => {
    let inputValue = currentCategory.id;
    const handleRenameConfirm = async () => {
      if (inputValue === '') return;
      // verify name doesnt already exist
      const existingCategory = allCategories.find(
        (cat) => cat.id === inputValue
      );
      if (existingCategory) {
        setCurrentCategory(existingCategory);
        return;
      }
      API.renameCategory(currentCategory.id, inputValue).then(() => {
        dispatch(photoListActions.getAll());
        setCurrentCategory({
          id: inputValue,
          label: inputValue,
          isProject: false,
        });
      });
    };
    Modal.confirm({
      title: GetText('popup.photomanager.new.folder.title'),
      content: (
        <Input
          defaultValue={inputValue}
          onChange={(e) => {
            inputValue = e.target.value;
          }}
        />
      ),
      onOk: () => handleRenameConfirm(),
    });
  };

  const handleSelectionMove = async (category: Category) => {
    // if (categoryId === SPECIAL_CATEGORIES.PROJECT) {
    //   handleAddPhotosToProject();
    //   return;
    // }
    await API.movePhotos(selectedPhotos, category.id);
    dispatch(photoListActions.getAll());
    setSelectedPhotos([]);
    setCurrentCategory(category);
  };

  // a selection delete is a move to the deleted category for now.
  // so we don't lose the photos, but we can't see them anymore
  const handleSelectionDelete = () => {
    // check if theare are photos used in the project, if so, we can't delete them
    if (selectedPhotos.some((id) => photoUsed.includes(id))) {
      Modal.error({
        title: 'Erreur',
        content:
          'Vous ne pouvez pas supprimer une photo utilisée dans le projet actuel',
      });
      return;
    }

    // if we are in the project category, we remove the photos from the project, not delete them
    // if (currentCategory === SPECIAL_CATEGORIES.PROJECT) {
    //   dispatch(photoListActions.removePhotosFromProject(selectedPhotos));
    //   setSelectedPhotos([]);
    //   return;
    // }

    API.movePhotos(selectedPhotos, SPECIAL_CATEGORIES.DELETED).then(() => {
      dispatch(photoListActions.getAll());
      setSelectedPhotos([]);
    });
  };

  // const handleAddPhotosToProject = () => {
  //   dispatch(photoListActions.addPhotosToProject(selectedPhotos));
  //   setSelectedPhotos([]);
  //   setCurrentCategory(SPECIAL_CATEGORIES.PROJECT);
  //   // TODO: show message to tell user everything went well?
  // };

  const handleAllSelectionDragStart = (e) => {
    AddDragData(e, DRAG_TYPE.PHOTOS, JSON.stringify(selectedPhotos));
    setIsDragging(true);

    // create invisible image for drag and drop as we will use our custom ghost image
    const invisibleDrag = document.createElement('div');
    invisibleDrag.style.visibility = 'hidden';
    invisibleDrag.style.backgroundColor = 'red';
    invisibleDrag.style.width = '200px';
    invisibleDrag.style.height = '20px';
    document.body.appendChild(invisibleDrag);
    (e.dataTransfer as DataTransfer).setDragImage(invisibleDrag, 0, 0);

    const dragEle = e.target;
    const handleDragEnd = () => {
      invisibleDrag.remove();
      setIsDragging(false);
    };

    dragEle.addEventListener('dragend', handleDragEnd);
  };

  const getCategoryItemProps = (category: Category): ButtonProps => {
    return {
      id: category.id,
      type: category.id === currentCategory.id ? 'primary' : 'dashed',
      style:
        currentDropFolder === category.id
          ? { backgroundColor: 'var(--selectionColor)' }
          : {},
      onDragOver: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCurrentDropFolder(category.id);
      },
      onDrop: () => {
        handleSelectionMove(category);
        setCurrentDropFolder(undefined);
      },
      onDragLeave: () => setCurrentDropFolder(undefined),
      onClick: () => setCurrentCategory(category),
      children: category.label,
    };
  };

  /**
   * Update photo filter
   */
  const handlePhotoFilterChange = (newFilter) => {
    dispatch(photoListActions.updateSortFilter(newFilter));
  };

  //-------------------- effect ---------------------

  // each time we change the current category, we reset the selected photos
  useEffect(() => {
    setSelectedPhotos([]);
    setPageIndex(0);
  }, [currentCategory, setPageIndex]);

  // on mount, we select the current project category
  useEffect(() => {
    if (projectCategory) {
      setCurrentCategory(projectCategory);
    }
  }, [projectCategory?.id]);

  //-------------------- render ---------------------

  return (
    <motion.div
      className="w-full h-full absolute top-0 left-0 bg-primaryLight z-20"
      initial={{
        opacity: 0,
        scale: 0.9,
        x: -100,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        x: 0,
        transition: {
          ease: 'easeOut',
          duration: 0.4,
        },
      }}
    >
      <Layout className="h-full">
        <Header className="bg-[var(--primary)] text-white flex justify-between pl-4 pr-5">
          <h2 className="text-white">{GetText('photomanager.panel.title')}</h2>
          <Button
            type="text"
            shape="circle"
            className="text-white pt-4 hover:text-primaryLight hover:scale-110"
            // size="large"
            icon={<CloseCircleOutlined className="text-3xl" />}
            onClick={onClose}
          />
        </Header>
        <Layout>
          <Sider width={leftPaneWidth}>
            <div className="relative flex flex-col bg-primaryLighter h-full overflow-y-auto px-2 pt-5">
              {projectCategory && (
                <Button
                  className="w-full mb-5 text-wrap h-fit hover:border-primaryDark hover:text-primaryLight"
                  size="large"
                  {...getCategoryItemProps(projectCategory)}
                />
              )}

              <div
                ref={categoriesSiderRef}
                key={allCategories.length}
                className="flex flex-col mb-20"
              >
                <div className="text-center font-bold text-gray-600 pb-2">
                  {GetText('photomanager.category.myFolders')}
                </div>
                {allCategories
                  .filter((cat) => cat.id !== projectCategory?.id)
                  .map((category, index, arr) => {
                    const displaySeparator =
                      category.isProject &&
                      index > 0 &&
                      !arr[index - 1].isProject;

                    return (
                      <Fragment key={category.id}>
                        {displaySeparator && (
                          <>
                            <div className="text-center text-gray-600 font-bold py-2">
                              {GetText('photomanager.category.myProjects')}
                            </div>
                          </>
                        )}
                        <Button
                          key={category.id}
                          className="w-full mb-1 overflow-hidden hover:border-primaryDark hover:text-primaryDark"
                          {...getCategoryItemProps(category)}
                        />
                      </Fragment>
                    );
                  })}
              </div>

              <div
                className="fixed bottom-0 left-0 px-2 py-4 bg-white  flex flex-col"
                style={{ width: leftPaneWidth }}
              >
                {/* <Button
                  type="dashed"
                  className="w-full mb-1"
                  onClick={() => setCurrentCategory(SPECIAL_CATEGORIES.ALL)}
                >
                  all photos
                </Button> */}
                <Button
                  className="w-full"
                  type="primary"
                  onClick={handleCreateNewFolder}
                >
                  <PlusOutlined /> {GetText('photomanager.panel.btn.newfolder')}
                </Button>
              </div>
            </div>
          </Sider>
          <Layout>
            <Content className="p-4 flex flex-col h-full gap-2">
              {/* ---------------------- TOOLBAR ---------------------*/}
              <div className="flex gap-2 items-center justify-between pb-2">
                {currentCategory && (
                  <>
                    <h2 className="pt-2">
                      {currentCategory.label}{' '}
                      <span className="text-sm">
                        ({filteredPhotos.length} photos)
                      </span>
                    </h2>
                    {canEditCategoryName && (
                      <Button
                        shape="circle"
                        size="small"
                        icon={<EditOutlined />}
                        onClick={handleRenameFolder}
                      />
                    )}
                  </>
                )}

                {selectedPhotos.length !== filteredPhotos.length ? (
                  <Button
                    shape="round"
                    type="default"
                    onClick={() =>
                      setSelectedPhotos(filteredPhotos.map((p) => p.id))
                    }
                  >
                    {GetText('photomanager.toolbar.selectAll')}
                  </Button>
                ) : (
                  <Button
                    shape="round"
                    type="default"
                    onClick={() => setSelectedPhotos([])}
                  >
                    {GetText('photomanager.toolbar.unselectAll')}
                  </Button>
                )}

                <Popover
                  placement="right"
                  trigger="click"
                  content={
                    <Radio.Group
                      value={photoFilter}
                      onChange={(e) => handlePhotoFilterChange(e.target.value)}
                    >
                      {Object.keys(PHOTO_FILTER_OPTIONS).map((filterOption) => {
                        const radioStyle = {
                          display: 'block',
                          height: '30px',
                          lineHeight: '30px',
                        };

                        return (
                          <Radio
                            key={filterOption}
                            style={radioStyle}
                            value={filterOption}
                          >
                            {GetText(filterOption)}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  }
                >
                  <Button shape="round" type="default">
                    {GetText('photoarea.sort.btn')}
                  </Button>
                </Popover>

                {/* {selectedPhotos.length > 0 &&
                  currentCategory !== SPECIAL_CATEGORIES.PROJECT && (
                    <Button
                      shape="round"
                      type={'primary'}
                      onClick={handleAddPhotosToProject}
                    >
                      Utiliser dans ce projet
                    </Button>
                  )} */}

                <div className="flex-grow" />

                {selectedPhotos.length > 0 && (
                  <Button
                    danger
                    shape="round"
                    type={'primary'}
                    onClick={handleSelectionDelete}
                  >
                    {selectedPhotos.length < 2
                      ? 'Supprimer'
                      : `Supprimer ${selectedPhotos.length} photos`}
                  </Button>
                )}
              </div>

              {/* ---------------------- CONTENT (photos) ---------------------*/}

              <div
                className={
                  'flex flex-wrap flex-1 content-start overflow-y-auto gap-1'
                }
              >
                {filteredPhotos.length === 0 && (
                  <div className="flex flex-col w-full h-full justify-center items-center ">
                    <h2 className=" text-gray-400">
                      {GetText('photomanager.folder.empty')}
                    </h2>
                    <Button
                      className="bg-selection font-bold items-center flex"
                      icon={<FaFileImport className="text-md mr-2" />}
                      size="large"
                      onClick={() => startImportImages(currentCategory.id)}
                    >
                      {GetText('photomanager.footer.button.import')}
                    </Button>
                  </div>
                )}

                {filteredPhotos
                  // filter per page
                  .filter((_, index) => {
                    if (totalPages === 1) return true;
                    const start = pageIndex * photosPerPage;
                    const end = start + photosPerPage;
                    return index >= start && index < end;
                  })
                  // map to components
                  .map((photo) => {
                    const isPhotoSelected = selectedPhotos.includes(photo.id);
                    return (
                      <PhotoDetailPopover
                        photo={photo}
                        key={photo.id}
                        isUsed={photoUsed.includes(photo.id)}
                      >
                        <div
                          key={photo.id}
                          className="relative cursor-pointer "
                        >
                          {/* Picture overlay to allow selection */}
                          <div
                            draggable={isPhotoSelected}
                            onDragStart={handleAllSelectionDragStart}
                            onDragEnd={() => setIsDragging(false)}
                            className={`absolute top-0 left-0 w-full h-full z-10 border-solid border-2  ${
                              isPhotoSelected ? 'border-primaryDark' : 'none'
                            }`}
                            onClick={() => {
                              if (isPhotoSelected) {
                                setSelectedPhotos(
                                  selectedPhotos.filter((id) => id !== photo.id)
                                );
                              } else {
                                setSelectedPhotos([
                                  ...selectedPhotos,
                                  photo.id,
                                ]);
                              }
                            }}
                          >
                            {isPhotoSelected && (
                              <Radio
                                className="rounded-lg px-0 py-0 ml-0.5"
                                checked={isPhotoSelected}
                              />
                            )}
                          </div>

                          <PhotoItem
                            key={photo.id}
                            photo={photo}
                            used={photoUsed.includes(photo.id)}
                            dispatch={dispatch}
                          />
                        </div>
                      </PhotoDetailPopover>
                    );
                  })}
              </div>

              {/* ---------------------- pagination ---------------------*/}
              {totalPages > 1 && (
                <div className="flex w-full justify-between rounded-3xl p-2 shadow-sm bg-gray-200 items-center">
                  <Button
                    shape="round"
                    type="default"
                    onClick={previousPage}
                    disabled={pageIndex === 0}
                  >
                    Previous page
                  </Button>
                  <div className="text-md">
                    page: <b>{pageIndex + 1}</b>/<b>{totalPages}</b>
                  </div>
                  <Button
                    shape="round"
                    type="default"
                    onClick={nextPage}
                    disabled={pageIndex === totalPages - 1}
                  >
                    Next page
                  </Button>
                </div>
              )}
            </Content>
            {/* ---------------------- footer  ---------------------*/}
            <div className="flex p-3 justify-end bg-primaryLighter">
              <Button
                className="bg-selection font-bold items-center flex"
                icon={<FaFileImport className="text-md mr-2" />}
                size="large"
                onClick={() => startImportImages(currentCategory.id)}
              >
                {GetText('photomanager.footer.button.import')}
              </Button>
            </div>
          </Layout>
        </Layout>
      </Layout>

      {/** Ghost image for drag and drop */}
      {isDragging && (
        <DragGhostImage>
          <div className="bg-blue-500 text-white px-2 py-1 rounded-md flex gap-2">
            <FileImageFilled />
            Move {selectedPhotos.length} images
          </div>
        </DragGhostImage>
      )}
    </motion.div>
  );
};
