import { capitalize } from 'lodash';
import { config, IsAlbumEditor, PROJECT_CONST } from '../../data/config';
import {
  ALBUM_TYPES,
  COVER_CLASSIC_CORNERS_TYPE,
  COVER_CLASSIC_FABRIC_TYPE,
  COVER_TYPE,
  PROJECT_CLASS,
} from '../../data/Constants';
import { GetText } from '../../data/LanguageHelper';
import { FRAME_TYPE } from '../../feature/frame/frame.types';
import { applyLayoutToPage } from '../../feature/layouts/helpers/applyLayoutToPage';
import { GetProjectOptions } from '../../feature/project/GetProjectOptions';
import { Project, ProjectOptions } from '../../types/project';
import { IPage } from '../../types/types';
import { DateUtils } from '../DateUtils';
import { RandomArrayElement } from '../MathUtils';
import { mmToPoint } from '../MeasureUtils';
import { CreatePage } from '../pageHelper';
import { GetDoc } from '../ProductHelper';
import { IsClassicCoverProject } from '../projectHelper';
import {
  CheckAndUpdateProjectSpineWidth,
  getSpineWidthMM,
} from '../spineHelper';

// --------------------- COVER CREATION ------------------------

/**
 *
 * @param {Project} project
 * @param {Array<String>} coverLayoutsList
 * @param {Object} layoutsByID
 *
 */
export function CreateProjectCustomCover(
  project: Project,
  coverLayoutsList,
  layoutsByID
) {
  const doc = GetDoc(project.docID);
  const coverNode = doc.cover;
  const randomLayoutCover = RandomArrayElement(coverLayoutsList);
  const coverPage = CreatePage(
    0,
    coverNode.width * 2 * coverNode.multiplier,
    coverNode.height * coverNode.multiplier
  );
  coverPage.isCover = true;
  applyLayoutToPage(
    project,
    coverPage,
    layoutsByID[randomLayoutCover],
    null,
    null
  );

  // if there is already a cover, remove it (this should never happen..)
  if (project.pageList && project.pageList[0] && project.pageList[0].isCover) {
    project.pageList.shift();
    console.warn(
      'ProjectHelper: customCover creation but there was already a cover..'
    );
  }

  // add new cover page at start
  project.pageList.unshift(coverPage);

  // update cover spine
  CheckAndUpdateProjectSpineWidth(project);
  return coverPage;
}

export function CreateClassicCoverPage(
  project,
  optionalCoverName: string = null,
  optionalCoverCorners: string = null
) {
  const doc = GetDoc(project.docID);
  const coverNode = doc.cover;
  const coverPage = CreatePage(
    0,
    coverNode.width * coverNode.multiplier,
    coverNode.height * coverNode.multiplier
  );
  coverPage.isCover = true;
  coverPage.coverClassicOptions = CreateClassicCoverOptions();

  if (optionalCoverName) {
    coverPage.coverClassicOptions.cover = optionalCoverName;
    coverPage.coverClassicOptions.coverLabelName =
      CoverLabelNameToCoverDisplayName(optionalCoverName);
    coverPage.coverClassicOptions.coverFabric = optionalCoverName
      .toLowerCase()
      .includes(COVER_CLASSIC_FABRIC_TYPE.LEATHER)
      ? COVER_CLASSIC_FABRIC_TYPE.LEATHER
      : COVER_CLASSIC_FABRIC_TYPE.LINEN;
  }

  if (optionalCoverCorners)
    coverPage.coverClassicOptions.corners = optionalCoverCorners;

  return coverPage;
}

// --------------------- COVER HELPERS ------------------------

/**
 * Retrieve cover bounds in pixel
 */
export function GetCoverSizeInPixel(project: Project): {
  width: number;
  height: number;
} {
  const doc = GetDoc(project.docID);
  const coverDoc = doc.cover;
  const coverWidth = coverDoc.width * coverDoc.multiplier;
  const coverHeight = coverDoc.height * coverDoc.multiplier;
  if (IsClassicCoverProject(project)) {
    return { width: coverWidth, height: coverHeight };
  }

  const spineWidth = mmToPoint(getSpineWidthMM(GetProjectOptions(project)));
  return { width: coverWidth * 2 + spineWidth, height: coverHeight };
}

/**
 *
 * @param {ProjectOptions} projectOptions
 * @returns {Boolean}
 */
export function HasCustomCover(project: Project): boolean {
  return IsAlbumEditor() && !IsClassicCoverProject(project);
}

/**
 *
 * @param {Project} project
 * @returns {ClassicCoverOptions}
 */
export const GetClassicCoverOptions = (project) =>
  IsClassicCoverProject(project)
    ? GetProjectCover(project).coverClassicOptions
    : null;

/**
 *
 * @param {ProjectOptions} projectOptions
 * @returns {Boolean}
 */
export function HasClassicCoverText(projectOptions) {
  const classicOptions = projectOptions.coverClassicOptions;
  return (
    classicOptions &&
    (classicOptions.line1 !== '' ||
      classicOptions.line2 !== '' ||
      classicOptions.spine !== '' ||
      classicOptions.edition !== '')
  );
}

/**
 *
 * @param {ProjectOptions} projectOptions
 * @returns {Boolean}
 */
export function HasClassicCoverCorners(projectOptions) {
  const classicOptions = projectOptions.coverClassicOptions;
  return (
    classicOptions &&
    classicOptions.corners &&
    classicOptions.corners !== COVER_CLASSIC_CORNERS_TYPE.NONE
  );
}

/**
 *
 * @param {Project} project
 * @returns {IPage}
 */
export const GetProjectCover = (project: Project): IPage => {
  if (project && project.pageList[0]?.isCover) return project.pageList[0];
  return null;
};

/**
 *
 * @param {Project} project
 * @returns {Frame}
 */
export const GetProjectSpine = (project) => {
  if (HasCustomCover(project)) {
    const cover = GetProjectCover(project);
    return cover.frames.filter((frame) => frame.type === FRAME_TYPE.SPINE)[0];
  }

  return null;
};

/**
 *
 * @param {Project} project
 * @returns {Frame}
 */
export const GetProjectSpineNum = (project) => {
  if (HasCustomCover(project)) {
    const cover = GetProjectCover(project);
    return cover.frames.filter(
      (frame) => frame.type === FRAME_TYPE.SPINE_NUM
    )[0];
  }

  return null;
};

// Mostly for pricint, we need the cover labelName to be sent to backend based on cover type
export const GetCoverLabelName = (cover: COVER_TYPE) => {
  if (cover === COVER_TYPE.COVER_CLASSIC) return 'Leather Black';
  else return 'Custom Cover';
};

/**
 *
 * @param {IPage} page
 * @returns {Boolean}
 */
export function IsClassicCoverPage(page) {
  return !!(page.isCover && page.coverClassicOptions);
}

export function IsCover(docID: string, pageIndex: number) {
  if (pageIndex === 0) {
    if (PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM) return true;

    // TODO: check if all calendars have cover!
    if (PROJECT_CONST.project_class === PROJECT_CLASS.CALENDAR) return true;
  }

  return false;
}

// --------------------- classic cover ------------------------

/**
 * Using plain object to avoid issue with redux!
 * @returns {ClassicCoverOptions}
 */
export function CreateClassicCoverOptions() {
  /** @type {ClassicCoverOptions} * */
  const classicOptions = {
    line1: '',
    line2: '',
    spine: '',
    edition: '',
    cover: 'Leather Black',
    corners: 'none',
    color: 'gold',
    fontName: 'Arial',
    coverLabelName: 'leatherblack',
    coverFabric: COVER_CLASSIC_FABRIC_TYPE.LEATHER,
  };
  return classicOptions;
}

/**
 * cover name separated with uppercase is needed for the cover generation (preview)
 * --> from "leatherblack" to "Leather Black"
 */
export function CoverLabelNameToCoverDisplayName(coverLabelName: string) {
  if (coverLabelName.indexOf('linen') !== -1)
    return `Linen ${capitalize(String(coverLabelName).split('linen')[1])}`;

  if (coverLabelName.indexOf('leather') !== -1)
    return `Leather ${capitalize(String(coverLabelName).split('leather')[1])}`;

  console.warn(`Not able to convert coverLabelName:${coverLabelName}`);
  return 'Leather Black';
}

// from "Leather Black" to "leatherblack"
export function CoverToCoverLabelName(coverName: string) {
  return coverName.split(' ').join('').toLowerCase();
}

// -> to translated cover name -> "Tissu Bleu" for linenblue
export function GetAlbumCoverDisplayName(project) {
  const cover = GetProjectCover(project);
  if (cover.coverClassicOptions)
    return GetText(
      `cover.${cover.coverClassicOptions.coverFabric}.${cover.coverClassicOptions.coverLabelName}.label`
    );
  return GetText('cover.custom.label');
}

/**
 *
 * @param {ClassicCoverOptions} options
 * @param {Boolean} withText
 * @returns
 */
export function ComposeClassicCoverURL(options, withText = true) {
  const optionCopy = { ...options };

  // for localhost, we alwys display final render of the classic cover
  // if(IsLocalhost)
  //  withText = true;

  if (!withText) {
    // to avoid rendering the image on backend (not good quality), be sure the params are empty for text
    optionCopy.line1 = '';
    optionCopy.line2 = '';
    optionCopy.spine = '';
    optionCopy.edition = '';

    // TODO: corners??
    // variables = new URLVariables();
    // variables.line1 = "";
    // variables.line2 = "";
    // variables.spine = "";
    // variables.edition = "";
    // variables.font = "Arial";
    // variables.color = voCasted.color;
    // variables.corners = voCasted.corners;
    // variables.cover = voCasted.cover;
  }
  optionCopy.font = optionCopy.fontName; // backend use "font" as parameter

  // url params
  let params: string;
  Object.keys(optionCopy).forEach((key) => {
    if (params !== '') {
      params += '&';
    }
    params += `${key}=${encodeURIComponent(optionCopy[key])}`;
  });

  // add a day timestamp, sometimes the backend fails to deliver a correct image, we want to be sure the cache do not hold it!
  const timestamp = DateUtils.GetTodayTimeStamp();

  // final url
  return `${config.baseUrl}/wizard/images/preview-cover.php?t=${timestamp}&${params}`;
}
