import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Popover, Row } from 'antd';
import { GetText } from '../../../data/LanguageHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import {
  GetProjectDisplayPage,
  GetProjectMaxPages,
  GetProjectMinPages,
} from '../../../utils/projectHelper';

export const ProjectPageModifierPopup = ({
  children,
}: PropsWithChildren<ReactNode>) => {
  const project = useSelector(editionSelectors.GetProjectSelector);
  const maxPages = GetProjectMaxPages(project);
  const minPages = GetProjectMinPages(project.docID);
  const pageStep = 2;

  const dispatch = useDispatch();
  const currentNumPages = GetProjectDisplayPage(project);
  const [numPages, setNumPages] = useState(currentNumPages);
  const [popoverVisible, setPopoverVisible] = useState(false);

  // --------------------- Methods ------------------------

  const handleTriggerClickChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handlePageChange = (value: number) => {
    let newValue = value;
    if (newValue % 2 !== 0) newValue += 1;
    if (newValue > maxPages) newValue = maxPages;
    if (newValue < minPages) newValue = minPages;
    setNumPages(newValue);
  };

  const handleConfirm = () => {
    // on confirm continue
    const onConfirmContinue = () => {
      dispatch(editionActions.UpdateProjectPageAmount(numPages, true));
    };
    const onConfirmCancel = () => {
      // do nothing but display previous popover
      setPopoverVisible(true);
    };

    // close this view on confirm.. (will be reopened if canceled below)
    setPopoverVisible(false);

    // warn if less pages
    if (currentNumPages > numPages) {
      const numPagesDeleted = currentNumPages - numPages;
      popupHelper.showChangePageDeleteWarning(
        numPagesDeleted,
        onConfirmContinue,
        onConfirmCancel
      );
    } else {
      onConfirmContinue();
    }
  };

  // --------------------- effect ------------------------

  useEffect(() => {
    setNumPages(GetProjectDisplayPage(project));
  }, [project]);

  // --------------------- render ------------------------

  return (
    <Popover
      content={
        <div // className="pic"
          className=""
        >
          <h4>{GetText('popup.pagemodifier.title')}</h4>
          <Row justify="center" align="middle">
            <Col>
              {/* <Tooltip title="remove pages" placement="right"> */}
              <Button
                disabled={numPages <= minPages}
                type="primary"
                icon={<MinusOutlined />}
                onClick={() => handlePageChange(numPages - pageStep)}
              />
              {/* </Tooltip> */}
            </Col>
            <Col>
              <InputNumber
                value={numPages}
                //  disabled={!state.pages}
                // value={props.pageList.length}
                min={minPages}
                max={maxPages}
                //  // defaultValue={30}
                step={pageStep}
                onChange={handlePageChange}
              />
            </Col>
            <Col>
              {/* <Tooltip title="add pages" placement="right"> */}
              <Button
                disabled={numPages >= maxPages}
                onClick={() => handlePageChange(numPages + pageStep)}
                type="primary"
                icon={<PlusOutlined />}
              />
              {/* </Tooltip> */}
            </Col>
            <Col style={{ paddingLeft: '12px' }}>
              <Button type="primary" onClick={() => handleConfirm()}>
                {GetText('common.confirm')}
              </Button>
            </Col>
          </Row>
        </div>
      }
      trigger="click"
      open={popoverVisible}
      onOpenChange={handleTriggerClickChange}
    >
      {children}
    </Popover>
  );
};
