import { isLayflat } from '../../../feature/layflat/layflatHelpers';
import { LAYOUT_TYPE } from '../../../feature/layouts/layout.type';
import { IPage } from '../../../types/types';

export function PageCanBeDragged(page: IPage, docID: string): boolean {
  if (page.isCover) return false;
  if (page.isCalendar) return false;
  if (
    page.layoutType === LAYOUT_TYPE.POSTCARD_BG ||
    page.layoutType === LAYOUT_TYPE.PROMO_URL
  )
    return false;

  if (isLayflat(docID)) {
    return page.merged; // only double pages can be dragged
  }
  return true;
}
