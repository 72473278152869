import QRCode from 'react-qr-code';
import { Frame } from '../../../types/types';

type Props = {
  frame: Frame;
  scaleRatio?: number; // used in page navigator
};

export const FrameQR = ({ frame, scaleRatio = 1 }: Props) => {
  return (
    <foreignObject
      x="0"
      y="0"
      className="unselectable divReset"
      style={{
        width: frame.width,
        height: frame.height,
        transform: `scale(${scaleRatio})`,
      }}
    >
      <div className="divReset">
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={frame.qrCode?.qrCode}
          viewBox={`0 0 256 256`}
          fgColor={frame.qrCode?.color}
          // level="Q"
        />
      </div>
    </foreignObject>
  );
};
