import { captureException, captureMessage, setContext } from '@sentry/browser';

export const notifyError = (
  error: Error | string,
  detail?: { [key: string]: unknown }
) => {
  // console log the error
  console.error(error);

  // add context if there is a detail
  if (detail) setContext('Error Detail', detail);

  // if error is string, just capture a message
  if (error instanceof String) captureMessage(error as string, detail);
  else captureException(error as Error, detail);
};

export const notifyMessageToDev = (
  message: string,
  detail?: { [key: string]: unknown }
) => {
  console.log('notifyMessageToDev', message);
  // add context if there is a detail
  if (detail) setContext('Message Detail', detail);

  captureMessage(message, detail);
};
