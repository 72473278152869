import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, Row, Select, Spin } from 'antd';
import { cloneDeep, isEqual } from 'lodash';
import {
  CatalogueCard,
  GetProductCatalogue,
} from '../../../data/catalogue/productCatalogue';
import { PROJECT_CONST } from '../../../data/config';
import { GetText } from '../../../data/LanguageHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { authSelectors } from '../../../feature/auth/authentification';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { layoutListSelectors } from '../../../feature/layouts/layout.store';
import { useProjectPrice } from '../../../feature/pricing/useProjectPrice';
import { CreateProject } from '../../../feature/project/CreateProject';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { projectListSelectors } from '../../../feature/projectList/projectList';
import { Project, ProjectOptions } from '../../../types/project';
import { CardHelper } from '../../../utils/card/CardHelper';
import { history, ROUTE_CONST } from '../../../utils/history';
import {
  GetDoc,
  GetPossibleUpgrades,
  GetProjectDocSizeCM,
} from '../../../utils/ProductHelper';

type Props = {
  isUpgrade?: boolean;
  onCloseCallback?: () => void;
};

const filterCatalogueForUpgrades = (
  catalogue: CatalogueCard,
  project: Project
) => {
  const filteredCatalogue = cloneDeep(catalogue);
  const projectdocID = project.docID;
  // const projectDoc = GetDoc(projectdocID);
  const possibleUpgrades = GetPossibleUpgrades(projectdocID);

  // for (let productKey in catalogue.products) {
  Object.keys(filteredCatalogue.products).forEach((productKey) => {
    const productNode = filteredCatalogue.products[productKey];
    let keepProduct = false;
    // for (let formatKey in productNode.formats) {
    Object.keys(productNode.formats).forEach((formatKey) => {
      const formatNode = productNode.formats[formatKey];
      // keep only projects with the same amount of pages per group
      // return GetDoc(doc_id).pages_per_group === projectDoc.pages_per_group;
      const filteredDocs: [] = formatNode.docs.filter((doc_id) =>
        possibleUpgrades.includes(doc_id)
      );
      if (filteredDocs.length > 0) {
        filteredCatalogue.products[productKey].formats[formatKey].docs =
          cloneDeep(filteredDocs);
        keepProduct = true;
      } else delete filteredCatalogue.products[productKey].formats[formatKey];
    });
    if (!keepProduct) {
      delete filteredCatalogue.products[productKey];
    }
  });
  console.log(filteredCatalogue);
  return filteredCatalogue;
};

const { Option } = Select;

export const CardProjectCreationView = ({
  isUpgrade,
  onCloseCallback,
}: Props) => {
  const projectClass = PROJECT_CONST.project_class;

  // --------------------- state / redux ------------------------

  const dispatch = useDispatch();
  const existingProject = useSelector(editionSelectors.GetProjectSelector);
  const projectListIsLoading = useSelector(projectListSelectors.loading);
  const rebranding = useSelector(authSelectors.GetRebranding);
  const layouts = useSelector(layoutListSelectors.getLayoutStore);

  // state
  const [project, setProject] = useState<Project>();
  const [catalogue, setCatalogue] = useState<CatalogueCard>(
    GetProductCatalogue() as CatalogueCard
  );

  const productList = catalogue.products;
  const productOptionKeys = Object.keys(productList).map((key) => key);

  const [state, setState] = useState({
    // => type :postcard, anouncement, greetings, magnet
    product: productOptionKeys[0], // Current product selected
    productOptions: productOptionKeys, // WALL, DESK, ETC...
    // => format : port, land, pano, square, bookmark
    format: null, // current format type selected
    formatOptions: null,
    // => docID (PC, PX, ACMP, etc...)
    size: null,
    sizeOptions: null,
  });
  //
  const docID = state.size;
  const doc = docID ? GetDoc(docID) : undefined;
  const cardPack = doc?.packs_default; // TODO: change this if we have the dropdown in this view!
  const numOfPages =
    docID && cardPack && CardHelper.GetTotalNumberOfPages(docID, cardPack);

  const previewURL = `/images/ui/menu/lefttabs/project/${projectClass}/types_${projectClass}_${state.product}_${state.size}.jpg`;

  // Each time the state change, we construct the new project options
  const newProjectOptions = useMemo(() => {
    let options: ProjectOptions = project
      ? GetProjectOptions(project)
      : ({} as ProjectOptions);
    if (docID) {
      options = {
        ...options,
        ...{
          docID,
          type: state.product,
          numPages: numOfPages,
          cardPack,
          envelope: options.envelope ? options.envelope : doc.envelopes_default,
        },
      };
      // securities
      // TODO: put this outside in a generic CheckProjectOptions
      if (!doc.envelopes.includes(options.envelope))
        options.envelope = doc.envelopes_default;
      if (options.matte && !doc.finish.includes('mate')) options.matte = false;
      if (options.coated && !doc.finish.includes('coating'))
        options.coated = false;
      return options;
    }
  }, [state, project, docID, cardPack, doc, numOfPages]);

  const projectPrice = useProjectPrice(newProjectOptions);

  // --------------------- methods ------------------------

  const updateState = (product?: string, format?: string, size?: string) => {
    let currentProduct: string = product || state.product;
    let currentFormat: string = format || state.format;
    let currentSize: string = size || state.size;

    // verify product is valid
    const productOptions = Object.keys(catalogue.products);
    currentProduct = productOptions.includes(currentProduct)
      ? currentProduct
      : productOptions[0];
    // verify format options and current format
    const productNode = catalogue.products[currentProduct];
    const formatOptions: string[] = Object.keys(productNode.formats);
    currentFormat = formatOptions.includes(currentFormat)
      ? currentFormat
      : productNode.default;
    // verify size options and current size
    const formatNode = productNode.formats[currentFormat];
    const sizeOptions = formatNode.docs;
    currentSize = sizeOptions.includes(currentSize)
      ? currentSize
      : formatNode.default;

    const newState = {
      product: currentProduct,
      productOptions,
      format: currentFormat,
      formatOptions,
      size: currentSize,
      sizeOptions,
    };

    if (!isEqual(newState, state))
      setState({
        product: currentProduct,
        productOptions,
        format: currentFormat,
        formatOptions,
        size: currentSize,
        sizeOptions,
      });
  };

  const handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }
    // invoke callback if there is one
    onCloseCallback?.();
  };

  const handleProductChange = (product: string) => {
    updateState(product);
  };

  const handleFormatChange = (format) => {
    updateState(state.product, format);
    // const product = catalogue.products[state.product];
    // // find possible size/docID
    // const formatNode = product.formats[val];
    // const sizeOptions = cloneDeep(formatNode.docs);
    // const currentSize =
    //   state.size && sizeOptions.includes(state.size)
    //     ? state.size
    //     : formatNode.default;

    // setState((oldState) => ({
    //   ...oldState,
    //   format: val,
    //   sizeOptions,
    //   size: currentSize,
    // }));
  };

  const handleSizeChange = (newSize: string) => {
    updateState(undefined, undefined, newSize);
    // setState((oldState) => ({
    //   ...oldState,
    //   size: val,
    // }));
  };

  const createNewproject = () => {
    // create new project
    const proj = CreateProject({
      classname: PROJECT_CONST.project_class,
      docID,
      type: state.product,
      projectName: 'new card project', // TODO:
      numPages: numOfPages,
      layoutStore: layouts,
      options: {
        cardPack,
        envelope: doc.envelopes_default,
      },
    });

    // update project
    setProject(proj);
    // show rename popup
    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        proj.name = newProjectName;
        dispatch(editionActions.CreateProject(cloneDeep(proj)));
      }
    );
  };

  const handleConfirm = () => {
    // ----- CASE UPGRADE ------
    if (isUpgrade) {
      const onConfirm = () => {
        dispatch(
          editionActions.UpgradeCurrentProject({
            type: state.product,
            docID: state.size,
            numPages: numOfPages,
            options: GetProjectOptions(project),
          })
        );
        handleClose();
      };
      // TODO: 2024: this is a bug found, nowhere are we using state.pages
      // so we comment this out for now
      // if (currentProjectPages > state.pages) {
      //   const numPagesDeleted = currentProjectPages - state.pages;
      //   popupHelper.showChangePageDeleteWarning(numPagesDeleted, onConfirm);
      // } else {
      //   onConfirm();
      // }
      onConfirm();
    }

    // ----- CASE NEW------
    else createNewproject();
  };

  // --------------------- effect ------------------------

  useEffect(() => {
    // ------ initialize for UPGRADE -------
    if (isUpgrade && existingProject && !project) {
      // filter catalogue for upgrades only
      setCatalogue(filterCatalogueForUpgrades(catalogue, existingProject));
      // update state to correct product, format and size
      const currentFormat = CardHelper.GetProjectFormat(existingProject.docID);
      updateState(existingProject.type, currentFormat, existingProject.docID);
      // update project
      setProject(existingProject);
    }

    // ------ initialize for NEW PROJECT -------
    else if (!isUpgrade && !existingProject && !project) {
      // rebranding filter products available
      if ((rebranding as any)?.subProductFilter?.cards) {
        catalogue.products = (rebranding as any).subProductFilter.cards;
      }
      handleProductChange(Object.keys(catalogue.products)[0]);
    }
  }, [isUpgrade, existingProject, catalogue, project]);

  // effect each time catalogue change, we want to be sure to update possible options
  useEffect(() => {
    if (catalogue) {
      handleProductChange(state.product);
    }
  }, [catalogue]);

  // --------------------- render ------------------------

  return (
    <Modal
      title={
        isUpgrade
          ? GetText('upgradeProject.title')
          : GetText('newProject.title')
      }
      open
      footer={null}
      width="600px"
      onCancel={handleClose}
    >
      <Spin spinning={projectListIsLoading}>
        <h3>{GetText('newProject.card.desc')}</h3>

        {/** ---- PRODUCT TYPE (postcard, annoucement, greetings, etc.. ) ------ */}
        <h4>{GetText('card.type')}</h4>
        <Select
          style={{ width: 400 }}
          value={state.product}
          placeholder="Select a Product"
          onChange={handleProductChange}
        >
          {state.productOptions.map((key) => (
            <Option key={key} value={key}>
              {GetText(`card.type.types_cards_${key}`)}
            </Option>
          ))}
        </Select>

        <div className="spacerV" />

        {/** ---- PRODUCT FORMAT (port, land, pano, square, bookmark ) ------ */}
        <h4>{GetText('card.format')}</h4>
        <Select
          disabled={
            !state.format ||
            (state.formatOptions && state.formatOptions.length < 2)
          }
          style={{ width: 400 }}
          value={state.format}
          onChange={handleFormatChange}
        >
          {state.formatOptions &&
            state.formatOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`card.format.format_${key}`)}
              </Option>
            ))}
        </Select>

        <div className="spacerV" />

        {/** ---- Product size (=== doc ID )------ */}
        <h4>{GetText('card.size')}</h4>
        <Select
          disabled={!state.size}
          value={state.size}
          style={{ width: 400 }}
          onChange={handleSizeChange}
        >
          {state.sizeOptions &&
            state.sizeOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`card.prefix.${key}`)} ({GetProjectDocSizeCM(key)})
              </Option>
            ))}
        </Select>

        <div className="spacerV" />
        <div className="spacerV" />

        {/** -----------  PREVIEW ----------- */}

        <Row className="preview">
          <img src={previewURL} />
        </Row>

        <Row justify="space-between">
          <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
            <Button type="link">
              {GetText('newProject.changeEditorClass.button')}
            </Button>
          </Link>
          <Button
            type="primary"
            disabled={projectPrice === 0}
            onClick={handleConfirm}
          >
            {GetText('common.continue')}
            {` (${projectPrice}€)`}
          </Button>
        </Row>
      </Spin>
    </Modal>
  );
};
