export const CATALOGUE_ALBUM = {
  // --------------------- Classic & Contemporary ------------------------

  // "S" :  small
  // "M" :  medium
  // "L" :  large

  // --------------------- Regular ------------------------

  // "RA" : Regular A5
  // "RB" :  Regular A4
  // "RC" : Regular A3
  // "RAL" :  Regular A5 landscape
  // "RBL" :  Regular A4 landscape
  // "RCL" :  Regular A3 landscape
  // "RDL" :  Regular A6 landscape

  // --------------------- Trendy ------------------------

  // "Q" : Square 22
  // "QA" : Square 25
  // "QB" : Square 34
  // "QF" : Square 30

  // --------------------- Casual ------------------------

  // "QD" : Soft square 21
  // "QE" : Soft square 24
  // "A" : Trendy A5
  // "B" : Trendy A4
  // "C" : Trendy A3 // NOT POSSIBLE IN THE END...
  // "AL" : Trendy A5 Landscape
  // "BL" : Trendy A4 Landscape
  // "DL" : Trendy A6 Landscape

  // --------------------- Layflat ------------------------

  // "LFS" : Layflat Small
  // "LFM" : Layflat Medium
  // "LFQA" : Layflat Square 22
  // "LFQB" : Layflat Square 25
  // "LFQC" : Layflat Square 30
  // "LFQD" : Layflat Square 34

  /// ///////////////////////////////////////////////////////////////
  // Notes on COVER size :
  //       Cover size:
  //        o   Casual: like the other casual, it’s the final format (x2) + spine width and you need to add some bleed like for the other covers.
  //        o   Regular:
  //              §  A3 portrait: height=46cm (=43cm + 2x1,5cm of bleeds for top and bottom) – width=31,7cm x 2 (=30,2+1,5 of bleed) +spine width
  //              §  A6 landscape: height=14,5cm (=11,5cm + 2x1,5cm of bleeds for top and bottom) – width=16,85cm x 2 (=15,35+1,5 of bleed) +spine width
  /// ///////////////////////////////////////////////////////////////

  products: {
    classic: {
      docs: ['S', 'M', 'L'],
      default: 'M',
    },
    contemporary: {
      docs: ['S', 'M', 'L'],
      default: 'M',
    },
    regular: {
      docs: ['RA', 'RB', 'RC', 'RAL', 'RBL', 'RCL'], // Removed RDL at Nico's request 221123
      default: 'RB',
    },
    trendy: {
      docs: ['Q', 'QA', 'QF', 'QB'],
      default: 'QA',
    },
    casual: {
      docs: ['QD', 'QE', 'A', 'B', 'DL', 'AL', 'BL'], // removed C, Casual A3 was not possible
      default: 'QE',
    },
    layflat: {
      docs: ['LFS', 'LFM', 'LFQA', 'LFQB', 'LFQC', 'LFQD'],
      default: 'LFS',
    },
  },

  spineType: {
    classic: ['S', 'M', 'L'],
    softCover: ['A', 'B', 'AL', 'BL', 'DL', 'QD', 'QE'],
    hardCover: ['Q', 'QA', 'QB', 'QF'],
    regular: ['RA', 'RB', 'RC', 'RAL', 'RBL', 'RCL', 'RDL'],
    layflat: ['LFS', 'LFM', 'LFQA', 'LFQB', 'LFQC', 'LFQD'],
  },

  upgrades: {},

  flyleafColors: {
    white: '#ffffff',
    cream: '#ffffcc',
    red: '#b8404b',
    black: '#181818',
    blue_marine: '#1e2477',
  },

  docs: {
    // --------------------- CLASSIC ------------------------

    // small
    S: {
      code: 30101,
      width: 138,
      height: 100,
      multiplier: 4.677165,
      cutBorder: 12.5,

      minPages: 30,
      maxPages: [200, 150],
      defaultPages: 30,
      adjustCropX: 5,

      flyleaf: true,
      insert: true,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      coverType: ['custom', 'classic'],
      cover: {
        type: 'custom',
        width: 143,
        height: 105,
        multiplier: 4.64,
        cutBorder: 25,
      },
    },

    // medium
    M: {
      code: 30201,
      width: 130,
      height: 101.2,
      multiplier: 6.835857,
      cutBorder: 12.5,

      minPages: 30,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: true,
      insert: true,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      coverType: ['custom', 'classic'],
      cover: {
        type: 'custom',
        width: 131.5,
        height: 103.7,
        multiplier: 6.887219,
        cutBorder: 25,
      },
    },

    // large
    L: {
      code: 30301,
      width: 118,
      height: 101.3,
      multiplier: 9.188576,
      cutBorder: 12.5,

      minPages: 30,
      maxPages: [200, 150],
      defaultPages: 30,
      adjustCropX: 12,

      flyleaf: true,
      insert: true,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      coverType: ['custom', 'classic'],
      cover: {
        type: 'custom',
        width: 121,
        height: 105.1,
        multiplier: 9.089608,
        cutBorder: 28,
      },
    },

    // --------------------- Regular ------------------------

    // Regular A5
    RA: {
      code: 32100,
      width: 148,
      height: 210,
      multiplier: 2.83464567,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 148,
        height: 210.06,
        multiplier: 2.9687167,
        cutBorder: 25,
      },
    },

    // Regular A4
    RB: {
      code: 32200,
      width: 148,
      height: 210,
      multiplier: 4.022132,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 148,
        height: 210.06,
        multiplier: 4.1562035,
        cutBorder: 25,
      },
    },

    // Regular A3
    RC: {
      code: 32300, // New! june 2020
      width: 148.5, // 29.7
      height: 210, // 42 cm
      multiplier: 5.66929134,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        // A3 portrait: height=46cm (=43cm + 2x1,5cm of bleeds for top and bottom) – width=31,7cm x 2 (=30,2+1,5 of bleed) +spine width
        width: 150, // 30cm
        height: 215, // 43cm
        multiplier: 5.66929134,
      },
    },

    // Regular A5 landscape
    RAL: {
      code: 33100,
      width: 210,
      height: 148,
      multiplier: 2.83464567,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 210,
        height: 152.9,
        multiplier: 2.929134,
        cutBorder: 25,
      },
    },

    // Regular A4 landscape
    RBL: {
      code: 33200,
      width: 210,
      height: 148,
      multiplier: 4.009,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 210,
        height: 152.9,
        multiplier: 4.10345,
        cutBorder: 25,
      },
    },

    // Regular A3 landscape
    RCL: {
      code: 33300,
      width: 210,
      height: 148,
      multiplier: 5.6693,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 210,
        height: 151,
        multiplier: 5.76378,
        cutBorder: 25,
      },
    },

    // Regular A6 landscape
    RDL: {
      code: 33000, // New! june 2020
      width: 210,
      height: 148.5,
      multiplier: 2.0044994381,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        // cover A6 landscape: height=14,5cm (=11,5cm + 2x1,5cm of bleeds for top and bottom) – width=16,85cm x 2 (=15,35+1,5 of bleed) +spine width
        width: 217, // 15.35cm
        height: 162.62, // 11.5cm
        multiplier: 2.0044994381,
        cutBorder: 25,
      },
    },

    // --------------------- TRENDY ------------------------

    // Square 22
    Q: {
      code: 30601,
      width: 105,
      height: 105,
      multiplier: 5.669291,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: true,
      insert: false,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      cover: {
        type: 'custom',
        width: 110,
        height: 109.5,
        multiplier: 5.591983,
        cutBorder: 20,
      },
    },

    // Square 25
    QA: {
      code: 30611,
      width: 105,
      height: 105,
      multiplier: 6.47919,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: true,
      insert: false,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      cover: {
        type: 'custom',
        width: 110,
        height: 110,
        multiplier: 6.339298,
        cutBorder: 22,
      },
    },

    // Square 34
    QB: {
      code: 30621,
      width: 105,
      height: 105,
      multiplier: 8.908886,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: true,
      insert: false,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      cover: {
        type: 'custom',
        width: 110,
        height: 110.7,
        multiplier: 8.607015,
        cutBorder: 20,
      },
    },

    // Square 30
    QF: {
      code: 31100,
      width: 105,
      height: 105,
      multiplier: 7.829021,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: true,
      insert: false,
      mate: true,
      pagePaperQuality: ['170', '250'],
      coating_default: true,

      cover: {
        type: 'custom',
        width: 110,
        height: 110.7,
        multiplier: 7.611863,
        cutBorder: 20,
      },
    },

    // --------------------- Casual ------------------------

    // Soft square 21
    QD: {
      code: 30631,
      width: 105,
      height: 105,
      multiplier: 5.669291,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 105,
        height: 105,
        multiplier: 5.669291,
        cutBorder: 12,
      },
    },

    // Soft square 24
    QE: {
      code: 30641,
      width: 105,
      height: 105,
      multiplier: 6.47919,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 105,
        height: 105,
        multiplier: 6.47919,
        cutBorder: 12,
      },
    },

    // Trendy A5
    A: {
      code: 30651,
      width: 148,
      height: 210,
      multiplier: 2.83464567,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 148,
        height: 210,
        multiplier: 2.83464567,
        cutBorder: 12,
      },
    },

    // Trendy A4
    B: {
      code: 30661,
      width: 148,
      height: 210,
      multiplier: 4.022132,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 148,
        height: 210,
        multiplier: 4.022132,
        cutBorder: 12,
      },
    },

    // //Casual A3 Portrait
    // "C" : {

    //   code:30661, // TODO: update product code
    //   width:148.5,
    //   height:210,
    //   multiplier:5.66929134,
    //   cutBorder:12.5,

    //   minPages:20,
    //   maxPages:150,
    //   defaultPages:30,

    //   flyleaf:true,
    //   insert:false,
    //    mate:true,
    pagePaperQuality: ['170'],

    // cover:{
    //     type:"custom",
    //     width:148.5,
    //     height:210,
    //     multiplier:5.66929134,
    //   }
    // },

    // Casual A5 Landscape
    AL: {
      code: 30681, // New! june 2020
      width: 210,
      height: 148.5,
      multiplier: 2.83464567,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 200],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 210,
        height: 148.5,
        multiplier: 2.83464567,
        cutBorder: 12,
      },
    },

    // Trendy A4 Landscape
    BL: {
      code: 30691, // New! june 2020
      width: 210,
      height: 148.5,
      multiplier: 4.0089988761,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 210,
        height: 148.5,
        multiplier: 4.0089988761,
        cutBorder: 12,
      },
    },

    // Trendy A6 Landscape
    DL: {
      code: 30671, // New! june 2020
      width: 210,
      height: 148.5,
      multiplier: 2.0044994381,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [200, 150],
      defaultPages: 30,

      flyleaf: false,
      insert: false,
      mate: true,
      pagePaperQuality: ['170'],
      coating_default: false,

      cover: {
        type: 'custom',
        width: 210,
        height: 148.5,
        multiplier: 2.0044994381,
        cutBorder: 12,
      },
    },

    // --------------------- LAYFLAT ------------------------

    // Layflat Small
    LFS: {
      code: 30102,
      width: 138,
      height: 100,
      multiplier: 4.677165,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [86, 86],
      defaultPages: 20,

      flyleaf: false,
      insert: false,
      mate: false,
      pagePaperQuality: ['250'],
      coating: false,
      coating_default: false,

      coverType: ['custom', 'classic'],
      cover: {
        type: 'custom',
        width: 143,
        height: 105,
        multiplier: 4.64,
        cutBorder: 25,
      },
    },

    // Layflat Medium
    LFM: {
      code: 30202,
      width: 130,
      height: 101.2,
      multiplier: 6.835857,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [86, 86],
      defaultPages: 20,

      flyleaf: false,
      insert: false,
      mate: false,
      pagePaperQuality: ['250'],
      coating: false,
      coating_default: false,

      coverType: ['custom', 'classic'],
      cover: {
        type: 'custom',
        width: 131.5,
        height: 103.7,
        multiplier: 6.887219,
        cutBorder: 25,
      },
    },

    // Layflat Square 22
    LFQA: {
      code: 30602,
      width: 105,
      height: 105,
      multiplier: 5.669291,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [86, 86],
      defaultPages: 20,

      flyleaf: false,
      insert: false,
      mate: false,
      pagePaperQuality: ['250'],
      coating: false,
      coating_default: false,

      cover: {
        type: 'custom',
        width: 110,
        height: 109.5,
        multiplier: 5.591983,
        cutBorder: 20,
      },
    },

    // Layflat Square 25
    LFQB: {
      code: 30612,
      width: 105,
      height: 105,
      multiplier: 6.47919,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [86, 86],
      defaultPages: 20,

      flyleaf: false,
      insert: false,
      mate: false,
      pagePaperQuality: ['250'],
      coating: false,
      coating_default: false,

      cover: {
        type: 'custom',
        width: 110,
        height: 110,
        multiplier: 6.339298,
        cutBorder: 22,
      },
    },

    // "LFQC" : Layflat Square 30
    LFQC: {
      code: 31101,
      width: 105,
      height: 105,
      multiplier: 7.829021,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [86, 86],
      defaultPages: 20,

      flyleaf: false,
      insert: false,
      mate: false,
      pagePaperQuality: ['250'],
      coating: false,
      coating_default: false,

      cover: {
        type: 'custom',
        width: 110,
        height: 110.7,
        multiplier: 7.611863,
        cutBorder: 20,
      },
    },

    // "LFQD" : Layflat Square 34
    LFQD: {
      code: 30622,
      width: 105,
      height: 105,
      multiplier: 8.908886,
      cutBorder: 12.5,

      minPages: 20,
      maxPages: [86, 86],
      defaultPages: 20,

      flyleaf: false,
      insert: false,
      mate: false,
      pagePaperQuality: ['250'],
      coating: false,
      coating_default: false,

      cover: {
        type: 'custom',
        width: 110,
        height: 110.7,
        multiplier: 8.607015,
        cutBorder: 20,
      },
    },
  },
};
