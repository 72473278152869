import { batch } from 'react-redux';
import { createReducer } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { TICTAC_COOKIES } from '../../data/Cookies';
import { GetText } from '../../data/LanguageHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { getPhotoCategoriesSelector } from '../../store/customSelectors/selectors';
import { StoreType } from '../../store/store.type';
import type { Category, Frame, Photo } from '../../types/types';
import { API } from '../../utils/API';
import { notifyError } from '../../utils/error/notifyError';
import { toast } from '../../utils/notification/toast';
import { GetUnsafeHTMLDiv } from '../../utils/ReactHelper';
import { alertActions } from '../alert/alert';
import { editionActions } from '../edition/edition';
import { injectPhotoIntoFrame } from '../frame/_helpers/injectPhotoIntoFrame';
import { photoListSelector } from './photo.selector';
import { PHOTO_FILTER_OPTIONS, tempImageFolderName } from './photo.type';

/** **********************************
// ACTIONS TYPES
************************************ */

// RESET
const RESET_UPLOADS = 'PHOTOS/RESET_UPLOADS';

// LIST
const GETALL_REQUEST = 'PHOTOS/GETALL_REQUEST';
const GETALL_SUCCESS = 'PHOTOS/GETALL_SUCCESS';
const GETALL_ERROR = 'PHOTOS/GETALL_ERROR';

const CLEAR_PHOTOS = 'PHOTOS/CLEAR_PHOTOS';

const UPDATE_PROJECT_PHOTOS = 'PHOTOS/UPDATE_PROJECT_PHOTOS';

// IMPORT
const IMPORT_START = 'PHOTOS/IMPORT_PHOTO_START';
const IMPORT_SUCCESS = 'PHOTOS/IMPORT_PHOTO_SUCCESS';
const IMPORT_ERROR = 'PHOTOS/IMPORT_PHOTO_ERROR';
const UPDATE_TEMP_PHOTO_SIZE = 'PHOTOS/UPDATE_TEMP_PHOTO_SIZE';

// UPLOAD
const UPLOAD_START = 'PHOTOS/UPLOAD_START';
const UPLOAD_SUCCESS = 'PHOTOS/UPLOAD_SUCCESS';
const UPLOAD_ERROR = 'PHOTOS/UPLOAD_ERROR';

// used to add an existing photo (from user photos) to the current projec
const ADD_PHOTO_TO_PROJECT = 'PHOTOS/ADD_PHOTO_TO_PROJECT';
// const REMOVE_PHOTO_FROM_PROJECT = 'PHOTOS/REMOVE_PHOTO_FROM_PROJECT';

// Delete
// const DELETE_REQUEST = 'PHOTOS/DELETE_REQUEST';
// const DELETE_SUCCESS = 'PHOTOS/DELETE_SUCCESS';
// const DELETE_ERROR = 'PHOTOS/DELETE_ERROR';

// SORT
const UPDATE_SORT_FILTER = 'PHOTOS/UPDATE_SORT_FILTER';

/** **********************************
// REDUCERS
************************************ */

// recover from local storage
const StoredTempPhotoMappingString = localStorage.getItem(
  TICTAC_COOKIES.TEMP_PHOTO_MAPPING
);
let StoredTempPhotoMapping = {};
if (StoredTempPhotoMapping) {
  StoredTempPhotoMapping = JSON.parse(StoredTempPhotoMappingString);
}

// initial store
const initialState: StoreType['photos'] = {
  // current photo list fetched synced with backend, all photos from all projects.
  backendPhotosByID: {},

  // all photos for this project, photos imported while the project was open or currnetly in project
  // this is what is displayed in the left area
  projectPhotosByID: {},

  // currently imported but not yet uploaded
  tempPhotosByID: {},

  // we keep track of uploaded photos temp.id vs final id so we can find it again during session if needed
  tempPhotoMapping: StoredTempPhotoMapping || {},
  sortFilter: PHOTO_FILTER_OPTIONS.BY_DATE_ASC,

  isUploading: false, // is currently uploading a file
  isLoading: false, // is currently loading list
  isDeleting: false, // is currently deleting an image
  isLoaded: false, // is the photo list currently loaded?

  error: null, // current error message

  totalUploadingItems: 0, // total items uploading
};

const reducer = createReducer(initialState, {
  [RESET_UPLOADS]: (state) => {
    state.tempPhotosByID = {};
    state.isUploading = false;
    state.totalUploadingItems = 0;
  },

  [CLEAR_PHOTOS]: (state) => {
    state.backendPhotosByID = {};
    state.isLoaded = false;
  },

  // --------------------- GET ALL ------------------------

  [GETALL_REQUEST]: (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  },
  [GETALL_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.backendPhotosByID = createItemListById(action.photosList);
    state.isLoaded = true;
  },
  [GETALL_ERROR]: (state, action) => {
    state.isLoading = false;
    state.error = action.error;
  },

  // --------------------- UPDATE PROJECT PHOTOS ------------------------

  [UPDATE_PROJECT_PHOTOS]: (state, action) => {
    state.projectPhotosByID = action.newProjectPhotosByID;
  },

  [UPDATE_SORT_FILTER]: (state, action) => {
    state.sortFilter = action.sortFilter;
  },

  // --------------------- IMPORT ------------------------

  [IMPORT_START]: (state, action) => {
    state.tempPhotosByID[action.tempPhoto.id] = action.tempPhoto;
    state.totalUploadingItems += 1;

    // when importing, always put back the filter to see the upload firsts!
    state.sortFilter = PHOTO_FILTER_OPTIONS.BY_IMPORT_DATE_DESC;
  },
  [IMPORT_ERROR]: (state, action) => {
    // newState = {...state};
    // newState.tempList[action.tempPhoto.id] = action.tempPhoto;
    alert(`Import error: ${action.error}`);
    // TODO: what we do in case of error?
    // return newState;
  },
  [IMPORT_SUCCESS]: (state, action) => {
    state.tempPhotosByID[action.tempPhoto.id] = action.tempPhoto; // just update temp photo!

    // store name for later if issue.
    state.tempPhotoMapping[`${action.tempPhoto.id}_name`] =
      action.tempPhoto.name;
    localStorage.setItem(
      TICTAC_COOKIES.TEMP_PHOTO_MAPPING,
      JSON.stringify(state.tempPhotoMapping)
    );
  },
  [UPDATE_TEMP_PHOTO_SIZE]: (state, action) => {
    state.tempPhotosByID[action.tempPhoto.id] = action.tempPhoto;
  },

  // --------------------- UPLOAD ------------------------

  [UPLOAD_START]: (state, action) => {
    state.tempPhotosByID[action.tempPhoto.id] = action.tempPhoto; // just update temp photo!
  },
  [UPLOAD_ERROR]: (state, action) => {
    const { tempPhoto, error } = action;

    // newState = {...state};
    // newState.tempList[action.tempPhoto.id] = action.tempPhoto;
    // alert("Upload error: " + error);
    // TODO: what we do in case of error?
    state.tempPhotosByID[tempPhoto.id].error = error;
    delete state.tempPhotosByID[tempPhoto.id]; // remove temp photo
    // tempPhoto.error = error;

    // TODO: is this right??
    // EDIT: No it's not, this is why we just displayed a popup first..
    // state.projectPhotosByID[tempPhoto.id] = tempPhoto; // add to project photo list

    // return newState;
  },
  [UPLOAD_SUCCESS]: (state, action) => {
    // newState = {...state};
    const { tempPhoto, newPhoto } = action;

    /// /////////////////////////////////////////////////////////////
    // BY doing a 'delete' here, we make the currently edited frame photo break..
    // so we need to also keep some kind of mapping, to be sure if the edited page/frame can find it again if needed
    state.tempPhotoMapping[tempPhoto.id] = newPhoto.id;
    localStorage.setItem(
      TICTAC_COOKIES.TEMP_PHOTO_MAPPING,
      JSON.stringify(state.tempPhotoMapping)
    );

    delete state.tempPhotosByID[tempPhoto.id]; // remove temp photo
    /// /////////////////////////////////////////////////////////////

    // add this new photo to project photo list
    state.backendPhotosByID[newPhoto.id] = newPhoto; // added in imageEditor scope.. we forgot to update the main list on each upload!!
    state.projectPhotosByID[newPhoto.id] = newPhoto;
  },

  [ADD_PHOTO_TO_PROJECT]: (state, action) => {
    const { photo } = action;
    state.projectPhotosByID[photo.id] = photo; // add to project photo list
    state.backendPhotosByID[photo.id] = photo; // added in imageEditor scope.. we forgot to update the main list on each upload!!
  },

  // [REMOVE_PHOTO_FROM_PROJECT]: (state, action) => {
  //   const { photoId } = action;
  //   delete state.projectPhotosByID[photoId];
  // },

  // --- DELETE
  /*
    case projectConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case projectConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case projectConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
      */
});

/** **********************************
// internal helpers
************************************ */

function createItemListById(itemArray) {
  const itemById = {};
  itemArray.forEach((item) => {
    itemById[item.id] = item;
  });
  return itemById;
}

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

/*
export function requestLogin() {
  return { type: LOAD };
}

export function createWidget(widget) {
  return { type: CREATE, widget };
}

export function updateWidget(widget) {
  return { type: UPDATE, widget };
}

export function removeWidget(widget) {
  return { type: REMOVE, widget };
}
*/

// function sort (sortFilter){
//   return { type: SORT, sortFilter };
// }

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

function getAll() {
  return (dispatch) => {
    dispatch(request());
    API.getUserPhotos().then(
      (photosList) => dispatch(success(photosList)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success(photosList) {
    return { type: GETALL_SUCCESS, photosList };
  }
  function failure(error) {
    return { type: GETALL_ERROR, error };
  }
}

function updateProjectPhotoList(newProjectPhotosByID) {
  return { type: UPDATE_PROJECT_PHOTOS, newProjectPhotosByID };
}

// this methods should be used really carefully, as it will update the whole list of photos
// this is for now used to "override" photos list with old editor2 categories without having to move the pictures on the backend. Which probably would be a better option
// TODO: let's remove this and use API.movePhotos instead in the future.
function updateBackendPhotoList(newBackendPhotosByID: {
  [key: string]: Photo;
}) {
  return {
    type: GETALL_SUCCESS,
    photosList: Object.values(newBackendPhotosByID),
  };
}

/**
 * Import a photo (temp photo created)
 * At start, the photo do not have the width nor height.
 * The photo is considered as "imported" when the width and height has been created
 * And the photo is not temp anymore when it has been uploaded
 */
function importPhotos(tempPhoto: Photo, intoFrame: Frame = null) {
  return async (dispatch, getState) => {
    // const { project } = getState().edition;
    // work with a copy as otherwise it's readonly
    const photoObj: Photo = cloneDeep(tempPhoto);

    // let's first check that the image is not existing in the current user photo database
    // we don't want to upload dupplicate files!
    const md5ByID = photoListSelector.getPhotoByMD5(getState());
    const matchingPhoto = md5ByID[photoObj.md5];
    if (matchingPhoto && !DebugFlags.DO_NOT_CHECK_DUPLICATES) {
      console.log(
        `We got a duplicate image, using existing one`,
        matchingPhoto
      );

      // special case when the matching photo found BUT still a temp photo not yet uploaded
      if (matchingPhoto.temp) {
        // if into frame, we directly inject the matching photo in the frame
        if (intoFrame) {
          const frameCopy = cloneDeep(intoFrame);
          injectPhotoIntoFrame(frameCopy, matchingPhoto);
          dispatch(editionActions.UpdateFrame(frameCopy));
        }
        // and we stop the import process
        return;
      }

      // if category differ, we need to move
      if (tempPhoto.cat !== matchingPhoto.cat) {
        // move existing photo then re import
        await API.movePhotos([matchingPhoto.id], photoObj.cat);
        // refetch image list
        await dispatch(getAll());

        // we need to retreive the category name for the toast
        const allCategories = getPhotoCategoriesSelector(getState());
        const oldCategory = allCategories.find(
          (c) => c.id === matchingPhoto.cat
        )?.label;
        const newCategory = allCategories.find(
          (c) => c.id === photoObj.cat
        )?.label;

        // toast warn
        toast({
          title: GetText('popup.doublons.warning.title'),
          description: GetText('popup.doublons.warning.detail', false, {
            NAME: photoObj.name,
            OLD: oldCategory,
            NEW: newCategory,
          }),
          key: `${oldCategory}_${newCategory}`,
          duration: 15,
        });

        // call this function again to re-import the photo
        dispatch(importPhotos(tempPhoto, intoFrame));
        return;
      }

      // case continue
      batch(() => {
        // simulate direct import
        dispatch(importStart(photoObj));
        // dispatch success uploading
        dispatch(uploadSuccess(photoObj, matchingPhoto));

        // case import in frame
        if (intoFrame) {
          const frameCopy = cloneDeep(intoFrame);
          injectPhotoIntoFrame(frameCopy, matchingPhoto);
          dispatch(editionActions.UpdateFrame(frameCopy));
        }
      });
      return;
    }

    // on photo import, we want the photo to have the correct category
    // get projecct ID to save photo in correct folder/category
    // TODO: we should force category information when creating the photo
    if (!photoObj.cat) {
      notifyError(
        'error: photo has no category, we should force it when creating the photo',
        photoObj
      );
      photoObj.cat = tempImageFolderName;
    }

    // start import, add to temp list
    dispatch(importStart(photoObj));

    // on complete function
    function handleImageLoadComplete(photoObjReady: Photo) {
      batch(() => {
        /// INJECT IN FRAME
        // now that we have the width and height, force import inside frame if we have a frame
        if (intoFrame) {
          const frameCopy = cloneDeep(intoFrame);
          injectPhotoIntoFrame(frameCopy, photoObjReady);
          dispatch(editionActions.UpdateFrame(frameCopy));
        }
        /// /////////////////////

        // notify
        dispatch(importSuccess(photoObjReady));

        // start upload photo
        if (DebugFlags.UPLOAD_DELAYED <= 0) {
          dispatch(uploadPhoto(photoObjReady.id));
        } else {
          setTimeout(() => {
            dispatch(uploadPhoto(photoObjReady.id));
          }, DebugFlags.UPLOAD_DELAYED * 1000);
        }

        // else
        //   dispatch(alertActions.error("DebugFlags: do not allow uploads"));
      });
    }

    // if no width, load image first to retrieve info before notify complete
    if (!photoObj.width && intoFrame) {
      // async load photo to get the width and height
      const i = new Image();
      i.onload = function () {
        const photoCopy = cloneDeep(photoObj); // we need to do this as it's readonly here..
        photoCopy.width = i.width;
        photoCopy.height = i.height;
        handleImageLoadComplete(photoCopy);
      };

      i.onerror = function (e) {
        // dispatch(importError(`Error while loading image '${tempPhoto.name}' with url: '${tempPhoto.temp_url}'`));
        dispatch(importError(e.toString(), photoObj)); // `Error while loading image '${tempPhoto.name}' with url: '${tempPhoto.temp_url}'`));
      };

      i.src = photoObj.temp_url;
    } else handleImageLoadComplete(cloneDeep(photoObj));
  };
}

function importStart(tempPhoto: Photo) {
  return { type: IMPORT_START, tempPhoto };
}
function importSuccess(tempPhoto: Photo) {
  return { type: IMPORT_SUCCESS, tempPhoto };
}
// function importError( error ) { return { type: IMPORT_ERROR, error } }

function uploadStart(tempPhoto) {
  return { type: UPLOAD_START, tempPhoto };
}
function uploadSuccess(tempPhoto, newPhoto) {
  return { type: UPLOAD_SUCCESS, tempPhoto, newPhoto };
}

function importError(error, tempPhoto) {
  return (dispatch) => dispatch(uploadError(error, tempPhoto));
}
function uploadError(error, tempPhoto) {
  // dispatch modal to inform user
  // TODO: later we could make a retry here ??
  Modal.error({
    title: GetText('popup.upload.error.title'),
    content: GetUnsafeHTMLDiv(
      GetText('popup.upload.error.desc')
        .replace('{NAME}', tempPhoto.name)
        .replace('{ERROR}', error.toString())
    ),
  });
  // update store
  return (dispatch) => {
    dispatch({ type: UPLOAD_ERROR, error, tempPhoto });
  };
}

function UpdateTempPhotoSize(tempPhoto: Photo) {
  return { type: UPDATE_TEMP_PHOTO_SIZE, tempPhoto };
}

// /** Photomanager
//  * simply add existing photos to current project
//  *  */
// function addPhotosToProject(photoIds: string[]) {
//   return (dispatch, getState) => {
//     batch(() => {
//       photoIds.forEach((photoId) => {
//         const existingPhoto = getState().photos.backendPhotosByID[photoId];
//         if (existingPhoto) console.log(existingPhoto);
//         dispatch({ type: ADD_PHOTO_TO_PROJECT, photo: existingPhoto });
//       });
//     });
//   };
// }

// /** Photomanager
//  * remove photos from current project
//  */
// function removePhotosFromProject(photoIds: string[]) {
//   return (dispatch, _getState) => {
//     batch(() => {
//       photoIds.forEach((photoId) => {
//         dispatch({ type: REMOVE_PHOTO_FROM_PROJECT, photoId: photoId });
//       });
//     });
//   };
// }

/**
 * Once photo has been imported
 * we then start uploading it
 */
function uploadPhoto(tempPhotoID: string) {
  return (dispatch, getState) => {
    // start
    const tempPhoto: Photo = photoListSelector.getTempPhotoByID(
      getState(),
      tempPhotoID
    );
    dispatch(uploadStart(tempPhoto));

    // API Call
    API.createPhoto(tempPhoto.name, tempPhoto.cat, tempPhoto.temp_url).then(
      (newPhoto) => {
        // we need to remap all possible temp photos in album with the new one!
        // const { project } = getState().edition;
        // RemapProjectTempPhotosWithUploadedPhotos(project, tempPhoto.id, newPhoto.id);
        batch(() => {
          dispatch(uploadSuccess(tempPhoto, newPhoto));
          dispatch(
            editionActions.RemapProjectTempPhotosWithUploadedPhotos(
              tempPhotoID,
              newPhoto.id
            )
          );
        });
      },
      (error) => {
        dispatch(uploadError(error.toString(), tempPhoto));
      }
    );

    // // async load photo to get the width and height
    // let i = new Image();
    // i.onload = function(){
    //   tempPhoto.width = i.width;
    //   tempPhoto.height = i.height;
    //   dispatch(uploadSuccess(tempPhoto));

    //   // TODO: start upload process here
    // }
    // i.onerror = function(e){
    //   dispatch(importError(e.toString()));
    // }
    // i.src = tempPhoto.origin;
  };
}

/**
 * Once photo has been imported
 * we then start uploading it
 */
function uploadEditedPhoto(
  newName: string,
  category: string,
  dataURL,
  frameToUpdate: Frame,
  onComplete
) {
  return (dispatch) => {
    // // get projecct ID to save photo in correct folder/category
    // const { project } = getState().edition;
    // const projectID = (project)? project.id : null;

    // // start
    // let tempPhoto = getTempPhotoByID(getState(), tempPhotoID);
    // dispatch( uploadStart( tempPhoto ) );

    // API Call
    API.createPhoto(newName, category, dataURL).then(
      (newPhoto) => {
        batch(() => {
          dispatch({ type: ADD_PHOTO_TO_PROJECT, photo: newPhoto });
          if (frameToUpdate) {
            frameToUpdate.photo = newPhoto.id;
            dispatch(editionActions.UpdateFrame(frameToUpdate));
          }
          onComplete();
        });
      },
      (error) => {
        // dispatch(uploadError(error.toString(), tempPhoto));
        Modal.error({
          title: GetText('popup.upload.error.title'),
          content: GetUnsafeHTMLDiv(
            GetText('popup.upload.error.desc')
              .replace('{NAME}', newName)
              .replace('{ERROR}', error.toString())
          ),
        });
        onComplete();
      }
    );
  };
}

function clearPhotos() {
  return { type: CLEAR_PHOTOS };
}

function resetUploads() {
  return { type: RESET_UPLOADS };
}

function updateSortFilter(sortFilter) {
  return { type: UPDATE_SORT_FILTER, sortFilter };
}

export default reducer;

export const photoListActions = {
  getAll,
  UpdateTempPhotoSize,

  // sort,
  updateSortFilter,
  importPhotos,
  clearPhotos,
  updateProjectPhotoList,
  resetUploads,

  updateBackendPhotoList,

  // photomanager
  // addPhotosToProject,
  // removePhotosFromProject,

  uploadEditedPhoto,
};
