import { createReducer, createSelector } from '@reduxjs/toolkit';
import { config } from '../../data/config';
import { StoreType } from '../../store/store.type';
import { API } from '../../utils/API';
import { alertActions } from '../alert/alert';
import { CreateOverlayerItem } from './overlayerHelper';

/** **********************************
// ACTIONS TYPES
************************************ */

const TAG = 'OVERLAYER';

// LIST
const GETALL_START = `${TAG}/GETALL_START`;
const GETALL_SUCCESS = `${TAG}/GETALL_SUCCESS`;
const GETALL_ERROR = `${TAG}/GETALL_ERROR`;

/** **********************************
// REDUCERS
************************************ */

const initialState: StoreType['overlayers'] = {
  byID: {},
  isLoading: false, // is currently loading list
  error: null, // current error message
};

const reducer = createReducer(initialState, {
  [GETALL_START]: (state) => {
    state.error = null;
    state.isLoading = true;
  },

  [GETALL_ERROR]: (state, action) => {
    state.error = action.error;
    state.isLoading = false;
  },

  [GETALL_SUCCESS]: (state, action) => {
    state.error = null;
    state.isLoading = false;
    state.byID = action.byID;
  },
});

/** **********************************
//  ACTIONS
************************************ */

function getAll(projectClass) {
  return (dispatch) => {
    dispatch(start());

    API.getOverlayers(projectClass).then(
      (jsonResponse) => {
        // alert("overlayer list received:"+JSON.stringify(jsonResponse));
        const resultByID = {};
        const securityMargin = 1; // 1pixel security margin
        // go through items
        jsonResponse.overlays.document.overlay.forEach((item) => {
          const overlayer = CreateOverlayerItem(
            item.$.name,
            Number(item.$.width),
            Number(item.$.height)
          );
          overlayer.cat = item.$.catagory;
          // overlayer.type = item.$.type;
          // overlayer.left = Number(item.$.left);
          // overlayer.top = Number(item.$.top);

          overlayer.bLeft = Number(item.$.bleft) - securityMargin;
          overlayer.bTop = Number(item.$.btop) - securityMargin;
          overlayer.bWidth = Number(item.$.bwidth) - securityMargin;
          overlayer.bHeight = Number(item.$.bheight) - securityMargin;

          // overlayer.luminance = Number(item.$.luminance);

          // to get correct path, we need to remove "overlays/" at start and "jpg" at end from the proxies..
          function cleanProxy(p: string) {
            return p.split('overlays/').join('').split('.png').join('');
          }

          overlayer.proxy = item.$.proxy;
          overlayer.thumb_url = `${
            config.apiPhoto
          }flex/loadoverlays.php?image=${cleanProxy(item.$.tnproxy)}`;
          overlayer.working_url = `${
            config.apiPhoto
          }flex/loadoverlays.php?image=${cleanProxy(item.$.proxy)}`;

          // vo from flash
          // vo = new OverlayerVo();
          // vo.id = node.@name;
          // vo.category = node.@category;
          // vo.id_tn = String(String(node.@tnproxy).split(".jpg")[0]).substring(12);
          // vo.proxy = String(node.@proxy);
          // vo.id_hd = String(String(node.@proxy).split(".jpg")[0]).substring(12);
          // vo.width = MeasureManager.PFLToPixel(Number(node.@width));
          // vo.height = MeasureManager.PFLToPixel(Number(node.@height));
          // vo.isCover = isCover;

          // https://editor.tictacphoto.com/flex/loadoverlayers.php?image=medium/online/christmas/tn_chaussette
          // https://editor.tictacphoto.com/flex/loadoverlayers.php?image=overlayers/small/online/christmas/tn_2015-02.jpg"

          // {"id":"2015-02","cat":"christmas","height":104,"width":142,"left":-2,"top":-2,"type":"photo","luminance":0.8,
          // "thumb_url":
          // "https://editor.tictacphoto.com/flex/loadoverlayers.php?image=overlayers/small/online/christmas/tn_2015-02.jpg"
          // ,"working_url":"https://editor.tictacphoto.com/flex/loadoverlayers.php?image=overlayers/small/online/christmas/2015-02.jpg"}

          // add to result list
          if (overlayer.id !== 'none') {
            // [HACK for first one of overlayers that is "none", it was previously used to remove overlayer from a photo]
            resultByID[overlayer.id] = overlayer;
          }

          // category:"new"
          // height:"104"
          // left:"-2"
          // luminance:"0.8"
          // name:"001 Amour"
          // proxy:"overlayers/small/online/love/001_Amour.jpg"
          // tnproxy:"overlayers/small/online/love/tn_001_Amour.jpg"
          // top:"-2"
          // type:"photo"
          // width:"142"
        });
        // dispatch(success(result.byID, result.filtered));
        dispatch(success(resultByID));
      },
      (error) => {
        dispatch(fail(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function start() {
    return { type: GETALL_START };
  }
  // function success(byID, filtered) { return { type: GETALL_SUCCESS, byID, filtered } }
  function success(byID) {
    return { type: GETALL_SUCCESS, byID };
  }
  function fail(error) {
    return { type: GETALL_ERROR, error };
  }
}

/** **********************************
// SELECTORS
************************************ */

const getAllOverlayersByID = createSelector(
  (state: StoreType) => state.overlayers.byID,
  (byID) => byID
);

const getAllOverlayerList = createSelector(
  (state) => state.overlayers.byID,
  (byID) => Object.keys(byID).map((key) => byID[key])
  // ( byID ) => Object.keys(byID)
);

// const getOverlayerCategories = createSelector(
//   state => state.overlayers.byID,
//   ( byID ) => {
//     let categories = Object.keys(byID).map( ( key, index )=> byID[key].cat );
//     return uniq( categories );
//   }
// );

// const getOverlayersByCategories = createSelector(
//   state => state.overlayers.byID,
//   ( byID ) => {
//     let overlayerByCategories = {};
//     Object.keys(byID).map( ( key, index )=> {
//       let item = byID[key]; // get item
//       if(!overlayerByCategories[item.cat]) // create category arry if not existing
//         overlayerByCategories[item.cat] = [];
//       overlayerByCategories[item.cat].push(item);// push item in correct category object
//     });
//     return overlayerByCategories;
//   }
// );

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export default reducer;

export const overlayerSelectors = {
  getAllOverlayersByID,
  getAllOverlayerList,
  // getOverlayerCategories,
  // getOverlayersByCategories,
};

export const overlayerActions = {
  getAll,
};
