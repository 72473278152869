import { FRAME_TYPE } from '../../../feature/frame/frame.types';
import { Frame, ICalendarColorOptions } from '../../../types/types';
import { CalendarHelper } from '../../../utils/calendar/CalendarHelper';
import { FrameMiniCalendar } from './FrameMiniCalendar';

type Props = {
  frame: Frame;
  editionScale: number;
  pageIndex: number;
  calendarColorOptions: ICalendarColorOptions;
};

export const FrameCalendarText = ({
  frame,
  editionScale,
  pageIndex,
  calendarColorOptions,
}: Props) => {
  // sec
  if (frame.type !== FRAME_TYPE.CALENDAR)
    alert('We should not retreive calendar text svg for non calendar frames..');

  const { calendarOptions } = frame;
  let x;
  let y;
  let anchor;
  let baseline;
  let numLines;

  // frame color
  const frameColor = CalendarHelper.GetFrameTextColor(pageIndex, frame);

  // recover content (normal OR minicalendar)
  const normalContent = CalendarHelper.IsMiniCalendarFrame(frame)
    ? null
    : CalendarHelper.GetFrameText(pageIndex, frame).split('\n');
  let hasOffsetY = false;
  if (normalContent) {
    numLines = normalContent.length;

    // positionning
    if (calendarOptions.hAlign === 'center') {
      x = '50%';
      anchor = 'middle';
    } else if (calendarOptions.hAlign === 'right') {
      x = '100%';
      anchor = 'end';
    } else {
      // DEFAULT LEFT
      x = `${CalendarHelper.GetFrameTextOffset(frame).x}px`;
      anchor = 'start';
    }

    if (calendarOptions.vAlign === 'center') {
      // TODO: this won't work with multi line!
      y = 50; // % but calculated with lines
      baseline = 'middle';
    } else if (calendarOptions.vAlign === 'bottom') {
      // TODO: this won't work with multi line!
      y = 95; // % but calculated with lines
      baseline = 'hanging';
    } else {
      // DEFAULT TOP
      // y=0; // % but calculated with lines
      y = CalendarHelper.GetFrameTextOffset(frame).y;
      if (y !== 0) {
        hasOffsetY = true;
      }
      baseline = 'text-before-edge';
      // baseline="hanging";
    }
  }

  // --------------------- RENDER ------------------------

  return (
    <svg
      width={frame.width}
      height={frame.height}
      textRendering="optimizeSpeed"
    >
      {
        // ---- STROKE ----
        <rect
          width={frame.width}
          height={frame.height}
          fillOpacity={0}
          stroke={calendarColorOptions.strokeColor}
          strokeWidth={calendarOptions.stroke ? 1 / editionScale : '0px'}
        />
      }

      <g>
        {
          // ---- CLASSIC CALENDAR FRAME ----
          normalContent &&
            normalContent.map((value, lineIndex, allLines) => (
              <text
                key={lineIndex}
                height={frame.width}
                width={frame.height / numLines}
                x={x}
                y={
                  !hasOffsetY
                    ? `${Math.round(y + (lineIndex * 95) / numLines)}%`
                    : `${y}px`
                }
                className="unselectable"
                style={{
                  // overflow:"hidden",
                  textAnchor: anchor, // halign
                  dominantBaseline: baseline, // valign
                  fontSize: `${calendarOptions.fontSize}px`,
                  fontWeight: calendarOptions.bold ? 'bold' : null,
                  fontFamily: calendarOptions.fontFamily,
                  fill: frameColor,
                  whiteSpace: 'pre',
                  // stroke:"black",
                  // strokeWidth:2
                }}
              >
                {value}
              </text>
            ))
        }

        {
          // ---- MINI CALENDARS ----
          frame &&
            CalendarHelper.IsMiniCalendarFrame(frame) &&
            calendarOptions && (
              <FrameMiniCalendar
                frame={frame}
                calendarOptions={calendarOptions}
                pageIndex={pageIndex}
              />
            )
        }
      </g>
    </svg>
  );
};
