import { cloneDeep } from 'lodash';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  PROJECT_CONST,
} from '../../data/config';
import { PROJECT_CLASS } from '../../data/Constants';
import { LayoutStore } from '../../store/store.type';
import type { Frame, IPage } from '../../types/types';
import { IsCover } from '../../utils/cover/coverHelper';
import { DateUtils } from '../../utils/DateUtils';
import { GetDoc } from '../../utils/ProductHelper';
import { CreateSpine, CreateSpineNum } from '../../utils/spineHelper';
import { FRAME_TYPE } from '../frame/frame.types';
import {
  CleanAndVerifyFrameText,
  ClearFrame,
  CreateFrame,
  CreateFrameBackground,
  IsFrameText,
} from '../frame/frameHelper';
import { Layout, LAYOUT_TYPE } from './layout.type';

/**
 *  Retrieve correct layout type based on page and docID
 */

export function GetPageLayoutType(page: IPage, _docID?: string): LAYOUT_TYPE {
  // ---- ALBUMS ----
  if (PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM) {
    // --------------------
    // NOTE: as discussed with nico, we don't use merged layouts anymore for layflats,
    // we want to use default layouts and apply to part of the screen
    // https://app.clickup.com/t/86c0mdefu
    // if (page.merged) return LAYOUT_TYPE.MERGED;
    // --------------------
    if (page.index === 0) return LAYOUT_TYPE.COVER;
    return LAYOUT_TYPE.DEFAULT;
  }

  // ---- CALENDARS ----
  if (PROJECT_CONST.project_class === PROJECT_CLASS.CALENDAR) {
    if (page.index === 0) return LAYOUT_TYPE.COVER;
    return page.isCalendar ? LAYOUT_TYPE.CALENDAR : LAYOUT_TYPE.DEFAULT;
  }

  return LAYOUT_TYPE.DEFAULT;
}

export function layoutCanBeAppliedToPage(layout: Layout, page: IPage) {
  // retro compatibility before pages had layout type
  if (!page.layoutType) return true;

  // cover layout
  if (page.index === 0 && layout.type === LAYOUT_TYPE.COVER) return true;

  // same layout type
  if (page.layoutType === layout.type) return true;

  // merged layout can have default layouts applied
  if (page.merged && layout.type === LAYOUT_TYPE.DEFAULT) return true;

  // PROMO URL
  if (
    !PROJECT_CONST.use_PROMO_URL_FRAMES &&
    page.layoutType === LAYOUT_TYPE.PROMO_URL
  )
    return true;

  return false;
}

/** Retrieve a list of ID of forced layout list for this page */
export const GetForcedLayoutsForPage = (
  docID: string,
  pageIndex: number
): Array<number> => {
  const doc = GetDoc(docID);
  const isCover = IsCover(docID, pageIndex);
  if (isCover && doc.layouts_cover) return doc.layouts_cover;
  if (doc.layouts_all) return doc.layouts_all;
  return null;
};

/**
Retreive a custom layout from a page
 */
export function GetCustomLayoutFromPage(page: IPage) {
  const pageCopy: IPage = cloneDeep(page);
  pageCopy.frames.forEach((f) => {
    ClearFrame(f);
  });
  const layout: Layout = {
    id: `custom_${DateUtils.GetTimeStamp()}`,
    isCustom: true,
    width: pageCopy.width,
    height: pageCopy.height,
    frames: pageCopy.frames,
    type: LAYOUT_TYPE.DEFAULT,
  };
  return layout;
}

/**
 * Merge two default layout in one "merged" layout (= 2pages layout for layflat for example)
 */
const mergeLayout = (layoutA: Layout, layoutB: Layout): Layout => {
  const mergedLayout: Layout = cloneDeep(layoutA);
  mergedLayout.id = `merged_${layoutA.id}_${layoutB.id}`;
  mergedLayout.type = LAYOUT_TYPE.MERGED;
  // warn if layout is not the same size
  if (layoutA.width !== layoutB.width || layoutA.height !== layoutB.height)
    console.warn(
      `Layouts ${layoutA.id} and ${layoutB.id} are not the same size`,
      layoutA,
      layoutB
    );
  // update background to fit both pages
  const bg = mergedLayout.frames[0];
  bg.width = bg.width * 2;
  bg.x = bg.width / 2;
  // update layout with to fit both pages
  mergedLayout.width = layoutA.width * 2;
  // add frames from layoutB at the right side
  layoutB.frames.forEach((frame, i) => {
    // we don't want to add the background frame
    if (i !== 0) {
      const newFrame = cloneDeep(frame);
      newFrame.x += layoutA.width;
      mergedLayout.frames.push(newFrame);
    }
  });
  // update layout num photos
  mergedLayout.numPhotos = layoutA.numPhotos + layoutB.numPhotos;
  return mergedLayout;
};

/**
 * Convert an existing project form flash editor to the current data structure
 */
export function convertOldLayoutList(oldLayoutListJSON): {
  byID: LayoutStore['byID'];
  filtered: LayoutStore['filtered'];
} {
  const result = {
    filtered: {
      /*
    default: {
        all:[],
        "1" : [],
        "2" : [],
      }
    },
   ....
   */
    },
    byID: {},
  };

  // go through layout list and convert it to new format
  oldLayoutListJSON.layouts.layout.forEach((oldNode) => {
    // only add layout if there is a valid content to it
    if (oldNode.$ && oldNode.frame) {
      const attributes = oldNode.$;
      const newLayout: Layout = {
        id: attributes.name,
        width: Number(attributes.pagewidth),
        height: Number(attributes.pageheight),
        type: LAYOUT_TYPE.DEFAULT,
        numPhotos: 0,
        frames: [],
        isCustom: false,
      };

      let hasSpine = false; // does the layout has a spine, if so we need to clean it afterwards

      // security for canvas items with only 1 frame element! That are not parsed as array..
      if (!Array.isArray(oldNode.frame)) {
        // alert("HUSTON! We have a situation" + typeof(oldNode.frame));
        oldNode.frame = [oldNode.frame];
      }

      // add all frames from xml node converted
      // oldNode.frame.foreach(( oldFrame, i, arr )=>{ // Cannot use foreach otherwise we cannot skip an element with "continue"
      for (let i = 0; i < oldNode.frame.length; i += 1) {
        const oldFrame = oldNode.frame[i];
        const attr = oldFrame.$;
        const newFrame: Frame = CreateFrame({}); // new Frame();
        newFrame.height = Number(oldFrame.$.height);
        newFrame.width = Number(oldFrame.$.width);
        newFrame.type = oldFrame.$.type;

        // newFrame.rotation = Math.round(Math.random() * 180);

        newFrame.rotation = Number(oldFrame.$.rotation);
        if (!newFrame.rotation) newFrame.rotation = 0;
        newFrame.x = Number(oldFrame.$.left);
        newFrame.y = Number(oldFrame.$.top);

        // positioning in app is centered, while it's 0,0 on old layouts
        newFrame.x += newFrame.width / 2;
        newFrame.y += newFrame.height / 2;

        // --- PHOTO ---
        if (newFrame.type === FRAME_TYPE.PHOTO) {
          // keep track of num of photos
          newLayout.numPhotos += 1;
        }

        // if we got a spine attribute or a spine number attribute, we are in a cover layout
        // groupeType=5 : SPINE
        // groupeType=5 : SPINE_NUMBER
        if (attr.grouptype && attr.grouptype === '5') {
          newLayout.type = LAYOUT_TYPE.COVER;
          newFrame.type = FRAME_TYPE.SPINE;

          // old layout inverted width and height as rotation was "inside"
          const tempHeight = newFrame.height;
          newFrame.height = Number(newFrame.width);
          newFrame.width = tempHeight;

          // be sure it is centered
          newFrame.x = newLayout.width / 2; // positioning in old layouts is at "0,0"

          hasSpine = true;
          continue; // we don't add it to list, we will add this afterwards
        }
        if (attr.grouptype && attr.grouptype === '6') {
          newLayout.type = LAYOUT_TYPE.COVER;
          newFrame.type = FRAME_TYPE.SPINE_NUM;
          hasSpine = true;
          continue;
        }

        // check if frame is calendar frame
        if (IsCalendarEditor() && attr.dateaction) {
          // <frame stroke="true" left="110" top="164" width="30" height="8"
          // rotation="0" fit="false" clip="false"
          // bleed="0.0" halign="center" type="text"
          // editable="false" font="Helvetica"
          // size="15" color="Black" bold="true" italic="false"
          // dateindex="4" dateaction="[weekday]" valign="center"
          // showborder="false" />
          newLayout.type = LAYOUT_TYPE.CALENDAR; // if there is a calendar frame, this is a calendar layout..
          newFrame.type = FRAME_TYPE.CALENDAR;
          newFrame.calendarOptions = {
            dateAction: attr.dateaction,
            dateIndex: attr.dateindex,
            stroke: attr.stroke === 'true',
            vAlign: attr.valign,
            hAlign: attr.halign,
            fontFamily: attr.font,
            fontSize: attr.size,
            bold: attr.bold === 'true',
            italic: attr.italic === 'true',
          };
        }

        if (newFrame.type === FRAME_TYPE.POSTCARD_BG) {
          newLayout.type = LAYOUT_TYPE.POSTCARD_BG;
        }
        // special calendar url frame
        if (
          newFrame.type === FRAME_TYPE.PROMO_URL &&
          newLayout.type !== LAYOUT_TYPE.POSTCARD_BG
        ) {
          newLayout.type = LAYOUT_TYPE.PROMO_URL;
        }

        // verify frame text
        if (IsFrameText(newFrame)) CleanAndVerifyFrameText(newFrame);
        /* if(newFrame.type === FRAME_TYPE.TEXT)
     {
      AddDefaultTextToFrame(newFrame);
     }
     */

        // add to list
        newLayout.frames.push(newFrame);
      }

      // --- BACKGROUND check ---
      if (
        newLayout.frames[0].type !== FRAME_TYPE.BKG &&
        newLayout.frames[0].type !== FRAME_TYPE.POSTCARD_BG
      ) {
        // check if frame is bigger than page width and heith, so it's a background
        const f = newLayout.frames[0];
        if (f.width > newLayout.width && f.height > newLayout.height) {
          if (f.type === FRAME_TYPE.PHOTO) newLayout.numPhotos -= 1; // background photos are not considered as "photos"
          f.type = FRAME_TYPE.BKG;
        }
        // otherwise create a background and push it at beginning
        else {
          newLayout.frames.unshift(
            CreateFrameBackground(newLayout.width, newLayout.height)
          );
        }
      }

      // verify that framebackground is filling enough
      // newLayout.frames[0] = CreateFrameBackground(newLayout.width, newLayout.height);
      if (hasSpine) {
        newLayout.frames.push(CreateSpine(newLayout.width, newLayout.height));
        newLayout.frames.push(
          CreateSpineNum(newLayout.width, newLayout.height)
        );
      }

      // add to result
      result.byID[newLayout.id] = newLayout;

      // add to filtered result
      if (!result.filtered[newLayout.type])
        result.filtered[newLayout.type] = {};

      const filterResult = result.filtered[newLayout.type];

      // add to filtered result
      if (!filterResult.all) filterResult.all = [];
      filterResult.all.push(newLayout.id);

      // add to filtered by photo
      if (!filterResult[newLayout.numPhotos])
        filterResult[newLayout.numPhotos] = [];

      filterResult[newLayout.numPhotos].push(newLayout.id);
    }
  });

  // --- MERGED LAYOUTS ---
  // EDIT: we don't need merged layouts anymore for layflats as we want to use those layouts as single layouts to apply to part of the merged
  /*
  if (IsAlbumEditor()) {
    // For new layflat albums, a new layout type has been created.
    // merged layouts are simple layouts merged on two pages with one background
    // here we create a full list of merged layouts to be used.
    const mergedLayouts: Layout[] = [];
    const listOfDefaultLayouts = Object.keys(result.byID)
      .map((key) => result.byID[key])
      .filter((layout) => layout.type === LAYOUT_TYPE.DEFAULT);

    // lets create new merged layout by merging all default layouts
    // for now the rule is simple, we create a layout with the page next to it, otherwise we have too many layouts
    
    listOfDefaultLayouts.forEach((layout, i) => {
      if (i % 2 === 1) return;
      const layoutA = layout;
      const layoutB = listOfDefaultLayouts[i + 1];
      const mergedLayout = mergeLayout(layoutA, layoutB);
      mergedLayouts.push(mergedLayout);
    });

    // add MERGED layouts type to results
    mergedLayouts.forEach((layout) => {
      result.byID[layout.id] = layout;
      if (!result.filtered[layout.type]) result.filtered[layout.type] = {};
      const filterResult = result.filtered[layout.type];
      // add to filtered result
      if (!filterResult.all) filterResult.all = [];
      filterResult.all.push(layout.id);
      // add to filtered by photo
      if (!filterResult[layout.numPhotos]) filterResult[layout.numPhotos] = [];
      filterResult[layout.numPhotos].push(layout.id);
    });
  }
    */

  return result;
}

/**
 * Clean layout frames that goes beyond the edge of the page
 * this is really important in case of merged layouts were we dont want layouts overlap on the other page
 **/
export const cleanLayoutBeyondEdgeFrames = (layout: Layout): Layout => {
  // we modify frames that goes beyond the edge of the page
  const layoutCopy = cloneDeep(layout);
  layoutCopy.frames.forEach((frame) => {
    // case frame is beyond edge on the right
    if (frame.x + frame.width / 2 > layout.width) {
      const diff = frame.x + frame.width / 2 - layout.width;
      frame.width -= diff;
      frame.x -= diff / 2;
    }
    // case frame is beyond edge on the left
    if (frame.x - frame.width / 2 < 0) {
      const diff = Math.abs(frame.x - frame.width / 2);
      frame.width -= diff;
      frame.x += diff / 2;
    }
  });
  return layoutCopy;
};
