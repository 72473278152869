import { GetProductCatalogue } from '../data/catalogue/productCatalogue';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../data/config';
import { ALBUM_TYPES, PROJECT_CLASS } from '../data/Constants';
import { DebugFlags } from '../debug/DebugFlags';
import { IDocument } from '../types/IDocument';
import { Project, ProjectOptions } from '../types/project';
import { CanvasHelper } from './canvas/CanvasHelper';
import { notifyError } from './error/notifyError';
import { pixelToCm } from './MeasureUtils';

export const DOC_ID_ENUM = {
  // --------------------- CALENDARS ------------------------
  // ---- WALL ----
  CAL_WALL: 'WCAL',
  CAL_WALL_XL: 'WCAL2',
  CAL_WALL_XXL: 'WCAL3',
  CAL_BIRTHDAY: 'WCAL4',
  CAL_LUX_A3: 'WCAL5',
  CAL_LUX_A4: 'WCAL6',
  // ---- DESK ----
  CAL_DESK_PANO: 'DCAL',
  CAL_DESK_A5: 'DCAL2',
  // ---- MAGNET ----
  CAL_MAGNET_A3: 'MCAL1',
  CAL_MAGNET_A4: 'MCAL2',
  CAL_MAGNET_A5: 'MCAL3',
  // ---- DIARY ----
  CAL_DIARY_A4: 'OCAL1',
  CAL_DIARY_A5: 'OCAL2',
  CAL_DIARY_A6: 'OCAL3',
  // ---- DESK PORTRAIT ----
  CAL_DESK_PORTRAIT_A5: 'PCAL1',
  CAL_DESK_PORTRAIT_A6: 'PCAL2',
};

export function GetDoc(docID: string): IDocument {
  const doc = IsCanvasEditor()
    ? CanvasHelper.GetDoc(docID)
    : GetProductCatalogue().docs[docID];

  if (!doc) {
    notifyError(
      new Error(
        `Document not found for docID: ${docID} in editor: ${PROJECT_CONST.project_class}`
      )
    );
  }
  return doc;
}

export function GetProjectSizePixel(docID: string): string {
  const doc = GetDoc(docID);
  return `${pixelToCm(doc.width * doc.multiplier).toFixed(1)}cm * ${pixelToCm(
    doc.height * doc.multiplier
  ).toFixed(1)}cm`;
}

export function GetProjectDocSizeCM(
  docID: string,
  precision = 1
): `${string} cm * ${string} cm` {
  const doc = GetDoc(docID);
  const w = pixelToCm(doc.width * doc.multiplier);
  let h = pixelToCm(doc.height * doc.multiplier);
  if (IsCalendar_WallXL(docID)) h *= 2;
  return `${w.toFixed(precision)} cm * ${h.toFixed(precision)} cm`;
}

export function GetProjectSizeCM(project: Project, precision = 1): string {
  return `${pixelToCm(project.width).toFixed(precision)} cm * ${pixelToCm(
    project.height
  ).toFixed(precision)} cm`;
}

/**
 * Get possible updates based on upgrade list in catalogue.
 * Only used for calendars right now.
 */
export function GetPossibleUpgrades(docID: string): string[] {
  const catalogue = GetProductCatalogue();

  if (catalogue.upgrades) {
    // find in node with possible upgrades
    for (const docs of catalogue.upgrades) {
      if (docs.includes(docID)) return docs;
    }
  }

  if (IsCalendar_WallXL(docID) && DebugFlags.ALLOW_CALENDAR_XL_UPGRADES)
    return [DOC_ID_ENUM.CAL_WALL_XL, DOC_ID_ENUM.CAL_WALL_XXL];

  return []; // otherwise return empty list
}

export function GetProjectOrientation(docID) {
  const doc = GetDoc(docID);

  if (doc.width > doc.height) {
    return 'landscape';
  }
  return 'portrait';
}

/**
 * Retrive the backend code for this project
 */
export function GetProjectCode({
  docID,
  cardPack,
  type,
}: ProjectOptions): number {
  const doc = GetDoc(docID);

  // ---- CARD ----
  // doc code for cards are related to the number of repetitions
  if (IsCardEditor()) {
    const cardDocCode = doc.codes[cardPack];
    return cardDocCode;
  }

  // ---- CANVAS ----
  if (IsCanvasEditor()) {
    return CanvasHelper.GetFinalDocCode(docID, type);
  }

  return doc.code;
}

export function GetDocIDByDocCode(
  docCode: number,
  optionalClassname: string,
  doNotWarn = false
) {
  const classname = optionalClassname || PROJECT_CONST.project_class;
  const catalogue = GetProductCatalogue(classname);
  docCode = parseInt(docCode, 10); // be sure to work with numbers
  let docID = null;

  // ---- ALBUMS ----
  if (classname === PROJECT_CLASS.ALBUM) {
    Object.keys(catalogue.docs).forEach((key) => {
      const { code } = catalogue.docs[key];
      if (code === docCode) docID = key;
    });
  }

  // ---- CALENDAR ----
  else if (classname === PROJECT_CLASS.CALENDAR) {
    Object.keys(catalogue.docs).forEach((key) => {
      const { code } = catalogue.docs[key];
      if (code === docCode) docID = key;
    });

    if (!docID) alert(`No ID found for docCode:${docCode}`);

    return docID;
  }

  // ---- CARD ----
  else if (classname === PROJECT_CLASS.CARD) {
    Object.keys(catalogue.docs).forEach((key) => {
      const { codes } = catalogue.docs[key];
      Object.keys(codes).forEach((cardPack) => {
        if (codes[cardPack] === docCode) docID = key;
      });
    });

    if (!docID) console.warn(`No ID found for docCode:${docCode}`);

    return docID;
  }

  // ---- CANVAS ----
  else if (classname === PROJECT_CLASS.CANVAS) {
    docID = CanvasHelper.GetCanvasDocIDByDocCode(docCode);
  }

  // TODO: handle error here?
  if (!docID && !doNotWarn) alert(`No ID found for docCode:${docCode}`);

  return docID;
}

/**
 * Retreive a product type by it's backend code
 */
export function GetProjectTypeByDocID(docID: string, isCustomCover = false) {
  // ---- ALBUMS ----
  if (IsAlbumEditor()) {
    let projectType = null;
    const catalogue = GetProductCatalogue();
    Object.keys(catalogue.products).forEach((type) => {
      catalogue.products[type].docs.forEach((id) => {
        if (id === docID) {
          projectType = type;
        }
      });
    });

    // Special project PREMIUM
    if (
      projectType === ALBUM_TYPES.CLASSIC ||
      projectType === ALBUM_TYPES.CONTEMPORARY
    ) {
      projectType = isCustomCover
        ? ALBUM_TYPES.CONTEMPORARY
        : ALBUM_TYPES.CLASSIC;
    }

    // // Special project LAYFLAT
    // if (
    //   projectType === ALBUM_TYPES.LAYFLAT ||
    //   projectType === ALBUM_TYPES.LAYFLAT_CLASSIC
    // ) {
    //   projectType = isCustomCover
    //     ? ALBUM_TYPES.LAYFLAT
    //     : ALBUM_TYPES.LAYFLAT_CLASSIC;
    // }
    return projectType;
  }

  // ---- CALENDARS ----
  if (IsCalendarEditor()) {
    let projectType = null;
    const catalogue = GetProductCatalogue();
    Object.keys(catalogue.products).forEach((type) => {
      catalogue.products[type].docs.forEach((id) => {
        if (id === docID) {
          projectType = type;
        }
      });
    });
    return projectType;
  }

  // ---- CARDS ----
  if (IsCardEditor()) {
    let projectType = null;
    const cat = GetProductCatalogue();
    // for (let type in cat.products) {
    Object.keys(cat.products).forEach((type) => {
      // for (let format in cat.products[type].formats) {
      Object.keys(cat.products[type].formats).forEach((format) => {
        // for (let doc of cat.products[type].formats[format].docs) {
        Object.keys(cat.products[type].formats[format].docs).forEach((doc) => {
          if (doc === docID) {
            projectType = type;
          }
        });
      });
    });
    return projectType;
  }

  alert('TODO: other product types');
}

// ---- CALENDAR ----

export function IsCalendar_magnetA5(docID): boolean {
  return docID === 'MCAL3';
}

export function IsCalendar_withMonthOverlap(docID): boolean {
  return (
    docID === 'WCAL' ||
    docID === 'WCAL2' ||
    docID === 'WCAL3' ||
    docID === 'DCAL' ||
    docID === 'DCAL2' ||
    docID === 'PCAL1' ||
    docID === 'PCAL2'
  );
}

export function IsCalendar_WallXL(docID): boolean {
  return (
    docID === DOC_ID_ENUM.CAL_WALL_XL || docID === DOC_ID_ENUM.CAL_WALL_XXL
  );
}

export function IsCalendar_Birthday(docID): boolean {
  return docID === 'WCAL4';
}

export function IsCalendar_Organizer(docID): boolean {
  return docID === 'OCAL1' || docID === 'OCAL2' || docID === 'OCAL3';
}

export function IsCalendar_Magnet(docID): boolean {
  return docID === 'MCAL1' || docID === 'MCAL2' || docID === 'MCAL3';
}

export function IsCalendar_WithSpiralHoles(docID): boolean {
  return IsCalendarEditor() && !IsCalendar_Magnet(docID);
}

export function IsCalendar_Lux(docID): boolean {
  return docID === 'WCAL5' || docID === 'WCAL6';
}
