import { FlagOutlined } from '@ant-design/icons';
import { captureMessage } from '@sentry/browser';
import { Modal } from 'antd';
import { Colors } from '../../data/Colors';
import { GetText } from '../../data/LanguageHelper';
import { API } from '../../utils/API';
import { notifyError } from '../../utils/error/notifyError';
import TextArea from 'antd/lib/input/TextArea';
import { openPopupThankYou } from './openPopupThankYou';

export const openPopupFeedback = (
  optionalTitle?: string,
  optionalDescription?: string,
  optionalDetail?: string
): Promise<void> => {
  return new Promise((resolve) => {
    let userFeedback: any = null;
    const title = optionalTitle || GetText('popup.feedback.title');
    const desc =
      optionalDescription || GetText('popup.feedback.description', true);

    // log
    captureMessage('Feedback Popup: Open');

    // open modal
    Modal.confirm({
      title: (
        <span>
          <FlagOutlined
            style={{
              color: Colors.SELECTION,
              fontSize: '180%',
              paddingRight: '10px',
            }}
          />
          {title}
        </span>
      ),
      icon: null,
      content: (
        <span>
          {desc} :
          <br />
          <br />
          <TextArea
            placeholder={GetText('popup.feedback.input')}
            rows={4}
            onChange={(e) => {
              userFeedback = e.target.value;
            }}
          />
          <br />
          <i>{optionalDetail}</i>
        </span>
      ),

      cancelText: GetText('common.cancel'),
      okText: GetText('common.ok'),
      onOk: () => {
        if (userFeedback && userFeedback !== '') {
          // log
          notifyError('Feedback Popup: Send: ' + userFeedback);
          //
          API.sendUserFeedbackToDev(userFeedback)
            .then(() => API.sendUserFeedbackToSupport(userFeedback))
            .then(openPopupThankYou)
            .then(() => {
              resolve();
            });
        } else resolve();
      },
      onCancel: () => {
        resolve();
      },
    });
  });
};
