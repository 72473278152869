import { Frame, Photo } from '../../../types/types';
import { getFrameMinZoom } from '../frameHelper';

export const injectPhotoIntoFrame = (
  frame: Frame,
  photo: Photo,
  keepExistingZoom = false
) => {
  const frameObj = frame;
  const photoObj = photo;

  // calculate correct zoom
  const minZoom = getFrameMinZoom(frameObj, photoObj);

  // keep existing crop and zoom
  if (keepExistingZoom) {
    if (frameObj.zoom < minZoom) frameObj.zoom = minZoom;

    const maxCLeft = photoObj.width * frameObj.zoom - frameObj.width;
    const maxCTop = photoObj.height * frameObj.zoom - frameObj.height;
    if (frameObj.cLeft > maxCLeft) frameObj.cLeft = maxCLeft;
    if (frameObj.cTop > maxCTop) frameObj.cTop = maxCTop;

    if (frameObj.cTop < 0) frameObj.cTop = 0;
    if (frameObj.cLeft < 0) frameObj.cLeft = 0;
  }
  // otherwise center content
  else {
    // TODO: handle correctly cleft and ctop
    frameObj.zoom = minZoom;
    frameObj.cLeft = (photoObj.width * frameObj.zoom - frameObj.width) / 2;
    frameObj.cTop = (photoObj.height * frameObj.zoom - frameObj.height) / 2;
  }

  // add photo ref to it
  frameObj.photo = photoObj.id;

  // if frame has a backgrond we need to remove it
  if (frameObj.background) frameObj.background = null;

  // TODO: this return is not necessary as it returns the original object
  return frameObj;
};
