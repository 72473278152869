type Props = {
  orientation: 'horizontal' | 'vertical';
  outsideMargin: number;
  widthOrX: number; // x for vertical, width for horizontal
  heightOrY: number; // y for horizontal, height for vertical
  variant?: 'light' | 'normal';
};

/*
 * Display the inner page shadow of albums (shadow between pages)
 */
export const InnerPageShadow = ({
  orientation,
  outsideMargin,
  widthOrX,
  heightOrY,
  variant = 'normal',
}: Props) => {
  const maxOpacity = variant === 'light' ? 0.2 : 0.4;
  const minOpacity = maxOpacity / 4;
  return (
    <g>
      {
        // --------------------- VERTICAL shadow ------------------------
        orientation === 'vertical' && (
          <g>
            <defs>
              <linearGradient id="Gradient1" x1="0" x2="1" y1="0" y2="0">
                <stop offset="0%" stopColor="black" stopOpacity="0" />
                <stop offset="25%" stopColor="black" stopOpacity={minOpacity} />
                <stop offset="50%" stopColor="black" stopOpacity={maxOpacity} />
                <stop offset="75%" stopColor="black" stopOpacity={minOpacity} />
                <stop offset="100%" stopColor="black" stopOpacity="0" />
              </linearGradient>
            </defs>
            <rect
              x={outsideMargin + widthOrX - 25}
              y={outsideMargin}
              height={heightOrY}
              width={50}
              fill="url(#Gradient1)"
            />
          </g>
        )
      }
      {
        // --------------------- HORIZONTAL shadow ------------------------
        orientation === 'horizontal' && (
          <g>
            <defs>
              <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor="black" stopOpacity="0" />
                <stop offset="25%" stopColor="black" stopOpacity={minOpacity} />
                <stop offset="50%" stopColor="black" stopOpacity={maxOpacity} />
                <stop offset="75%" stopColor="black" stopOpacity={minOpacity} />
                <stop offset="100%" stopColor="black" stopOpacity="0" />
              </linearGradient>
            </defs>
            <rect
              y={outsideMargin + heightOrY - 10}
              x={outsideMargin}
              height={20}
              width={widthOrX}
              fill="url(#Gradient1)"
            />
          </g>
        )
      }
    </g>
  );
};
