import { Colors } from '../../data/Colors';
import { IsCanvasEditor } from '../../data/config';
import { DebugFlags } from '../../debug/DebugFlags';
import { Frame, Photo } from '../../types/types';
import { INCH_TO_PIXEL } from '../../utils/MeasureUtils';
import { hasOwn } from '../../utils/ObjectUtils';
import { GetUID } from '../../utils/UID';
import { DATE_ACTION_ENUM } from '../calendar/CalendarEnums';
import { isFrameEmpty } from './_helpers/isFrameEmpty';
import { getDefaultFrameShadow } from './shadow/shadowHelper';
import {
  defaultTextOptions,
  FRAME_TYPE,
  IMAGE_QUALITY,
  OLD_DEFAULT_TEXT,
} from './frame.types';

export function CreateFrame(frameOptions: Partial<Frame>): Frame {
  const defaultOptions: Frame = {
    id: GetUID(),
    type: FRAME_TYPE.PHOTO,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    zoom: 1,
    cLeft: 0,
    cTop: 0,
    border: 0,
    borderRadius: 0,
    rotation: 0,
    borderColor: Colors.WHITE,
  };

  const newFrame = { ...defaultOptions, ...frameOptions };
  return newFrame;
}

// /**
//  *
//  */
// export class Frame
// {
// /**
//  *
//  * @param {String} id
//  * @param {String} type
//  * @param {Number} width
//  * @param {Number} height
//  * @param {Number} x
//  * @param {Number} y
//  * @param {Number} rotation
//  * @param {IFrameShadow} shadow
//  * @param {ICalendarFrameOptions} calendarOptions
//  *
//  */
// constructor( type, width, height, x, y, rotation=0, calendarOptions=null )
// {
// this.id = GetUID();
// this.type = type;
// this.width = Number(width);
// this.height = Number(height);
// this.x = Number(x);
// this.y = Number(y);
// this.rotation = Number(rotation);

// // crop values
// this.zoom = 1;
// this.cLeft = 0;
// this.cTop = 0;

// // optional
// this.photo = null;
// this.text = null; // {"value" : "this is the text", "valign" : "middle", "halign" : "left", "size" : 18, "color" : ""}
// this.background = null; // background info (nor background ID, nor fill color)
// // this.backgroundProxy = null; // should be in the background object!
// this.clipart = null; // clipart ID
// // this.clipartProxy = null; // Should be in the clipart object!
// this.fillColor = null;

// // border/mask/shadow
// this.border = 0;
// this.borderColor = Colors.WHITE;
// // public var showBorder:Boolean = true;// can this frame have a border // WHY? if no border, no display, right?
// // public var border :number = 0; // border thickenss
// this.shadow = null;

// // --- CALENDAR ---
// this.calendarOptions = null
// }
// }

export function ResetFrameCropValues(frame: Frame) {
  frame.zoom = 1;
  frame.cLeft = 0;
  frame.cTop = 0;
}

export function NeedFrameUpload(frame: Frame): boolean {
  // if empty, not needed to upload!
  if (isFrameEmpty(frame)) return false;

  // frames that always needs to be uploaded
  if (
    [
      FRAME_TYPE.TEXT,
      FRAME_TYPE.SPINE,
      FRAME_TYPE.SPINE_NUM,
      FRAME_TYPE.POSTCARD_BG,
      FRAME_TYPE.QR_CODE,
      FRAME_TYPE.PROMO_URL,
    ].includes(frame.type)
  )
    return true;

  // CALENDAR--> upload some specific types
  if (frame.type === FRAME_TYPE.CALENDAR) {
    /** @type {ICalendarFrameOptions} * */
    const options = frame.calendarOptions;
    const { dateAction } = options;
    if (
      // special calendar frames that must be "png" generated
      dateAction === DATE_ACTION_ENUM.NOTES ||
      dateAction === DATE_ACTION_ENUM.MINICALENDAR_PREVIOUS ||
      dateAction === DATE_ACTION_ENUM.MINICALENDAR_FULL ||
      dateAction === DATE_ACTION_ENUM.MINICALENDAR_NEXT ||
      // Frame calendar with rotation (organizer date and year)
      frame.rotation !== 0
    )
      return true;
  }

  // mask
  // TODO: later discuss this with Keith : https://www.pdflib.com/pdflib-cookbook/path_objects/clipping/php/
  // [Edit after discussion with keith]
  // if(frame.photo && frame.borderRadius)
  // return true;

  // POPART EFFECT --> awlays upload popart effect
  // if( isPopart && photoVo ) return true;

  // all others are not uploaded (photo online, cliparts, overlayers, backgrounds)
  return false;
}

// --------------------- Frame text ------------------------

export function CreateNewFrameText(value, width, height): Frame {
  return CreateFrame({
    type: FRAME_TYPE.TEXT,
    width,
    height,
    text: { ...defaultTextOptions, ...{ value } },
  });
}

export function IsFrameText(frame: Frame) {
  return (
    frame.type === FRAME_TYPE.TEXT ||
    frame.type === FRAME_TYPE.SPINE ||
    frame.type === FRAME_TYPE.SPINE_NUM
  );
}

export function AddDefaultTextToFrame(frame /*: Frame */) {
  if (IsFrameText(frame)) frame.text = { ...defaultTextOptions };
}

//
/**
 * Cleans and verifies the text properties of a given frame.
 * This function ensures that the frame's text properties are set to default values
 * if they are missing or contain outdated values. It also performs specific checks
 * for spine number frames and removes any special backspace characters from the text.
 *
 * @param {Frame} frame - The frame object to be cleaned and verified.
 *                        The frame should have a type that indicates it contains text,
 *                        such as TEXT, SPINE, or SPINE_NUM.
 */
export function CleanAndVerifyFrameText(frame: Frame) {
  if (!IsFrameText(frame))
    console.warn(`Frame is not a frame text:${JSON.stringify(frame)}`);
  else {
    // if frame has old default text, we remove it ( TODO: WILL BE REMOVED LATER )
    if (!frame.text) AddDefaultTextToFrame(frame);

    const textObj = frame.text;
    if (textObj) {
      // TODO: maybe we should put this sequence in a objectHelper (clean with existing object default model..)
      if (!hasOwn(textObj, 'value') || textObj.value === OLD_DEFAULT_TEXT)
        textObj.value = defaultTextOptions.value;
      if (!hasOwn(textObj, 'size')) textObj.size = defaultTextOptions.size;
      if (!hasOwn(textObj, 'family'))
        textObj.family = defaultTextOptions.family;
      if (!hasOwn(textObj, 'color') || textObj.color === 0xff0000) {
        // 0xff0000 was old version, so we need to clean this...
        textObj.color = defaultTextOptions.color;
      }
      if (!hasOwn(textObj, 'valign'))
        textObj.valign = defaultTextOptions.valign;
      if (!hasOwn(textObj, 'halign'))
        textObj.halign = defaultTextOptions.halign;
      if (!hasOwn(textObj, 'bold')) textObj.bold = defaultTextOptions.bold;
      if (!hasOwn(textObj, 'italic'))
        textObj.italic = defaultTextOptions.italic;
    }

    // security for spine num
    if (frame.type === FRAME_TYPE.SPINE_NUM && textObj.value.length > 3)
      textObj.value = '00';

    // clean 'backspace special' chars!!
    textObj.value = textObj.value.replace('\b', ''); // bug from client reported 06/11/2020
  }
}

// --------------------- Frame background ------------------------

export function CreateFrameBackground(pageWidth: number, pageHeight: number) {
  const marginX: number = pageWidth * 0.01; // TODO: this should maybe be in cm?
  // const marginY:number = pageHeight*.025; // TODO: this should maybe be in cm?
  const marginY: number = marginX;
  // const margin:number = cmToPixel(1) ; // TODO: this should maybe be in cm? EDIT: cannot be done here as layout is in PFL

  return CreateFrame({
    type: FRAME_TYPE.BKG,
    width: pageWidth + marginX * 2,
    height: pageHeight + marginY * 2,
    x: pageWidth / 2,
    y: pageHeight / 2,
  });
}

export function frameCanBeSelected(frame /*: Frame */) {
  if (DebugFlags.ALLOW_ALL_FRAME_SELECTION) return true;

  return (
    frame.type !== FRAME_TYPE.PROMO_URL && frame.type !== FRAME_TYPE.POSTCARD_BG
  );
}

export function frameCanBeHovered(frame /*: Frame */) {
  if (DebugFlags.ALLOW_ALL_FRAME_SELECTION) return true;

  return (
    frame.type !== FRAME_TYPE.PROMO_URL && frame.type !== FRAME_TYPE.POSTCARD_BG
  );
}

export function frameCanMove(frame /*: Frame */) {
  return (
    frame.type !== FRAME_TYPE.BKG &&
    frame.type !== FRAME_TYPE.SPINE_NUM &&
    frame.type !== FRAME_TYPE.SPINE &&
    frame.type !== FRAME_TYPE.CALENDAR
  );
}

export function frameCanChangeDepth(frame /*: Frame */) {
  return (
    frame.type !== FRAME_TYPE.BKG &&
    frame.type !== FRAME_TYPE.SPINE_NUM &&
    frame.type !== FRAME_TYPE.SPINE &&
    frame.type !== FRAME_TYPE.CALENDAR
  );
}

export function frameCanHavePhoto(frame /*: Frame */) {
  return (
    frame.type === FRAME_TYPE.PHOTO ||
    frame.type === FRAME_TYPE.BKG ||
    frame.type === FRAME_TYPE.OVERLAYER ||
    (frame.type === FRAME_TYPE.CALENDAR &&
      frame.calendarOptions.dateAction === DATE_ACTION_ENUM.DAYDATE)
  );
}

export function frameCanRotate(frame /*: Frame */) {
  return (
    frame.type !== FRAME_TYPE.BKG &&
    frame.type !== FRAME_TYPE.SPINE_NUM &&
    frame.type !== FRAME_TYPE.CALENDAR &&
    frame.type !== FRAME_TYPE.SPINE
  );
}
export function frameCanCrop(frame /*: Frame */) {
  return frame.type === FRAME_TYPE.PHOTO || frame.type === FRAME_TYPE.TEXT;
}
export function frameCanInsideMove(frame /*: Frame */) {
  return !!frame.photo;
}

export function frameCanSwapStart(frame /*: Frame */) {
  return !!frame.photo;
}

export function frameCanInsideRotation(frame /*: Frame */) {
  return frame.type === FRAME_TYPE.PHOTO && !isFrameEmpty(frame);
}

export function frameCanSwapDrop(frame /*: Frame */) {
  return (
    frame.type === FRAME_TYPE.PHOTO ||
    frame.type === FRAME_TYPE.BKG ||
    frame.type === FRAME_TYPE.OVERLAYER ||
    (frame.type === FRAME_TYPE.CALENDAR &&
      frame.calendarOptions.dateAction === DATE_ACTION_ENUM.DAYDATE)
  );
}

export function frameCanScale(frame /*: Frame */) {
  return (
    frame.type !== FRAME_TYPE.BKG &&
    frame.type !== FRAME_TYPE.SPINE_NUM &&
    frame.type !== FRAME_TYPE.SPINE &&
    frame.type !== FRAME_TYPE.CALENDAR
  );
}
export function frameCanZoom(frame /*: Frame */) {
  return (
    (frame.type !== FRAME_TYPE.BKG || frame.photo) &&
    frame.type !== FRAME_TYPE.SPINE_NUM &&
    frame.type !== FRAME_TYPE.SPINE &&
    frame.type !== FRAME_TYPE.CALENDAR
  );
}

export function frameHasDeleteButton(frame: Frame) {
  return frameCanBeCleared(frame) || frameCanBeDeleted(frame);
}

export function frameCanBeDeleted(frame /*: Frame */) {
  return (
    frame.type !== FRAME_TYPE.BKG &&
    frame.type !== FRAME_TYPE.SPINE &&
    frame.type !== FRAME_TYPE.SPINE_NUM &&
    frame.type !== FRAME_TYPE.CALENDAR &&
    frame.type !== FRAME_TYPE.POSTCARD_BG
  );
}

export function frameCanPhotoEdit(frame /*: Frame */) {
  return frame.photo;
}

export function frameCanHaveBorder(frame /*: Frame */) {
  return frame.type === FRAME_TYPE.PHOTO && !isFrameEmpty(frame);
}
export function frameCanHaveMask(frame /*: Frame */) {
  return frame.type === FRAME_TYPE.PHOTO && !isFrameEmpty(frame);
}

export function frameCanHaveShadow(frame /*: Frame */) {
  return frame.type === FRAME_TYPE.PHOTO && !isFrameEmpty(frame);
}

export function frameCanHaveText(frame) {
  return (
    frame.type === FRAME_TYPE.TEXT ||
    frame.type === FRAME_TYPE.SPINE ||
    frame.type === FRAME_TYPE.SPINE_NUM ||
    (frame.type === FRAME_TYPE.CALENDAR &&
      frame.calendarOptions.dateAction === DATE_ACTION_ENUM.DAYDATE)
  );
}

export function frameCanBeCleared(frame /*: Frame */) {
  return (
    frame.type !== FRAME_TYPE.QR_CODE &&
    frame.type !== FRAME_TYPE.CLIPART &&
    frame.type !== FRAME_TYPE.POSTCARD_BG &&
    !isFrameEmpty(frame)
  );
}

// ---- CLEAR FRAME ----
export function ClearFrame(frame: Frame) {
  if (frame.type === FRAME_TYPE.PHOTO || frame.type === FRAME_TYPE.OVERLAYER) {
    frame.photo = null; // reset photo
    frame.zoom = 1; // reset zoom
    frame.cLeft = 0; // reset cLeft
    frame.cTop = 0; // reset cTop
    // remove shadow
    frame.shadow = getDefaultFrameShadow();
  } else if (frame.type === FRAME_TYPE.BKG) {
    frame.photo = null; // reset photo
    frame.background = null; // reset photo
    frame.zoom = 1; // reset zoom
    frame.cLeft = 0; // reset cLeft
    frame.cTop = 0; // reset cTop
  } else if (frame.type === FRAME_TYPE.CALENDAR) {
    frame.photo = null; // reset photo
    frame.background = null; // reset photo
    frame.zoom = 1; // reset zoom
    frame.cLeft = 0; // reset cLeft
    frame.cTop = 0; // reset cTop
    frame.text = null; // clear potential additional user text
  } else if (frame.type === FRAME_TYPE.TEXT) {
    frame.text = { ...defaultTextOptions };
  } else if (frame.type === FRAME_TYPE.SPINE || FRAME_TYPE.SPINE_NUM) {
    frame.text = { ...defaultTextOptions };
  }
  // TODO: clear other frame type
}

/**
 * get the minimum zoom of the frame to fit the frame content
 **/
export function getFrameMinZoom(frameObj: Frame, photoObj: Photo) {
  if (!photoObj || !photoObj.width) return 1; // security
  const frameRatio = frameObj.width / frameObj.height;
  const photoRatio = photoObj.width / photoObj.height;

  // to avoid issue we round to the 2nd decimal
  if (frameRatio > photoRatio) return frameObj.width / photoObj.width;
  return frameObj.height / photoObj.height;
}

/**
 *
 Scale a frame, and the cleft, ctop and zoom values for possible exisint content!
 */
export function ScaleFrameAndContent(frame, newWidth, newHeight) {
  const scaleX = newWidth / frame.width;
  const scaleY = newHeight / frame.height;

  // use biggest zoom to be sure we are still correctly linked in frame
  const zoomScale = scaleX > scaleY ? scaleX : scaleY;

  //
  frame.width *= scaleX;
  frame.height *= scaleY;

  const widthDiff = frame.width * scaleX - frame.width * zoomScale;
  const heighDiff = frame.height * scaleY - frame.height * zoomScale;

  frame.cLeft = frame.cLeft * zoomScale - widthDiff / 2;
  frame.cTop = frame.cTop * zoomScale - heighDiff / 2;
  frame.zoom *= zoomScale;

  // we need to scale frame text size also to keep consistency
  if (frame.type === FRAME_TYPE.TEXT && frame.text) {
    frame.text.size *= scaleY > scaleX ? scaleX : scaleY;
  }
}

// ---- CHECK IMAGE QUALITY INDICATOR ----
export function GetImageQualityIndicator(frame: Frame): number {
  // no photo, no problem
  if (!frame.photo) return IMAGE_QUALITY.GOOD;

  const okDpi = IsCanvasEditor() ? 96 : 144; // === minimum resolution needed for correct printing
  const lowDpi = okDpi * 0.75; // until 75%, it's acceptable
  const imageDpi = INCH_TO_PIXEL / frame.zoom;

  if (imageDpi < lowDpi) return IMAGE_QUALITY.BAD;
  if (imageDpi < okDpi) return IMAGE_QUALITY.LOW;
  return IMAGE_QUALITY.GOOD;
}

// export function CheckImageResolution( frame /*:Frame*/ ) :number
// {
//   // project resolution is often 144 (from previous)
//   // imageScale below is the image zoom value..

//   // var okImageDpi :number = Infos.project.resolution ;
//   // var lowImageDpi :number = Infos.project.resolution *0.75; //*0.85;
//   // //var badImageDpi :number = Infos.project.resolution *0.70;

//   // var imageLowScale:number = INCH_TO_PIXEL/okImageDpi; // this scale is the scale corresponding to an image injected in 72 dpi to equal 300 dpi
//   // var imageBadScale:number  = INCH_TO_PIXEL/lowImageDpi; // this scale is the scale corresponding to an image injected in 72 dpi to equal 300 dpi

//   // if(imageScale > imageBadScale) return -1;
//   // if(imageScale > imageLowScale) return 0;
//   // return 1;
//   return IMAGE_QUALITY.GOOD;
// }

export function svgToPng(svg, onSuccess, onError) {
  // create canvas
  const canvas = document.createElement('canvas');
  canvas.width = 800;
  canvas.height = 300;

  // get contextxt
  const ctx = canvas.getContext('2d');

  const xml = new XMLSerializer().serializeToString(svg);
  const img = new Image();
  img.crossOrigin = 'Anonymous';

  // on img load
  img.onload = function () {
    // draw to canvas
    ctx.drawImage(img, 0, 0);

    // TODO: set it again maybe?
    // this.crossOrigin = "Anonymous";

    const url = canvas.toDataURL('image/png');
    const png = new Image();
    png.src = url;
    png.crossOrigin = 'Anonymous';
    // $('#test-blobs').append(png);
    // URL.revokeObjectURL(url);
    // if (current <= frames) {
    // processImage();
    // }
    if (onSuccess) onSuccess(png);
    // window.open(url,'Image','width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
    // window.open(url,'Image');
    // var win = window.open();
    // win.document.write('<iframe src="' + url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  };

  // on image error
  img.onerror = function (error) {
    // console.log(error);
    // processImage();
    if (onError) onError(error);
  };

  // img.src = URL.createObjectURL(blob);
  img.src = buildSvgImageUrl(xml);

  const win = window.open();
  win?.document.write(
    `<iframe src="${img.src}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
  );
}

function buildSvgImageUrl(svg) {
  const b64 = window.btoa(svg);
  return `data:image/svg+xml;base64,${b64}`;
}
