import { SPECIAL_CATEGORIES } from '../../_components/PhotoManager/types';
import { Category } from '../../types/types';

export const isSpecialCategory = (category: string) => {
  return (
    Object.values<string>(SPECIAL_CATEGORIES).includes(category) ||
    category?.includes('@@')
  );
};

export const isProjectCategory = (category: string) => {
  return category.includes('@@');
};

/**
 * sort categories by label
 * We put the project categories below the custom categories
 * and we sort them by label
 */
export const sortCategories = (a: Category, b: Category) => {
  // we want special categories always at the end
  const aIsSpecial = isSpecialCategory(a.id);
  const bIsSpecial = isSpecialCategory(b.id);
  if (aIsSpecial && !bIsSpecial) return 1;
  if (!aIsSpecial && bIsSpecial) return -1;

  // we want to display project categories after the others
  const aIsProject = a.isProject;
  const bIsProject = b.isProject;
  if (aIsProject && !bIsProject) return 1;
  if (!aIsProject && bIsProject) return -1;

  // default sort on label
  return a.label.localeCompare(b.label);
};
