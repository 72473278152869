import { Link } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { config } from '../../data/config';
import { GetText } from '../../data/LanguageHelper';
import { ROUTE_CONST } from '../../utils/history';

type Props = {
  loggingIn: boolean;
  errorMessage: string;
  username: string;
  onSubmit: (email: string, password: string) => void;
};

export const LoginForm = ({
  loggingIn,
  errorMessage,
  username,
  onSubmit,
}: Props) => {
  //
  // const [form] = Form.useForm();

  const handleFinish = ({ email, password }) => {
    onSubmit(email, password);
  };

  // --------------------- RENDER ------------------------

  return (
    <Form onFinish={handleFinish} className="login-form">
      <Form.Item
        name="email"
        initialValue={username}
        rules={[
          { required: true, message: GetText('error.login.user') },
          // {type: 'email', message: 'The input is not valid E-mail!'}]
          { type: 'email', message: GetText('error.login.user') },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={GetText('login.user')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: GetText('error.login.pass') }]}
      >
        <Input
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder={GetText('login.pass')}
        />
      </Form.Item>
      <Form.Item>
        {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
        <a
          className="login-form-forgot"
          href={config.forgetPasswordLink}
          target="_blank"
          rel="noreferrer"
        >
          {GetText('login.forgotPassword')}
        </a>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={loggingIn}
        >
          {loggingIn
            ? GetText('common.wait')
            : GetText('login.continue.button')}
        </Button>
        Or{' '}
        <Link to={ROUTE_CONST.REGISTER}>
          {GetText('login.register.button')}
        </Link>
      </Form.Item>
    </Form>
  );
};
