import { useSelector } from 'react-redux';
import { captureException } from '@sentry/browser';
import { photoListSelector } from '../feature/photoList/photo.selector';
import { useAssetsSelector } from './useAssetsSelector';

export const useTempPhotoReplace = () => {
  const { getPhotosById } = useAssetsSelector();
  const tempPhotoMapping = useSelector(
    photoListSelector.getTempPhotoToNewPhotoMapping
  );

  /*
  give a photo ID and check if it correctly exist!
  if not, check if there is a temporary (loading) photo with that same ID and returns it
  */
  const checkReplaceTempPhotoID = (photoID: string): string => {
    // try to get photo by ID
    const photoObj = getPhotosById(photoID);

    // if not existing, check if it exists in temp photo mapping
    if (!photoObj) {
      if (tempPhotoMapping.hasOwnProperty(photoID)) {
        return tempPhotoMapping[photoID];
      } else {
        console.warn(`Not able to find photo object for ID:${photoID}`);
        captureException(
          new Error(`Not able to find photo object for ID:${photoID}`)
        );
        // TODO: should we not return a default photo here?
      }
    }
    return photoID;
  };

  return {
    checkReplaceTempPhotoID,
  };
};
