import { useSelector } from 'react-redux';
import { backgroundSelectors } from '../feature/backgrounds/background.store';
import { clipartSelectors } from '../feature/cliparts/cliparts';
import { overlayerSelectors } from '../feature/overlayers/overlayers';
import { photoListSelector } from '../feature/photoList/photo.selector';
import { Background, Clipart, OverlayerItem, Photo } from '../types/types';

export type AssetSelector = {
  getPhotosById: (id: string) => Photo;
  getBackgroundsById: (id: string) => Background;
  getOverlayersById: (id: string) => OverlayerItem;
  getClipartsById: (id: string) => Clipart;
};

/**
 * This hook gives you access to the assets by their ID
 */
export const useAssetsSelector = (): AssetSelector => {
  const photoMap: { [key: string]: Photo } = useSelector(
    photoListSelector.getAllPhotosByID
  );
  const backgroundMap: { [key: string]: Background } = useSelector(
    backgroundSelectors.getAllBackgroundsByID
  );
  const overlayerMap: { [key: string]: OverlayerItem } = useSelector(
    overlayerSelectors.getAllOverlayersByID
  );
  const clipartMap: { [key: string]: Clipart } = useSelector(
    clipartSelectors.getAllClipartsByID
  );

  return {
    getPhotosById: (id: string) => photoMap[id],
    getBackgroundsById: (id: string) => backgroundMap[id],
    getOverlayersById: (id: string) => overlayerMap[id],
    getClipartsById: (id: string) => clipartMap[id],
  };
};
