import { Frame, IPage } from '../../../types/types';

export function getFrameByID(frameID: string, pageList: Array<IPage>): Frame {
  let theFrame = null;
  if (pageList && frameID) {
    pageList.forEach((page: IPage) => {
      page.frames.forEach((frame: Frame) => {
        if (frame.id === frameID) {
          theFrame = frame;
        }
      });
    });
  }
  return theFrame;
}
