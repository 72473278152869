import domtoimage, { Options } from 'dom-to-image';
import $ from 'jquery';

/**
 * Escape html characters from a string
 * @param {string} unsafe
 */
export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function trimHtmlTags(input): string {
  return input.replace(/<\/?[^>]+(>|$)/g, '');
}

export function openCanvasContentInNewWindow(canvas) {
  // convert canvas to blob then open
  canvas.toBlob((blob) => {
    openBlobInNewWindow(blob);
  }, 'image/png');
}

export function openBlobInNewWindow(blobOrFile) {
  const objectURL = URL.createObjectURL(blobOrFile);
  window.open(objectURL);
}

export const imageURLToBase64 = async (imageUrl: string) => {
  // the timestamp was added to avoid caching issues with CORS
  // https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
  return await fetch(imageUrl + '&t=' + new Date().getTime(), {
    credentials: 'include',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });
};

// export function OLD_imageURLToBase64(imageUrl): Promise {
//   return new Promise((resolve, reject) => {
//     /// //////////////////////////////////////////////////////////////
//     // // OLD METHOD
//     //  var xhr = new XMLHttpRequest();
//     //  xhr.onload = function() {
//     //    var reader = new FileReader();
//     //    reader.onloadend = function() {
//     //     resolve(reader.result);
//     //    }
//     //    reader.readAsDataURL(xhr.response);
//     //   };
//     //  xhr.onerror = function(){
//     //   reject("Error image to base64: " + xhr.status + " -> " + xhr.statusText);
//     //  }
//     //  xhr.withCredentials = true;

//     //  // OLD
//     //  xhr.open('GET', imageUrl);
//     //  // USING PROXY (working but credentials are not..)
//     //  // var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
//     //  // xhr.open('GET', proxyUrl + imageUrl);
//     //  // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

//     //  // alert("optionalSessionID:" + optionalSessionID)
//     //  // if(optionalSessionID)
//     //  //  xhr.setRequestHeader('Set-Cookie', 'TTPOESESSID=' + optionalSessionID );
//     //  xhr.responseType = 'blob';
//     //  xhr.send();

//     /// //////////////////////////////////////////////////////////////
//     const xhr = new XMLHttpRequest();
//     xhr.responseType = 'arraybuffer';
//     xhr.open('GET', imageUrl + '?t=' + new Date().getTime());
//     xhr.onload = function () {
//       let base64;
//       let binary;
//       let bytes;
//       let mediaType;

//       bytes = new Uint8Array(xhr.response);
//       // NOTE String.fromCharCode.apply(String, ...
//       // may cause "Maximum call stack size exceeded"
//       binary = [].map.call(bytes, (byte) => String.fromCharCode(byte)).join('');
//       mediaType = xhr.getResponseHeader('content-type');
//       base64 = [
//         'data:',
//         mediaType ? `${mediaType};` : '',
//         'base64,',
//         btoa(binary),
//       ].join('');

//       resolve(base64);
//     };
//     xhr.onerror = function () {
//       reject(`Error image to base64: ${xhr.status} -> ${xhr.statusText}`);
//     };
//     // xhr.withCredentials = true;
//     xhr.send();
//   });
// }

export function dataURLToBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function downloadDataURL(dataURL, filename = 'my_image.jpeg') {
  const link = document.createElement('a');
  link.download = filename;
  link.href = dataURL;
  link.click();
}

export function downloadTextAsFile(
  textString: string,
  filename = 'my_text.txt'
) {
  const myblob = new Blob([textString], { type: 'text/plain' });
  const dataURL = window.URL.createObjectURL(myblob);
  downloadDataURL(dataURL, filename);
}

/**
 * Convert dataURL string (base64) to file
 */
export function dataURLtoFile(dataURL: string, filename: string) {
  // first convert to blob
  const blob: Blob = dataURLToBlob(dataURL);
  // then to file
  return new File([blob], filename, { type: blob.type });
}

// load fonts before generating the image.
const loadFonts = async () => {
  if ('fonts' in document) {
    await Promise.all(
      Array.from(document.fonts.values()).map((font) => font.load())
    );
    await document.fonts.ready;
  }
};

/**
 * Export a dom element to image
 * @param {} node
 */
export function domElementToPng(
  node: HTMLElement,
  scale = 10,
  workWithACopy = false
): Promise<string> {
  let domElement: HTMLElement = node;

  // make a clone copy and put offscreen so it's not visible!
  if (workWithACopy) {
    domElement = $(node).clone()[0];
    // hide it
    domElement.style.position = 'absolute';
    domElement.style.left = '0px';
    domElement.style.top = '0px';
    domElement.style.padding = '0px';
    domElement.style.margin = '0px';
    domElement.style.zIndex = '-1000';
    // add it so it can be rendered
    document.body.append(domElement);
  }

  // scale to print in higher quality
  const options: Options | undefined = scale
    ? {
        width: domElement.clientWidth * scale,
        height: domElement.clientHeight * scale,
        style: {
          transform: `scale(${scale})`,
          'transform-origin': 'top left',
        },
        cacheBust: false,
      }
    : undefined;

  // Wait for fonts then process images
  return loadFonts()
    .then(() => replaceAllSVGImages(domElement))
    .then(() => {
      return new Promise((resolve) => {
        requestAnimationFrame(() => {
          domtoimage.toPng(domElement, options).then((dataUrl) => {
            // if we worked with a copy, just remove element now that we got the image
            if (workWithACopy) document.body.removeChild(domElement);
            resolve(dataUrl);
          });
        });
      });
    });
}

/**
 *
 * This function will replace all images in a SVG in preparation for an export.
 * It will load all image tag inside the svg in a queue
 * once the items are loaded, it will put the href attribute to use a base64 version of the images.
 * This allow an SVG to include the image data directly.
 */
export const replaceAllSVGImages = (node: Node) => {
  const allImages: HTMLOrSVGImageElement[] = $(node).find('image').get();

  const loadAndReplaceImage = async (svgElement: HTMLOrSVGImageElement) => {
    try {
      const imageURL: string = $(svgElement).attr('href').replace('tn', 'work');
      const base64Content = await imageURLToBase64(imageURL);
      $(svgElement).attr('href', base64Content);
    } catch (e) {
      console.warn(`Replace SVG Images: Error:${e}`);
      return Promise.resolve();
    }
  };

  const promises = allImages.map((svgElement) =>
    loadAndReplaceImage(svgElement)
  );
  return Promise.all(promises);
};
