import { Frame } from '../../../types/types';
import {
  FRAME_SHADOW_SIZE,
  GetShadowFrameRect,
  SHADOW_OFFSET,
} from './shadowHelper';

type Props = {
  frame: Frame;
  isForPrint?: boolean;
};

export const FrameShadow = ({ frame, isForPrint = false }: Props) => {
  const frameShadow = frame.shadow;
  const shadowRect = !isForPrint
    ? GetShadowFrameRect(frame)
    : {
        x: 0,
        y: 0,
        width: FRAME_SHADOW_SIZE,
        height: FRAME_SHADOW_SIZE,
      };

  // radius of the shadow
  const scaleX = frame.width / FRAME_SHADOW_SIZE;
  const scaleY = frame.height / FRAME_SHADOW_SIZE;
  const rx = frame.borderRadius ? frame.borderRadius / scaleX : 10;
  const ry = frame.borderRadius ? frame.borderRadius / scaleY : 10;

  return (
    <svg
      x={shadowRect.x}
      y={shadowRect.y}
      width={shadowRect.width}
      height={shadowRect.height}
    >
      <defs>
        <filter id="blur">
          <feGaussianBlur stdDeviation="5" />
        </filter>
      </defs>
      <g
        transform={`scale(${shadowRect.width / FRAME_SHADOW_SIZE}, ${
          shadowRect.height / FRAME_SHADOW_SIZE
        })`}
      >
        {/* THIS ALLOW TO PREVIEW THE SHADOW OFFSET */}
        {/* <rect
                    width={FRAME_SHADOW_SIZE}
                    height={FRAME_SHADOW_SIZE}
                    rx="0" ry="0" fill={Colors.GREEN_FLASH}
                    style={{opacity:0.5}}
                /> */}
        <rect
          filter="url(#blur)"
          x={SHADOW_OFFSET}
          y={SHADOW_OFFSET}
          width={FRAME_SHADOW_SIZE - SHADOW_OFFSET * 2}
          height={FRAME_SHADOW_SIZE - SHADOW_OFFSET * 2}
          rx={rx}
          ry={ry}
          fill={frameShadow.hexColor}
          style={{ opacity: frameShadow.opacity }}
        />
        {/* <text y="10">{JSON.stringify(shadowRect)}</text> */}
      </g>
    </svg>
    // </svg>
  );
};
