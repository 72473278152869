import { PureComponent } from 'react';

interface MultiLineTextProps {
  text: string; // text multiline with "\n"
}

export class MultiLineText extends PureComponent<MultiLineTextProps> {
  render() {
    const separators = ['\n', '\\n', '<br />', '<br>', '<br/>'];
    let textArray = this.props.text;
    separators.forEach((sep) => {
      textArray = textArray.split(sep).join('@@');
    });
    textArray = textArray.split('@@');
    return (
      <>
        {textArray.map((value) => (
          <p>{value}</p>
        ))}
      </>
    );
  }
}
