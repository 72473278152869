import { createRef, useEffect, useState } from 'react';
import {
  CloseCircleOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  PictureOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Button, Tooltip, Upload } from 'antd';
import { cloneDeep, isObject } from 'lodash';
import { ForeignObjectUnscaled } from '../../../_components/SvgForeignObjectUnscaled';
import { Colors } from '../../../data/Colors';
import { PROJECT_CONST } from '../../../data/config';
import { GetText } from '../../../data/LanguageHelper';
import { DebugFlags } from '../../../debug/DebugFlags';
import { isFrameEmpty } from '../../../feature/frame/_helpers/isFrameEmpty';
import { FRAME_TYPE, IMAGE_QUALITY } from '../../../feature/frame/frame.types';
import {
  frameCanBeHovered,
  frameCanBeSelected,
  GetImageQualityIndicator,
} from '../../../feature/frame/frameHelper';
import { FrameQR } from '../../../feature/frame/qrcode/FrameQR';
import { FrameShadow } from '../../../feature/frame/shadow/FrameShadow';
import { getDefaultFrameShadow } from '../../../feature/frame/shadow/shadowHelper';
import { FrameText } from '../../../feature/frame/text/FrameText';
import { OverlayerHelper } from '../../../feature/overlayers/overlayerHelper';
import { AssetSelector } from '../../../hooks/useAssetsSelector';
import {
  Frame,
  ICalendarColorOptions,
  ImageAsset,
  OverlayerItem,
  Photo,
} from '../../../types/types';
import { IsSafari } from '../../../utils/BrowserHelper';
import { mmToPoint } from '../../../utils/MeasureUtils';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { FrameCalendarText } from './FrameCalendarText';
import { FramePostcardBG } from './FramePostcardBG';

type Props = {
  frame: Frame;
  pageIndex: number;
  calendarColorOptions: ICalendarColorOptions;
  frameIndex: number;
  isForPrint?: boolean;
  isPreviewMode: boolean;
  editionScale: number;
  isSelected: boolean;
  isEditing: boolean; // are we currenctly editing this frame? (inside move, zoom, move, rotate )

  // assets
  assetSelector: AssetSelector; // NOTE: asset selector is passed as props to avoid issue with redux in storybook

  // callbacks
  onUploaderChange: (
    info: UploadChangeParam<UploadFile<any>>,
    frame: Frame
  ) => void;
  onFrameTextLimitReached?: (frameLimit: {
    width?: number;
    height?: number;
  }) => void;

  onFrameDoubleClick: (frame: Frame) => void;
  onFrameMouseDown: (frameIndex, e) => void;

  onDragOverFrame: (e, i) => void;
  onDragLeaveFrame: (e, i) => void;
  onDropOnFrame: (e, i, frame) => void;

  onDummyUploadRequest: ({ file, onSuccess }: any) => void;

  onAddTextCtaClicked: () => void;
  // onAddImageCtaClicked: () => void;
};

export const FrameArea = ({
  frame: baseFrame,
  pageIndex,
  calendarColorOptions,
  frameIndex,
  isForPrint,
  isPreviewMode,
  editionScale,
  isSelected,
  isEditing,

  assetSelector,

  onUploaderChange,
  onFrameTextLimitReached,
  onFrameDoubleClick,
  onFrameMouseDown,

  // onAddImageCtaClicked,
  onAddTextCtaClicked,

  onDragOverFrame,
  onDragLeaveFrame,
  onDropOnFrame,

  onDummyUploadRequest,
}: Props) => {
  const [loading, setLoading] = useState(false); // is currently loading
  const [loaded, setLoaded] = useState(false); // current loaded url
  const [loadingUrl, setLoadingUrl] = useState(null); // currently used url
  const [finalUrl, setFinalUrl] = useState(null); // final url to be loaded
  const [error, setError] = useState(null); // has error
  const [draggingOver, setDraggingOver] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [qualityWarningHide, setQualityWarningHide] = useState(false);

  const {
    getPhotosById,
    getBackgroundsById,
    getOverlayersById,
    getClipartsById,
  } = assetSelector;

  const frame: Frame = cloneDeep(baseFrame); // let's work with a copy of the frame

  // position (translated to center)
  const framePos = {
    x: frame.x - frame.width / 2,
    y: frame.y - frame.height / 2,
  };

  let pic: ImageAsset = null;
  if (frame.photo) {
    pic = getPhotosById(frame.photo);
  } else if (frame.background) {
    pic = getBackgroundsById(frame.background);
  }

  if (pic && DebugFlags.SIMULATE_ERROR_ON_FRAME_LOAD)
    pic.thumb_url = 'testrror.png';

  const clipart = frame.clipart ? getClipartsById(frame.clipart) : null;
  const overlayer: OverlayerItem = frame.overlayer
    ? getOverlayersById(frame.overlayer)
    : null;
  // const overlayerContentRect = (overlayer)? OverlayerHelper.GetOverLayerContentRect(overlayer, frame) : null;
  const overlayerRect = overlayer
    ? OverlayerHelper.GetOverLayerOverRect(overlayer, frame)
    : null;
  const isEmpty = isFrameEmpty(frame);
  const emptyButtonFullyDisplaySize = 200 / editionScale;
  const borderInPixel = mmToPoint(frame.border);
  if (!frame.shadow || !isObject(frame.shadow))
    frame.shadow = getDefaultFrameShadow();

  // quality
  const imageQuality = GetImageQualityIndicator(frame);

  let imageURL = pic ? loadingUrl : null;
  // if for print, we don't use the checkLoad system, we directly use the high url
  if (pic && (isForPrint || isPreviewMode)) {
    imageURL = pic.full_url || pic.working_url;
  }

  // const contentRect = overlayerContentRect? overlayerContentRect : {
  const contentRect = {
    x: 0,
    y: 0,
    width: frame.width,
    height: frame.height,
  };

  const frameTextDivRef = createRef<HTMLDivElement>();

  // --------------------- methods ------------------------

  const handleImageLoadComplete = () => {
    // case we need to load other
    setLoading(false);
    setLoaded(true);
    setError(null);
  };

  const handleImageLoadError = () => {
    setLoading(false);
    setLoaded(false);
    setError(`Error while loading:${loadingUrl}`);
  };

  const loadDummy = (url) => {
    // load dummy image
    const img = new Image();
    img.src = url;
    img.addEventListener('load', handleImageLoadComplete);
    img.addEventListener('error', handleImageLoadError);
  };

  const checkLoad = (low_url, high_url) => {
    // case new url!
    if (finalUrl !== high_url) {
      // case nothing loaded yet
      setLoading(true);
      setLoaded(false);
      setFinalUrl(high_url);
      setLoadingUrl(low_url);
      setError(null);
      loadDummy(low_url);
    }
    // case low url loaded but still need to load high url
    else if (!error && !loading && loadingUrl !== finalUrl) {
      setLoading(true);
      setLoaded(false);
      setFinalUrl(high_url);
      setLoadingUrl(high_url);
      setError(null);

      loadDummy(high_url);
    }
  };

  const checkAndLoadContent = () => {
    const photo = frame.photo ? getPhotosById(frame.photo) : null;
    const bkg = frame.background ? getBackgroundsById(frame.background) : null;
    const clip = frame.clipart ? getClipartsById(frame.clipart) : null;
    // const overlayer = frame.overlayer ? overlayersByID[frame.overlayer] : null;
    if (photo) {
      const lowUrl = photo.temp ? photo.temp_url : photo.thumb_url;
      const highUrl = photo.temp ? photo.temp_url : photo.working_url;
      checkLoad(lowUrl, highUrl);
    } else if (bkg) {
      const lowUrl = bkg.thumb_url;
      const highUrl = bkg.working_url;
      checkLoad(lowUrl, highUrl);
    } else if (clip) {
      const lowUrl = clip.thumb_url;
      const highUrl = clip.working_url;
      checkLoad(lowUrl, highUrl);
    }
    // else if( overlayer )
    // {
    //     const low_url = overlayer.thumb_url ;
    //     const high_url = overlayer.working_url;
    //     checkLoad(low_url, high_url);
    // }

    // securities
    if (!error && frame.photo && !pic && !isEditing) {
      setError(`Photo with ID:'${frame.photo}' not found`);
    }

    // const clipart = (frame.clipart)? clipartsByID[frame.clipart]:null;
    // let imageAsset = (pic)? pic : clipart? clipart : null;

    // if(imageAsset)

    // // image not in image list
    // if( frame.photo )
    // {
    //     if( !pic && !error )
    //         handleImageLoadError( "error while loading photo with id: " + frame.photo );
    //     else if(!loaded && !loading && !error)
    //         handleImageLoadStart( (pic.temp) ? pic.temp_url : pic.thumb_url  );
    // }
    // else if( frame.clipart )
    // {
    //     if( !clipart && !error )
    //         handleImageLoadError( "error while loading clipart with id: " + frame.clipart );
    //     else if(!loaded && !loading && !error)
    //         handleImageLoadStart( clipart.thumb_url );
    // }
  };

  // --------------------- effects ------------------------

  // on mount
  // useEffect(() => {
  //   checkAndLoadContent();
  // }, []);

  // on each render update, we verify text frame
  useEffect(() => {
    checkAndLoadContent();

    // check text frame limits
    if (
      frame.type === FRAME_TYPE.TEXT &&
      frameTextDivRef &&
      frameTextDivRef.current
    ) {
      if (frameTextDivRef.current.clientWidth - frame.width > 1) {
        // console.log( "we reached the WIDTH limit!" );
        onFrameTextLimitReached?.({
          width: frameTextDivRef.current.clientWidth,
        });
      }
      if (frameTextDivRef.current.clientHeight - frame.height > 1) {
        // console.log( "we reached the HEIGHT limit!" );
        onFrameTextLimitReached?.({
          height: frameTextDivRef.current.clientHeight,
        });
      }
    }
  });

  // --------------------- render ------------------------

  return (
    <g
      id={frame.id}
      key={frame.id}
      transform={
        isForPrint
          ? ''
          : `translate(${framePos.x},${framePos.y}) rotate(${frame.rotation},${
              frame.width / 2
            },${frame.height / 2})`
      }
      // className="editionFrame"
      // onClick={(e)=> { onFrameSelect( e.target, frame ) ;}} >
      className={frameCanBeSelected(frame) ? '' : 'unselectable unclickable'}
      onMouseDown={(e) => {
        onFrameMouseDown(frameIndex, e);
      }}
      onDoubleClick={() => {
        onFrameDoubleClick(frame);
      }}
      onDragOver={(e) => {
        setDraggingOver(true);
        onDragOverFrame(e, frameIndex);
      }}
      onDragLeave={(e) => {
        setDraggingOver(false);
        onDragLeaveFrame(e, frameIndex);
      }}
      onDrop={(e) => {
        setDraggingOver(false);
        setIsOver(false);
        onDropOnFrame(e, frameIndex, frame);
      }}
      onMouseEnter={() => {
        setIsOver(true);
      }}
      onMouseLeave={() => {
        setIsOver(false);
      }}
    >
      {frame.borderRadius && (
        <defs>
          <clipPath id={`mask_${frame.id}`}>
            <rect
              x={0}
              y={0}
              ry={
                frame.borderRadius && frame.border
                  ? frame.borderRadius + borderInPixel
                  : frame.borderRadius
              }
              rx={
                frame.borderRadius && frame.border
                  ? frame.borderRadius + borderInPixel
                  : frame.borderRadius
              }
              width={frame.width}
              height={frame.height}
            />
          </clipPath>
        </defs>
      )}

      <g>
        {
          // --------------------- SHADOW ------------------------
          frame.shadow && frame.shadow.enabled && !isForPrint && (
            <FrameShadow frame={frame} />
          )
        }

        <rect
          x="0"
          y="0"
          width={frame.width}
          height={frame.height}
          style={{
            fill: frame.fillColor,
            fillOpacity:
              frame.fillColor && frame.fillColor !== '#ffffff' ? 1 : 0,
          }}
        />

        {/** *******************
                IMAGE
                Check this for more clipping/mask information
                https://webdesign.tutsplus.com/tutorials/a-comprehensive-guide-to-clipping-and-masking-in-svg--cms-30380
                ********************** */}
        {pic && (
          <g clipPath={`url(#mask_${frame.id})`}>
            <svg
              x={contentRect.x}
              y={contentRect.y}
              width={contentRect.width}
              height={contentRect.height}
              viewBox={`${frame.cLeft} ${frame.cTop} ${contentRect.width} ${contentRect.height}`}
            >
              <image
                key={`${pic.id}_${isPreviewMode}`}
                pointerEvents="none" // edit 2024 without this we have a dragable bug on clipart on Firefox
                className={`${
                  isPreviewMode ? 'frameArea_image_preview' : 'frameArea_image'
                } ${loaded ? 'frame_loaded' : ''}`}
                href={imageURL}
                xlinkHref={imageURL}
                width={pic.width * frame.zoom}
                height={pic.height * frame.zoom}
                preserveAspectRatio="none"
              />
            </svg>
          </g>
        )}

        {
          // --------------------- Clipart ------------------------
          clipart && (
            <image // id={frame.id}
              key={`${clipart.id}_${isPreviewMode}`}
              pointerEvents="none" // edit 2024 without this we have a dragable bug on clipart on Firefox
              // href={frame.content.image}
              // href={ clipart.thumb_url}
              className={`${
                isPreviewMode ? 'frameArea_image_preview' : 'frameArea_image'
              } ${loaded ? 'frame_loaded' : ''}`}
              // xlinkHref={ clipart.thumb_url}
              href={loadingUrl}
              xlinkHref={loadingUrl}
              width={frame.width}
              height={frame.height}
              preserveAspectRatio="none"
            />
          )
        }

        {DebugFlags.IS_LOCALHOST &&
          pic &&
          (pic as Photo).temp &&
          !isForPrint && (
            <rect x={10} y={10} width={10} height={10} fill="blue" />
          )}

        {
          // --------------------- overlayer ------------------------
          overlayer && (
            <g key={`${overlayer.id}_${isPreviewMode}`}>
              <image
                pointerEvents="none" // edit 2024 without this we have a dragable bug on clipart on Firefox
                className={`${
                  isPreviewMode ? 'frameArea_image_preview' : 'frameArea_image'
                } ${loaded ? 'frame_loaded' : ''}`}
                href={overlayer.working_url}
                xlinkHref={overlayer.working_url}
                width={overlayerRect.width}
                height={overlayerRect.height}
                x={overlayerRect.x}
                y={overlayerRect.y}
                preserveAspectRatio="none"
              />

              {/* { // TODO: what if no photo? Empty
                    //  (!pic) &&
                    //     <rect
                    //         x={contentRect.x} y={contentRect.y}
                    //         width={contentRect.width} height={contentRect.height}
                    //         fill="black"
                    //         />
                    } */}
            </g>
          )
        }

        {/* --------------------- CALENDAR ------------------------ */}
        {frame.type === FRAME_TYPE.CALENDAR && (
          <FrameCalendarText
            frame={frame}
            editionScale={editionScale}
            calendarColorOptions={calendarColorOptions}
            pageIndex={pageIndex}
          />
        )}

        {/* --------------------- QRCODE ------------------------ */}
        {frame.type === FRAME_TYPE.QR_CODE && <FrameQR frame={frame} />}

        {/* --------------------- PROMO URL ------------------------ */}
        {frame.type === FRAME_TYPE.PROMO_URL &&
          PROJECT_CONST.use_PROMO_URL_FRAMES && (
            <svg width={frame.width} height={frame.height}>
              {/*  // BACKGROUND
                    <rect
                        width={t.width} height={t.height}
                        fill={Colors.SELECTION}
                        /> */}
              <text
                height={contentRect.height}
                width={contentRect.width}
                y={contentRect.height / 2}
                x={contentRect.width - 5} // align right // -x is the margin for correct printing
                className="unselectable"
                style={{
                  // overflow:"hidden",
                  textAnchor: 'end', // halign
                  dominantBaseline: 'middle', // valign
                  fontSize: '10px',
                  paddingRight: '10px',
                  // fontWeight:"bold",
                  fontFamily: 'Helvetica',
                  fill: '#000000',
                  // stroke:"black",
                  // strokeWidth:2
                }}
              >
                {PROJECT_CONST.displayUrl}
              </text>
            </svg>
          )}

        {/* --------------------- POSTCARD BG ------------------------ */}
        {frame.type === FRAME_TYPE.POSTCARD_BG && <FramePostcardBG />}

        {/* --------------------- DEBUG HELPER------------------------ */}
        {/* DISPLAY DEBUG TEXT OVER FRAME */}
        {/* <foreignObject
          x="0"
          y="0"
          className="unselectable"
          height={frame.height}
          width={frame.width}
          style={{
            color: '#ff0000',
            pointerEvents: 'none',
            overflow: 'hidden',
            wordWrap: 'break-word',
          }}
        >
          <div>{JSON.stringify(frame)}</div>
        </foreignObject> */}

        {
          // --------------------- Frame photo empty ------------------------
          // TODO: make a CTA here and call this uploader / import method outside the frame area
          !isPreviewMode &&
            isEmpty &&
            (frame.type === FRAME_TYPE.PHOTO ||
              frame.type === FRAME_TYPE.OVERLAYER) && (
              <ForeignObjectUnscaled
                editionScale={editionScale}
                width={frame.width}
                height={frame.height}
              >
                <Upload
                  id={`${frame.id}_uploader`}
                  name="frameImport"
                  multiple={false}
                  accept={PROJECT_CONST.acceptedImageFileFormats}
                  customRequest={onDummyUploadRequest}
                  onChange={(info) => {
                    onUploaderChange(info, frame);
                  }}
                  showUploadList={false}
                  className="frameEmpty"
                >
                  <p>
                    <Button
                      type="primary"
                      // shape="round"
                      size={
                        contentRect.width < emptyButtonFullyDisplaySize
                          ? 'middle'
                          : 'small'
                      }
                      style={{
                        fontSize:
                          contentRect.width < emptyButtonFullyDisplaySize
                            ? 15
                            : 12,
                        position: 'unset',
                      }} // position:unset is necessary to work correclty with svg scaling and foreign object
                      icon={<PictureOutlined />}
                    >
                      {contentRect.width < emptyButtonFullyDisplaySize
                        ? ''
                        : GetText('edition.frame.photo.cta')}
                    </Button>
                    {/* this is a text <img src="https://secure.gravatar.com/avatar/8d7547e891de94ba241c7501baffb7fa?s=160&d=retro&r=pg" /><button> truc machin brol</button> */}
                  </p>
                  {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
                </Upload>
              </ForeignObjectUnscaled>
            )
        }

        {
          // --------------------- Loading! ------------------------
          loading && (
            <foreignObject
              x={contentRect.x}
              y={contentRect.y}
              className="unselectable"
              height={contentRect.height}
              width={contentRect.width}
              style={{ position: 'unset' }}
            >
              <div
                className="unselectable"
                style={{
                  width: contentRect.width,
                  height: contentRect.height,
                  position: 'unset',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {IsSafari() ? (
                  GetText('common.loading')
                ) : (
                  <LoadingOutlined className="loadingIcon" spin />
                )}
              </div>
            </foreignObject>
          )
        }

        {
          // --------------------- Error! ------------------------
          error && (
            <foreignObject
              x="0"
              y="0"
              className="unselectable"
              height={contentRect.height}
              width={contentRect.width}
            >
              <div className="frameError">
                <CloseCircleOutlined />
                {error}
              </div>
            </foreignObject>
          )
        }

        {
          // --------------------- FRAME TEXT ------------------------
          !isEmpty && frame.text && (
            <FrameText frame={frame} frameTextDivRef={frameTextDivRef} />
          )
        }

        {
          // --------------------- Frame Text empty ------------------------
          // TODO: put everything for foreign object unscaled to a single container...
          !isPreviewMode && isEmpty && frame.type === FRAME_TYPE.TEXT && (
            <ForeignObjectUnscaled
              editionScale={editionScale}
              width={contentRect.width}
              height={contentRect.height}
            >
              <div
                className="frameEmpty_text"
                // style={{ userSelect: 'none', pointerEvents: 'none' }}
              >
                <Button
                  type="primary"
                  size={
                    contentRect.width < emptyButtonFullyDisplaySize
                      ? 'middle'
                      : 'small'
                  }
                  icon={<FontSizeOutlined />}
                  style={{
                    fontSize:
                      contentRect.width < emptyButtonFullyDisplaySize ? 15 : 12,
                    position: 'unset',
                  }}
                  onClick={onAddTextCtaClicked}
                >
                  {contentRect.width < emptyButtonFullyDisplaySize
                    ? ''
                    : GetText('edition.frame.text.watermark')}
                </Button>
              </div>
            </ForeignObjectUnscaled>
          )
        }

        {/* <foreignObject x="0" y="0" className="unselectable"
                    height={t.height}
                    width={t.width}
                    style={{
                        color:"#fff000",
                        //pointerEvents:"none",
                        overflow:"hidden",
                        wordWrap:"break-word"}}>

                    <div
                        onDrop={ (e)=>{ handleDropOnFrame(e, i )} }
                        onDragOver={ (e)=>{ handleDragOverFrame(e, i )} }
                        >
                    drop area
                    </div>
                </foreignObject> */}

        {/** *******************************************
                 SVG TEXT DO NOT WRAP......

                    <text id={frame.id}
                            height={frame.height}
                            width={frame.width}
                            //x={frame.x}
                            //y={frame.y}
                            backgroundcolor="#fff000"
                            //transform={"rotate("+ frame.angle +"," + frame.width/2 +"," + frame.height/2 +")"}
                            preserveAspectRatio="none"
                            color="#ff0000"
                            fontFamily="Verdana"
                            fontSize="55"
                            onClick={(e)=> { onFrameSelect( e.target, frame ) ;}}
                            >
                        {frame.text.value}
                    </text>
            ******************************************* */}

        {
          // --------------------- Frame border ------------------------
          !isEmpty && frame.border !== 0 && (
            <rect
              rx={frame.borderRadius}
              ry={frame.borderRadius}
              x={borderInPixel / 2}
              y={borderInPixel / 2}
              width={frame.width - borderInPixel}
              height={frame.height - borderInPixel}
              style={{
                pointerEvents: 'none',
                stroke: frame.borderColor,
                strokeWidth: borderInPixel,
                fill: '#ff0000',
                // fillOpacity:"0.4",
                fillOpacity: '0',
              }}
            />
          )
        }

        {
          // --------------------- text and spine borders ------------------------
          !isForPrint &&
            !isPreviewMode &&
            (frame.type === FRAME_TYPE.SPINE ||
              frame.type === FRAME_TYPE.SPINE_NUM) && (
              <rect
                x={contentRect.x}
                y={contentRect.y}
                width={contentRect.width}
                height={contentRect.height}
                className="frame_text_border"
              />
            )
        }

        {
          // --------------------- selectionBox ------------------------
          !isPreviewMode && !isForPrint && (
            <rect
              x="0"
              y="0"
              width={frame.width}
              height={frame.height}
              className={`editionFrame${
                draggingOver
                  ? '_dragOver'
                  : isOver && frameCanBeHovered(frame)
                    ? '_hover'
                    : ''
              } `}
            />
          )
        }

        {
          // --------------------- quality indicator ------------------------

          !isForPrint &&
            !isPreviewMode &&
            frame.photo &&
            imageQuality !== IMAGE_QUALITY.GOOD && (
              <ForeignObjectUnscaled
                editionScale={editionScale}
                className="unselectable unclickable"
                height={contentRect.height}
                width={contentRect.width}
              >
                <div className="qualityIndicator_wrapper">
                  <Tooltip
                    overlayClassName="unselectable"
                    open={isSelected && !qualityWarningHide && !isEditing}
                    title={
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setQualityWarningHide(true);
                        }}
                      >
                        {imageQuality === IMAGE_QUALITY.BAD
                          ? GetText('tooltip.frame.quality.ko')
                          : GetText('tooltip.frame.quality.low')}
                      </span>
                    }
                  >
                    <WarningTwoTone
                      className="unselectable"
                      onClick={() => {
                        setQualityWarningHide(!qualityWarningHide);
                      }}
                      twoToneColor={
                        imageQuality === IMAGE_QUALITY.BAD
                          ? [Colors.SELECTION, Colors.RED_FLASH]
                          : [Colors.BLACK, Colors.SELECTION]
                      }
                      style={{
                        fontSize: '20px',
                        color:
                          imageQuality === IMAGE_QUALITY.BAD
                            ? Colors.RED_FLASH
                            : Colors.SELECTION,
                      }}
                    />
                  </Tooltip>
                </div>
              </ForeignObjectUnscaled>
            )
        }
      </g>
    </g>
  );
};
