/**
 * Move an element in an array from one index to another.
 * @param arr The array to move the element in.
 * @param fromIndex The index of the element to move.
 * @param toIndex The index to move the element to.
 * @returns The array with the element moved.
 */
export function moveArrayItemIndex(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}
