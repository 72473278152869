import { Modal } from 'antd';
import { cloneDeep } from 'lodash';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../data/config';
import { COVER_TYPE, PAPER_QUALITY } from '../data/Constants';
import { GetText } from '../data/LanguageHelper';
import {
  getCoverBackgroundRect,
  getPageBackgroundRect,
  upgradeFrameBackground,
} from '../feature/backgrounds/backgroundHelper';
import { ScaleFrameAndContent } from '../feature/frame/frameHelper';
import { isLayflat } from '../feature/layflat/layflatHelpers';
import { Layout, LAYOUT_TYPE } from '../feature/layouts/layout.type';
import { GetProjectOptions } from '../feature/project/GetProjectOptions';
import { Project, ProjectOptions } from '../types/project';
import { Background, IPage, Photo } from '../types/types';
import { CanvasHelper } from './canvas/CanvasHelper';
import { CardHelper } from './card/CardHelper';
import {
  CreateClassicCoverOptions,
  CreateProjectCustomCover,
  GetCoverSizeInPixel,
  GetProjectCover,
  IsClassicCoverPage,
} from './cover/coverHelper';
import { notifyError } from './error/notifyError';
import { DateUtils } from './DateUtils';
import {
  GetDoc,
  GetDocIDByDocCode,
  GetProjectSizeCM,
  GetProjectTypeByDocID,
} from './ProductHelper';
import { CheckAndUpdateProjectSpineWidth } from './spineHelper';

/**
 *
 */
export function ApplyOptionsToProject(
  project: Project,
  newOptions: Partial<ProjectOptions>
) {
  const options = { ...GetProjectOptions(project), ...newOptions };
  const doc = GetDoc(project.docID);

  // common
  project.coated =
    options.coated !== undefined ? options.coated : project.coated;
  project.matte = options.matte !== undefined ? options.matte : project.matte;

  // -------  album  -------
  if (IsAlbumEditor()) {
    project.paper = options.paper;
    project.pageNumber = options.pageNumber;
    project.pageNumberColor = options.pageNumberColor;
    project.pagePaperQuality = options.pagePaperQuality
      ? options.pagePaperQuality
      : PAPER_QUALITY.QUALITY_250;
    project.cover_matte = options.cover_matte;
    project.flyleaf = options.flyleaf;
    project.coverType = options.coverType;

    // securities
    if (project.paper && !doc.insert) project.paper = false;
    if (!doc.pagePaperQuality.includes(project.pagePaperQuality))
      project.pagePaperQuality =
        doc.pagePaperQuality[doc.pagePaperQuality.length - 1]; // take highest quality
    if (!project.flyleaf || !doc.flyleaf) project.flyleaf = 'white';
  }

  // -------  calendar  -------
  if (IsCalendarEditor()) {
    if (options.startYear !== undefined) project.startYear = options.startYear;
    if (options.startMonth !== undefined)
      project.startMonth = options.startMonth;
    if (options.startDay !== undefined) project.startDay = options.startDay;
    if (options.calendarColorOptions !== undefined)
      project.calendarColorOptions = options.calendarColorOptions;

    // security
    if (project.coated && !doc.coating) project.coated = false;
    project.matte = false; // never matte for calendars
  }

  // -------  card  -------
  if (IsCardEditor()) {
    project.cardPack = options.cardPack;
    project.envelope = options.envelope
      ? options.envelope
      : doc.envelopes_default;

    // securities
    if (!doc.envelopes.includes(project.envelope))
      project.envelope = doc.envelopes_default;
    if (project.matte && !doc.finish.includes('mate')) project.matte = false;
    if (project.coated && !doc.finish.includes('coating'))
      project.coated = false;
    if (!Object.keys(doc.codes).includes(project.cardPack)) {
      // TODO: up
      console.log(
        'We are changing card packs here!, meaning we could need to remove pages from project..'
      );
      project.cardPack = doc.packs_default;
    }
  }

  // -------  canvas  -------
  if (IsCanvasEditor()) {
    project.canvasFrameColor = options.canvasFrameColor;
    project.canvasFormat = options.canvasFormat;
  }
}

export function IsClassicCoverProject(project: Project) {
  return project.coverType === COVER_TYPE.COVER_CLASSIC;
}

/**
 * retrieve the "display" page count for the final user and pricing.
 * Merges pages are uniq pages in the system but count for 2 pages in the final product.
 * Cover doen't count as a page.
 */
export function GetProjectDisplayPage(project: Project): number {
  //  if(Infos.isCards) return pageCount*CardsManager.instance.cardsRepeatValue;
  return project.pageList
    .map((page) => {
      if (page.isCover) return 0;
      if (page.merged) return 2;
      return 1;
    })
    .reduce((acc, val) => acc + val, 0);
}

/**
 *
 Get a project description (classname type, docID, etc..)
 */
export function GetProjectDescription(project: Project) {
  // ---- CANVAS ----
  if (IsCanvasEditor()) {
    const product = GetText(`class.${project.classname}`);
    const type = GetText(`canvas.type.${project.type}_types`);
    const format = GetText(
      `canvas.orientation.types_canvas_${project.canvasFormat}`
    );
    const dimension = GetProjectSizeCM(project, 0);
    return `${product} - ${type} - ${format} - ${dimension}`;
  }

  // ---- OTHER PRODUCTS ----
  const numPages = GetProjectDisplayPage(project);
  return `${GetText(`class.${project.classname}`)} - ${
    project.type
  } - ${GetText(
    `${PROJECT_CONST.project_class_no_s}.prefix.${project.docID}`
  )} - ${numPages} ${GetText('common.pages')}`;
}

/**
 * Get the max pages for a project
 * ALBUM ONLY
 * @param {Project | ProjectOptions} projectOrOptions
 * @returns {number}
 */
export function GetProjectMaxPages(projectOrOptions: Project | ProjectOptions) {
  if (!IsAlbumEditor()) return 0;

  const pagePaperQuality = projectOrOptions.pagePaperQuality;
  // there is an EXCEPTION for classic cover albums, with 250gr paper quality,  were if there are "inserts" max pages is 120.
  if (
    pagePaperQuality === PAPER_QUALITY.QUALITY_250 &&
    projectOrOptions.coverType === COVER_TYPE.COVER_CLASSIC && // TODO: verify this is correct with layflat albums that are now able to have classic covers
    projectOrOptions.paper
  )
    return 120;

  const index = pagePaperQuality === PAPER_QUALITY.QUALITY_250 ? 1 : 0;
  const maxPages = GetDoc(projectOrOptions.docID)?.maxPages[index];
  return maxPages;
}

/**
 * Get the min pages for a project
 * @param {string} docID
 * @returns {number}
 */
export function GetProjectMinPages(docID: string) {
  return GetDoc(docID)?.minPages;
}

/**
 *
 * @param {Project} project
 * @param {Number} newWidth
 * @param {Number} newHeight
 * @param {Record} layoutStore
 * @param {Record.<Background>} backgroundsByID
 */
export function UpgradeProjectSize(
  project: Project,
  newWidth: number,
  newHeight: number,
  layoutStore: Record<string, Layout>,
  backgroundsByID: Record<string, Background>
) {
  const doc = GetDoc(project.docID);

  // change size
  project.width = newWidth; // the width in pixel
  project.height = newHeight; // the height in pixel
  project.cutBorder = doc.cutBorder;

  // ---- ALBUM size UPGRADES ----
  if (IsAlbumEditor()) {
    // verify insert options
    if (!doc.insert && project.paper) project.paper = false;

    // verify paper quality options
    if (
      !doc.pagePaperQuality.includes(PAPER_QUALITY.QUALITY_250) &&
      project.pagePaperQuality === PAPER_QUALITY.QUALITY_250
    )
      project.pagePaperQuality = PAPER_QUALITY.QUALITY_170;

    // upgrade cover if album
    upgradeProjectCover(project, layoutStore, backgroundsByID);
  }

  // TODO: for now we don't allow upgrades of layflat albums (too complex)
  // Best option would be to check the "merged" value of pages to know if we need to double the size or not!
  if (!isLayflat(project.docID)) {
    // upgrade all other pages
    project.pageList
      .filter((p) => !p.isCover) // do not update cover, it was done before.
      .forEach((page: IPage) => {
        upgradeProjectPage(
          project,
          page,
          project.width,
          project.height,
          getPageBackgroundRect(project, page.merged),
          backgroundsByID
        );
      });
  }
}

/**
 * Upgrade a project page to a new size
 *
 * @param {Project} project
 * @param {IPage} page
 * @param {number} newWidth
 * @param {number} newHeight
 * @param {Rectangle} backgroundBounds
 * @param {Record.<Background>} backgroundsByID
 *
 * @returns {void}
 */
function upgradeProjectPage(
  project,
  page,
  newWidth,
  newHeight,
  backgroundBounds,
  backgroundsByID
) {
  const scaleX = newWidth / page.width;
  const scaleY = newHeight / page.height;

  // upgrade frames
  page.frames.forEach((frame, index, _arr) => {
    if (index === 0) {
      upgradeFrameBackground(frame, backgroundBounds, backgroundsByID);
    } else {
      // update posx and y
      frame.x *= scaleX;
      frame.y *= scaleY;

      // update size and content
      ScaleFrameAndContent(frame, frame.width * scaleX, frame.height * scaleY);
    }

    if (IsCanvasEditor())
      CanvasHelper.CheckModifyFrameForEdge(
        project.type,
        newWidth,
        newHeight,
        frame
      );
  });

  page.width = newWidth;
  page.height = newHeight;
  return page;
}

/**
 * Clean and verify custom cover
 * -> check if cover size is correct based on num pages and project options
 * -> check if spine in the cover has correct size, and if not clean it
 */
export function cleanAndVerifyCustomCover(project: Project) {
  const cover = GetProjectCover(project);

  if (project.coverType === COVER_TYPE.COVER_CUSTOM) {
    // retreive correct cover size (with spine Width and options)
    const newCoverSize = GetCoverSizeInPixel(project);
    // upgrade project size to correct width:
    upgradeProjectPage(
      project,
      cover,
      newCoverSize.width,
      newCoverSize.height,
      getCoverBackgroundRect(project)
    );
    // be sure the spine frame is at correct position and size applyBorderToAllFrames
    CheckAndUpdateProjectSpineWidth(project);
  }
}

export function upgradeProjectCover(
  project: Project,
  layoutStore,
  backgroundsById
) {
  let cover = GetProjectCover(project);

  // Security, this should never never happen!!
  // if we don't have a cover, we create one
  if (!cover) {
    Modal.warn({
      title: 'Fixing album cover',
      content:
        'This project has no cover, we need to create one! This should never happen',
    });
    CreateProjectCustomCover(
      project,
      layoutStore.filtered[LAYOUT_TYPE.COVER].all,
      layoutStore.byID
    );
    cover = GetProjectCover(project);
  }

  // verify that cover type is inline with the cover, otherwise we need to change this.
  if (
    project.coverType === COVER_TYPE.COVER_CLASSIC &&
    !IsClassicCoverPage(cover)
  ) {
    cover.frames = [];
    cover.coverClassicOptions = CreateClassicCoverOptions();
  }

  if (
    project.coverType === COVER_TYPE.COVER_CUSTOM &&
    IsClassicCoverPage(cover)
  ) {
    project.pageList.shift(); // remove old cover
    cover = CreateProjectCustomCover(
      project,
      layoutStore.filtered[LAYOUT_TYPE.COVER].all,
      layoutStore.byID
    );
  }

  // // previous project type is still in the cover info
  // const prevCoverType = IsClassicCoverPage(cover)
  //   ? COVER_TYPE.COVER_CLASSIC
  //   : COVER_TYPE.COVER_CUSTOM;
  // const newCoverType = project.coverType;

  // // case change from different cover types
  // if (newCoverType !== prevCoverType) {
  //   // case classic :
  //   if (IsClassicCoverProject(project)) {
  //     // remove frames
  //     cover.frames = [];
  //     cover.coverClassicOptions = CreateClassicCoverOptions();
  //   }
  //   // Case custom cover and we come from a classic cover
  //   else {
  //     project.pageList.shift(); // remove old cover
  //     cover = CreateProjectCustomCover(
  //       project,
  //       layoutStore.filtered[LAYOUT_TYPE.COVER].all,
  //       layoutStore.byID
  //     );
  //   }
  // }

  // new size (calculated also with project options)
  // retreive correct cover size
  const newCoverSize = GetCoverSizeInPixel(project);
  upgradeProjectPage(
    project,
    cover,
    newCoverSize.width,
    newCoverSize.height,
    getCoverBackgroundRect(project),
    backgroundsById
  );
}

/**
 * inject an existing page list at a specific point in the project.
 */
export function InjectPagesAtIndex(
  pageList: IPage[],
  newPages: IPage[],
  indexToInject: number = 0
) {
  const numPages = pageList.length;
  // splice pageList
  pageList.splice(indexToInject, 0, ...newPages);

  // clean all indexes
  for (let index = 0; index < pageList.length; index++) {
    if (pageList[index].index !== index) {
      pageList[index].index = index;
      // notify in case of it was an addition to the end of initial page list as this should not happen..
      if (indexToInject === numPages) {
        console.warn(
          `projectHelper.InjectPagesAtIndex: index of pages were modified... (numpages:${numPages} indexToInject:${indexToInject})`
        );
      }
    }
  }
}

/**
 * Clean project photo list
 * -> verify that all project photos are in the project photo list
 * -> if they are not, find it in the backend list and add it to project photo list
 */
/**
 *
 * @param {Project} project
 * @param {*} projectPhotosByID
 * @param {*} backendPhotosByID
 * @returns
 */
export function CleanProjectPhotoList(
  project: Project,
  projectPhotosByID: Record<string, Photo>,
  backendPhotosByID: Record<string, Photo>
) {
  if (!projectPhotosByID) projectPhotosByID = {};

  const backendPhotos = cloneDeep(backendPhotosByID);

  // 2024 Add
  // initial cleanup, if photo has been deleted via photo manager, is not available anymore in backend photo list
  // but it might still be available here in the project photo list, we need to remove it.
  const projectPhotos = Object.keys(projectPhotosByID);
  projectPhotos.forEach((photoID) => {
    if (!backendPhotos[photoID]) {
      delete projectPhotosByID[photoID];
      notifyError(
        new Error(
          `Project photo with ID ${photoID} was not found in backend photo list, we delete it from project photo list`
        ),
        { projectPhotosByID, backendPhotosByID }
      );
    }
  });

  project.pageList.forEach((page, index) => {
    // check page index
    if (page.index !== index) page.index = index;

    // verify frames
    page.frames.forEach((frame) => {
      if (frame.photo && !projectPhotosByID[frame.photo]) {
        if (backendPhotos[frame.photo])
          projectPhotosByID[frame.photo] = backendPhotos[frame.photo];
        else
          notifyError(
            new Error(
              'A frame has photo that do not exist in backend photo list!'
            ),
            { frame, backendPhotos, projectPhotosByID }
          );
        // TODO: what do we do in this case!
      }
    });
  });

  // console.log('backendPhotos', backendPhotos);
  console.log('----- clean project photo list -----');
  // now that we checked all possible missing frames, we replace by backend version (to be sure to be up to date!)
  // this might not be mandatory later if this webservice do not change anymore.. but for now let's keep this..
  const newProjectPhotosByID = {};
  Object.keys(projectPhotosByID).forEach((photoID) => {
    newProjectPhotosByID[photoID] = backendPhotos[photoID];
  });

  return newProjectPhotosByID;
}

/**
 * check if everything is right before ordering
 * if not, throw a string with the error to debug
 */
export function verifyProjectBeforeOrder(project: Project): string {
  if (!project.id) return 'Project has no ID';

  return null;
}

/**
 * Save current project online
 */
// export function SaveProject()
// {
//  project.build_saved = Infos.buildVersion;

//  // data loading
//  DataLoadingManager.instance.showLoading(false, null, ResourcesManager.getString("loading.project.save"));

//   clearUploader();
//   saveUploader = new MultipartURLLoader();
//   saveUploader.dataFormat = URLLoaderDataFormat.TEXT;
//   saveUploader.addEventListener(Event.COMPLETE, projectSaveComplete);
//   saveUploader.addEventListener(IOErrorEvent.IO_ERROR, projectSaveError);

//   var fileName : String = "0"; // don't know why but was previously like this

//   //Add Variables
//   var docTypeStr:String = (Infos.isCanvas)?String( parseInt(project.docType) + CanvasManager.instance.CanvasModifier ) : project.docType;
//   saveUploader.addVariable("classname", project.classname);
//   saveUploader.addVariable("build", project.build_created);
//   saveUploader.addVariable("product", docTypeStr);
//   if(project.IsCustomRebrandedProduct) saveUploader.addVariable("promoEditor", Infos.REBRANDING_ID);

//   if(project.id) saveUploader.addVariable("id", project.id);
//   else Debug.log("Save new project (no existing id)"); // save project with no ID = create a new project

//   saveUploader.addVariable("name", project.name);
//   saveUploader.addVariable("Filename", fileName);
//   saveUploader.addFile(project.getSaveBytes(), fileName, "uploadfile");

//   //send
//   var url : String = Infos.config.serverNormalUrl + "/" + "flex/project_save.php"
//   Debug.log("  url : "+url);
//   saveUploader.load(url, false);
// }

// !! TODO:: fix below !!!

// package data
// {
//  import manager.CanvasManager;

//  import offline.data.CanvasPriceInfo;

//  /**
//   * Parameters that can be passed to the projectManager createProject function
//   * Those parameters are set in the configuratorTabs
//   */
//  public class ProjectCreationParamsVo
//  {
//   // COMMON
//   public var coated : Boolean; //aka vernish
//   public var matte : Boolean; // not compatible with coated-varnish
//   public var themeId: String; // theme to be applied to project (if there is one)
//   public var pagePaperQuality : String;
//   public var flyleaf : String;

//   // ALBUM
//   public var coverType : String;
//   public var pageCount :number = -1; // amount of pages for this album
//   public var paper : Boolean;
//   public var pageNumber : Boolean; // do we display page numbers?
//   public var classicCoverColor : String;
//   public var classicCoverCorner : String;

//   // CALENDARS
//   public var calendarStartDayIndex:number;
//   public var calendarStartMonthIndex:number;
//   public var calendarYear:number;

//   // CANVAS
//   public var canvasType : String ;  // = canvas type/material (forex, wood, alu, etc..)
//   public var canvasFrameColor : String ;  // = canvas frame color for kadapak (black, silver, gold, blur or red)
//   public var canvasWidth :number;  // = width of canvas for custom/multiple canvas type
//   public var canvasHeight :number;  // = height of canvas for custom/multiple canvas type
//   public var canvasMargin :number; // = margin of canvas for multiple canvas type
//   public var canvasMLayout : String; // = layout for multiple canvas type
//   public var canvasStyle : String ; // = styles_style1, styles_style2, styles_style5
//   public var canvasPriceInfo : CanvasPriceInfo ; // offline calculation price

//   // CARDS
//   public var envelope : String ;

//   /**
//    * SHORTCUT TO CREATE project init params based on session xml
//    */
/**
 *
 * @param {ISession} sess
 * @returns {ProjectCreationParams}
 */
export const GetProjectCreationParamsFromSession = (sess) => {
  // var sess : SessionVo = Infos.session;
  const classname: string = sess.classname ? sess.classname : sess.return_page;

  // if we are not in the correct classname, switch!
  if (classname !== PROJECT_CONST.project_class) {
    // TODO: what to do? REDIRECT!! and be sure to keep session..
    // alert("WE ARE NOT IN THE CORRECT CLASSNAME:" + classname + " but editor is: "+ PROJECT_CONST.project_class);
    // alert("project creation aborded..");

    // SWITCH TO CORRECT EDITOR? TODO:
    // window.location = "/"+classname;
    // return;

    // DO NOTHING IF NOT IN THE CORRECT EDITOR (BUT LOG WARNING)
    console.warn(
      `Cannot create '${classname}' project in '${PROJECT_CONST.project_class}' editor! `
    );
    return null;
  }

  /** @type {ProjectCreationParams} * */
  const params = {
    classname,
    options: {},
  };

  // Common
  params.projectName = `Project ${DateUtils.ToYYYYMMDD()}`;
  const docCode = sess.product_id;
  params.docID = GetDocIDByDocCode(docCode);
  const doc = GetDoc(params.docID);

  // ---- CASE ALBUMS
  if (IsAlbumEditor()) {
    params.type = GetProjectTypeByDocID(
      params.docID,
      sess.cover_name === 'Custom Cover'
    );
    params.numPages = !Number.isNaN(parseFloat(sess.product_page_count))
      ? parseFloat(sess.product_page_count)
      : doc.minPages;

    // options
    params.options.paper = sess.product_inserts === '1';
    params.options.pagePaperQuality = sess.product_pagePaperQuality
      ? sess.product_pagePaperQuality
      : doc.pagePaperQuality[0];

    params.options.flyleaf = sess.product_flyleaf
      ? sess.product_flyleaf
      : 'white';
    params.options.coated = sess.product_coated === '1';
    params.options.matte = sess.product_matte === '1';

    // CLASSIC Cover details
    // if (IsClassicCoverProject(params.type)) {
    params.coverName = sess.cover_name && sess.cover_name; // (Mostly this is 'Custom Cover', except on classic it can be 'Leather Black' and the others)
    params.coverCorners = sess.cover_corners && sess.cover_corners; // (defaults to none, can be roundgold, roundsilver, squaregold, squaresilver)
    // }
  }

  // ---- CASE CALENDARS
  else if (IsCalendarEditor()) {
    params.type = GetProjectTypeByDocID(params.docID);
    params.numPages = doc.numPages;

    params.options.startDay = sess.weekstart ? parseInt(sess.weekstart, 10) : 1;
    params.options.startMonth = sess.month ? parseInt(sess.month, 10) : 0;
    params.options.startYear = sess.year
      ? parseInt(sess.year, 10)
      : new Date().getFullYear() + 1;
    params.options.coated = sess.product_coated === '1';
    params.options.matte = sess.product_matte === '1';
  }

  // ---- CASE CALENDARS
  else if (IsCardEditor()) {
    params.type = GetProjectTypeByDocID(params.docID);
    const cardPack = CardHelper.GetCardPackByDocCode(docCode); // TODO: change this if we have the dropdown in this view!
    params.numPages = CardHelper.GetTotalNumberOfPages(params.docID, cardPack);

    params.options.cardPack = cardPack;
    params.options.envelope = sess.envelope
      ? sess.envelope
      : doc.envelopes_default;
    params.options.matte = sess.product_matte === '1';
  }

  // ---- CASE CANVAS
  else if (IsCanvasEditor()) {
    // check for canvas modifier system
    params.type = CanvasHelper.GetCanvasTypeByDocCode(docCode);
    params.numPages = 1; // always 1 for canvas
    params.canvasFreeWidth = !Number.isNaN(parseFloat(sess.width))
      ? parseFloat(sess.width)
      : null; // in cm
    params.canvasFreeHeight = !Number.isNaN(parseFloat(sess.height))
      ? parseFloat(sess.height)
      : null; // in cm

    params.options.canvasFormat =
      CanvasHelper.GetCanvasFormatByDocCode(docCode);
    params.options.canvasFrameColor = sess.frame_color
      ? sess.frame_color
      : 'black';

    // params.canvasMargin = (!isNaN(parseFloat(sess.gap)))? parseFloat(sess.gap)  : null; // in cm
    // params.canvasWidth = (!isNaN(parseFloat(sess.width)))? parseFloat(sess.width)  : null; // in cm
    // params.canvasHeight = (!isNaN(parseFloat(sess.height)))? parseFloat(sess.height) : null; // in cm
    // params.canvasMLayout = (!isNaN(parseFloat(sess.rows)))? sess.rows + "x" + sess.cols : null;
    // switch (sess.product_style ) {
    //  case "popart" :
    //   params.canvasStyle = "styles_style5";
    //   break;
    //  case "pelemele" :
    //   params.canvasStyle = "styles_style2";
    //   break;
    //  default :
    //   params.canvasStyle = "styles_style1";
    //   break;
    // }
  }

  return params;
};

//  }
// }
