// https://momentjs.com/
import moment from 'moment';

function GetTimeStamp(): number {
  return Date.now();
}

function GetTodayTimeStamp(): string {
  return ToYYYYMMDD();
}

function ToReadableDate(date: Date): string {
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

function ToYYYYMMDD(date: Date = new Date(), separator = '-'): string {
  return moment(date).format(`YYYY[${separator}]MM[${separator}]DD`);
}

// function ToYYYYMMDDHHmm( date:Date = Date.now(), separator = "-" ):String
// {
//   return moment(date).format("YYYY["+separator+"]MM["+separator+"]DD["+separator+"]HH["+separator+"]MM");
// }

// const toDisplayDate = (date: Date): string => {
//   return moment(date).format('LL');
// };

export const DateUtils = {
  GetTimeStamp,
  ToYYYYMMDD,
  GetTodayTimeStamp,
  // toDisplayDate,
  ToReadableDate,
};
