import { CATALOGUE_ALBUM } from '../data/catalogue/albumCatalogue';
import { PAPER_QUALITY } from '../data/Constants';
import { spineSizes, SpineType } from '../data/spineConfig';
import { FRAME_TYPE } from '../feature/frame/frame.types';
import {
  CleanAndVerifyFrameText,
  CreateFrame,
} from '../feature/frame/frameHelper';
import { GetProjectOptions } from '../feature/project/GetProjectOptions';
import { Project, ProjectOptions } from '../types/project';
import type { Frame, IPage } from '../types/types';
import {
  GetProjectCover,
  GetProjectSpine,
  GetProjectSpineNum,
  HasCustomCover,
} from './cover/coverHelper';
import { mmToPoint } from './MeasureUtils';

// TODO: create a unit testing for this file.

/**
 * Get the spine type based on the docID
 * example: S, M, L => classic
 */
const getSpineType = (docID: string): SpineType => {
  for (const [spineType, docIDs] of Object.entries(CATALOGUE_ALBUM.spineType)) {
    if (docIDs.includes(docID)) {
      return spineType as SpineType;
    }
  }
  // default classic
  return 'classic';
};

export function CheckAndUpdateProjectSpineWidth(project: Project) {
  if (HasCustomCover(project)) {
    const newSpineWidth = getSpineWidthMM(GetProjectOptions(project));
    const cover: IPage = GetProjectCover(project);

    // update spine
    let spine: Frame = GetProjectSpine(project);
    if (!spine) {
      // add if not existing!
      spine = CreateSpine(cover.width, cover.height);
      cover.frames.push(spine);
    }

    // update depending on options!
    spine.height = mmToPoint(newSpineWidth);

    // update spine num
    let spineNum: Frame = GetProjectSpineNum(project);
    if (!spineNum) {
      // add if not existing!
      spineNum = CreateSpineNum(cover.width, cover.height);
      cover.frames.push(spineNum);
    }
    spineNum.width = mmToPoint(newSpineWidth);

    // [Security]
    // update text to be able to fit the size of spine
    if (spine.text.size > spine.height * 0.75)
      spine.text.size = Math.floor(spine.height * 0.75);

    // [Security]
    // update text to be able to fit the size of spine
    if (spineNum.text.size > spineNum.width * 0.75)
      spineNum.text.size = Math.floor(spineNum.width * 0.75);
  }
}

export function CreateSpine(coverWidth: number, coverHeight: number) {
  // let newFrame = new Frame( FRAME_TYPE.SPINE, coverHeight*.9, coverHeight*0.1, coverWidth*.5,coverHeight*.45, -90 );
  const spineFrame = CreateFrame({
    type: FRAME_TYPE.SPINE,
    width: coverHeight * 0.9,
    height: coverHeight * 0.1,
    x: coverWidth * 0.5,
    y: coverHeight * 0.45,
    rotation: -90,
  });
  CleanAndVerifyFrameText(spineFrame);
  return spineFrame;
}

export function CreateSpineNum(coverWidth: number, coverHeight: number) {
  // let newFrame = new Frame( FRAME_TYPE.SPINE_NUM, coverHeight*0.1, coverHeight*0.1, coverWidth*.5, coverHeight*.95, 0 );
  const numFrame = CreateFrame({
    type: FRAME_TYPE.SPINE_NUM,
    width: coverHeight * 0.1,
    height: coverHeight * 0.1,
    x: coverWidth * 0.5,
    y: coverHeight * 0.95,
    rotation: 0,
  });
  CleanAndVerifyFrameText(numFrame);
  return numFrame;
}

// export function GetProjectSpineWidthMM( options:ProjectOptions )
// {
//  return getSpineWidthMM( options.docID, options.numPages-1, options.paper, options.coated, options.pagePaperQuality);
// }

/**
 * Helper: Get the album spine width based on the docID and paper
 * Paper is that page put between each pages of the book, it impact the width of the spine
 * result in mm !!!
 *
 * Soft cover = Casual
 * Hard cover = Classic, comptemporary et trendy
 *
 * Q* = trendy
 * QA* = trendy
 * QB* = trendy
 *
 * QE* = Casual
 * QD* = Casual
 * A* = Casual
 * B* = Casual
 * */
export function getSpineWidthMM(projectOptions: ProjectOptions): number {
  const { docID, paper, pagePaperQuality, numPages: rawPages } = projectOptions;

  // use numpages by 10 pages
  // ex 148 => Math.round(148/10) => 15*10 = 150
  let numPages = Math.round(rawPages / 10) * 10;

  // security
  if (numPages < 20) {
    // TODO: get rid of this..
    // its because at creation, we get here before the num of pages of album are created...
    numPages = 20;
  }

  // using the new map system
  const paperKey: string = paper ? 'insert' : 'noInsert';
  let qualityIndex = 0; // default is paper quality 170
  // if (pagePaperQuality === PAPER_QUALITY.QUALITY_200) qualityIndex = 1;
  if (pagePaperQuality === PAPER_QUALITY.QUALITY_250) qualityIndex = 2;

  const spineType = getSpineType(docID);
  const spineWidth = spineSizes[spineType][numPages][paperKey][qualityIndex];
  console.log('spineWidth: ', spineWidth);
  return spineWidth;
}
