import { CATALOGUE_ALBUM } from './catalogue/albumCatalogue';

export type SpineType = keyof typeof CATALOGUE_ALBUM.spineType;

// TODO: later this spine map should be retreived from the backend
export const spineSizes: {
  [key in SpineType]: {
    [numPages: number]: {
      noInsert: [number, number, number]; // Note: array : [170gr, 200gr, 250gr]
      insert: [number, number, number]; // Note: array : [170gr, 200gr, 250gr]
    };
  };
} = {
  // ---- Classic (S, M, L)
  classic: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 8] },
    30: { noInsert: [8, 8, 10], insert: [8, 8, 10] },
    40: { noInsert: [8, 8, 10], insert: [8, 10, 14] },
    50: { noInsert: [8, 10, 14], insert: [10, 12, 14] },
    60: { noInsert: [10, 12, 14], insert: [10, 12, 14] },
    70: { noInsert: [10, 12, 14], insert: [14, 14, 16] },
    80: { noInsert: [10, 14, 16], insert: [14, 16, 18] },
    90: { noInsert: [14, 14, 18], insert: [14, 16, 22] },
    100: { noInsert: [14, 16, 20], insert: [14, 18, 22] },
    110: { noInsert: [14, 16, 20], insert: [14, 18, 22] },
    120: { noInsert: [14, 16, 20], insert: [16, 20, 26] },
    130: { noInsert: [14, 18, 22], insert: [18, 22, 26] },
    140: { noInsert: [16, 18, 26], insert: [18, 22, 30] },
    150: { noInsert: [16, 20, 26], insert: [20, 24, 30] },
    160: { noInsert: [16, 20, 26], insert: [20, 26, 32] },
    170: { noInsert: [18, 22, 28], insert: [22, 26, 32] },
    180: { noInsert: [18, 22, 28], insert: [24, 28, 34] },
    190: { noInsert: [20, 24, 30], insert: [24, 30, 34] },
    200: { noInsert: [20, 24, 32], insert: [26, 30, 36] },
  },

  // ---- soft cover (casuals) (A, B, QD, QE)
  softCover: {
    20: { noInsert: [2, 2, 2], insert: [2, 2, 2] },
    30: { noInsert: [3, 3, 3], insert: [3, 3, 3] },
    40: { noInsert: [3.8, 3.8, 3.8], insert: [3.8, 3.8, 3.8] },
    50: { noInsert: [4.5, 4.5, 4.5], insert: [4.5, 4.5, 4.5] },
    60: { noInsert: [5.3, 5.3, 5.3], insert: [5.3, 5.3, 5.3] },
    70: { noInsert: [6, 6, 6], insert: [6, 6, 6] },
    80: { noInsert: [6.8, 6.8, 6.8], insert: [6.8, 6.8, 6.8] },
    90: { noInsert: [7.5, 7.5, 7.5], insert: [7.5, 7.5, 7.5] },
    100: { noInsert: [8.3, 8.3, 8.3], insert: [8.3, 8.3, 8.3] },
    110: { noInsert: [9.1, 9.1, 9.1], insert: [9.1, 9.1, 9.1] },
    120: { noInsert: [10, 10, 10], insert: [10, 10, 10] },
    130: { noInsert: [10.7, 10.7, 10.7], insert: [10.7, 10.7, 10.7] },
    140: { noInsert: [11.5, 11.5, 11.5], insert: [11.5, 11.5, 11.5] },
    150: { noInsert: [12.3, 12.3, 12.3], insert: [12.3, 12.3, 12.3] },
    160: { noInsert: [13.1, 13.1, 13.1], insert: [13.1, 13.1, 13.1] },
    170: { noInsert: [13.9, 13.9, 13.9], insert: [13.9, 13.9, 13.9] },
    180: { noInsert: [14.7, 14.7, 14.7], insert: [14.7, 14.7, 14.7] },
    190: { noInsert: [15.5, 15.5, 15.5], insert: [15.5, 15.5, 15.5] },
    200: { noInsert: [16.3, 16.3, 16.3], insert: [16.3, 16.3, 16.3] },
  },

  // ---- hard cover (Q, QA, QB, QF)
  // classic, contemporary, trendy
  hardCover: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 10] },
    30: { noInsert: [8, 8, 10], insert: [8, 8, 10] },
    40: { noInsert: [8, 8, 10], insert: [8, 10, 14] },
    50: { noInsert: [8, 10, 14], insert: [10, 10, 14] },
    60: { noInsert: [10, 10, 14], insert: [10, 14, 14] },
    70: { noInsert: [10, 14, 14], insert: [14, 14, 16] },
    80: { noInsert: [10, 14, 16], insert: [14, 14, 18] },
    90: { noInsert: [14, 14, 18], insert: [14, 16, 22] },
    100: { noInsert: [14, 14, 18], insert: [14, 18, 22] },
    110: { noInsert: [14, 16, 20], insert: [16, 18, 26] },
    120: { noInsert: [14, 16, 20], insert: [16, 20, 26] },
    130: { noInsert: [14, 18, 22], insert: [18, 22, 26] },
    140: { noInsert: [16, 18, 26], insert: [18, 22, 30] },
    150: { noInsert: [16, 20, 26], insert: [20, 24, 30] },
    160: { noInsert: [16, 20, 26], insert: [20, 26, 32] },
    170: { noInsert: [18, 22, 28], insert: [22, 26, 32] },
    180: { noInsert: [18, 22, 28], insert: [24, 28, 34] },
    190: { noInsert: [20, 24, 30], insert: [24, 30, 34] },
    200: { noInsert: [20, 24, 32], insert: [26, 30, 36] },
  },

  // ---- Regular
  regular: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 10] },
    30: { noInsert: [8, 8, 10], insert: [8, 8, 10] },
    40: { noInsert: [8, 8, 10], insert: [8, 10, 14] },
    50: { noInsert: [8, 10, 14], insert: [10, 10, 14] },
    60: { noInsert: [10, 10, 14], insert: [10, 14, 14] },
    70: { noInsert: [10, 14, 14], insert: [14, 14, 16] },
    80: { noInsert: [10, 14, 16], insert: [14, 14, 18] },
    90: { noInsert: [14, 14, 18], insert: [14, 16, 22] },
    100: { noInsert: [14, 14, 18], insert: [14, 18, 22] },
    110: { noInsert: [14, 16, 20], insert: [16, 18, 26] },
    120: { noInsert: [14, 16, 20], insert: [16, 20, 26] },
    130: { noInsert: [14, 18, 22], insert: [18, 22, 26] },
    140: { noInsert: [16, 18, 26], insert: [18, 22, 30] },
    150: { noInsert: [16, 20, 26], insert: [20, 24, 30] },
    160: { noInsert: [16, 20, 26], insert: [20, 26, 32] },
    170: { noInsert: [18, 22, 28], insert: [22, 26, 32] },
    180: { noInsert: [18, 22, 28], insert: [24, 28, 34] },
    190: { noInsert: [20, 24, 30], insert: [24, 30, 34] },
    200: { noInsert: [20, 24, 32], insert: [26, 30, 36] },
  },

  // ---- layflat
  layflat: {
    20: { noInsert: [8, 8, 8], insert: [8, 8, 8] },
    30: { noInsert: [10, 10, 10], insert: [10, 10, 10] },
    40: { noInsert: [14, 14, 14], insert: [14, 14, 14] },
    50: { noInsert: [14, 14, 14], insert: [14, 14, 14] },
    60: { noInsert: [16, 16, 16], insert: [16, 16, 16] },
    70: { noInsert: [18, 18, 18], insert: [18, 18, 18] },
    80: { noInsert: [20, 20, 20], insert: [20, 20, 20] },
    90: { noInsert: [22, 22, 22], insert: [22, 22, 22] },
  },
};
