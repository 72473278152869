import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckCircleTwoTone,
  FireOutlined,
  InboxOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Popover,
  Progress,
  Radio,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import clsx from 'clsx';
import { SPECIAL_CATEGORIES } from '../../../_components/PhotoManager/types';
import { Colors } from '../../../data/Colors';
import { GetText } from '../../../data/LanguageHelper';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { photoListSelector } from '../../../feature/photoList/photo.selector';
import { photoListActions } from '../../../feature/photoList/photo.store';
import { PHOTO_FILTER_OPTIONS } from '../../../feature/photoList/photo.type';
import { projectListActions } from '../../../feature/projectList/projectList';
import { UIActions, UISelectors } from '../../../feature/ui/ui';
import { CustomDragger } from '../../../feature/upload/CustomDragger';
import {
  getPhotoCategoriesSelector,
  getProjectCategoryIdSelector,
} from '../../../store/customSelectors/selectors';
import type { Photo } from '../../../types/types';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';
import { GetUID } from '../../../utils/UID';
import PhotoItem from './PhotoItem';

const { Option } = Select;

export const PhotosArea = () => {
  const draggerRef = useRef(null);

  // --------------------- redux ------------------------
  const dispatch = useDispatch();

  const photosAreLoading = useSelector(photoListSelector.isLoading);
  const tempPhotosList = useSelector(photoListSelector.getTempPhotosList);
  const photosByCategory = useSelector(photoListSelector.getPhotosByCategories);
  const photosCategories = useSelector(getPhotoCategoriesSelector);
  const photoFilter = useSelector(photoListSelector.sortFilterSelector);
  const photoUsed = useSelector(editionSelectors.GetPhotosUsedSelector);
  const projectCateoryId = useSelector(getProjectCategoryIdSelector);
  const currentCategoryId = useSelector(UISelectors.getPhotoCategory);

  const uploadingPercent = useSelector(
    photoListSelector.getUploadingPercentSelector
  );
  const totalUploadingItems = useSelector(
    photoListSelector.getTotalUploadingItemsSelector
  );

  // --------------------- state ----------------

  // const [currentCategoryId, setCurrentCategoryId] = useState(
  //   photosCategories[0].id // first category should be the project category
  // );
  const [displayUnusedOnly, setDisplayUnusedOnly] = useState(false);
  const [draggerKey, setDraggerKey] = useState(GetUID());

  // console.log('RENDERING PHOTO AREA NOW');

  const uploadingDetail =
    tempPhotosList && tempPhotosList.length > 0
      ? ` ( ${tempPhotosList.length} uploading... )`
      : '';

  const displayList = photosByCategory[currentCategoryId] || [];

  const unusedPhotoList = displayList.filter(
    (photo) => !photoUsed.includes(photo.id)
  );
  const displayPhotoList = displayUnusedOnly ? unusedPhotoList : displayList;

  // --------------------- methods ------------------------

  const handleCategoryChange = (id) => {
    console.log('handleCategoryChange: ', id);
    dispatch(UIActions.changePhotoCategory(id));
  };

  /**
   * Update photo filter
   */
  const handlePhotoFilterChange = (newFilter) => {
    dispatch(photoListActions.updateSortFilter(newFilter));
  };

  const handleDragStart = (evt, photoObj: Photo) => {
    console.log('dragstart: image id: ', photoObj.id);
    // evt.dataTransfer.setData("id", id);
    // evt.dataTransfer.setData("src", src);
    AddDragData(evt, DRAG_TYPE.PHOTO, photoObj.id);
  };

  const handleAutoFillClicked = () => {
    // on confirm continue
    const onConfirmContinue = () => {
      dispatch(editionActions.makeAutoFill(currentCategoryId));
    };
    const onConfirmCancel = () => {
      // do nothing..
    };

    // show warning modal
    Modal.confirm({
      title: GetText('popup.autoFill.warning.title'),
      content: GetText('popup.autoFill.warning.description').replace(
        /<br \/>/g,
        '\n'
      ),
      okText: GetText('common.ok'),
      cancelText: GetText('common.cancel'),
      onOk() {
        onConfirmContinue();
      },
      onCancel() {
        onConfirmCancel();
      },
    });
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    // retrieve project list, this is needed to be sure catergories are up to date with their names
    dispatch(projectListActions.getAll());
  }, [dispatch]);

  // each time the project category id change (when saving project for example), we update current category
  useEffect(() => {
    // each time the project category id change, we might need to refresh the "current category" if it's not in the list anymore
    if (!photosCategories.some((cat) => cat.id === currentCategoryId)) {
      console.log(
        'no categorie with name',
        currentCategoryId,
        'we update current category to project category',
        projectCateoryId
      );
      dispatch(UIActions.changePhotoCategory(projectCateoryId));
    }
  }, [dispatch, projectCateoryId, photosCategories, currentCategoryId]);

  //--------------------- render ------------------------

  return (
    <div className="tabContent photosArea">
      {
        // --------------------- Import area ------------------------ */
        <div className="importArea">
          <CustomDragger
            name="file-upload-photoarea"
            className={`uploadDragger${
              displayList.length === 0 ? '_noPhotos' : ''
            }`}
            key={draggerKey}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined
                className={clsx('breathing', {
                  breathing: displayList.length === 0,
                })}
                style={{ color: Colors.MAIN }}
              />
            </p>
            <p
              className={`ant-upload-text${
                displayList.length === 0 ? ' breathing' : ''
              }`}
            >
              {GetText('lefttab.photos.import.area')}
            </p>

            {/* <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
          </CustomDragger>
        </div>
      }

      <div className="content">
        <Spin spinning={photosAreLoading}>
          <div>
            {
              // --------------------- project photos ------------------------
              displayPhotoList && (
                <div className="content">
                  {
                    // --------------------- uploading box detail ------------------------ */
                    tempPhotosList.length > 0 && (
                      <div className="uploadingBox">
                        <div className="title">
                          {`Uploading ${
                            totalUploadingItems - tempPhotosList.length + 1
                          }/${totalUploadingItems}`}
                        </div>
                        <Progress
                          percent={uploadingPercent}
                          size="small"
                          style={{ width: '100%' }}
                        />
                        <div className="detail">{tempPhotosList[0].name}</div>
                      </div>
                    )
                  }

                  <div className="flex-col  pt-2 space-y-1 w-full">
                    {/* // --------------------- Photo manager ------------------------ */}
                    <Tooltip
                      title={GetText('photomanager.button.tooltip')}
                      placement="right"
                    >
                      <Button
                        icon={<PictureOutlined />}
                        type="primary"
                        className="w-full"
                        onClick={() =>
                          dispatch(UIActions.togglePhotoManager(true))
                        }
                      >
                        {GetText('topbar.photomanager.btn.label')}
                      </Button>
                    </Tooltip>

                    {/* // --------------------- Auto fill button ------------------------ */}
                    {displayPhotoList.length > 0 && (
                      <Tooltip
                        title={GetText('tooltip.folder.autoFill')}
                        placement="right"
                      >
                        <Button
                          icon={<FireOutlined />}
                          className="font-bold bg-selection w-full"
                          onClick={(e) => handleAutoFillClicked()}
                        >
                          {GetText('edition.toolbar.btn.autofill.label')}
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                  {/* 

                    {/* <Radio.Group onChange={onChange} defaultValue="a" size="small"> */}
                  {/* <Radio.Group defaultValue="a" size="small" style={{width:"100%", fontSize:"60%"}}>
                            {/* <Radio.Button value="a"> ({allPhotoList.length })</Radio.Button> * /}
                            <Radio.Button value="b">Unused only ({UnUsedPhotoNum})</Radio.Button>
                        </Radio.Group> */}

                  {/* <h4>{allPhotoList.length } photo(s) {uploadingDetail} */}
                  {/* <div style={{ width:"100%", textAlign:"right"}}><Switch checkedChildren={`display ${unusedPhotoList.length} unused only`} unCheckedChildren={`display all ${allPhotoList.length } photo(s)`} checked={!displayUnusedOnly} onChange={()=>{setState({displayUnusedOnly: !displayUnusedOnly})}} /></div> */}

                  {
                    // --------------------- Filter bar ------------------------
                    displayList.length > 0 && (
                      <div className="filterBar">
                        {displayUnusedOnly
                          ? unusedPhotoList.length
                          : displayList.length}{' '}
                        {GetText('common.photos')}
                        <span className="spacer" />
                        <Popover
                          placement="right"
                          trigger="click"
                          content={
                            <Radio.Group
                              value={photoFilter}
                              onChange={(e) =>
                                handlePhotoFilterChange(e.target.value)
                              }
                            >
                              {Object.keys(PHOTO_FILTER_OPTIONS).map(
                                (filterOption) => {
                                  const radioStyle = {
                                    display: 'block',
                                    height: '30px',
                                    lineHeight: '30px',
                                  };

                                  return (
                                    <Radio
                                      key={filterOption}
                                      style={radioStyle}
                                      value={filterOption}
                                    >
                                      {GetText(filterOption)}
                                    </Radio>
                                  );
                                }
                              )}
                            </Radio.Group>
                            // )} ><Button size="small">{GetText(photoFilter)}</Button></Popover>
                          }
                        >
                          <Button size="small">
                            {GetText('photoarea.sort.btn')}
                          </Button>
                        </Popover>
                        {/* <Switch checkedChildren={`display ${unusedPhotoList.length} unused only`} unCheckedChildren={`display all ${allPhotoList.length } photo(s)`} checked={!displayUnusedOnly} onChange={()=>{setState({displayUnusedOnly: !displayUnusedOnly})}} /> */}
                        <span className="spacer" />
                        <Tooltip
                          title={
                            !displayUnusedOnly
                              ? GetText(
                                  'lefttab.photos.unused.tooltip'
                                ).replace(
                                  '{NUM}',
                                  unusedPhotoList.length.toString()
                                )
                              : GetText('lefttab.photos.all.tooltip').replace(
                                  '{NUM}',
                                  displayList.length.toString()
                                )
                          }
                        >
                          <CheckCircleTwoTone
                            twoToneColor={
                              displayUnusedOnly ? '#52c41a' : '#cccccc'
                            }
                            onClick={() => {
                              setDisplayUnusedOnly(!displayUnusedOnly);
                            }}
                          />
                        </Tooltip>
                        {/* <Switch checkedChildren={`display ${unusedPhotoList.length} unused only`} unCheckedChildren={`display all ${allPhotoList.length } photo(s)`} checked={!displayUnusedOnly} onChange={()=>{setState({displayUnusedOnly: !displayUnusedOnly})}} /> */}
                      </div>
                    )
                  }

                  {/* // --------------------- category selection------------------------ */}

                  <Select
                    defaultValue={currentCategoryId}
                    value={currentCategoryId}
                    placeholder="select a category"
                    onChange={(value) => {
                      handleCategoryChange(value);
                    }}
                    className="filterSelect"
                    style={{ width: '100%' }}
                  >
                    {photosCategories
                      // we don't want to display the editor2 category as it's often tooo big for this view
                      .filter(
                        (category) => category.id !== SPECIAL_CATEGORIES.EDITOR2
                      )
                      .map((category) => {
                        const numPhotos =
                          photosByCategory?.[category.id]?.length || '';
                        return (
                          <Option key={category.id} value={category.id}>
                            {category.label} ({numPhotos})
                          </Option>
                        );
                      })}
                  </Select>

                  {/* </h4>
                        {
                            DebugFlags.DEBUGGING &&
                            <h5>unused:{UnUsedPhotoNum}</h5>
                        } */}

                  {
                    // --------------------- photo container ------------------------
                    <div className="photosContainer">
                      {displayPhotoList.map((photo) => (
                        <PhotoItem
                          key={photo.id}
                          photo={photo}
                          used={photoUsed.includes(photo.id)}
                          dispatch={dispatch}
                          onDragStart={(e) => handleDragStart(e, photo)}
                          variant="withName"
                        />
                      ))}
                    </div>
                  }
                </div>
              )
            }

            {/* {  // --------------------- Temp photos ------------------------
                    ( tempPhotosList ) &&
                        tempPhotosList.map((tempPhoto, key) => {

                            //let photo = tempPhotosList[itemID];
                            return (

                                <PhotoItem
                                    key={tempPhoto.id}
                                    photo={tempPhoto}
                                    // scrollContainer={ scrollContainer }
                                    onDragStart = {(e)=> handleDragStart(e, tempPhoto )} />

                            )
                        })
                }      */}

            {/* {   ( photosByCategory && currentCategory ) &&

                            <div>
                                {/ * <h4>Photos: {tempPhotosList.length - 1 }</h4> * /}
                                <div className="photosContainer" >
                                    {
                                        (photosByCategory && photosByCategory[currentCategory]) &&
                                        photosByCategory[currentCategory].map(( photo:Photo, key) => {

                                        return (

                                            <PhotoItem
                                                key={ photo.id }
                                                photo={ photo }
                                                // scrollContainer={ scrollContainer }
                                                onDragStart = { handleDragStart } />

                                        )
                                        })
                                    }
                                </div>
                            </div>
                        } */}
          </div>
        </Spin>
      </div>

      {/*
                <form onSubmit={(e)=>_handleSubmit(e)}>
                    <Button className="fileInput"
                        type="upload"
                        onChange={(e)=>_handleImageChange(e)} multiple />
                    <button styleclassName="submitButton"
                        type="submit"
                        onClick={(e)=>_handleSubmit(e)}>Upload Image</button>
                    </form>
                    <div className="imgPreview">
                    {$imagePreview}
                    </div>
                    */}
    </div>
  );
};
