import { Colors } from '../../../data/Colors';
import { Frame } from '../../../types/types';
import {
  defaultTextOptions,
  FRAME_TYPE,
  OLD_DEFAULT_TEXT,
} from '../frame.types';

export const isFrameEmpty = (frameObj: Frame) => {
  // TODO: review this condition!
  if (frameObj.type === FRAME_TYPE.PHOTO) {
    if (!frameObj.photo && !frameObj.text) return true;
  }

  if (frameObj.type === FRAME_TYPE.OVERLAYER) {
    if (!frameObj.photo) return true;
  }
  if (frameObj.type === FRAME_TYPE.TEXT) {
    if (
      !frameObj.text ||
      frameObj.text.value === defaultTextOptions.value ||
      frameObj.text.value === OLD_DEFAULT_TEXT
    )
      return true;
  }
  if (frameObj.type === FRAME_TYPE.BKG) {
    if (
      !frameObj.photo &&
      !frameObj.background &&
      (!frameObj.fillColor || frameObj.fillColor === Colors.WHITE)
    )
      return true;
  }

  // TODO: by doing this, it do not print anymore.. in reality, frame is not empty.. so we need another way
  // if(frameObj.type === FRAME_TYPE.CALENDAR)
  // {
  // if(!frameObj.photo)
  // return true;
  // }

  return false;
};
