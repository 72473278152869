import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, Popover, Select, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import { GetText } from '../../../data/LanguageHelper';
import { DebugFlags } from '../../../debug/DebugFlags';
import { backgroundSelectors } from '../../../feature/backgrounds/background.store';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { FRAME_TYPE } from '../../../feature/frame/frame.types';
import { applyLayoutToPage } from '../../../feature/layouts/helpers/applyLayoutToPage';
import {
  layoutListActions,
  layoutListSelectors,
} from '../../../feature/layouts/layout.store';
import { Layout } from '../../../feature/layouts/layout.type';
import { photoListSelector } from '../../../feature/photoList/photo.selector';
import { UISelectors } from '../../../feature/ui/ui';
import { IsCover } from '../../../utils/cover/coverHelper';
import { AddDragData, DRAG_TYPE } from '../../../utils/DragUtils';
import { TABS_ENUM } from './TABS_ENUM';

const { Option } = Select;

export const LayoutsArea = () => {
  // --------------------- redux ------------------------
  const dispatch = useDispatch();
  const project = useSelector(editionSelectors.GetProjectSelector);
  const selectedTab = useSelector(UISelectors.getSelectedTab);
  const selectedPageIndex = useSelector(editionSelectors.GetSelectedPageIndex);
  const selectedFrameID = useSelector(editionSelectors.GetSelectedFrameID);
  const currentPage = useSelector(editionSelectors.GetSelectedPage);
  const photosById = useSelector(photoListSelector.getAllPhotosByID);
  const backgroundsByID = useSelector(
    backgroundSelectors.getAllBackgroundsByID
  );
  const layoutsByID = useSelector(layoutListSelectors.getLayoutsByID);
  const allowSavePageLayout = useSelector(
    layoutListSelectors.AllowSavePageLayout
  );
  const layoutsByPhotoCategories = useSelector(
    layoutListSelectors.getLayoutPhotosCategories
  );
  const currentPageLayouts = useSelector(
    layoutListSelectors.getCurrentPageLayouts
  );
  const tabIsActive = selectedTab === TABS_ENUM.LAYOUT;

  // --------------------- state ------------------------

  const [currentCategory, setCurrentCategory] = useState('all');

  const page = project ? project.pageList?.[selectedPageIndex] : undefined;
  let pageRatio = project ? page.width / page.height : -1;

  // page pages that are merged, we want to display layouts for single pages. So we update the ratio
  if (page && page.merged) {
    pageRatio = page.width / 2 / page.height;
  }

  const isClassicCover = !!(currentPage && currentPage.coverClassicOptions);

  const layoutsToDisplay = useMemo(() => {
    return !isClassicCover &&
      currentPageLayouts &&
      currentPageLayouts[currentCategory]
      ? currentPageLayouts[currentCategory]
      : [];
  }, [currentPageLayouts, currentCategory, isClassicCover]);

  // --------------------- methods ------------------------

  const handleLayoutClick = (layoutItem: Layout) => {
    // get a copy of page to work with
    const pageCopy = cloneDeep(project.pageList[selectedPageIndex]);

    // if the page is merged, we need to apply layout to a part of the current page.
    let mergedPageAreaToUse = undefined;
    if (pageCopy.merged) {
      // we find on wich part of the page the selected frame is
      const selectedFrame = pageCopy.frames.find(
        (frame) => frame.id === selectedFrameID
      );
      if (selectedFrame) {
        // if the frame is on the left part of the page, we apply the layout to the left part of the page
        if (selectedFrame.x < pageCopy.width / 2) {
          mergedPageAreaToUse = 'left';
        } else {
          mergedPageAreaToUse = 'right';
        }
      }
    }

    // apply layout to copy
    applyLayoutToPage(
      project,
      pageCopy,
      layoutItem,
      photosById,
      backgroundsByID,
      mergedPageAreaToUse
    );

    // clear possible selected frame
    dispatch(editionActions.ChangeSelectedFrame(null));

    // dispatch page update
    dispatch(editionActions.UpdatePage(pageCopy));

    // layout applied, allow undoable action
    dispatch(editionActions.AddUndoableAction());
  };

  const handleApplyToAll = (item) => {
    dispatch(editionActions.ApplyLayoutToAllPages(item.id));
  };

  const handleSaveCurrentLayout = () => {
    dispatch(layoutListActions.SaveCurrentPageLayout());
    setCurrentCategory('custom');
  };

  const handleDeleteCustomLayout = (item: Layout) => {
    dispatch(layoutListActions.DeleteCustomLayout(item.id));
  };

  const handleItemDragStart = (evt, item) => {
    AddDragData(evt, DRAG_TYPE.LAYOUT, item.id);
  };

  const handleCategoryChange = (newCategory) => {
    setCurrentCategory(newCategory);
  };

  // --------------------- render ------------------------

  // do not render tab is not active
  if (!tabIsActive) return null;

  return (
    <div className="tabContent layoutsArea">
      {/* // --------------------- HEADER ------------------------ */}
      <div className="tabHeader">
        <h3>{GetText('lefttab.layouts')}</h3>
        {/* <Spin spinning={layouts.isLoading}> */}
        <Select
          value={currentCategory}
          onChange={(value) => {
            handleCategoryChange(value);
          }}
          className="dropdown"
        >
          {layoutsByPhotoCategories.map((category) => {
            let label = `${category} ${GetText('common.photos')}`;
            if (category === 'all')
              label = GetText('lefttab.layouts.allLayouts');
            if (category === 'custom')
              label = GetText('lefttab.layouts.customLayouts');
            return (
              <Option key={category} value={category}>
                {label}
              </Option>
            );
          })}
        </Select>
      </div>

      {
        // --------------------- CONTENT SCROLLABLE  ------------------------
        <div className="tabScrollContent">
          {
            // --------------------- layout LIST ------------------------
            <div className="layoutsContainer">
              {
                // --------------------- CASE layouts available ------------------------
                layoutsToDisplay &&
                  layoutsToDisplay.map((layoutID) => {
                    const item: Layout = layoutsByID[layoutID];
                    let overContent = (
                      <div
                        className="BackgroundOverlay"
                        style={DebugFlags.DEBUGGING ? { width: 200 } : null}
                      >
                        {
                          // --- DEBUG - show json format
                          DebugFlags.DEBUGGING && (
                            <Button
                              danger
                              size="small"
                              className="button"
                              onClick={() => {
                                alert(JSON.stringify(item));
                              }}
                            >
                              Debug: SHOW LAYOUT
                            </Button>
                          )
                        }

                        <Button
                          className="button"
                          type="primary"
                          onClick={() => handleLayoutClick(item)}
                        >
                          {GetText('lefttab.layouts.apply')}
                        </Button>

                        {
                          // --- APPLY TO ALL
                          // allow apply to all button only if not on cover page
                          project &&
                            !IsCover(project.docID, selectedPageIndex) && (
                              <Button
                                className="button"
                                type="primary"
                                onClick={() => handleApplyToAll(item)}
                              >
                                {GetText('lefttab.layouts.applytoall')}
                              </Button>
                            )
                        }
                        {
                          // --- DELETE
                          item.isCustom && (
                            <Button
                              danger
                              className="button"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteCustomLayout(item);
                              }}
                            >
                              {GetText('lefttab.layouts.delete')}
                            </Button>
                          )
                        }
                      </div>
                    );
                    let overContentDelay = DebugFlags.DEBUGGING ? 0.5 : 0.5;
                    if (currentPage.isCover) {
                      overContent = null;
                      overContentDelay = 999999;
                    }

                    if (pageRatio === -1) {
                      // security if no project loaded
                      pageRatio = item.width / item.height;
                    }

                    const contentW = 90;
                    const contentH = contentW / pageRatio;

                    const scaleX = contentW / item.width;
                    const scaleY = contentH / item.height;

                    return (
                      <Popover
                        key={item.id}
                        content={overContent}
                        placement="right"
                        trigger="hover"
                        mouseEnterDelay={overContentDelay}
                      >
                        <div
                          role="button"
                          tabIndex={0}
                          className="layoutItem"
                          style={{
                            width: contentW,
                            height: contentH,
                          }}
                          onDragStart={(e) => handleItemDragStart(e, item)}
                          onClick={() => handleLayoutClick(item)}
                          draggable
                        >
                          {
                            // see doc of popover that extends the tooltip
                            // https://gary-shen.github.io/ant-design/components/tooltip/
                          }

                          <svg viewBox={`0 0 ${contentW} ${contentH}`}>
                            {item.frames &&
                              /// ////////////////
                              // DRAW FRAMES
                              item.frames.map((frame, _i) => {
                                // with frame from json
                                const t = {
                                  // x : (frame.x - frame.width/2)*scaleRatio,
                                  // y : (frame.y - frame.height/2)*scaleRatio,
                                  x: frame.x * scaleX,
                                  y: frame.y * scaleY,
                                  width: frame.width * scaleX,
                                  height: frame.height * scaleY,
                                  angle: frame.rotation,
                                };

                                // SEcurity, sometimes we got undifined width
                                // TODO: we should validate this higher when we generate layouts.. some kind of "verify" function with logs
                                if (!Number.isNaN(frame.width)) {
                                  let classname = 'frame';
                                  if (frame.type === FRAME_TYPE.TEXT) {
                                    classname = 'frameText';
                                  } else if (
                                    frame.type === FRAME_TYPE.CLIPART
                                  ) {
                                    classname = 'frameClipart';
                                  }
                                  // Draw frame
                                  return (
                                    <g key={frame.id}>
                                      <rect
                                        x={t.x - t.width / 2}
                                        y={t.y - t.height / 2}
                                        width={t.width}
                                        height={t.height}
                                        // transform={"rotate("+ t.angle +"," + t.width/2 +"," + t.height/2 +")"}
                                        transform={`rotate(${t.angle})`}
                                        className={classname}
                                      />
                                      {frame.type === FRAME_TYPE.TEXT && (
                                        <text
                                          height={t.height}
                                          width={t.width}
                                          y={t.y}
                                          x={t.x}
                                          className="unselectable"
                                          style={{
                                            // overflow:"hidden",
                                            textAnchor: 'middle', // halign
                                            dominantBaseline: 'middle', // valign
                                            fontSize: '10px',
                                            // fontWeight:"bold",
                                            fontFamily: 'Helvetica',
                                            fill: 'var(--primary)',
                                          }}
                                        >
                                          T
                                        </text>
                                      )}
                                    </g>
                                  );
                                }
                                return <g />;
                              })}
                          </svg>
                        </div>
                      </Popover>
                    );
                  })
              }
            </div>
          }

          {
            // --------------------- CASE EMPTY ------------------------
            (!layoutsToDisplay || layoutsToDisplay.length === 0) && (
              <Empty
                style={{ width: '100%' }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={GetText('lefttab.layout.empty.label')}
              />
            )
          }
        </div>
      }

      {!page.merged && (
        // --------------------- FOOTER ------------------------
        <div className="tabFooter">
          {
            //* SAVE CUSTOM LAYOUT */
            <Tooltip
              title={GetText('tooltip.customLayout.save')}
              placement="right"
            >
              <Button
                className="customSaveButton"
                type="primary"
                disabled={!allowSavePageLayout}
                onClick={() => handleSaveCurrentLayout()}
              >
                {GetText('customLayout.save')}
              </Button>
            </Tooltip>
          }
        </div>
      )}
    </div>
  );
};
