'use client';

import { GetText } from '../../data/LanguageHelper';
import { EmbedAssets } from '../../images/EmbedAssets';
import { notifyError } from '../../utils/error/notifyError';

export const ErrorDisplayView = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  // notify
  notifyError(error);

  // retrieve possible rebranding logo from session
  let rebranding = undefined;
  try {
    const sessionStore = JSON.parse(localStorage.getItem('session'));
    rebranding = sessionStore?.rebranding;
  } catch (e) {
    localStorage.removeItem('session');
  }

  // display
  return (
    <div className="flex flex-col w-full h-screen z-10 text-red-600 p-4 justify-center align-middle text-center gap-4">
      <div className="max-w-2xl mx-auto px-4">
        <img
          src={rebranding?.logo || EmbedAssets.Logo}
          width="100"
          className="-mt-5"
        />
        <h1 className="pt-4 text-red-600">😢 {GetText('popup.error.title')}</h1>
        {/* <Image src={gif} /> */}
        <p className="text-sm whitespace-pre-wrap text-primaryDark">
          {GetText('popup.error.description')}
        </p>
        <p className="text-gray-400 bg-gray-100 p-2 rounded-lg">
          <i>{error.message}</i>
        </p>
        <button
          className="cursor-pointer rounded-lg border-b-2 text-lg px-4 py-2 bg-primaryDark text-white"
          onClick={resetErrorBoundary}
        >
          {GetText('popup.error.button')}
        </button>
      </div>
    </div>
  );
};
