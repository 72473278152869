import { createReducer, createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { config, IsAlbumEditor } from '../../data/config';
import { StoreType } from '../../store/store.type';
import { Background } from '../../types/types';
import { API } from '../../utils/API';
import { alertActions } from '../alert/alert';
import { editionSelectors } from '../edition/edition.selector';
import { BackgroundHelper } from './backgroundHelper';

/** **********************************
// ACTIONS TYPES
************************************ */

const TAG = 'BKG';

// LIST
const GETALL_START = `${TAG}/GETALL_START`;
const GETALL_SUCCESS = `${TAG}/GETALL_SUCCESS`;
const GETALL_ERROR = `${TAG}/GETALL_ERROR`;

// SORT
// const SORT = TAG+ "/SORT";

/** **********************************
// REDUCERS
************************************ */

const initialState: StoreType['backgrounds'] = {
  byID: {},
  isLoading: false, // is currently loading list
  error: null, // current error message
};

const reducer = createReducer(initialState, {
  [GETALL_START]: (state) => {
    state.error = null;
    state.isLoading = true;
  },

  [GETALL_ERROR]: (state, action) => {
    state.error = action.error;
    state.isLoading = false;
  },

  [GETALL_SUCCESS]: (state, action) => {
    state.error = null;
    state.isLoading = false;
    state.byID = action.byID;
  },
});

// --------------------- helpers ------------------------
// to get correct path, we need to remove "backgrounds/" at start and "jpg" at end from the proxies..
const cleanProxy = (proxy: string) => {
  return proxy.split('backgrounds/').join('').split('.jpg').join('');
};

// --------------------- actions ------------------------

function getAll(projectClass) {
  return (dispatch) => {
    dispatch(start());

    API.getBackgrounds(projectClass).then(
      (jsonResponse) => {
        const resultByID = {};
        // go through items
        jsonResponse.backgrounds.document.forEach((docBackgrounds) => {
          const docSuffix = docBackgrounds.$.name;

          // do we have backgrounds (canvas do not for example..)
          if (docBackgrounds.background) {
            docBackgrounds.background.forEach((item) => {
              // TODO create background item interface or class
              /** @type {Background} */
              const bkg: Background = {
                id: `${docSuffix}_${item.$.name}`,
                name: item.$.name,
                cat: item.$.category,
                // bkg.height = Number(item.$.height); // THIS is the thumb size !!
                // bkg.width = Number(item.$.width); // THIS is the thumb size !!
                height: Number(item.$.realheight),
                width: Number(item.$.realwidth),
                realHeight: Number(item.$.realheight),
                realWidth: Number(item.$.realwidth),
                left: Number(item.$.left),
                top: Number(item.$.top),
                // type: item.$.type,
                luminance: Number(item.$.luminance),
                proxy: item.$.proxy,
                thumb_url: `${
                  config.apiPhoto
                }flex/loadbackgrounds.php?image=${cleanProxy(item.$.tnproxy)}`,
                working_url: `${
                  config.apiPhoto
                }flex/loadbackgrounds.php?image=${cleanProxy(item.$.proxy)}`,
                full_url: `${
                  config.apiPhoto
                }flex/loadbackgrounds.php?image=${cleanProxy(item.$.proxy)}`,
                orderSuffix: item.$.orderSuffix,
              };

              // add to result list
              resultByID[bkg.id] = bkg;
            });
          }
        });

        // dispatch(success(result.byID, result.filtered));
        dispatch(success(resultByID));
      },
      (error) => {
        dispatch(fail(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function start() {
    return { type: GETALL_START };
  }
  // function success(byID, filtered) { return { type: GETALL_SUCCESS, byID, filtered } }
  function success(byID) {
    return { type: GETALL_SUCCESS, byID };
  }
  function fail(error) {
    return { type: GETALL_ERROR, error };
  }
}

/** **********************************
// SELECTORS
************************************ */

type RootState = StoreType;
const selfSelect = (state: RootState) => state.backgrounds;

const getAllBackgroundsByID = createSelector(
  selfSelect,
  (edition) => edition.byID
);

const getAllBackgroundList = createSelector(getAllBackgroundsByID, (byID) =>
  Object.keys(byID).map((key) => byID[key])
);

const getProjectValidInternalBackgroundList = createSelector(
  getAllBackgroundList,
  (state) =>
    editionSelectors.GetProjectSizeRatioSelector
      ? editionSelectors.GetProjectSizeRatioSelector(state)
      : null,
  (fullList, projectSizeRatio) =>
    BackgroundHelper.GetValidBackgroundListForSizeRatio(
      projectSizeRatio,
      fullList
    )
);

const getProjectValidCoverBackgroundList = createSelector(
  getAllBackgroundList,
  (state) =>
    editionSelectors.GetProjectCoverSizeRatioSelector
      ? editionSelectors.GetProjectCoverSizeRatioSelector(state)
      : null,
  (fullList, coverProjectRatio) =>
    BackgroundHelper.GetValidBackgroundListForSizeRatio(
      coverProjectRatio,
      fullList
    )
);

const getProjectValidBackgroundList = createSelector(
  (state) =>
    editionSelectors.GetSelectedPageIndex
      ? editionSelectors.GetSelectedPageIndex(state)
      : null,
  getProjectValidInternalBackgroundList,
  getProjectValidCoverBackgroundList,
  (pageIndex, internalBackgrounds, coverBackgrounds) => {
    if (IsAlbumEditor() && pageIndex === 0) return coverBackgrounds;
    return internalBackgrounds;
  }
);

const getBackgroundCategories = createSelector(
  getProjectValidBackgroundList,
  (backgroundList /** @type {Array<Background>}* */) => {
    let categories = backgroundList.map((item) => item.cat);

    // less intensive to sort unique categories
    categories = uniq(categories);

    // sort categories
    categories = categories.sort((a, b) => (b === 'stretch' ? -1 : a > b));

    return uniq(categories) as string[];
  }
);

const getBackgroundsByCategories = createSelector(
  getProjectValidBackgroundList,
  (backgroundList) => {
    const backgroundByCategories = {};
    backgroundList.forEach((item) => {
      // create category arry if not existing
      if (!backgroundByCategories[item.cat])
        backgroundByCategories[item.cat] = [];
      // push item in correct category object
      backgroundByCategories[item.cat].push(item);
    });
    return backgroundByCategories;
  }
);

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export default reducer;

export const backgroundSelectors = {
  getAllBackgroundsByID,
  getAllBackgroundList,
  getBackgroundCategories,
  getBackgroundsByCategories,
};

export const backgroundActions = {
  getAll,
  // sort
};
