export const OLD_DEFAULT_TEXT = 'Add your text here';

export enum IMAGE_QUALITY {
  GOOD = 1,
  LOW = 0,
  BAD = -1,
}

export enum FRAME_TYPE {
  // -- common --
  PHOTO = 'picture',
  BKG = 'bkg',
  TEXT = 'text',
  CLIPART = 'clipart',
  OVERLAYER = 'overlayer',

  // --- SPECIAL FRAMES ---
  PAGE_NUMBER = 'page_number',
  QR_CODE = 'qr_code',
  SPINE = 'spine',
  SPINE_NUM = 'spine_num',
  CALENDAR = 'calendar',
  PROMO_URL = 'url', // special frame form cards
  POSTCARD_BG = 'postcard_bg', // special frame form cards
}

export const BORDER_SIZE = {
  NO: 0,
  '1MM': 1,
  '2MM': 2,
  '4MM': 4,
};

export const defaultTextOptions = {
  value: '',
  size: 24,
  family: 'Arial',
  color: '#000000',
  valign: 'middle',
  halign: 'center',
  bold: false,
  italic: false,
};
