import { Frame } from '../../types/types';

export enum LAYOUT_TYPE {
  DEFAULT = 'default', // common type is a layout that can be used for common pages

  MERGED = 'merged', // layflat project can have "merged" layouts. This is a layout on two pages

  // special layouts
  COVER = 'cover',
  CALENDAR = 'calendars', // those are calendar layouts( with calendar date actions )
  POSTCARD_BG = 'postcard_bg',
  PROMO_URL = 'promo_url',
}

export type Layout = {
  id: string;
  width: number;
  height: number;
  frames: Frame[];
  isCustom: boolean;
  // optional
  type?: LAYOUT_TYPE;
  numPhotos?: number;
};
