import { createSelector } from '@reduxjs/toolkit';
import { StoreType } from '../../store/store.type';
import { Photo } from '../../types/types';
import { PHOTO_FILTER_OPTIONS } from './photo.type';

// --------------------- helpers ------------------------

function sortPhotos(filter: PHOTO_FILTER_OPTIONS, photoList: Photo[]) {
  return photoList.sort((a: Photo, b: Photo) => {
    // TEMP photo case?
    // if( a.temp && !b.temp)
    //   return -1;
    // if( !a.temp && b.temp)
    //   return 1;

    // BY PHOTO NAME
    // for now we just sort by id to be sure to have latest uploaded first in the list
    if (filter === PHOTO_FILTER_OPTIONS.BY_NAME_DESC)
      return a.name < b.name ? 1 : -1;
    if (filter === PHOTO_FILTER_OPTIONS.BY_NAME_ASC)
      return a.name < b.name ? -1 : 1;

    // BY PHOTO DATE
    // for now we just sort by id to be sure to have latest uploaded first in the list
    if (filter === PHOTO_FILTER_OPTIONS.BY_DATE_DESC)
      return Number(a.creation_date) < Number(b.creation_date) ? -1 : 1;
    if (filter === PHOTO_FILTER_OPTIONS.BY_DATE_ASC)
      return Number(a.creation_date) < Number(b.creation_date) ? 1 : -1;

    // BY IMPORT DATE
    // --> sort by id to be sure to have latest uploaded first in the list
    if (filter === PHOTO_FILTER_OPTIONS.BY_IMPORT_DATE_DESC)
      return Number(a.id) < Number(b.id) ? 1 : -1;
    if (filter === PHOTO_FILTER_OPTIONS.BY_IMPORT_DATE_ASC)
      return Number(a.id) < Number(b.id) ? -1 : 1;

    // default unkown filter..
    return 0;

    // if (a.id > b.id )
    //   return -1;
    // if (a est supérieur à b selon les critères de tri)
    //   return 1;
    // // a doit être égal à b
    // return 0;
  });
}

// --------------------- selectors ------------------------

const selfSelect = (state: StoreType) => state.photos;
const tempByIDSelector = createSelector(
  selfSelect,
  (state) => state.tempPhotosByID
);

// sort filter
const sortFilterSelector = createSelector(
  selfSelect,
  (state) => state.sortFilter
);

// backend photos
const backendPhotoMap = createSelector(
  selfSelect,
  (state) => state.backendPhotosByID
);
const backendPhotoList = createSelector(
  backendPhotoMap,
  sortFilterSelector,
  (byID, sortFilter) => {
    const photoList = Object.keys(byID).map((key) => byID[key]);
    return sortPhotos(sortFilter, photoList);
  }
);

const projectPhotosByIDSelector = (state) =>
  state.photos && state.photos.projectPhotosByID;

const getTotalUploadingItemsSelector = (state) =>
  state.photos && state.photos.totalUploadingItems;
const getTempPhotoToNewPhotoMapping = (state) =>
  state.photos && state.photos.tempPhotoMapping;

const getTempPhotoByID = (state, tempID) =>
  state.photos &&
  state.photos.tempPhotosByID &&
  state.photos.tempPhotosByID[tempID];

/**
 * returns a map of all photos (backend + temp): [id] => photo
 **/
const getAllPhotosByID = createSelector(
  backendPhotoMap,
  tempByIDSelector,
  (projectPhotosById, tempById) =>
    ({ ...tempById, ...projectPhotosById }) as Record<string, Photo>
);
const getAllPhotoList = createSelector(
  getAllPhotosByID,
  sortFilterSelector,
  (byID, sortFilter) => {
    const photoList = Object.keys(byID).map((key) => byID[key]);
    return sortPhotos(sortFilter, photoList);
  }
);

const getTempPhotosList = createSelector(tempByIDSelector, (byID) => {
  const photoList = Object.keys(byID).map((key) => byID[key]);
  return sortPhotos(null, photoList);
});

const hasPhotosStillImporting = createSelector(
  getTempPhotosList,
  (tempPhotoList) =>
    tempPhotoList &&
    tempPhotoList.filter((tempPhoto) => !tempPhoto.error).length > 0
);

const getUploadingPercentSelector = createSelector(
  getTempPhotosList,
  (state) => state.photos.totalUploadingItems,
  (tempPhotoList, totalUploadingItems) => {
    const pct =
      Math.round(
        ((totalUploadingItems - tempPhotoList.length) / totalUploadingItems) *
          1000
      ) / 10;
    return pct;
  }
);

const getProjectPhotosList = createSelector(
  projectPhotosByIDSelector,
  sortFilterSelector,
  (byID, sortFilter) => {
    const photoList = Object.keys(byID).map((key) => byID[key]);
    return sortPhotos(sortFilter, photoList);
  }
);

const getPhotosByCategories = createSelector(getAllPhotoList, (photoList) => {
  const photosCategories: { [categoryID: string]: Photo[] } = {};
  photoList.forEach((photo) => {
    const cat = photo.cat;
    if (cat && !photosCategories[cat]) photosCategories[cat] = [];
    photosCategories[cat].push(photo);
  });
  return photosCategories;
});

const getPhotoByMD5 = createSelector(getAllPhotosByID, (byID) => {
  const photosByMD5 = {};
  Object.keys(byID).forEach((key) => {
    const item: Photo = byID[key]; // get item
    photosByMD5[item.md5] = item;
  });
  return photosByMD5;
});

// --------------------- export ------------------------

export const photoListSelector = {
  isLoading: (state) => state.photos.isLoading,
  getTempPhotoByID,
  getAllBackendPhotosByID: backendPhotoMap,
  getAllBackendPhotoList: backendPhotoList,
  getAllPhotosByID,
  getAllPhotoList,
  getPhotosByCategories,
  hasPhotosStillImporting,
  getTempPhotosList,
  getProjectPhotosList,
  getPhotoByMD5,
  getUploadingPercentSelector,
  getTempPhotoToNewPhotoMapping,
  getTotalUploadingItemsSelector,
  sortFilterSelector,
};
