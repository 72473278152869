/**
 * Get the performance level of the device
 * @returns a number between 1 and 10 where 1 is the worst and 10 is the best
 */
export const getPerformanceLevel = async (): Promise<number> => {
  const cpuCores = navigator.hardwareConcurrency || 4;
  const ram = (navigator as any).deviceMemory || 4;

  // Simple CPU Benchmark
  const benchmarkCPU = (): Promise<number> => {
    return new Promise((resolve) => {
      const start = performance.now();
      let total = 0;
      for (let i = 0; i < 1e7; i++) {
        total += Math.sqrt(i); // Simulated workload
      }
      const duration = performance.now() - start;
      resolve(duration);
    });
  };

  const cpuBenchmarkTime = await benchmarkCPU();

  // Simple GPU Detection
  const getGPUScore = (): number => {
    try {
      const canvas = document.createElement('canvas');
      const gl = (canvas.getContext('webgl') ||
        canvas.getContext('experimental-webgl')) as WebGLRenderingContext;
      if (!gl) return 1;

      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (!debugInfo) return 1;

      const gpu =
        gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'Unknown GPU';

      // More comprehensive GPU tiering
      if (/RTX|RX\s*\d{4}/i.test(gpu)) return 3; // High-end GPUs
      if (/NVIDIA|AMD|Radeon/i.test(gpu)) return 2.5; // Mid-range GPUs
      if (/Intel|UHD|Iris/i.test(gpu)) return 2; // Integrated GPUs
      return 1;
    } catch (error) {
      console.warn('GPU detection failed:', error);
      return 1;
    }
  };

  const gpuScore = getGPUScore();

  // Scoring system
  let score = 1; // Base score

  // CPU Core Contribution
  if (cpuCores >= 12) score += 4;
  else if (cpuCores >= 8) score += 3;
  else if (cpuCores >= 6) score += 2;
  else if (cpuCores >= 4) score += 1;

  // RAM Contribution (with safety check)
  if (typeof ram === 'number') {
    if (ram >= 16) score += 3;
    else if (ram >= 8) score += 2;
    else if (ram >= 6) score += 1;
  }

  // CPU Benchmark Contribution (lower is better)
  if (cpuBenchmarkTime < 100) score += 3;
  else if (cpuBenchmarkTime < 200) score += 2;
  else if (cpuBenchmarkTime < 400) score += 1;

  // GPU Contribution
  score += gpuScore;

  // Normalize between 1 and 10
  const normalizedScore = Math.max(1, Math.min(score, 10));
  return normalizedScore;
};
