import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import { CustomDragger } from './CustomDragger';

type UploaderState = {
  startImportImages: (category: string) => void;
  currentCategory: string;
};

// CONTEXT
const PhotoUploaderContext = createContext<UploaderState>({} as UploaderState);

// CONTEXT HOOK
export const usePhotoUploader = () => {
  const context = useContext(PhotoUploaderContext);
  if (!context) {
    throw new Error('usePhotoUpload must be used within a PhotoUploadProvider');
  }
  return context;
};

// CONTEXT PROVIDER
export const PhotoUploadProvider = ({ children }: { children: ReactNode }) => {
  const draggerRef = useRef(null);

  const [currentCategory, setCurrentCategory] = useState<string>();

  // --------------------- methods ------------------------

  const startImportImages = (
    category: string,
    ref: React.MutableRefObject<any> = draggerRef
  ) => {
    setCurrentCategory(category);
    (ref || draggerRef).current?.upload.uploader.fileInput.click();
  };

  // --------------------- render ------------------------

  return (
    <PhotoUploaderContext.Provider
      value={{
        currentCategory,
        startImportImages,
      }}
    >
      <CustomDragger
        ref={draggerRef}
        style={{ visibility: 'hidden', display: 'none' }} // needed a display none to avoid some weird layout bug...
      />
      {children}
    </PhotoUploaderContext.Provider>
  );
};
