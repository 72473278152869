import { Colors } from '../../../data/Colors';
import type { Frame, IFrameShadow } from '../../../types/types';
import { ToRadians } from '../../../utils/MathUtils';

export const FRAME_SHADOW_SIZE = 256;
export const SHADOW_OFFSET = 20;

export function getDefaultFrameShadow(): IFrameShadow {
  return {
    enabled: false,
    distance: 10,
    hexColor: Colors.BLACK,
    opacity: 0.3,
    angle: 45,
  };
}

/**
 * Retrieve shadow rect based on frame properties
 */
export function GetShadowFrameRect(frame: Frame) {
  const scaleX: number = frame.width / FRAME_SHADOW_SIZE;
  const scaleY: number = frame.height / FRAME_SHADOW_SIZE;
  const relativeOffsetX = SHADOW_OFFSET * scaleX;
  const relativeOffsetY = SHADOW_OFFSET * scaleY;
  return {
    x:
      Math.cos(ToRadians(frame.shadow.angle)) * frame.shadow.distance -
      relativeOffsetX,
    y:
      Math.sin(ToRadians(frame.shadow.angle)) * frame.shadow.distance -
      relativeOffsetY,
    width: frame.width + relativeOffsetX * 2,
    height: frame.height + relativeOffsetY * 2,
  };
}
