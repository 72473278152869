import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  BgColorsOutlined,
  ClearOutlined,
  FormOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Modal,
  Popover,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { cloneDeep } from 'lodash';
import ColorPicker from '../../../../_components/ColorPicker';
import { Colors } from '../../../../data/Colors';
import { PAPER_FINISH, PAPER_QUALITY } from '../../../../data/Constants';
import { GetText } from '../../../../data/LanguageHelper';
import { editionActions } from '../../../../feature/edition/edition';
import { editionSelectors } from '../../../../feature/edition/edition.selector';
import { getColorIntensity } from '../../../../feature/order/printColorsHelper';
import { pricingSelectors } from '../../../../feature/pricing/pricing';
import { ProjectOptionsPrice } from '../../../../feature/pricing/pricing.type';
import { IDocument } from '../../../../types/IDocument';
import { Project, ProjectOptions } from '../../../../types/project';
import {
  GetAlbumCoverDisplayName,
  HasCustomCover,
} from '../../../../utils/cover/coverHelper';
import { GetDoc, GetProjectDocSizeCM } from '../../../../utils/ProductHelper';
import { getProjectPreviewUrl } from '../../../../utils/project/getProjectPreviewUrl';
import {
  GetProjectDisplayPage,
  GetProjectMaxPages,
} from '../../../../utils/projectHelper';
import { AlbumProjectCreationView } from '../../projectCreation/AlbumProjectCreationView';

const { Paragraph } = Typography;
const { Option } = Select;

export const AlbumProjectArea = () => {
  // --------------------- state ------------------------

  const [upgradePopupVisible, setUpgradePopupVisible] = useState(false);

  // selectors
  const dispatch = useDispatch();
  const project = useSelector(editionSelectors.GetProjectSelector);
  // const projectName = useSelector(editionSelectors.GetProjectName);
  const projectOptions = useSelector(
    editionSelectors.GetProjectOptionsSelector
  );
  const projectOptionsPrice: ProjectOptionsPrice = useSelector(
    pricingSelectors.GetProjectOptionsPrice
  );

  const currentProject: Project = project;
  const numPages: number = currentProject
    ? GetProjectDisplayPage(currentProject)
    : null;
  const projectSizeInCM: string = currentProject
    ? GetProjectDocSizeCM(currentProject.docID)
    : null;

  let doc: IDocument;
  let allowInserts: boolean;
  let allowCoating: boolean;
  let allowMate: boolean;
  let pagePaperQualityOptions: PAPER_QUALITY[];
  let coverDetail = null;
  if (currentProject) {
    doc = GetDoc(currentProject.docID);
    allowInserts = doc.insert;
    allowMate = doc.mate;
    allowCoating = doc.coating === false ? false : true;
    pagePaperQualityOptions = doc.pagePaperQuality;
    coverDetail = GetAlbumCoverDisplayName(currentProject);
  }

  const previewURL = getProjectPreviewUrl({
    classname: project.classname,
    projectType: project.type,
    docID: project.docID,
    coverType: project.coverType,
  });

  // --------------------- methods ------------------------

  const handleProjectNameChange = (newName) => {
    dispatch(editionActions.UpdateProjectName(newName));
  };

  const handlePaperFinishChange = (paperFinish: string, flag) => {
    const newProjectOptions: ProjectOptions = { ...projectOptions };
    if (paperFinish === PAPER_FINISH.COATED) {
      newProjectOptions.coated = flag;
      if (flag) newProjectOptions.matte = false; // be sure to force false to avoid having both options
    } else {
      newProjectOptions.matte = flag;
      if (flag) newProjectOptions.coated = false; // be sure to force false to avoid having both options
    }
    dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  const handleInsertChange = (val) => {
    const newProjectOptions: ProjectOptions = { ...projectOptions };
    newProjectOptions.paper = val;
    dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  const handlePageNumberChange = (val: boolean) => {
    const newProjectOptions: ProjectOptions = { ...projectOptions };
    newProjectOptions.pageNumber = val;
    dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  const handlePageNumberColorChange = (val: string) => {
    const newProjectOptions: ProjectOptions = { ...projectOptions };
    newProjectOptions.pageNumberColor = val;
    dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  const handleCoverFinishChange = (matteCover: boolean) => {
    const newProjectOptions: ProjectOptions = { ...projectOptions };
    newProjectOptions.cover_matte = matteCover;
    dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  const handleResetButtonClick = () => {
    Modal.confirm({
      title: GetText('project.clearFrames.title'),
      content: GetText('project.clearFrames.description'),
      okText: GetText('common.ok'),
      cancelText: GetText('common.cancel'),
      onOk: () => {
        dispatch(editionActions.resetProjectFrames());
      },
    });
  };

  const handlepagePaperQualityChange = (val) => {
    const options: ProjectOptions = { ...projectOptions };
    const proj: Project = cloneDeep(project);
    proj.pagePaperQuality = val;
    const newMaxPages: number = GetProjectMaxPages(proj);

    // check for max pages min pages change!
    if (proj.pageList.length > newMaxPages) {
      const numPagesToDelete = proj.pageList.length - newMaxPages - 1; // -1 for cover
      Modal.confirm({
        title: GetText('popup.paperquality.confirmDelete.title'),
        content: GetText('popup.paperquality.confirmDelete.desc').replace(
          '{NUM}',
          `${numPagesToDelete}`
        ),
        okText: GetText('common.confirm'),
        cancelText: GetText('common.cancel'),
        onOk: () => {
          batch(() => {
            // upgrade page amount
            options.pagePaperQuality = val;
            dispatch(editionActions.UpdateProjectOptions(options));
            dispatch(
              editionActions.UpgradeCurrentProject(
                { type: proj.type, docID: proj.docID, numPages: newMaxPages },
                false
              )
            );
          });
        },
      });
      return;
    }

    options.pagePaperQuality = val; // val ? PAPER_QUALITY.QUALITY_250:PAPER_QUALITY.QUALITY_170;
    dispatch(editionActions.UpdateProjectOptions(options));
  };

  // --------------------- render ------------------------

  return (
    <div className="tabContent projectArea">
      {upgradePopupVisible && (
        <AlbumProjectCreationView
          isUpgrade={true}
          onCloseCallback={() => {
            setUpgradePopupVisible(false);
          }}
        />
      )}

      <h3>{GetText('lefttab.project')}</h3>

      {currentProject && (
        <div>
          <Paragraph
            editable={{
              onChange: (str) => {
                handleProjectNameChange(str);
              },
            }}
          >
            {currentProject.name}
          </Paragraph>

          <h4>{GetText('album.type')}</h4>
          <ul style={{ paddingLeft: '20px', listStyleType: 'circle' }}>
            <li>{GetText(`album.type.types_albums_${currentProject.type}`)}</li>
            <li>{coverDetail}</li>
            <li>
              {GetText(`album.prefix.${currentProject.docID}`)}:{' '}
              {projectSizeInCM}
            </li>
            <li>
              {numPages} {GetText('common.pages')}
            </li>
          </ul>

          <Button
            type="primary"
            icon={<FormOutlined />}
            onClick={() => {
              setUpgradePopupVisible(true);
            }}
          >
            {GetText('common.modify')}
          </Button>

          <div className="spacerV" />
          <div className="spacerV" />
          <h4>{GetText('album.option')}</h4>
          <div>
            <Tooltip
              title={GetText('lefttab.project.album.matte')}
              placement="right"
            >
              <Checkbox
                checked={currentProject.matte}
                onChange={(e) =>
                  handlePaperFinishChange(PAPER_FINISH.MATTE, e.target.checked)
                }
                style={{
                  display: currentProject.matte ? 'inline-flex' : 'none',
                }}
              >
                {GetText('lefttab.project.album.matte')}{' '}
                {`(${projectOptionsPrice.matte.toFixed(2)}€)`}
              </Checkbox>
            </Tooltip>
          </div>
          {allowCoating && (
            <div>
              <Tooltip
                title={GetText('infos.lefttab.albums.coating')}
                placement="right"
              >
                <Checkbox
                  checked={currentProject.coated}
                  onChange={(e) =>
                    handlePaperFinishChange(
                      PAPER_FINISH.COATED,
                      e.target.checked
                    )
                  }
                >
                  {GetText('lefttab.project.album.coated')}{' '}
                  {`(${projectOptionsPrice.coated.toFixed(2)}€)`}
                </Checkbox>
              </Tooltip>
            </div>
          )}
          {allowInserts && (
            <div>
              <Tooltip
                title={GetText('infos.lefttab.albums.pergamine')}
                placement="right"
              >
                <Checkbox
                  checked={currentProject.paper}
                  onChange={(e) => handleInsertChange(e.target.checked)}
                >
                  {GetText('lefttab.project.album.paper')}{' '}
                  {`(${projectOptionsPrice.paper.toFixed(2)}€)`}
                </Checkbox>
              </Tooltip>
            </div>
          )}
          <Tooltip
            title={GetText('lefttab.project.album.pageNumber')}
            placement="right"
          >
            <Checkbox
              checked={currentProject.pageNumber}
              onChange={(e) => handlePageNumberChange(e.target.checked)}
            >
              {GetText('lefttab.project.album.pageNumber')}{' '}
              {/** PAGE NUMBER COLOR*/}
              <Popover
                content={
                  <div style={{ width: 230 }}>
                    <p>{GetText('tooltip.transformtool.text.color')}</p>
                    <ColorPicker
                      spacing={5}
                      itemSize={15}
                      colors={Colors.displayColors}
                      selected={currentProject.pageNumberColor}
                      onChange={(color) =>
                        handlePageNumberColorChange(color.hex)
                      }
                    />
                  </div>
                }
                trigger="focus"
              >
                <Button
                  size="small"
                  // type="link"
                  icon={<BgColorsOutlined />}
                  style={{
                    display: currentProject.pageNumber ? 'inline-flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: currentProject.pageNumberColor || 'black',
                    backgroundColor:
                      currentProject.pageNumberColor &&
                      getColorIntensity(currentProject.pageNumberColor) > 0.5
                        ? '#666666'
                        : 'transparent',
                    fontWeight: 'bolder',
                    borderRadius: '35%',
                  }}
                />
              </Popover>
            </Checkbox>
          </Tooltip>

          {HasCustomCover(currentProject) && allowMate && (
            <div>
              <Tooltip
                title={GetText('infos.lefttab.albums.cover.matte')}
                placement="right"
              >
                <Checkbox
                  checked={currentProject.cover_matte}
                  onChange={(e) => handleCoverFinishChange(e.target.checked)}
                >
                  {GetText('lefttab.project.album.cover.matte')}{' '}
                  {`(${projectOptionsPrice.cover_matte.toFixed(2)}€)`}
                </Checkbox>
              </Tooltip>
            </div>
          )}

          <div>
            <h5 style={{ paddingTop: '12px' }}>
              {GetText('leftab.project.album.paper.quality.label')}
            </h5>
            <Select
              // defaultValue="lucy"
              style={{ width: '100%' }}
              value={currentProject.pagePaperQuality}
              size="small"
              // placeholder="Select a Product"
              disabled={pagePaperQualityOptions.length < 2}
              onChange={(val) => handlepagePaperQualityChange(val)}
            >
              {Object.keys(pagePaperQualityOptions).map((key) => (
                <Option
                  key={pagePaperQualityOptions[key]}
                  value={pagePaperQualityOptions[key]}
                >
                  {GetText(
                    `leftab.project.album.paper.quality.${pagePaperQualityOptions[key]}`
                  )}
                </Option>
              ))}
            </Select>
          </div>

          <Row className="preview">
            <img src={previewURL} />
          </Row>

          <div className="flex justify-end pt-4">
            <Button
              type="dashed"
              size="small"
              className="text-xs"
              icon={<ClearOutlined />}
              onClick={() => {
                handleResetButtonClick();
              }}
            >
              {GetText('project.clearFrames.button')}
            </Button>
          </div>

          {/* <Row type="flex" justify="end">
                    <Button
                        // onClick={handleConfirm}
                        disabled
                        >Upgrade Project</Button>
                 </Row> */}
        </div>
      )}
    </div>
  );
};
