import { Modal } from 'antd';
import { GetText } from '../../data/LanguageHelper';

export const openPopupThankYou = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    // open modal
    Modal.success({
      title: GetText('common.thankyou'),
      okText: GetText('common.ok'),
      onOk: () => {
        resolve();
      },
      onCancel: () => {
        resolve();
      },
    });
  });
};
