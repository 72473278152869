import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, InputNumber, Modal, Row, Select, Spin } from 'antd';
import { cloneDeep, isEqual } from 'lodash';
import { CANVAS_FORMAT } from '../../../data/catalogue/canvasCatalogue.types';
import {
  CanvasProduct,
  CatalogueCanvas,
  GetProductCatalogue,
} from '../../../data/catalogue/productCatalogue';
import { PROJECT_CONST } from '../../../data/config';
import { GetText } from '../../../data/LanguageHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { authSelectors } from '../../../feature/auth/authentification';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { layoutListSelectors } from '../../../feature/layouts/layout.store';
import { useProjectPrice } from '../../../feature/pricing/useProjectPrice';
import { CreateProject } from '../../../feature/project/CreateProject';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { projectListSelectors } from '../../../feature/projectList/projectList';
import { Project, ProjectOptions } from '../../../types/project';
import { CanvasHelper } from '../../../utils/canvas/CanvasHelper';
import { history, ROUTE_CONST } from '../../../utils/history';
import { cmToPixel, pixelToCm } from '../../../utils/MeasureUtils';
import { GetDoc, GetProjectDocSizeCM } from '../../../utils/ProductHelper';

const { Option } = Select;

type Props = {
  isUpgrade?: boolean;
  onCloseCallback?: () => void;
};

export const CanvasProjectCreationView = ({
  isUpgrade,
  onCloseCallback,
}: Props) => {
  // --------------------- state / redux ------------------------

  const dispatch = useDispatch();
  const rebranding: any = useSelector(authSelectors.GetRebranding);
  const existingProject = useSelector(editionSelectors.GetProjectSelector);
  const projectsAreLoading = useSelector(projectListSelectors.loading);
  const layouts = useSelector(layoutListSelectors.getLayoutStore);

  //
  const [project, setProject] = useState<Project>();
  const catalogue: CatalogueCanvas = GetProductCatalogue() as CatalogueCanvas; // make a copy, in case of
  // rebranding filter products
  if (!isUpgrade && rebranding?.subProductFilter?.canvas) {
    catalogue.products = rebranding.subProductFilter.canvas;
  }
  const productList = catalogue.products;
  const productOptionKeys = Object.keys(productList) as CanvasProduct[];

  const freeFormatMinSize = GetDoc('CF').minSize;
  const freeFormatMaxSizeMin = Number(GetDoc('CF').maxSize.split('x')[0]);
  const freeFormatMaxSizeMax = Number(GetDoc('CF').maxSize.split('x')[1]);

  const [state, setState] = useState({
    product: productOptionKeys[0], // canvas, wood, poster, etc...
    productOptions: productOptionKeys,

    format: null, // port, land, square, etc...
    formatOptions: null,

    size: null, // = "20x20", "30x45", etc...
    sizeOptions: null,

    // free format
    freeWidth: 25,
    freeHeight: 25,
  });

  const previewURL = CanvasHelper.GetPreviewUrl(state.product, state.format);

  const getDocID = (optionalSize?: string): string | undefined => {
    const { product, format, size } = state;
    return CanvasHelper.GetCanvasDocID(product, format, optionalSize || size);
  };

  const docID = getDocID();

  // Each time the state change, we construct the new project options
  const newProjectOptions: ProjectOptions = useMemo(() => {
    let options = project ? GetProjectOptions(project) : undefined;
    if (docID) {
      options = {
        ...options,
        ...{
          docID,
          type: state.product,
          numPages: 1,
          // canvasFrameColor: state.kadapakColor,
          canvasFormat: state.format,
          height: cmToPixel(state.freeHeight),
          width: cmToPixel(state.freeWidth),
        },
      };
    }
    return options;
  }, [state, project, docID]);

  const projectPrice = useProjectPrice(newProjectOptions);

  // --------------------- methods ------------------------

  const updateState = (
    product?: CanvasProduct,
    format?: string,
    size?: string,
    freeformatWidth?: number,
    freeformatHeight?: number
  ) => {
    let currentProduct: CanvasProduct = product || state.product;
    let currentFormat: string = format || state.format;
    let currentSize: string = size || state.size;
    const currentFreeFormatWidth: number = freeformatWidth || state.freeWidth;
    const currentFreeFormatHeight: number =
      freeformatHeight || state.freeHeight;

    // verify product is valid
    const productOptions = Object.keys(catalogue.products) as CanvasProduct[];
    currentProduct = productOptions.includes(currentProduct)
      ? currentProduct
      : productOptions[0];

    // verify format options and current format
    const productNode = catalogue.products[currentProduct];
    const formatOptions: string[] = Object.keys(productNode);
    currentFormat = formatOptions.includes(currentFormat)
      ? currentFormat
      : formatOptions[0];

    // verify size options and current size
    const formatNode = productNode[currentFormat];
    const sizeOptions: string[] = formatNode.sizes;

    if (currentFormat === 'freeformat') currentSize = undefined;
    else
      currentSize = sizeOptions.includes(currentSize)
        ? currentSize
        : formatNode.default;

    const newState = {
      product: currentProduct,
      productOptions,
      format: currentFormat,
      formatOptions,
      size: currentSize,
      sizeOptions,
    };

    if (!isEqual(newState, state))
      setState({
        product: currentProduct,
        productOptions,
        format: currentFormat,
        formatOptions,
        size: currentSize,
        sizeOptions,

        freeWidth: currentFreeFormatWidth,
        freeHeight: currentFreeFormatHeight,
      });
  };

  const handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }
    // invoke callback if there is one
    onCloseCallback?.();
  };

  const handleProductChange = (product: CanvasProduct) => {
    updateState(product);
  };

  const handleFormatChange = (format: string) => {
    updateState(state.product, format);
  };

  const handleSizeChange = (newSize: string) => {
    updateState(undefined, undefined, newSize);
  };

  const handleFreeFormatChange = ({
    width,
    height,
  }: {
    width?: number;
    height?: number;
  }) => {
    updateState(undefined, undefined, undefined, width, height);
  };

  const handleConfirm = () => {
    // ----- CASE UPGRADE ------
    // case upgrade
    if (isUpgrade) {
      GetProjectOptions(project);
      dispatch(
        editionActions.UpgradeCurrentProject({
          type: state.product,
          docID,
          options: {
            // canvasFrameColor: state.kadapakColor,
            canvasFormat: state.format,
          },
          canvasFreeHeight: state.freeHeight,
          canvasFreeWidth: state.freeWidth,
        })
      );
      handleClose();
      return;
    }

    // ----- CASE NEW------
    // create new project
    const proj = CreateProject({
      classname: PROJECT_CONST.project_class,
      docID,
      type: state.product,
      projectName: 'new canvas project', // TODO:
      numPages: 1, // always 1 page for canvas
      layoutStore: layouts,
      // project options
      options: {
        // canvasFrameColor: state.kadapakColor,
        canvasFormat: state.format,
      },
      // canvas only creation params
      canvasFreeHeight: state.freeHeight,
      canvasFreeWidth: state.freeWidth,
    });

    // update project
    setProject(proj);
    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        proj.name = newProjectName;
        dispatch(editionActions.CreateProject(cloneDeep(proj)));
      },
      // cancel
      () => {}
    );
  };

  const getSizeLabel = (sizeFormat: string): string => {
    const sizeDocId = getDocID(sizeFormat);
    if (!sizeDocId) return '';
    return GetProjectDocSizeCM(sizeDocId);
  };

  // --------------------- effect ------------------------

  useEffect(() => {
    // --- Initialize for Project UPGRADE ---
    if (isUpgrade && existingProject && !project) {
      // case custom / free size
      if (existingProject.canvasFormat === CANVAS_FORMAT.FREE) {
        const customW = Math.round(pixelToCm(existingProject.width));
        const customH = Math.round(pixelToCm(existingProject.height));
        updateState(
          existingProject.type as CanvasProduct,
          existingProject.canvasFormat,
          undefined,
          customW,
          customH
        );
      }
      // case normal size
      else
        updateState(
          existingProject.type as CanvasProduct,
          existingProject.canvasFormat,
          existingProject.docID.split('_')[1]
        );
      setProject(existingProject);
    }

    // --- Initialize for new Project ---
    // else if(!isUpgrade && !existingProject && !project) {
    //   handleProductChange(state.productOptions[0]);
    // }
  }, [isUpgrade, existingProject, catalogue]);

  // effect each time catalogue change, we want to be sure to update possible options
  useEffect(() => {
    if (catalogue) {
      updateState();
    }
  }, [catalogue]);

  // --------------------- render ------------------------

  return (
    <Modal
      title={
        isUpgrade
          ? GetText('upgradeProject.title')
          : GetText('newProject.title')
      }
      open
      footer={null}
      className="projectCreationView"
      onCancel={handleClose}
    >
      <Spin spinning={projectsAreLoading}>
        {/* <h3>{GetText("newProject.canvas.desc")}</h3> */}

        {/** --- TYPES: canvas, wood, plexi --- */}
        <h4>{GetText('canvas.configurator.type.label')}</h4>
        <Select
          // defaultValue="lucy"
          value={state.product}
          placeholder="Select a Product"
          onChange={handleProductChange}
        >
          {state.productOptions.map((key) => (
            <Option key={key} value={key}>
              {GetText(`canvas.type.${key}_types`)}
            </Option>
          ))}
        </Select>

        <div className="spacerV" />

        {/** --- FORMAT: portrait, landscape, square, etc... --- */}
        <div className="labelAndSelect">
          <h4>{GetText('canvas.configurator.orientation.label')}</h4>
          <Select
            disabled={!state.format}
            value={state.format}
            onChange={handleFormatChange}
          >
            {state.formatOptions &&
              state.formatOptions.map((key) => (
                <Option key={key} value={key}>
                  {GetText(`canvas.orientation.types_canvas_${key}`)}
                </Option>
              ))}
          </Select>
        </div>

        <div className="spacerV" />

        {/** --- SIZES: 30x3O, 60x40, etc... --- */}
        <div className="labelAndSelect">
          <h4>{GetText('canvas.configurator.size.label')}</h4>

          {
            // normal size system
            state.format !== 'freeformat' && (
              <Select
                // defaultValue="lucy"
                disabled={!state.sizeOptions}
                value={state.size}
                onChange={handleSizeChange}
              >
                {state.sizeOptions &&
                  state.sizeOptions.map((sizeFormat) => (
                    <Option key={sizeFormat} value={sizeFormat}>
                      {getSizeLabel(sizeFormat)}
                    </Option>
                  ))}
              </Select>
            )
          }

          {
            // free size system
            state.format === 'freeformat' && (
              <div style={{ display: 'inline-block' }}>
                <InputNumber
                  value={state.freeWidth}
                  min={freeFormatMinSize}
                  max={freeFormatMaxSizeMin}
                  // size="small"
                  // formatter={value => `${value} cm`}
                  // parser={value => value.replace(' cm', '')}
                  step={1}
                  onChange={(val) => handleFreeFormatChange({ width: val })}
                />
                <span> cm * </span>
                <InputNumber
                  value={state.freeHeight}
                  // size="small"
                  min={freeFormatMinSize}
                  max={freeFormatMaxSizeMax}
                  // formatter={value => `${value} cm`}
                  // parser={value => value.replace(' cm', '')}
                  step={1}
                  onChange={(val) => handleFreeFormatChange({ height: val })}
                />
                <span> cm</span>
              </div>
            )
          }
        </div>

        <div className="spacerV" />

        {/** --- PREVIEW --- */}
        <Row className="preview">
          <img src={previewURL} />
        </Row>

        <Row justify="space-between">
          <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
            <Button type="link">
              {GetText('newProject.changeEditorClass.button')}
            </Button>
          </Link>

          <Button
            type="primary"
            disabled={projectPrice === 0}
            onClick={handleConfirm}
          >
            {GetText('common.continue')}
            {` (${projectPrice}€)`}
          </Button>
        </Row>
      </Spin>
    </Modal>
  );
};
