import { useSelector } from 'react-redux';
import { ProjectOptions } from '../../types/project';
import { pricingSelectors } from './pricing';

/**
 * retrieve project price based on explicit options or current project options.
 * @returns project price as number
 */
export const useProjectPrice = (explicitOptions?: ProjectOptions) => {
  const root = useSelector((state) => state);
  return pricingSelectors.GetProjectPrice(root, explicitOptions);
};
