import { cloneDeep } from 'lodash';
import { IsCanvasEditor } from '../../../data/config';
import { Project } from '../../../types/project';
import { Background, Frame, IPage, Photo } from '../../../types/types';
import { CanvasHelper } from '../../../utils/canvas/CanvasHelper';
import { notifyError } from '../../../utils/error/notifyError';
import { GetDoc } from '../../../utils/ProductHelper';
import { GetUID } from '../../../utils/UID';
import {
  getCoverBackgroundRect,
  getPageBackgroundRect,
  upgradeFrameBackground,
} from '../../backgrounds/backgroundHelper';
import { injectPhotoIntoFrame } from '../../frame/_helpers/injectPhotoIntoFrame';
import { FRAME_TYPE } from '../../frame/frame.types';
import { CleanAndVerifyFrameText } from '../../frame/frameHelper';
import { mergePages, splitMergedPage } from '../../layflat/layflatHelpers';
import { Layout } from '../layout.type';
import {
  cleanLayoutBeyondEdgeFrames,
  layoutCanBeAppliedToPage,
} from '../layoutHelper';

export const applyLayoutToPage = (
  currentProject: Project,
  page: IPage,
  layoutObj: Layout,
  photosByID: Record<string, Photo>,
  backgroundsByID: Record<string, Background>,
  mergedPageAreaToUse?: 'left' | 'right' | undefined
): IPage => {
  // copy layout to modify original one!
  if (!layoutCanBeAppliedToPage(layoutObj, page)) return;

  // reteive background bounds
  const backgroundBounds = page.isCover
    ? getCoverBackgroundRect(currentProject)
    : getPageBackgroundRect(currentProject); // later if we need merged pages with big background we might need to pass "double page layout" param here

  // if page is merged page, we need to apply layout to a part of the current page.
  // to do so, we need to split the page into 2 pages, apply layout to the first one and merge them back
  // then we can return the updated page
  if (page.merged) {
    let mergedPageArea = mergedPageAreaToUse;
    if (mergedPageArea === undefined) {
      notifyError(
        'applyLayoutToPage: mergedPageArea is undefined for a page that is merged'
      );
      mergedPageArea = 'left';
    }

    const [pageLeft, pageRight] = splitMergedPage(page);
    const pageToUpdate = mergedPageArea === 'left' ? pageLeft : pageRight;
    const updatedPage = applyLayoutToPage(
      currentProject,
      pageToUpdate,
      cleanLayoutBeyondEdgeFrames(layoutObj),
      photosByID,
      backgroundsByID
    );
    const newPage =
      mergedPageArea === 'left'
        ? mergePages(updatedPage, pageRight)
        : mergePages(pageLeft, updatedPage);
    page.frames = newPage.frames;
    return page;
  }

  const newLayout: Layout = cloneDeep(layoutObj);
  const { docID } = currentProject;
  const doc = GetDoc(docID);

  // first lets keep all images of this page into an array
  const photosInPage = [];
  let spineInPage = null;
  let spineNumInPage = null;
  const textsInPage = [];
  page.frames.forEach((frame, i) => {
    // Check if we have photos, if so we keep those to add it later
    if (i !== 0 && frame.photo && frame.photo !== null && frame.photo !== '')
      photosInPage.push(frame.photo);

    // keep spine content in memory
    if (frame.type === FRAME_TYPE.SPINE) {
      spineInPage = frame;
    } else if (frame.type === FRAME_TYPE.SPINE_NUM) {
      spineNumInPage = frame;
    } else if (frame.text && frame.type === FRAME_TYPE.TEXT) {
      textsInPage.push(frame);
    }
  });

  // save background detail
  const oldBackground = page.frames[0];

  // save text content also

  // TODO: handle here photos in background to put back in backgrounds... with type:bkg instead of type:picture

  // then change the page layout
  const layoutScaleX = page.width / newLayout.width;
  const layoutScaleY = page.height / newLayout.height;
  page.frames = [];

  // keep trace of layout ID
  page.layoutID = newLayout.id;

  let nextPhotoIndex = 0;
  newLayout.frames.forEach((frame, i) => {
    const newFrame: Frame = { ...frame };
    newFrame.height *=
      newFrame.rotation === 90 || newFrame.rotation === -90
        ? layoutScaleX
        : layoutScaleY;
    newFrame.width *=
      newFrame.rotation === 90 || newFrame.rotation === -90
        ? layoutScaleY
        : layoutScaleX;
    newFrame.x *= layoutScaleX;
    newFrame.y *= layoutScaleY;

    // add a new ID
    // TODO: we should maybe have an object to dupplicate with basic frame caracteristics?
    newFrame.id = GetUID();
    newFrame.cLeft = 0;
    newFrame.cTop = 0;
    newFrame.zoom = 1;

    // --- HANDLE BACKGROUND
    if (i === 0 && newFrame.type !== FRAME_TYPE.POSTCARD_BG) {
      // update background bounds
      upgradeFrameBackground(newFrame, backgroundBounds, backgroundsByID);

      // try to apply previous background
      if (oldBackground) {
        // keep fill
        newFrame.fillColor = oldBackground.fillColor;

        // background
        if (oldBackground.background) {
          injectPhotoIntoFrame(
            newFrame,
            backgroundsByID[oldBackground.background] as any
          );
          newFrame.background = oldBackground.background;
          newFrame.photo = null;
        }

        // apply previous background photo (and keep zoom and crop values if possible)
        else if (oldBackground.photo) {
          newFrame.zoom = oldBackground.zoom;
          newFrame.cLeft = oldBackground.cLeft;
          newFrame.cTop = oldBackground.cTop;
          injectPhotoIntoFrame(newFrame, photosByID[oldBackground.photo], true);
        }
      }
    }

    // update photos frame
    if (
      newFrame.type === FRAME_TYPE.PHOTO &&
      nextPhotoIndex < photosInPage.length
    ) {
      const photoObj = photosByID[photosInPage[nextPhotoIndex]];
      if (photoObj) injectPhotoIntoFrame(newFrame, photoObj);
      else newFrame.photo = photoObj.id; // if photo obj do not exist, it means that the frame might have an error TODO:

      nextPhotoIndex += 1;
    }

    // if spine, we add content back
    if (newFrame.type === FRAME_TYPE.SPINE && spineInPage) {
      newFrame.text = spineInPage.text;
      CleanAndVerifyFrameText(newFrame);
    }
    if (newFrame.type === FRAME_TYPE.SPINE_NUM && spineNumInPage) {
      newFrame.text = spineNumInPage.text;
      CleanAndVerifyFrameText(newFrame);
    }
    if (newFrame.type === FRAME_TYPE.TEXT && textsInPage.length > 0) {
      newFrame.text = textsInPage.shift().text;
      CleanAndVerifyFrameText(newFrame.text);
    }

    // ---- CALENDAR FRAME ----
    if (newFrame.type === FRAME_TYPE.CALENDAR) {
      // handle scaling of calendar frames (mainly for text!)
      if (doc.layoutTextScaling)
        newFrame.calendarOptions.fontSize = Math.round(
          newFrame.calendarOptions.fontSize * doc.layoutTextScaling
        );

      // set page as calendar page
      page.isCalendar = true;
    }

    // ---- CASE CANVAS, be sure to have correct frame size with edge and cut border enough offset ----
    if (IsCanvasEditor()) {
      CanvasHelper.CheckModifyFrameForEdge(
        currentProject.type,
        page.width,
        page.height,
        newFrame
      );
    }

    // save layout type during apply
    page.layoutType = newLayout.type;

    // add to page
    page.frames.push(newFrame);
  });

  // return updated page
  return page;
};
