import {
  DragEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CirclePicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import {
  BgColorsOutlined,
  BorderOutlined,
  DeleteOutlined,
  DesktopOutlined,
  EditOutlined,
  InfoOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { captureException, captureMessage } from '@sentry/browser';
import { Button, Popover, Radio, Slider, Switch, Tooltip } from 'antd';
import { cloneDeep, isObject } from 'lodash';
import { ImageEditor } from '../../_components/ImageEditor';
import ImgIcon from '../../_components/ImgIcon';
import { PhotoManager } from '../../_components/PhotoManager/PhotoManager';
import {
  CANVAS_TYPES,
  CanvasEdge,
} from '../../data/catalogue/canvasCatalogue.types';
import { Colors } from '../../data/Colors';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../../data/config';
import { GetText } from '../../data/LanguageHelper';
import { DebugFlags, IsLocalhost } from '../../debug/DebugFlags';
import { popupHelper } from '../../feature/alert/popupHelper';
import { backgroundSelectors } from '../../feature/backgrounds/background.store';
import { applyBackgroundToPage } from '../../feature/backgrounds/backgroundHelper';
import { AddClipartToPage } from '../../feature/cliparts/clipartHelper';
import { editionActions } from '../../feature/edition/edition';
import { editionSelectors } from '../../feature/edition/edition.selector';
import { getSnappingLines } from '../../feature/edition/getSnappingLines';
import { injectPhotoIntoFrame } from '../../feature/frame/_helpers/injectPhotoIntoFrame';
import { isFrameEmpty } from '../../feature/frame/_helpers/isFrameEmpty';
import { isFrameOutsidePageBounds } from '../../feature/frame/_helpers/isFrameOutsidePage';
import {
  defaultTextOptions,
  FRAME_TYPE,
} from '../../feature/frame/frame.types';
import {
  ClearFrame,
  frameCanBeCleared,
  frameCanBeDeleted,
  frameCanChangeDepth,
  frameCanHaveBorder,
  frameCanHaveMask,
  frameCanHavePhoto,
  frameCanHaveShadow,
  frameCanHaveText,
  frameCanInsideRotation,
  frameCanMove,
  frameCanPhotoEdit,
  frameCanSwapDrop,
  frameCanSwapStart,
  frameHasDeleteButton,
  getFrameMinZoom,
} from '../../feature/frame/frameHelper';
import { getDefaultFrameShadow } from '../../feature/frame/shadow/shadowHelper';
import { applyLayoutToPage } from '../../feature/layouts/helpers/applyLayoutToPage';
import { layoutListSelectors } from '../../feature/layouts/layout.store';
import { Layout } from '../../feature/layouts/layout.type';
import { OverlayerHelper } from '../../feature/overlayers/overlayerHelper';
import { photoListSelector } from '../../feature/photoList/photo.selector';
import { photoListActions } from '../../feature/photoList/photo.store';
import { UIActions, UISelectors } from '../../feature/ui/ui';
import { useAssetsSelector } from '../../hooks/useAssetsSelector';
import { usePageEditing } from '../../hooks/usePageEditing';
import { useTempPhotoReplace } from '../../hooks/useTempPhotoReplace';
import { EmbedIcons } from '../../images/EmbedIcons';
import { getPhotoCategoriesSelector } from '../../store/customSelectors/selectors';
import {
  Frame,
  IFrameShadow,
  InsidePage,
  IPage,
  Photo,
} from '../../types/types';
import { moveArrayItemIndex } from '../../utils/ArrayUtils';
import { CanvasHelper } from '../../utils/canvas/CanvasHelper';
import { DateUtils } from '../../utils/DateUtils';
import { DRAG_TYPE, GetDragData } from '../../utils/DragUtils';
import { mmToPoint, pixelToCm } from '../../utils/MeasureUtils';
import {
  GetPageFrameByID,
  getPageGroup,
  getPageGroupDisplayName,
  getPageOfFrame,
} from '../../utils/pageHelper';
import { GetDoc } from '../../utils/ProductHelper';
import { InnerPageShadow } from './EditionArea/InnerPageShadow';
import { InsidePageArea } from './EditionArea/InsidePageArea';
import { PageArea } from './EditionArea/PageArea';
import { SnappingLines } from './EditionArea/SnappingLines';
import { MainEditionToolbar } from './EditionArea/toolbars/MainEditionToolbar';
import { TextEditionToolbar } from './EditionArea/toolbars/TextEditionToolbar';
import TransformTool, {
  TRANFORM_TOOL_ACTION,
  TransformToolRef,
} from './EditionArea/TransformTool';

export const EditionArea = () => {
  // REDUX
  const dispatch = useDispatch();
  const project = useSelector(editionSelectors.GetProjectSelector);
  const selectedPageIndex = useSelector(editionSelectors.GetSelectedPageIndex);
  const currentPage = useSelector(editionSelectors.GetSelectedPage);
  const selectedFrameID = useSelector(editionSelectors.GetSelectedFrameID);
  const photoManagerOpen = useSelector(UISelectors.photoManagerOpen);
  const allCategories = useSelector(getPhotoCategoriesSelector);

  // photo and background map
  // TODO: later try to remove this and use directly the one from assetSelector
  const photosByID = useSelector(photoListSelector.getAllPhotosByID);
  const backgroundsByID = useSelector(
    backgroundSelectors.getAllBackgroundsByID
  );

  const { pageList } = project;
  const {
    getPhotosById,
    getBackgroundsById,
    getClipartsById,
    getOverlayersById,
  } = useAssetsSelector();

  const needSave = useSelector(editionSelectors.NeedSave);
  const hasPhotosImporting = useSelector(
    photoListSelector.hasPhotosStillImporting
  );
  const layoutsByID = useSelector(layoutListSelectors.getLayoutsByID);

  const gridMode = useSelector(UISelectors.GetCurrentGridMode);
  const { checkReplaceTempPhotoID } = useTempPhotoReplace();

  // selectors

  const isPreviewMode = useSelector(UISelectors.IsPreviewMode);

  // const
  const tooltipDelay = 1;

  // ui state
  const [rootX, setRootX] = useState<number>(null);
  const [rootY, setRootY] = useState<number>(null);
  const [transformBounds, setTransformBounds] = useState(null);
  const [editionScale, setEditionScale] = useState(1);
  const [editedPhoto, setEditedPhoto] = useState(null);
  const [previousSelectedPageIndex, setPreviousSelectedPageIndex] =
    useState(null);
  const [swapFrameID, setSwapFrameID] = useState(null);

  // create refs
  const editionAreaRef = useRef<HTMLDivElement>();
  const pageContainerRef = useRef<SVGSVGElement>();
  const textInputRef = useRef<HTMLTextAreaElement>();
  const stageAreaRef = useRef<HTMLDivElement>();

  //const transformToolRef = createRef<HTMLDivElement>();
  const transformToolRef = useRef<TransformToolRef>(null);

  const { docID, type, canvasFrameColor } = project;

  const pageGroup = pageList
    ? getPageGroup(docID, selectedPageIndex, pageList)
    : [];

  // TODO: note this is dangerous, it seems it change selectedFrame on right click during an action
  const selectedFrame = useMemo(() => {
    if (selectedFrameID && currentPage) {
      console.log('changing selected frame');
      return cloneDeep(GetPageFrameByID(currentPage, selectedFrameID));
    }
    return null;
  }, [selectedFrameID, currentPage]);

  const pageEdit = usePageEditing();
  const { page: editedPage, action: editAction } = pageEdit;

  // editedFrame is the frame in the current page ID which correspond to selectedFrameID
  const editedFrame = editedPage
    ? GetPageFrameByID(editedPage, selectedFrameID)
    : selectedFrame;

  // this is a trick, if there is an "editedPage" it means we are currenctly editing (moving, scaling, etc..)
  const isEditing = !!editedPage;

  const doc = GetDoc(docID);

  // ALBUM inside pages
  // if the page group is only one page size and is not the cover, we need to display inside pages
  if (
    IsAlbumEditor() &&
    pageGroup.length === 1 &&
    !pageGroup[0].isCover &&
    !pageGroup[0].merged
  ) {
    const insidePage: InsidePage = {
      isInsidePage: true,
      width: pageGroup[0].width,
      height: pageGroup[0].height,
    };
    if (pageGroup[0].index === 1) {
      pageGroup.unshift(insidePage as IPage);
    } // first page
    else {
      pageGroup.push(insidePage as IPage);
    } // end page
  }

  // TODO: spacing could be in "doc" so easier to customize...
  const pageSpacing = useMemo(() => {
    if (IsAlbumEditor()) return 0;
    if (IsCalendarEditor()) return 0;
    if (IsCardEditor()) return doc.pages_per_group > 2 ? 0 : 10;
    // cards with insidepages (>2) don't have spaces between insidepages
    else return 0;
  }, [doc]);

  // group width&height
  let pagesWidth = 0;
  let pagesHeight = 0;
  if (pageGroup && pageGroup.length > 0) {
    if (doc.pages_group_orientation === 'vertical') {
      pageGroup.forEach((page) => {
        pagesHeight += page.height;
      });
      pagesWidth = pageGroup[0].width;
    } else {
      pageGroup.forEach((page) => {
        pagesWidth += page.width;
      });
      pagesHeight = pageGroup[0].height;
    }
  }

  // const pageIndex = pageGroup.length > 0 ? pageGroup[0].index : 0;

  let outsideMargin = pagesWidth * 0.05; // 10% margin

  // in case of canvas editor, we need to add the "edge size" to the margin
  const canvasEdge: CanvasEdge = {
    size: 0,
    isKadapak: false,
    style: {},
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  };
  if (IsCanvasEditor()) {
    canvasEdge.size = mmToPoint(CanvasHelper.GetEdgeSizeMM(type));
    outsideMargin += canvasEdge.size;
    canvasEdge.isKadapak = type === CANVAS_TYPES.KADAPAK;
    canvasEdge.style = {
      strokeWidth: canvasEdge.size,
      stroke: canvasEdge.isKadapak
        ? CanvasHelper.GetFrameColorByID(canvasFrameColor)
        : null,
      strokeOpacity: canvasEdge.isKadapak ? 1 : 0.5,
    };
    const canvasPage: IPage = pageGroup[0];
    canvasEdge.left = -canvasEdge.size / 2;
    canvasEdge.top = -canvasEdge.size / 2;
    canvasEdge.right = canvasPage.width + canvasEdge.size / 2;
    canvasEdge.bottom = canvasPage.height + canvasEdge.size / 2;
  }
  const isClassicCover = pageGroup && pageGroup[0].coverClassicOptions;

  // TODO: should be in a "toolbar" component
  let editedFrameMinZoom = 1;
  let editedFrameMaxZoom = 1.5;
  let editedFramePhotoObj: Photo = null;
  if (editedFrame && editedFrame.photo) {
    editedFramePhotoObj = getPhotosById(editedFrame.photo);
    editedFrameMinZoom = getFrameMinZoom(editedFrame, editedFramePhotoObj);
    // max zoom is a fixed number as dpi is fixed for images... So we try to keep it at 1.5 if possible
    editedFrameMaxZoom = editedFrame.zoom > 1.5 ? editedFrame.zoom : 1.5;
  }

  let toolbarPosX = 0;
  let toolbarPosY = 0;
  if (transformBounds) {
    const marginY = 15;
    const limitY = window.innerHeight - 230;
    toolbarPosY =
      transformBounds.bottom + marginY > limitY
        ? transformBounds.top - marginY - 70
        : transformBounds.bottom + marginY;

    const toolbarWidth = 600;
    const limitX = window.innerWidth - toolbarWidth * 0.5;
    toolbarPosX = transformBounds.right - transformBounds.width * 0.5;
    if (toolbarPosX > limitX) toolbarPosX = limitX;
  }

  const frameShadow: IFrameShadow = cloneDeep(
    editedFrame && isObject(editedFrame.shadow)
      ? editedFrame.shadow
      : getDefaultFrameShadow()
  );

  const snappingLines = getSnappingLines(pageGroup, editedPage, editedFrame);

  // ---------------------------------- Methods -------------------------------------

  const handleCheckLeaveWarning = useCallback(
    (e) => {
      if (needSave || hasPhotosImporting) {
        popupHelper.ShowLeaveWarningPopup(hasPhotosImporting);

        const confirmationMessage = hasPhotosImporting
          ? 'Photos still importing, do not leave!'
          : 'Please save your project before leaving!';
        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
      }
      return null;
    },
    [needSave, hasPhotosImporting]
  );

  const updateEditorScaleFactor = useCallback(() => {
    // update edition scale!
    if (pageContainerRef) {
      const pageDomElement = document.getElementById(
        `page_${selectedPageIndex}`
      );

      if (!pageDomElement) {
        captureMessage("no pageDomElement, can't update scale factor");
        return;
      }

      const pageBounds = pageDomElement.getBoundingClientRect();
      const scaleFactor = pageBounds.width / pageList[selectedPageIndex].width;

      if (
        scaleFactor !== editionScale ||
        pageBounds.x !== rootX ||
        pageBounds.y !== rootY
      ) {
        setEditionScale(scaleFactor);
        setRootX(pageBounds.x);
        setRootY(pageBounds.y);
      }
    }
  }, [
    selectedPageIndex,
    pageContainerRef,
    editionScale,
    pageList,
    rootX,
    rootY,
  ]);

  const handleEditionResize = useCallback(() => {
    updateEditorScaleFactor();
  }, [updateEditorScaleFactor]);

  function getInfoOverContent(frame: Frame) {
    const photoItem: Photo = frame.photo ? getPhotosById(frame.photo) : null;
    let imgURL = photoItem?.working_url;
    if (photoItem?.temp) imgURL = photoItem?.temp_url;

    const w = 350;
    return (
      <div
        style={{
          width: w,
          maxWidth: w,
          wordWrap: 'break-word',
          fontSize: 12,
        }}
      >
        {photoItem && (
          <div>
            <img
              width={w}
              height={(w * photoItem.height) / photoItem.width}
              src={imgURL}
              alt="thumbnail overlay"
              style={{ marginBottom: 6 }}
            />

            <div>{`${GetText('tooltip.image.info.name')} : ${
              photoItem.name
            }`}</div>
            <div>{`${GetText('tooltip.image.info.size')} : ${photoItem.width}x${
              photoItem.height
            }px`}</div>
            <div>{`${GetText(
              'tooltip.photo.info.creationDate'
            )} : ${DateUtils.ToReadableDate(
              new Date(Number(photoItem.creation_date))
            )}`}</div>
          </div>
        )}
        {IsLocalhost && <div>{JSON.stringify(frame)}</div>}
        {IsLocalhost && photoItem && <div>{JSON.stringify(photoItem)}</div>}
      </div>
    );
  }

  const handleAddDefaultTextToFrame = () => {
    pageEdit.start();
    pageEdit.modifyFrame((frame) => {
      frame.text = { ...defaultTextOptions };
      return frame;
    });
    pageEdit.end();
  };

  function handleFrameInsideRotate() {
    pageEdit.start();
    pageEdit.modifyFrame((frameCopy) => {
      // check for temp photo
      checkCorrectEditedTempPhoto(frameCopy);

      // invert width and height
      const tempW = frameCopy.width;
      // const tempC = frameCopy.cLeft;
      frameCopy.width = frameCopy.height;
      frameCopy.height = tempW;

      // update rotation
      frameCopy.rotation += 90;
      if (frameCopy.rotation > 360) {
        frameCopy.rotation -= 360;
      }
      // update image
      injectPhotoIntoFrame(frameCopy, getPhotosById(frameCopy.photo), true);
      return frameCopy;
    });
    pageEdit.end();
  }

  function handleFrameMoveUp() {
    moveEditedFrameIndex(1);
  }

  function handleFrameMoveDown() {
    moveEditedFrameIndex(-1);
  }

  function moveEditedFrameIndex(moveAdd: -1 | 1) {
    pageEdit.start();
    pageEdit.modifyPage((pageCopy, frameCopy) => {
      let index = -1;
      // find index
      pageCopy.frames.forEach((f, i) => {
        if (f.id === frameCopy.id) {
          index = i;
        }
      });

      // TODO: check security here, this should never happen!
      if (index === -1) {
        captureException(
          new Error(`FRAME with ID ${frameCopy.id} not found in edited page`)
        );
      } else {
        const newIndex = index + moveAdd;
        const minIndex = pageCopy.merged ? 2 : 1; // 2 backgrounds for merged pages. TODO: if later we have only one background for merged pages we need to review this
        const maxIndex = pageCopy.frames.length - 1;

        // do not allow to go below index 1 (as background is index 0)
        if (newIndex >= minIndex && newIndex <= maxIndex) {
          moveArrayItemIndex(pageCopy.frames, index, newIndex);
        }
      }
      return pageCopy;
    });
    pageEdit.end();
  }

  // --------------------- Frame Zoom ------------------------

  function handleFrameZoomChange(newZoomValue: number) {
    // if we are not editing the page currently, start editing
    if (!editedPage) {
      pageEdit.start(TRANFORM_TOOL_ACTION.ZOOM);
    }

    // change zoom only if it's in the limits
    if (
      newZoomValue < editedFrameMaxZoom &&
      newZoomValue > editedFrameMinZoom
    ) {
      pageEdit.modifyFrame((frame) => {
        checkCorrectEditedTempPhoto(frame);
        const pic: Photo = getPhotosById(frame.photo);
        if (!pic || !pic.width) {
          // no picture, no zoom
          captureException(new Error('No picture to zoom'));
          return;
        }
        const diffX = pic.width * newZoomValue - pic.width * frame.zoom;
        const diffY = pic.height * newZoomValue - pic.height * frame.zoom;
        frame.zoom = newZoomValue;
        frame.cLeft += diffX / 2;
        frame.cTop += diffY / 2;

        // update photo crop values and position (manage limits of zoom/cleft/ctop)
        injectPhotoIntoFrame(frame, pic, true);
        return frame;
      });
    }
  }

  function handleFrameZoomEnd(zoomValue) {
    console.log('handleFrameZoomEnd', zoomValue);
    handleFrameZoomChange(zoomValue);
    pageEdit.end();
  }

  function zoomTooltipFormatter(value) {
    return `Zoom: ${(value * 100).toFixed(1)}%`;
  }

  // --------------------- Frame Delete ------------------------

  function handleFrameDelete() {
    pageEdit.start();
    if (selectedFrameID) {
      // first we try to clear it
      if (frameCanBeCleared(editedFrame)) {
        pageEdit.modifyFrame((frameCopy) => {
          ClearFrame(frameCopy);
          return frameCopy;
        });
      }
      // if already empty, we delete it
      else if (frameCanBeDeleted(editedFrame)) {
        pageEdit.modifyPage((pageCopy) => {
          pageCopy.frames = pageCopy.frames.filter(
            (val) => val.id !== selectedFrameID
          );
          return pageCopy;
        });
      }
    }
    pageEdit.end();
  }

  /**
   * Page navigation
   */
  function handleNextPageClick() {
    const currentPageGroup: Array<IPage> = getPageGroup(
      docID,
      selectedPageIndex,
      pageList
    );

    const lastPageIndex = currentPageGroup[currentPageGroup.length - 1].index;
    let newPage = lastPageIndex + 1;
    // let newPage = (selectedPageIndex%2===0)? selectedPageIndex+2 : selectedPageIndex +1;
    const limit = pageList.length - 1;
    if (newPage > limit) newPage = limit;
    goTopage(newPage);
  }

  function handlePreviousPageClick() {
    let newPage =
      selectedPageIndex % 2 === 0
        ? selectedPageIndex - 1
        : selectedPageIndex - 2;
    if (newPage < 0) newPage = 0;
    goTopage(newPage);
  }

  function handleFirstPageClick() {
    goTopage(0);
  }

  function handleLastPageClick() {
    goTopage(pageList.length - 1);
  }

  function goTopage(newPage) {
    dispatch(editionActions.ChangePage(newPage));
  }

  /** handle edited frame double click */
  function handleEditedFrameDoubleClick() {
    // case frame photo, if empty, start import

    // case frame test, focus on edited frame text
    // THIS IS Bad, but I need to make a generic way of uploading outside the view...
    // const { editedFrame } = state;

    if (editedFrame) {
      // photo
      if (
        (editedFrame.type === FRAME_TYPE.PHOTO ||
          editedFrame.type === FRAME_TYPE.OVERLAYER) &&
        isFrameEmpty(editedFrame)
      ) {
        const uploader = document.getElementById(`${editedFrame.id}_uploader`);
        if (uploader) {
          uploader.click();
        }
      }
      // texst
      else if (editedFrame && editedFrame.type === FRAME_TYPE.TEXT) {
        const toolbarTextEditButton = document.getElementById(
          'toolbarTextEditButton'
        );
        if (toolbarTextEditButton) {
          toolbarTextEditButton.click();
        }
      }
    }
  }

  /**
   * FRAME edition
   */
  function handleEditedFrameStart(_newFrameState: Frame, action = null) {
    pageEdit.start(action);
  }

  /**
   *
   * @param {Fame} newFrameState
   * We receive the updated frame object from the transforml tool
   * -> we update the edited page and frame from the state
   * -> once the edition is over, we push to redux store and remove edited page so we render only from props.
   */
  const handleEditedFrameUpdate = (
    newFrameState: Frame,
    refreshContent = false
  ) => {
    if (editedFrame) {
      pageEdit.modifyFrame((_frame) => {
        // refresh content inside the frame (for example on zoom, scale, crop etc..)
        // when changing scaling, if there is an image, we need to update image too!
        if (refreshContent && newFrameState.photo) {
          // retreive photo object
          checkCorrectEditedTempPhoto(newFrameState);
          const photoObj = getPhotosById(newFrameState.photo);
          injectPhotoIntoFrame(newFrameState, photoObj, true);
        }
        return newFrameState;
      });
    }
  };

  /**
   * Handle frame update complete from the transforml tool (on modification complete)
   */
  const handleEditedFrameUpdateComplete = (f: Frame) => {
    // special case when frame is moved outside page bounds
    if (pageEdit.action === TRANFORM_TOOL_ACTION.MOVE) {
      const checkResult = checkMoveFrameToAnotherPage(f, pageEdit.page);
      if (checkResult === 'moved' || checkResult === 'deleted') {
        pageEdit.reset(); // we don't' continue classic page editing, a custom action has been done
        return;
      }
    }
    pageEdit.end();
  };

  /* 
    this verify with currently edited frame photo that we correctly have a photo object
    check if edited frame is still in page bounds! 
    if not, nor we move to another page, nor we simply delete the frame
   */
  const checkMoveFrameToAnotherPage = (
    f: Frame,
    p: IPage
  ): 'none' | 'moved' | 'deleted' => {
    if (isFrameOutsidePageBounds(f, p)) {
      // a frame can be moved if:
      // - there are multiple page on the page group
      // - the frame is still on the height bounds
      const canBeMoved = pageGroup.length > 1 && f.y > 0 && f.y < p.height;

      // case moving to the page at the right
      if (canBeMoved && p.index === pageGroup[0].index && f.x > p.width) {
        console.log('move frame to page on the right');
        dispatch(editionActions.moveFrameToAnotherPage(f, p, pageGroup[1]));
        return 'moved';
      }
      // case moving to the page at the left
      else if (canBeMoved && p.index === pageGroup[1].index && f.x < 0) {
        console.log('move frame to page on the left');
        dispatch(editionActions.moveFrameToAnotherPage(f, p, pageGroup[0]));
        return 'moved';
      }
      // otherwise we delete the frame
      else {
        dispatch(editionActions.deleteFrame(f.id));
        return 'deleted';
      }
    }
    return 'none';
  };

  const checkCorrectEditedTempPhoto = (frame: Frame) => {
    if (frame.photo) frame.photo = checkReplaceTempPhotoID(frame.photo);
  };

  function handleItemDroppedOnTransformTool(evt) {
    handleItemDropOnFrame(evt, editedFrame);
  }

  /**
   * Handle item drop on frame
   */
  const handleItemDropOnFrame = (evt: DragEvent, frame: Frame) => {
    // the frame could be on another page as the current one (drag & drop on another page)
    const page = getPageOfFrame(pageList, frame.id);
    const pageCopy = cloneDeep(page);
    const pageIndex = page.index;

    // if we didn't drop on the selected page, just change it
    if (selectedPageIndex !== pageIndex)
      dispatch(editionActions.ChangePage(pageCopy.index));

    // try to get exact position on page
    const pageDomElement = document.getElementById(`page_${pageIndex}`);
    let pageBounds = null;
    const dropPoint = {
      x: pageCopy.width / 2,
      y: pageCopy.height / 2,
    };
    if (pageDomElement) {
      pageBounds = pageDomElement.getBoundingClientRect();
      dropPoint.x =
        (evt.clientX - pageBounds.left) * (pageCopy.width / pageBounds.width);
      dropPoint.y =
        (evt.clientY - pageBounds.top) * (pageCopy.height / pageBounds.height);
    }

    // check for type of drop
    let photoID = GetDragData(evt, DRAG_TYPE.PHOTO);
    const clipartID = GetDragData(evt, DRAG_TYPE.CLIPART);
    const backgroundID = GetDragData(evt, DRAG_TYPE.BACKGROUND);
    const layoutID = GetDragData(evt, DRAG_TYPE.LAYOUT);
    const overlayerID = GetDragData(evt, DRAG_TYPE.OVERLAYER);

    // ------- case photo
    if (photoID && frameCanHavePhoto(frame)) {
      photoID = checkReplaceTempPhotoID(photoID);
      const frameCopy = cloneDeep(frame);
      injectPhotoIntoFrame(frameCopy, getPhotosById(photoID));
      dispatch(editionActions.UpdateFrame(frameCopy));
      dispatch(editionActions.AddUndoableAction());
    }

    // ------- case layout
    else if (layoutID) {
      const layout: Layout = layoutsByID[layoutID];
      // sepcial case if page is merged, we need to apply to correct part of the screen!
      let mergedPageAreaToUse: 'left' | 'right' = 'left';
      if (pageCopy.merged) {
        mergedPageAreaToUse = frame.x < pageCopy.width / 2 ? 'left' : 'right';
      }
      applyLayoutToPage(
        project,
        pageCopy,
        layout,
        photosByID,
        backgroundsByID,
        mergedPageAreaToUse
      );
      dispatch(editionActions.UpdatePage(pageCopy));
      dispatch(editionActions.AddUndoableAction());
    }

    // ------- case background
    else if (backgroundID) {
      // sepcial case if page is merged, we need to apply to correct part of the screen!
      let mergedPageAreaToUse: 'left' | 'right' = 'left';
      if (pageCopy.merged) {
        mergedPageAreaToUse = frame.x < pageCopy.width / 2 ? 'left' : 'right';
      }
      applyBackgroundToPage(
        pageCopy,
        getBackgroundsById(backgroundID),
        mergedPageAreaToUse
      );
      dispatch(editionActions.UpdatePage(pageCopy));
      dispatch(editionActions.AddUndoableAction());
    }

    // ------- case clipart
    else if (clipartID) {
      AddClipartToPage(pageCopy, getClipartsById(clipartID), dropPoint);
      dispatch(editionActions.UpdatePage(pageCopy));
      dispatch(editionActions.AddUndoableAction());
    }

    // ------- case Overlayer
    else if (overlayerID) {
      // case drop overlayer on frame photo
      if (
        (frame.type === FRAME_TYPE.PHOTO ||
          frame.type === FRAME_TYPE.OVERLAYER) &&
        frame.photo
      ) {
        const frameCopy = cloneDeep(frame);
        const pic = checkReplaceTempPhotoID(frame.photo);
        OverlayerHelper.AddOverlayerToFrame(
          frameCopy,
          getOverlayersById(overlayerID),
          getPhotosById(pic)
        );

        dispatch(editionActions.UpdateFrame(frameCopy));
        dispatch(editionActions.AddUndoableAction());
      }
      // otherwise add new overlayer to page!
      else {
        OverlayerHelper.AddOverlayerToPage(
          pageCopy,
          getOverlayersById(overlayerID),
          dropPoint
        );
        dispatch(editionActions.UpdatePage(pageCopy));
        dispatch(editionActions.AddUndoableAction());
      }
    }
  };

  function handleFrameMouseDown(pageIndex: number, frameIndex: number, event) {
    // recover frame
    const frame = pageList[pageIndex].frames[frameIndex];

    // case already selected, activate move on transform tool
    if (editedFrame && editedFrame.id === frame.id && frameCanMove(frame)) {
      /////////////////////////////////////////////////////////////////
      // [hack] no better way for now...
      // actually, we cannot do this inside the Transform tool because it will block all actions on frames (buttons to upload, add texts etc..)
      // So we need the frame or the edition areat to "trigger" the start move from the transform tool
      transformToolRef.current.handleMoveStart(event);
      /////////////////////////////////////////////////////////////////
    } else {
      handleFrameSelect(pageIndex, frameIndex);
    }
  }

  function handleFrameDoubleClick(frame: Frame) {
    // case already selected, handle edited frame douhle click
    if (editedFrame && editedFrame.id === frame.id) {
      handleEditedFrameDoubleClick();
    }
  }

  /**
   * Handle frame select
   * - update selected frame
   * - update current page
   * - update pagePosX (to correctly positionate the transform tool)
   */
  function handleFrameSelect(newPageIndex, newFrameIndex) {
    const newSelectedFrame = pageList[newPageIndex].frames[newFrameIndex];

    // be sure to update the selected page on the store if frame is not on current page
    if (newPageIndex !== selectedPageIndex) {
      dispatch(editionActions.ChangePage(newPageIndex));
    }

    // update global selected FrameID
    dispatch(editionActions.ChangeSelectedFrame(newSelectedFrame.id));

    // TODO: this has been commented on 4th july 2024, we now retrieve the selectedFrame based on selectedFrameId
    // setPageEditState({ editedFrame: cloneDeep(selectedFrame) });
  }

  function handlePhotoImportIntoFrame(tempPhoto: Photo, frame: Frame) {
    dispatch(photoListActions.importPhotos(tempPhoto, frame));
    // TODO: check undoable here as the photo might still be "temp" during uploading process...
  }

  /**
   * This is a helper to modify the current edited frame
   * it will start a page edit, modify the frame with the modifier functino, then end the pageEdit
   * This will also create automatically an undoable action
   */
  const modifySelectedFrame = (
    frameModifier: (frame: Frame) => void,
    endEditionAfterModification: boolean = true
  ) => {
    pageEdit.start();
    pageEdit.modifyFrame((frame) => {
      frameModifier(frame);
      return frame;
    });
    if (endEditionAfterModification) pageEdit.end();
  };

  // ---- HANDLE FRAME SIZE LIMIT ----
  const handleFrameTextLimits = (
    frameID: string,
    { width, height }: { width?: number; height?: number }
  ) => {
    // CASE we have an edited frame, and the edited frame is the one we are trying to update, it means we already are in a pageEdit process.
    if (editedFrame && editedFrame.id === frameID) {
      if (width) {
        editedFrame.width = width;
      }
      if (height) {
        editedFrame.height = height;
      }
    }

    // CASE the current selected frame is the frame ID, we just start and editing process
    else if (!editedFrame && selectedFrameID === frameID) {
      modifySelectedFrame((frame) => {
        if (width) {
          frame.width = width;
        }
        if (height) {
          frame.height = height;
        }
      });
    }

    // // otherwise, it is another frame that must be updated!
    // else {
    //   // we never update a frame directly, but the current page
    //   // makes sure we are not in the editing of a page/frame
    //   console.warn('Text limit reached on non edited frame!');
    //   setPageEditState({
    //     editedPage: null,
    //     editedFrame: null,
    //   });

    //   try {
    //     // TODO: warning, this was done in a callback after set state, we might add this part in a use effect now...
    //     const frame = cloneDeep(
    //       currentVisibleFrames && currentVisibleFrames[frameID]
    //     );
    //     if (width) {
    //       frame.width = width;
    //     }
    //     if (height) {
    //       frame.height = height;
    //     }
    //     // find frame in current page
    //     dispatch(editionActions.UpdateFrame(frame));
    //   } catch (e) {
    //     alert(`error while fixing limit of text frame:${e}`);
    //   }
    // }
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // ---- BORDER CHANGE ----

  const handleBorderColorChange = (color) => {
    modifySelectedFrame((frame) => {
      frame.borderColor = color.hex;
    });
  };

  const handleBorderSizeChange = (newSize: number) => {
    modifySelectedFrame((frame) => {
      frame.border = newSize;
    });
  };

  const applyBorderToAllFrames = (borderSize: number, borderColor: string) => {
    dispatch(editionActions.ApplyBorderToAll(borderSize, borderColor));
  };

  const applyBorderRadiusToAllFrames = (borderRadius: number) => {
    dispatch(editionActions.ApplyBorderRadiusToAll(borderRadius));
  };

  const applyCurrentTextOptionsToAll = () => {
    dispatch(editionActions.ApplyTextOptionsToAll(editedFrame));
  };

  const handleBorderRadiusChange = (
    newRadius: number,
    andEndEdition = true
  ) => {
    modifySelectedFrame((frame) => {
      frame.borderRadius = newRadius;
    }, andEndEdition);
  };

  // ---- SHADOW CHANGE ----

  const handleFrameShadowUpdate = (
    shadow: IFrameShadow,
    andEndEdition = true
  ) => {
    modifySelectedFrame((frame) => {
      frame.shadow = shadow;
    }, andEndEdition);
  };

  const applyShadowToAllFrames = (shadow: IFrameShadow) => {
    dispatch(editionActions.ApplyShadowToAll(shadow));
  };

  // ---- TEXT CHANGE ----

  const onTextChange = ({ target: { value } }) => {
    modifySelectedFrame((frame) => {
      frame.text.value = value;
    });
  };

  const onTextColorChange = (color) => {
    modifySelectedFrame((frame) => {
      frame.text.color = color.hex;
    });
  };

  const onTextBGColorChange = (color) => {
    modifySelectedFrame((frame) => {
      if (color && color.hex) {
        frame.fillColor = color.hex;
      } else frame.fillColor = null;
    });
  };

  const handleTextFamilyChange = (value) => {
    modifySelectedFrame((frame) => {
      frame.text.family = value;
    });
  };

  const handleTextSizeChange = (value) => {
    modifySelectedFrame((frame) => {
      frame.text.size = value;
    });
  };

  const toggleTextItalic = () => {
    modifySelectedFrame((frame) => {
      frame.text.italic = !frame.text.italic;
    });
  };

  const toggleTextBold = () => {
    modifySelectedFrame((frame) => {
      frame.text.bold = !frame.text.bold;
    });
  };

  const changeTextHAlign = (align) => {
    modifySelectedFrame((frame) => {
      frame.text.halign = align;
    });
  };

  const changeTextVAlign = (align) => {
    modifySelectedFrame((frame) => {
      frame.text.valign = align;
    });
  };

  const clearText = () => {
    modifySelectedFrame((frame) => {
      frame.text.value = '';
      if (textInputRef) {
        textInputRef.current.value = '';
      }
    });
  };

  const handlePreviewClick = () => {
    dispatch(UIActions.SetPreviewMode(!isPreviewMode));
  };

  // --------------------- effects ------------------------

  // listen for window events
  useEffect(() => {
    // leave warning
    window.addEventListener('beforeunload', handleCheckLeaveWarning);
    // resize
    window.addEventListener('resize', handleEditionResize);
    handleEditionResize();

    return () => {
      // leave warning
      window.removeEventListener('beforeunload', handleCheckLeaveWarning);
      // resize
      window.removeEventListener('resize', handleEditionResize);
    };
  }, [handleEditionResize, handleCheckLeaveWarning]);

  useEffect(() => {
    // change transform bounds
    if (transformToolRef.current) {
      const newTransformBounds =
        transformToolRef.current.transformTool.getBoundingClientRect();
      if (
        !transformBounds ||
        newTransformBounds.x !== transformBounds.x ||
        newTransformBounds.y !== transformBounds.y ||
        newTransformBounds.width !== transformBounds.width ||
        newTransformBounds.height !== transformBounds.height
      ) {
        setTransformBounds(newTransformBounds);
      }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////// TODO: THIS HAS BEEN COMMENTED ON 4th july 2024
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // if we are not editing a page (!editepage)
    // and we have a selected frame currently, we need to be sure this is the same as the one from the store
    // if (!editedPage && editedFrame && editedFrame.id === selectedFrameID) {
    //   const storeFrame = cloneDeep(
    //     GetPageFrameByID(currentPage, editedFrame.id)
    //   );
    //   if (!isEqual(storeFrame, editedFrame)) {
    //     setPageEditState({
    //       editedFrame: storeFrame,
    //     });
    //   }
    // }

    // // if selectedframe has been cleared, we need to be sure to remove it here
    // if (
    //   editedFrame &&
    //   (!selectedFrameID || editedFrame.id !== selectedFrameID)
    // ) {
    //   // TODO: the line above was added to allow the selected frame to be
    //   // if( editedFrame && !selectedFrameID)
    //   setPageEditState({
    //     editedFrame: null,
    //   });
    // }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // update editor scale factor
    updateEditorScaleFactor();

    // always focus on text area if open!
    if (textInputRef.current) {
      textInputRef.current.focus();
    }

    // Case swapping!
    if (
      swapFrameID &&
      editedFrame &&
      editedFrame.id !== swapFrameID &&
      frameCanSwapDrop(editedFrame)
    ) {
      // handle FrameSwap!
      dispatch(editionActions.SwapFrameContent(swapFrameID, editedFrame.id));
      setSwapFrameID(null);
    }
  }, [
    editedFrame,
    editedPage,
    dispatch,
    selectedPageIndex,
    pageList,
    selectedFrameID,
    currentPage,
    swapFrameID,
    transformToolRef,
    textInputRef,
    updateEditorScaleFactor,
    transformBounds,
  ]);

  // => effect on page change
  useEffect(() => {
    if (previousSelectedPageIndex !== selectedPageIndex) {
      // const newPage = pageList[selectedPageIndex];
      // check if we have a edited frame, we keep it if the new page contains it
      // let keepFrame;
      // if (editedFrame) {
      //   newPage.frames.forEach((frame) => {
      //     if (frame.id === editedFrame.id) {
      //       keepFrame = frame;
      //     }
      //   });
      // }

      setPreviousSelectedPageIndex(selectedPageIndex);
      // TODO: commented on 4th july 2024 with new editedPage system
      // setPageEditState({ editedFrame: cloneDeep(keepFrame) });
      setSwapFrameID(null); // reset swapping frame
    }
  }, [previousSelectedPageIndex, selectedPageIndex, pageList, editedFrame]);

  // --------------------- render ------------------------

  return (
    <div
      className={`editionArea ${
        isPreviewMode ? `previewBG_${PROJECT_CONST.project_class}` : ''
      }`}
      // onDrop={(e)=>onDropContent(e, "this is optional info" )}
      // onDragOver={(e)=>{e.preventDefault();}}
      style={{
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        width: '100vw',
      }}
      ref={editionAreaRef}
    >
      {
        // --------------------- Main Toolbar ------------------------
        !isPreviewMode && <MainEditionToolbar />
      }

      {
        // DEBUG:
        // TODO: remove this
        //   <div
        //   style={{
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     color: 'yellow',
        //     backgroundColor: '#ff0000DD',
        //   }}
        // >
        //   <pre>{JSON.stringify(selectedFrame, null, 2)}</pre>
        // </div>
      }

      <div
        role="button"
        tabIndex={0}
        ref={stageAreaRef}
        className="stageArea"
        onClick={(e) => {
          // unselect on background click
          if ((e.target as any).id === 'thePages') {
            dispatch(editionActions.ChangeSelectedFrame(null));
          }
        }}
      >
        {
          // --------------------- image Editor ------------------------
          editedPhoto && (
            <ImageEditor
              photoID={editedPhoto}
              frame={editedFrame}
              onClose={() => {
                setEditedPhoto(null);
              }}
            />
          )
        }

        {
          // --------------------- Pages ------------------------
          pageList && (
            <div className="pageDivWrapper">
              <svg
                id="thePages"
                ref={pageContainerRef}
                className={isPreviewMode ? 'pagesSVG_preview' : 'pagesSVG'}
                viewBox={`0 0 ${pagesWidth + outsideMargin * 2} ${
                  pagesHeight + outsideMargin * 2
                }`}
              >
                {
                  // --------------------- Page Group ------------------------
                  pageGroup.map((pageObj, groupIndex) => {
                    // case inside page
                    if ((pageObj as InsidePage).isInsidePage) {
                      return (
                        <InsidePageArea
                          key={`insidePage_${groupIndex}`}
                          editionScale={editionScale}
                          isPreviewMode={isPreviewMode}
                          x={outsideMargin + groupIndex * pageObj.width}
                          y={outsideMargin}
                          height={pageObj.height}
                          width={pageObj.width}
                        />
                      );
                    }

                    // get "editedPage" if index correspond and we are editing
                    const page =
                      editedPage && pageObj.index === editedPage.index
                        ? editedPage
                        : pageObj;

                    const pageX =
                      doc.pages_group_orientation === 'vertical'
                        ? outsideMargin
                        : outsideMargin +
                          groupIndex * (pageObj.width + pageSpacing);
                    const pageY =
                      doc.pages_group_orientation === 'vertical'
                        ? outsideMargin +
                          groupIndex * (pageObj.height + pageSpacing)
                        : outsideMargin;

                    return (
                      <PageArea
                        id={`page_${page.index}`}
                        pageIndex={page.index}
                        key={page.index}
                        page={page}
                        gridMode={gridMode}
                        docID={docID}
                        selected={selectedPageIndex === page.index}
                        selectedFrameID={selectedFrameID}
                        isEditing={isEditing}
                        isPreviewMode={isPreviewMode}
                        editionScale={editionScale}
                        calendarColorOptions={project.calendarColorOptions}
                        canvasEdge={canvasEdge}
                        x={pageX}
                        y={pageY}
                        showPageNumber={!page.isCover && project.pageNumber}
                        pageNumberColor={project.pageNumberColor}
                        onFrameMouseDown={(p, f, e) => {
                          handleFrameMouseDown(p, f, e);
                        }}
                        onFrameDoubleClick={(frame) => {
                          handleFrameDoubleClick(frame);
                        }}
                        onFrameItemDrop={(dropEvent, frameObj) => {
                          handleItemDropOnFrame(dropEvent, frameObj);
                        }}
                        onFramePhotoImport={(photo, frame) => {
                          handlePhotoImportIntoFrame(photo, frame);
                        }}
                        onFrameTextLimitReached={(frameID, sizeLimit) => {
                          handleFrameTextLimits(frameID, sizeLimit);
                        }}
                        onFrameAddTextCtaClick={(frameId) => {
                          if (editedFrame && editedFrame.id === frameId) {
                            handleEditedFrameDoubleClick();
                          }
                        }}
                      />
                    );
                  })
                }

                {
                  // --------------------- Page between shadow ------------------------
                  pageSpacing === 0 &&
                    (pageGroup.length === 2 || pageGroup[0].merged) && (
                      <InnerPageShadow
                        orientation={
                          doc.pages_group_orientation === 'vertical'
                            ? 'horizontal'
                            : 'vertical'
                        } // the shadow orientation is the opposite of the pages group orientation
                        widthOrX={
                          pageGroup[0].merged
                            ? pageGroup[0].width / 2
                            : pageGroup[0].width
                        }
                        heightOrY={pageGroup[0].height}
                        outsideMargin={outsideMargin}
                        variant={pageGroup[0].merged ? 'light' : 'normal'} // merged page have a lighter shadow
                      />
                    )
                }

                {
                  // --------------------- pageDetails ------------------------
                  !isClassicCover && (
                    <g>
                      <text
                        className="unselectable"
                        // x="50%" text-anchor="middle"
                        x={10 / editionScale}
                        y={20 / editionScale}
                        fontSize={12 / editionScale}
                        fill="#aaaaaa"
                      >
                        --- {pixelToCm(pageGroup[0].width).toFixed(1)} cm ---
                      </text>
                      <text
                        className="unselectable"
                        x={-100 / editionScale}
                        y={10 / editionScale}
                        fontSize={12 / editionScale}
                        transform="rotate(-90)"
                        fill="#aaaaaa"
                      >
                        {' '}
                        --- {pixelToCm(pageGroup[0].height).toFixed(1)} cm ---
                      </text>
                    </g>
                  )
                }
              </svg>

              {
                // --------------------- Snapping lines ------------------------
                editedPage &&
                  editedFrame &&
                  snappingLines &&
                  (editAction === TRANFORM_TOOL_ACTION.MOVE ||
                    editAction === TRANFORM_TOOL_ACTION.SCALE ||
                    editAction === TRANFORM_TOOL_ACTION.CROP) && (
                    <SnappingLines
                      rootX={rootX}
                      rootY={rootY}
                      pageGroup={pageGroup}
                      editionScale={editionScale}
                      snappingLines={snappingLines}
                      editedFrame={editedFrame}
                    />
                  )
              }

              {
                // --------------------- Edited Frame (Transform tools) ------------------------
                editedFrame && (
                  <div
                    style={{
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                      // backgroundColor: '#ff000022',
                      position: 'absolute',
                      pointerEvents: 'none',
                    }}
                  >
                    <TransformTool
                      // using the "Key" attribute linked to image id will force the tool to be "re created" each time we change frame
                      // THIS IS GREAT to avoid the anti pattern props->state
                      // see here : https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
                      key={`selectionBox_${editedFrame.id}_${editedFrame.photo}`}
                      ref={transformToolRef}
                      pagePosX={rootX}
                      pagePosY={rootY}
                      editedFrame={editedFrame}
                      editedPage={editedPage}
                      editionScale={editionScale}
                      snappingLines={snappingLines}
                      getPhotosById={getPhotosById}
                      onDoubleClick={() => {
                        handleEditedFrameDoubleClick();
                      }}
                      onFrameUpdateStart={handleEditedFrameStart}
                      onFrameUpdate={handleEditedFrameUpdate}
                      onFrameUpdateCompleted={handleEditedFrameUpdateComplete}
                      onItemDropped={(dropEvent) => {
                        handleItemDroppedOnTransformTool(dropEvent);
                      }}
                    />

                    {
                      // --------------------- Transform toolbar ------------------------
                      transformBounds &&
                        editedFrame &&
                        // do not display toolbar while those actions are performed
                        editAction !== TRANFORM_TOOL_ACTION.MOVE &&
                        editAction !== TRANFORM_TOOL_ACTION.SCALE &&
                        editAction !== TRANFORM_TOOL_ACTION.ROTATE &&
                        editAction !== TRANFORM_TOOL_ACTION.CROP &&
                        editAction !== TRANFORM_TOOL_ACTION.INSIDE_MOVE && (
                          <div
                            className="transformToolbarWrapper"
                            key={`toolbar_${editedFrame.id}`}
                            style={{
                              left: toolbarPosX,
                              top: toolbarPosY,
                            }}
                          >
                            <div className="transformToolbar">
                              {
                                // ---- ZOOM SLIDER ----
                                editedFrame.photo && (
                                  <span className="zoomSliderWrapper">
                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.zoom.minus'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        shape="circle"
                                        onClick={() => {
                                          handleFrameZoomEnd(
                                            editedFrame.zoom - 0.01
                                          );
                                        }}
                                      >
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.ZoomOutIcon}
                                        />
                                      </Button>
                                    </Tooltip>

                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title="Zoom on image"
                                      placement="top"
                                    >
                                      <Slider
                                        className="zoomSlider"
                                        defaultValue={editedFrame.zoom}
                                        value={editedFrame.zoom}
                                        tooltip={{
                                          formatter: zoomTooltipFormatter,
                                        }}
                                        step={0.01}
                                        min={editedFrameMinZoom}
                                        max={editedFrameMaxZoom}
                                        onChange={(value) => {
                                          handleFrameZoomChange(value);
                                        }}
                                        onAfterChange={(value) => {
                                          handleFrameZoomEnd(value);
                                        }}
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.zoom.plus'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        shape="circle"
                                        onClick={() => {
                                          handleFrameZoomEnd(
                                            editedFrame.zoom + 0.01
                                          );
                                        }}
                                      >
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.ZoomInIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </span>
                                )
                              }

                              {
                                // ---- LAYER UP ----
                                frameCanChangeDepth(editedFrame) && (
                                  <Tooltip
                                    mouseEnterDelay={tooltipDelay}
                                    title={GetText(
                                      'tooltip.transformtool.depth.plus'
                                    )}
                                    placement="top"
                                  >
                                    <Button
                                      type="primary"
                                      block
                                      onClick={() => {
                                        handleFrameMoveUp();
                                      }}
                                    >
                                      <ImgIcon
                                        className="customIcon"
                                        icon={EmbedIcons.LayerUpIcon}
                                      />
                                    </Button>
                                  </Tooltip>
                                )
                              }
                              {
                                // ---- LAYER DOWN ----
                                frameCanChangeDepth(editedFrame) && (
                                  <Tooltip
                                    mouseEnterDelay={tooltipDelay}
                                    title={GetText(
                                      'tooltip.transformtool.depth.minus'
                                    )}
                                    placement="top"
                                  >
                                    <Button
                                      type="primary"
                                      block
                                      onClick={() => {
                                        handleFrameMoveDown();
                                      }}
                                    >
                                      <ImgIcon
                                        className="customIcon"
                                        icon={EmbedIcons.LayerDownIcon}
                                      />
                                    </Button>
                                  </Tooltip>
                                )
                              }

                              {
                                // ---- inside rotation icon ----
                                frameCanInsideRotation(editedFrame) && (
                                  <Tooltip
                                    mouseEnterDelay={tooltipDelay}
                                    title={GetText(
                                      'tooltip.transformtool.rotate'
                                    )}
                                    placement="top"
                                  >
                                    <Button
                                      type="primary"
                                      block
                                      onClick={() => {
                                        handleFrameInsideRotate();
                                      }}
                                    >
                                      <ImgIcon
                                        className="customIcon"
                                        icon={EmbedIcons.InsideRotateIcon}
                                      />
                                    </Button>
                                  </Tooltip>
                                )
                              }

                              {
                                // ---- SWAP ICON ----
                                frameCanSwapStart(editedFrame) && (
                                  <Tooltip
                                    mouseEnterDelay={tooltipDelay}
                                    title={GetText(
                                      'tooltip.transformtool.swap'
                                    )}
                                    placement="top"
                                  >
                                    <Button
                                      type="primary"
                                      style={{
                                        backgroundColor:
                                          swapFrameID === editedFrame.id
                                            ? Colors.SELECTION
                                            : null,
                                        borderColor:
                                          swapFrameID === editedFrame.id
                                            ? Colors.SELECTION
                                            : null,
                                      }}
                                      block
                                      onClick={() => {
                                        if (swapFrameID !== editedFrame.id)
                                          setSwapFrameID(editedFrame.id);
                                        else {
                                          // reset
                                          setSwapFrameID(null);
                                        }
                                      }}
                                    >
                                      <ImgIcon
                                        className="customIcon"
                                        icon={EmbedIcons.SwapIcon}
                                      />
                                    </Button>
                                  </Tooltip>
                                )
                              }

                              {
                                // ---- EDIT ICON ----
                                editedFramePhotoObj &&
                                  !editedFramePhotoObj.temp &&
                                  frameCanPhotoEdit(editedFrame) && (
                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.edit.image'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        id="toolbarEditPhotoButton"
                                        type="primary"
                                        block
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                          if (editedFrame.photo) {
                                            setEditedPhoto(editedFrame.photo);
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  )
                              }

                              {
                                // --- BORDER TOOLBAR ---
                                frameCanHaveBorder(editedFrame) && (
                                  <Popover
                                    trigger="click"
                                    placement="bottom"
                                    content={
                                      <span className="borderToolbar">
                                        <Radio.Group
                                          onChange={(e) => {
                                            handleBorderSizeChange(
                                              e.target.value
                                            );
                                          }}
                                          value={editedFrame.border}
                                        >
                                          <Radio.Button value={0}>
                                            {GetText('toolbar.border.noborder')}
                                          </Radio.Button>
                                          <Radio.Button value={1}>
                                            1mm
                                          </Radio.Button>
                                          <Radio.Button value={2}>
                                            2mm
                                          </Radio.Button>
                                          <Radio.Button value={4}>
                                            4mm
                                          </Radio.Button>
                                        </Radio.Group>

                                        <div className="spacerH" />

                                        <Popover
                                          content={
                                            <CirclePicker
                                              width={230}
                                              circleSpacing={5}
                                              circleSize={15}
                                              colors={Colors.displayColors}
                                              color={editedFrame.borderColor}
                                              onChange={handleBorderColorChange}
                                            />
                                          }
                                          title="Change Color"
                                          trigger="focus"
                                        >
                                          <Button
                                            type="default"
                                            icon={<BgColorsOutlined />}
                                            className="button"
                                            onClick={(e) =>
                                              e.currentTarget.focus()
                                            }
                                            style={{
                                              color: editedFrame.borderColor,
                                            }}
                                          />
                                        </Popover>

                                        <div className="spacerH" />
                                        <Button
                                          type="primary"
                                          onClick={() => {
                                            applyBorderToAllFrames(
                                              editedFrame.border,
                                              editedFrame.borderColor
                                            );
                                          }}
                                        >
                                          {GetText(
                                            'lefttab.backgrounds.applytoall'
                                          )}
                                        </Button>
                                      </span>
                                    }
                                  >
                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.border'
                                      )}
                                      placement="top"
                                    >
                                      <Button
                                        type="primary"
                                        block
                                        icon={<BorderOutlined />}
                                      />
                                    </Tooltip>

                                    {/* <Button type="primary" icon="bg-colors"
                                        onClick={(e) => e.target.focus()}
                                        style={{backgroundColor:editedFrame.text.color ,margin:5}} /> */}
                                  </Popover>
                                )
                              }

                              {
                                // --------------------- MASK TOOLBAR ------------------------
                                frameCanHaveMask(editedFrame) && (
                                  <Popover
                                    trigger="click"
                                    placement="bottom"
                                    content={
                                      // --------------------- MASK TOOLBAR OVERLAY ------------------------
                                      <span className="maskToolbar">
                                        <h5>
                                          {GetText(
                                            'toolbar.mask.cornerRadiusMask'
                                          )}
                                        </h5>
                                        <div className="spacer" />
                                        <div className="spacer" />
                                        <Tooltip
                                          mouseEnterDelay={tooltipDelay}
                                          placement="top"
                                        >
                                          <Slider
                                            className="zoomSlider"
                                            style={{ width: 100 }}
                                            tooltip={{
                                              formatter: (value) =>
                                                `corner radius: ${value}`,
                                            }}
                                            tooltipPlacement="bottom"
                                            value={
                                              editedFrame.borderRadius
                                                ? editedFrame.borderRadius
                                                : 0
                                            }
                                            step={1}
                                            min={0}
                                            max={
                                              editedFrame.height >
                                              editedFrame.width
                                                ? Math.round(
                                                    editedFrame.height / 2
                                                  )
                                                : Math.round(
                                                    editedFrame.width / 2
                                                  )
                                            }
                                            // onChange={(value)=>{ handleFrameZoom(value); }}
                                            // onAfterChange={(value)=>{ handleFrameZoomEnd(value); }}
                                            onAfterChange={(value) => {
                                              handleBorderRadiusChange(value);
                                            }}
                                            onChange={(value) => {
                                              handleBorderRadiusChange(
                                                value,
                                                false
                                              );
                                            }}
                                          />
                                        </Tooltip>

                                        <div className="spacerH" />
                                        <Button
                                          type="primary"
                                          onClick={() => {
                                            applyBorderRadiusToAllFrames(
                                              editedFrame.borderRadius
                                            );
                                          }}
                                        >
                                          {GetText(
                                            'lefttab.backgrounds.applytoall'
                                          )}
                                        </Button>
                                      </span> // --------------------- END of MASK TOOLBAR OVERLAY ------------------------
                                    }
                                  >
                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.masks'
                                      )}
                                      placement="top"
                                    >
                                      <Button type="primary" block>
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.MaskIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Popover>
                                )
                              }

                              {
                                // --------------------- SHADOW TOOLBAR ------------------------
                                frameCanHaveShadow(editedFrame) && (
                                  <Popover
                                    trigger="click"
                                    placement="bottom"
                                    content={
                                      <span className="shadowToolbar">
                                        {/* <h5>Use shadow:</h5> */}
                                        {/* <Checkbox checked={frameShadow.enabled} onChange={(e)=>{ frameShadow.enabled = e.target.checked; handleFrameShadowUpdate( frameShadow) } }>Checkbox</Checkbox> */}
                                        <Switch
                                          checked={frameShadow.enabled}
                                          // checkedChildren={<span>Shadow: <CheckOutlined/> </span>}
                                          // unCheckedChildren={<span>Shadow: <CloseOutlined /></span>}

                                          checkedChildren={
                                            <span>Shadow: ON</span>
                                          }
                                          unCheckedChildren={
                                            <span>Shadow: OFF</span>
                                          }
                                          onChange={(value) => {
                                            frameShadow.enabled = value;
                                            handleFrameShadowUpdate(
                                              frameShadow
                                            );
                                          }}
                                        />

                                        <div className="spacerH" />
                                        <div className="spacerH" />

                                        <h5>Distance: </h5>
                                        <Tooltip
                                          mouseEnterDelay={tooltipDelay}
                                          placement="top"
                                        >
                                          <Slider
                                            disabled={!frameShadow.enabled}
                                            className="zoomSlider"
                                            style={{ width: 100 }}
                                            // Tooltip mouseEnterDelay={tooltipDelay} Visible={true}
                                            defaultValue={frameShadow.angle}
                                            tooltip={{
                                              formatter: (value) =>
                                                `distance: ${value}`,
                                            }}
                                            tooltipPlacement="bottom"
                                            value={frameShadow.distance}
                                            step={1}
                                            min={0}
                                            max={100}
                                            // onChange={(value)=>{ handleFrameZoom(value); }}
                                            // onAfterChange={(value)=>{ handleFrameZoomEnd(value); }}
                                            onAfterChange={(value) => {
                                              frameShadow.distance = value;
                                              handleFrameShadowUpdate(
                                                frameShadow
                                              );
                                            }}
                                            onChange={(value) => {
                                              frameShadow.distance = value;
                                              handleFrameShadowUpdate(
                                                frameShadow,
                                                false
                                              );
                                            }}
                                          />
                                        </Tooltip>

                                        <div className="spacerH" />
                                        <div className="spacerH" />

                                        <h5>Opacity: </h5>
                                        <Tooltip
                                          mouseEnterDelay={tooltipDelay}
                                          placement="top"
                                        >
                                          <Slider
                                            disabled={!frameShadow.enabled}
                                            className="zoomSlider"
                                            style={{ width: 100 }}
                                            // Tooltip mouseEnterDelay={tooltipDelay} Visible={true}
                                            defaultValue={frameShadow.opacity}
                                            tooltip={{
                                              formatter: (value) =>
                                                `opacity: ${value}`,
                                            }}
                                            tooltipPlacement="bottom"
                                            value={frameShadow.opacity}
                                            step={0.1}
                                            min={0}
                                            max={1}
                                            // onChange={(value)=>{ handleFrameZoom(value); }}
                                            // onAfterChange={(value)=>{ handleFrameZoomEnd(value); }}
                                            onAfterChange={(value) => {
                                              frameShadow.opacity = value;
                                              handleFrameShadowUpdate(
                                                frameShadow
                                              );
                                            }}
                                            onChange={(value) => {
                                              frameShadow.opacity = value;
                                              handleFrameShadowUpdate(
                                                frameShadow,
                                                false
                                              );
                                            }}
                                          />
                                        </Tooltip>

                                        <div className="spacerH" />
                                        <div className="spacerH" />

                                        <h5>Angle: </h5>
                                        <Tooltip
                                          mouseEnterDelay={tooltipDelay}
                                          placement="top"
                                        >
                                          <Slider
                                            disabled={!frameShadow.enabled}
                                            className="zoomSlider"
                                            style={{ width: 100 }}
                                            // Tooltip mouseEnterDelay={tooltipDelay} Visible={true}
                                            defaultValue={frameShadow.angle}
                                            tooltip={{
                                              formatter: (value) =>
                                                `angle: ${value}°`,
                                            }}
                                            tooltipPlacement="bottom"
                                            value={frameShadow.angle}
                                            step={5}
                                            min={0}
                                            max={360}
                                            // onChange={(value)=>{ handleFrameZoom(value); }}
                                            // onAfterChange={(value)=>{ handleFrameZoomEnd(value); }}
                                            onAfterChange={(value) => {
                                              frameShadow.angle = value;
                                              handleFrameShadowUpdate(
                                                frameShadow
                                              );
                                            }}
                                            onChange={(value) => {
                                              frameShadow.angle = value;
                                              handleFrameShadowUpdate(
                                                frameShadow,
                                                false
                                              );
                                            }}
                                          />
                                        </Tooltip>

                                        <div className="spacerH" />
                                        <div className="spacerH" />

                                        <Popover
                                          content={
                                            <CirclePicker
                                              width={230}
                                              circleSpacing={5}
                                              circleSize={15}
                                              colors={Colors.displayColors}
                                              color={frameShadow.hexColor}
                                              onChange={(e) => {
                                                frameShadow.hexColor = e.hex;
                                                handleFrameShadowUpdate(
                                                  frameShadow
                                                );
                                              }}
                                            />
                                          }
                                          title="Change Color"
                                          trigger="focus"
                                        >
                                          <Button
                                            type="default"
                                            icon={<BgColorsOutlined />}
                                            className="button"
                                            disabled={!frameShadow.enabled}
                                            onClick={(e) =>
                                              e.currentTarget.focus()
                                            }
                                            style={{
                                              backgroundColor:
                                                frameShadow.hexColor,
                                            }}
                                          />
                                        </Popover>

                                        <div className="spacerH" />
                                        <Button
                                          type="primary"
                                          onClick={() => {
                                            applyShadowToAllFrames(
                                              editedFrame.shadow
                                            );
                                          }}
                                        >
                                          {GetText(
                                            'lefttab.backgrounds.applytoall'
                                          )}
                                        </Button>
                                        {/* <Button type="primary" onClick={()=>{  alert("Apply shadow!"); }} >{GetText("lefttab.shadow.apply")}</Button> */}
                                      </span>
                                    }
                                  >
                                    <Tooltip
                                      mouseEnterDelay={tooltipDelay}
                                      title={GetText(
                                        'tooltip.transformtool.shadow'
                                      )}
                                      placement="top"
                                    >
                                      <Button type="primary" block>
                                        <ImgIcon
                                          className="customIcon"
                                          icon={EmbedIcons.ShadowIcon}
                                        />
                                      </Button>
                                    </Tooltip>
                                    {/* <Button type="primary" icon="bg-colors"
                                        onClick={(e) => e.target.focus()}
                                        style={{backgroundColor:editedFrame.text.color ,margin:5}} /> */}
                                  </Popover>
                                )
                              }

                              {/* ---------------------------- Text toolbar ------------------------------- */}
                              {frameCanHaveText(editedFrame) && (
                                <TextEditionToolbar
                                  editedFrame={editedFrame}
                                  textInputRef={textInputRef}
                                  onTextChange={onTextChange}
                                  onTextColorChange={onTextColorChange}
                                  onTextBGColorChange={onTextBGColorChange}
                                  onTextFamilyChange={handleTextFamilyChange}
                                  onTextSizeChange={handleTextSizeChange}
                                  onToggleTextItalic={toggleTextItalic}
                                  onToggleTextBold={toggleTextBold}
                                  onChangeTextHAlign={changeTextHAlign}
                                  onChangeTextVAlign={changeTextVAlign}
                                  onAddDefaultTextToFrame={
                                    handleAddDefaultTextToFrame
                                  }
                                  onApplyCurrentTextOptionsToAll={
                                    applyCurrentTextOptionsToAll
                                  }
                                  onClearText={clearText}
                                />
                              )}

                              {
                                // ---- INFO ----
                                ((IsLocalhost && editedFrame) ||
                                  editedFramePhotoObj) && (
                                  <Popover
                                    content={getInfoOverContent(editedFrame)}
                                    mouseEnterDelay={tooltipDelay}
                                    placement="top"
                                  >
                                    <Button
                                      type="primary"
                                      block
                                      icon={<InfoOutlined />}
                                    />
                                  </Popover>
                                )
                              }

                              {
                                // ---- DELETE ----
                                frameHasDeleteButton(editedFrame) && (
                                  <Tooltip
                                    mouseEnterDelay={tooltipDelay}
                                    title={GetText(
                                      'tooltip.transformtool.delete'
                                    )}
                                    placement="top"
                                  >
                                    <Button
                                      block
                                      icon={<DeleteOutlined />}
                                      className="button_delete"
                                      onClick={() => {
                                        handleFrameDelete();
                                      }}
                                    />
                                  </Tooltip>
                                )
                              }
                            </div>
                          </div>
                        )
                    }
                  </div>
                )
              }

              {
                /* // --------------------- Page Details -- Next/Previous pages ------------------------ */
                pageList.length > 1 && (
                  <div
                    className="pageDetail"
                    style={{
                      marginBottom: isPreviewMode ? '35px' : null,
                    }}
                  >
                    <div className="spacer" />
                    <Button
                      type="primary"
                      size="small"
                      disabled={selectedPageIndex === 0}
                      onClick={() => {
                        handleFirstPageClick();
                      }}
                    >
                      <LeftOutlined />
                      {GetText('edition.toolbar.page.first')}
                    </Button>
                    <div className="spacer" />
                    <Button
                      type="primary"
                      size="small"
                      disabled={selectedPageIndex === 0}
                      onClick={() => {
                        handlePreviousPageClick();
                      }}
                    >
                      <LeftOutlined />
                      {GetText('edition.toolbar.page.previous')}
                    </Button>
                    <div className="content unselectable">
                      {getPageGroupDisplayName(pageGroup, docID)}
                    </div>
                    <Button
                      type="primary"
                      size="small"
                      disabled={selectedPageIndex > pageList.length - 2}
                      onClick={() => {
                        handleNextPageClick();
                      }}
                    >
                      {GetText('edition.toolbar.page.next')}
                      <RightOutlined />
                    </Button>
                    <div className="spacer" />
                    <Button
                      type="primary"
                      size="small"
                      disabled={selectedPageIndex > pageList.length - 2}
                      onClick={() => {
                        handleLastPageClick();
                      }}
                    >
                      {GetText('edition.toolbar.page.last')}
                      <RightOutlined />
                    </Button>
                  </div>
                )
              }
              <div className="spacer" />
              {DebugFlags.IS_LOCALHOST && (
                <div className="debug_text">
                  EditoScale: {editionScale.toFixed(3)}
                </div>
              )}
            </div>
          )
        }

        <Button
          className="previewButton"
          icon={<DesktopOutlined />}
          danger={isPreviewMode}
          type={isPreviewMode ? 'default' : 'dashed'}
          onClick={() => handlePreviewClick()}
        >
          {GetText(isPreviewMode ? 'preview.button.off' : 'preview.button.on')}
        </Button>

        {/* ------------ photo manager ----------------- */}
        {/* <Button
          className="absolute right-2 top-30"
          icon={<PictureOutlined />}
          type="primary"
          onClick={() => dispatch(UIActions.togglePhotoManager(true))}
        >
          {GetText('topbar.photomanager.btn.label')}
        </Button> */}

        {photoManagerOpen && (
          <PhotoManager
            onClose={() => {
              dispatch(UIActions.togglePhotoManager(false));
            }}
          />
        )}
      </div>
    </div>
  );
};
