import { Frame, IPage } from '../../../types/types';

export const isFrameOutsidePageBounds = (frame: Frame, page: IPage) => {
  const frameCenter = { x: frame.x, y: frame.y };
  const margin =
    frame.width > frame.height ? frame.height / 3 : frame.width / 3;
  return (
    frameCenter.x + margin < 0 ||
    frameCenter.x - margin > page.width ||
    frameCenter.y + margin < 0 ||
    frameCenter.y - margin > page.height
  );
};
