import { Frame, IPage } from '../../../types/types';
import { mmToPoint } from '../../../utils/MeasureUtils';

type Props = {
  rootX: number;
  rootY: number;
  editionScale: number;
  pageGroup: IPage[];
  editedFrame: Frame;
  snappingLines: { v: number[]; h: number[] };
};

export const SnappingLines = ({
  rootX,
  rootY,
  editionScale,
  editedFrame,
  snappingLines,
}: Props) => {
  const touchedSnappingLines = {
    v: [],
    h: [],
  };

  // filter the snapping lines with the current frame position!
  const snapTreshold = mmToPoint(0.5); // less than in thransform tool (5mm) because here it's just for displaying!
  const frame = editedFrame;
  const frameYs =
    frame.rotation === 0
      ? [frame.y, frame.y - frame.height / 2, frame.y + frame.height / 2]
      : [frame.y];
  const frameXs =
    frame.rotation === 0
      ? [frame.x, frame.x - frame.width / 2, frame.x + frame.width / 2]
      : [frame.x];
  touchedSnappingLines.h = snappingLines.h.filter((posY) => {
    for (const frameY of frameYs) {
      if (Math.abs(frameY - posY) < snapTreshold) {
        return true;
      }
    }
    return false;
  });
  touchedSnappingLines.v = snappingLines.v.filter((posX) => {
    for (const frameX of frameXs) {
      if (Math.abs(frameX - posX) < snapTreshold) {
        return true;
      }
    }
    return false;
  });

  // --------------------- Render ------------------------

  if (!touchedSnappingLines) return null;
  return (
    <div
      className="unclickable unselectable"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {
        // ---- VERTICAL ----
        touchedSnappingLines.v.map((value, index) => (
          <div
            key={`snapV_${value}_${index}`}
            className="snappingLineV"
            style={{
              left: rootX + value * editionScale,
            }}
          />
        ))
      }
      {
        // ---- HORIZONTAL ----
        touchedSnappingLines.h.map((value, index) => (
          <div
            key={`snapH_${value}_${index}`}
            className="snappingLineH"
            style={{
              top: rootY + value * editionScale,
            }}
          />
        ))
      }
    </div>
  );
};
