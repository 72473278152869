import { ALBUM_TYPES, COVER_TYPE } from '../../data/Constants';
import { GetDoc } from '../ProductHelper';

export const getValidCoverType = (
  projectType: string,
  docID: string,
  coverType: COVER_TYPE
): COVER_TYPE => {
  // special cases for contemporary and classif for now
  if (projectType === ALBUM_TYPES.CONTEMPORARY) return COVER_TYPE.COVER_CUSTOM;
  if (projectType === ALBUM_TYPES.CLASSIC) return COVER_TYPE.COVER_CLASSIC;

  // verify doc
  const doc = GetDoc(docID);
  if (doc.coverType) {
    if (!doc.coverType.includes(coverType)) return doc.coverType[0];
    else return coverType;
  }

  // by default return the cover type custom
  return COVER_TYPE.COVER_CUSTOM;
};
