import { Modal } from 'antd';

export const warnCustomer = (title: string, content: string) => {
  Modal.warn({
    width: '600px',
    title,
    content: (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {content.split('\\n').join('\n')}
      </div>
    ),
  });
};
