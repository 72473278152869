import convert from 'color-convert';

const colors = {
  MAIN: 'var(--primary)',
  MAIN_LIGHT: 'var(--primary_light)',
  MAIN_LIGHTER: 'var(--primary_lighter)',

  BACKGROUND_COLOR: '#e5e5e5',
  FULLSCREEN_COLOR: '#7c7c7c',
  GRID_COLOR: '#00e7e7',

  BLACK: '#000000',
  WHITE: '#ffffff',
  GREEN: '#62a639',
  GREEN_FLASH: '#00ff00',
  GREEN_TRANSFORM_TOOL: '#70ae4b',

  RED: '#d45a5a',
  RED_FLASH: '#ff0000',
  SELECTION: 'var(--selectionColor)',

  GREY_LIGHT: '#e9e8e9',
  GREY: '#bdbdbd',
  GREY_MEDIUM: '#888888',
  GREY_DARK: '#7c7c7c',
  GREY_DARKER: '#505050',

  GREY_BLUE: '#8c98a6',

  ORANGE: '#ff934b', // "#d27f48"

  displayColors: [],

  rgbColors: [
    '#ECFFE7',
    '#E9F2C4',
    '#E0E8EA',
    '#E4F7F7',
    '#FFFFFF',
    '#FBB481',
    '#EFA4FF',
    '#B9A9DF',
    '#5D95FF',
    '#3391D4',
    '#A1D433',
    '#F4F0FF',
    '#F7EFCF',
    '#DCDCE4',
    '#ECFFFF',
    '#DFFF7E',
    '#EF5E66',
    '#EF56B7',
    '#8976C7',
    '#4A85FF',
    '#3399BC',
    '#99E733',
    '#ECE4EF',
    '#E7EFD3',
    '#CEDFE0',
    '#E4F7EF',
    '#DFFF28',
    '#F7333B',
    '#EF0099',
    '#8D86F7',
    '#467DEF',
    '#206CCF',
    '#53B930',
    '#D7EBFF',
    '#E9E7E9',
    '#CEDFE4',
    '#DEF5EB',
    '#FFCC00',
    '#FF4633',
    '#BF0081',
    '#646EC7',
    '#203C9C',
    '#0891C7',
    '#43A930',
    '#E4EFEC',
    '#FFF5DE',
    '#DAEBFF',
    '#CADCD2',
    '#D79928',
    '#C72333',
    '#890066',
    '#4070E7',
    '#2E3484',
    '#4BB176',
    '#289133',
    '#F4F7F7',
    '#E7ECDF',
    '#D2E0E7',
    '#EAF7EA',
    '#DF9100',
    '#CF1B33',
    '#7E006E',
    '#4857D1',
    '#282A78',
    '#63A94B',
    '#187923',
    '#EDF7F7',
    '#F5F1FF',
    '#D4EEF7',
    '#DAEFD2',
    '#CF9900',
    '#892030',
    '#4F0054',
    '#8A96D6',
    '#362074',
    '#3B9133',
    '#007630',
    '#EAF8FF',
    '#F7E9E7',
    '#CDE3F2',
    '#EAFFE6',
    '#D46600',
    '#662B18',
    '#56004E',
    '#3D56A7',
    '#0B3381',
    '#2B9189',
    '#285E28',
    '#E6F2FF',
    '#FBECEF',
    '#CAE4E4',
    '#EAFFCE',
    '#AF3B00',
    '#5A2620',
    '#280056',
    '#304BAF',
    '#303279',
    '#239156',
    '#083C00',
    '#DEE8E1',
    '#ECDCE7',
    '#D2E8E4',
    '#DEF7BE',
    '#B94630',
    '#412324',
    '#4F3028',
    '#2E439F',
    '#2A1046',
    '#33A943',
    '#003300',
    '#E4EDE6',
    '#EFECFF',
    '#CAF0FF',
    '#E2F3C6',
    '#A17E18',
    '#33181E',
    '#230800',
    '#263B97',
    '#183371',
    '#00666E',
    '#00435B',
    '#E6F7BC',
    '#EDE2E7',
    '#CEE8F7',
    '#DEEBB4',
    '#996618',
    '#646974',
    '#201828',
    '#203479',
    '#37314E',
    '#3B4E79',
    '#002F00',
    '#DEEFB4',
    '#F2E9F4',
    '#D9F0F7',
    '#DAE7C4',
    '#896930',
    '#889098',
    '#4B5264',
    '#2B2864',
    '#222346',
    '#505868',
    '#181818',
    '#E4F0D1',
    '#EBE4FF',
    '#F2F7FF',
    '#E8FFD4',
    '#815628',
    '#8A8892',
    '#6C6970',
    '#404143',
    '#20182B',
    '#383840',
    '#000000',
  ],

  oldPrintColorReplacement: [
    '#EFEDE2',
    '#F4E6C8',
    '#DDE0EA',
    '#DCE9E9',
    '#FFFFFF',
    '#F4AB8D',
    '#F0AFCF',
    '#BAB0D6',
    '#63C5F1',
    '#04B3CC',
    '#C6D12D',
    '#F3E5E4',
    '#F4E1CC',
    '#D3D5E6',
    '#EBEEE4',
    '#FDF497',
    '#EB6863',
    '#E86CA6',
    '#907FBB',
    '#24B3E8',
    '#16B1BD',
    '#95BF3D',
    '#F0DEE8',
    '#F0E6D7',
    '#CAD5E0',
    '#E1EDE3',
    '#FCEE3B',
    '#E7212F',
    '#E50585',
    '#8D90C5',
    '#2CA1DB',
    '#00A3D8',
    '#6FB345',
    '#D8EAED',
    '#F1EAE0',
    '#D4DFEA',
    '#D8E8E1',
    '#FDCB0F',
    '#E94F2E',
    '#C71783',
    '#6377B9',
    '#0461AB',
    '#00A2BA',
    '#32A84A',
    '#F2ECE8',
    '#E6D5C8',
    '#CFE0EA',
    '#CADFDB',
    '#F6B719',
    '#E52128',
    '#AB2175',
    '#3681C3',
    '#03519D',
    '#4BB692',
    '#08A14A',
    '#EEEAEA',
    '#F2E9E4',
    '#CBDBE2',
    '#E9EEDF',
    '#F49821',
    '#D61F28',
    '#8F2682',
    '#436FB6',
    '#263D8E',
    '#57B36E',
    '#089547',
    '#EFEEE9',
    '#F2E3DC',
    '#C9E4E7',
    '#DBE7D4',
    '#F4A41E',
    '#B22026',
    '#732A83',
    '#8AA0D2',
    '#312F83',
    '#26AB5A',
    '#0D8242',
    '#E5E2DE',
    '#E9D0CC',
    '#BEDFEB',
    '#D7DCCD',
    '#ED7A24',
    '#933D20',
    '#5A184F',
    '#1A81C4',
    '#02508E',
    '#009F96',
    '#0D6835',
    '#E4E3DE',
    '#F6DEE0',
    '#C1E0E6',
    '#EFEDD2',
    '#E85325',
    '#8B2F1D',
    '#1F194F',
    '#026EB6',
    '#213E79',
    '#009A71',
    '#034D2C',
    '#D8D8D3',
    '#EFD9DE',
    '#CFE8E7',
    '#E7EBCA',
    '#CC4A27',
    '#4C1E11',
    '#4C2815',
    '#0E6AB3',
    '#1E2351',
    '#00984B',
    '#182F1B',
    '#D4D0C8',
    '#E7E0E7',
    '#C6E6E8',
    '#E9E8D3',
    '#C3902C',
    '#2C1310',
    '#260F0F',
    '#085CA7',
    '#044C7C',
    '#008A92',
    '#056068',
    '#F3EBC7',
    '#E9D7D7',
    '#C7E3E4',
    '#E6E1BF',
    '#C37F2A',
    '#7E8C97',
    '#151226',
    '#0A4F91',
    '#3A405C',
    '#415874',
    '#0F2E1C',
    '#EBE3C7',
    '#E5D9E3',
    '#CADCDA',
    '#DBDAC5',
    '#B58636',
    '#A2ADB3',
    '#5C7280',
    '#1D3F74',
    '#163158',
    '#585C68',
    '#1A1B1F',
    '#E5DFCB',
    '#DAD2DF',
    '#E7E6E1',
    '#DBE0C9',
    '#A26127',
    '#B0AEB3',
    '#818085',
    '#4F4F54',
    '#111327',
    '#37363B',
    '#000000',
  ],

  printColorsByID: {},
};

colors.displayColors = colors.rgbColors.map((hex) => {
  hex = hex.substring(1); // remove the #
  const cmyk = convert.hex.cmyk(hex);
  return `#${convert.cmyk.hex(cmyk)}`; // convert to cmyk, then back to hex, so we see if color fits..
});

// create print color replacement list by display color ID
// NOTE: we should not be using this... look at that : https://graphicdesign.stackexchange.com/questions/79815/how-to-make-my-logo-color-look-the-same-in-web-print
// best option is to really choose correct color space at beginning..
colors.displayColors.forEach((hexColor, colorIndex) => {
  colors.printColorsByID[hexColor] =
    colors.oldPrintColorReplacement[colorIndex];
});

export const Colors = colors;
