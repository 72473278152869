export const CATALOGUE_CANVAS = {
  // // --------------------- FORMAT PORTRAIT ------------------------

  // CPP  : starts at 20x30cm

  // // --------------------- FORMAT LANDSCAPE ------------------------

  // CPL  : starts at 30x20cm

  // // --------------------- FORMAT SQUARE ------------------------

  // CPS  : starts at 30x30cm

  // // --------------------- FORMAT FREE ------------------------

  // CPF  : starts at 30x30cm

  products: {
    // // --------------------- Classic canvas ------------------------
    canvas: {
      port: {
        doc: 'CP',
        sizes: [
          '20x30',
          '30x45',
          '40x60',
          // "40x80",
          // "50x75",
          '60x90',
          // "70x105",
          // "80x120",
          '90x135',
          // '100x150',
          // '120x180',
        ],
        default: '20x30',
      },
      land: {
        doc: 'CL',
        sizes: [
          '30x20',
          '45x30',
          '60x40',
          // "75x50",
          '90x60',
          // "80x40",
          // "105x70",
          // "120x80",
          '135x90',
          // '150x100',
          // '180x120',
        ],
        default: '30x20',
      },
      square: {
        doc: 'CS',
        sizes: ['30x30', '40x40', '60x60', '90x90'],
        default: '30x30',
      },
      freeformat: {
        doc: 'CF',
      },
    },

    // // --------------------- Wood ------------------------
    // NOTE wood Canvas (45mm) have been removed from the catalogue in 2024 update
    // wood: {
    //   port: {
    //     doc: 'CP',
    //     sizes: [
    //       // "20x30",
    //       // "30x45",
    //       '40x60',
    //       '40x80',
    //       // "50x75",
    //       '60x90',
    //       // "70x105",
    //       // "80x120",
    //       // "90x135",
    //       // "100x150",
    //       // "120x180",
    //     ],
    //     default: '40x60',
    //   },
    //   land: {
    //     doc: 'CL',
    //     sizes: [
    //       // "30x20",
    //       // "45x30",
    //       '60x40',
    //       // "75x50",
    //       '80x40',
    //       '90x60',
    //       // "105x70",
    //       // "120x80",
    //       // "135x90",
    //       // "150x100",
    //       // "180x120",
    //     ],
    //     default: '60x40',
    //   },
    //   square: {
    //     doc: 'CS',
    //     sizes: [
    //       // "30x30",
    //       '40x40',
    //       '60x60',
    //       '90x90',
    //     ],
    //     default: '40x40',
    //   },
    //   // "freeformat" : {
    //   //   "doc" : "CF",
    //   // }
    // },

    // // --------------------- Poster ------------------------

    poster: {
      port: {
        doc: 'CP',
        sizes: [
          // "20x30",
          '30x45',
          '40x60',
          // "40x80",
          '50x75',
          '60x90',
          '70x105',
          '80x120',
          '90x135',
          // '100x150',
          // '120x180',
        ],
        default: '30x45',
      },
      land: {
        doc: 'CL',
        sizes: [
          // "30x20",
          '45x30',
          '60x40',
          '75x50',
          '90x60',
          // "80x40",
          '105x70',
          '120x80',
          '135x90',
          // '150x100',
          // '180x120',
        ],
        default: '45x30',
      },
      freeformat: {
        doc: 'CF',
      },
    },

    // // --------------------- Plexi ------------------------

    plexi: {
      port: {
        doc: 'CP',
        sizes: [
          '20x30',
          '30x45',
          '40x60',
          // "40x80",
          // "50x75",
          '60x90',
          // "70x105",
          // "80x120",
          '90x135',
          // '100x150',
          // '120x180',
        ],
        default: '20x30',
      },
      land: {
        doc: 'CL',
        sizes: [
          '30x20',
          '45x30',
          '60x40',
          // "75x50",
          '90x60',
          // "80x40",
          // "105x70",
          // "120x80",
          '135x90',
          // '150x100',
          // '180x120',
        ],
        default: '30x20',
      },
      square: {
        doc: 'CS',
        sizes: ['30x30', '40x40', '60x60', '90x90'],
        default: '30x30',
      },
      freeformat: {
        doc: 'CF',
      },
    },

    // // --------------------- Kadapak ------------------------

    // kadapak: {
    //   port: {
    //     doc: 'CP',
    //     sizes: [
    //       // "20x30",
    //       '30x45',
    //       '40x60',
    //       // "40x80",
    //       // "50x75",
    //       '60x90',
    //       // "70x105",
    //       // "80x120",
    //       '90x135',
    //       '100x150',
    //       '120x180',
    //     ],
    //     default: '30x45',
    //   },
    //   land: {
    //     doc: 'CL',
    //     sizes: [
    //       // "30x20",
    //       '45x30',
    //       '60x40',
    //       // "75x50",
    //       '90x60',
    //       // "80x40",
    //       // "105x70",
    //       // "120x80",
    //       '135x90',
    //       '150x100',
    //       '180x120',
    //     ],
    //     default: '45x30',
    //   },
    //   square: {
    //     doc: 'CS',
    //     sizes: ['30x30', '40x40', '60x60', '90x90'],
    //     default: '30x30',
    //   },
    //   freeformat: {
    //     doc: 'CF',
    //   },
    // },

    // // --------------------- Alu dibond ------------------------

    // aluminium: {
    //   port: {
    //     doc: 'CP',
    //     sizes: [
    //       '20x30',
    //       '30x45',
    //       '40x60',
    //       // "40x80",
    //       // "50x75",
    //       '60x90',
    //       // "70x105",
    //       // "80x120",
    //       '90x135',
    //       '100x150',
    //       '120x180',
    //     ],
    //     default: '20x30',
    //   },
    //   land: {
    //     doc: 'CL',
    //     sizes: [
    //       '30x20',
    //       '45x30',
    //       '60x40',
    //       // "75x50",
    //       '90x60',
    //       // "80x40",
    //       // "105x70",
    //       // "120x80",
    //       '135x90',
    //       '150x100',
    //       '180x120',
    //     ],
    //     default: '30x20',
    //   },
    //   square: {
    //     doc: 'CS',
    //     sizes: ['30x30', '40x40', '60x60', '90x90'],
    //     default: '30x30',
    //   },
    //   freeformat: {
    //     doc: 'CF',
    //   },
    // },

    // // --------------------- dibound mounting ------------------------

    aluminium_mounting: {
      port: {
        doc: 'CP',
        sizes: [
          '20x30',
          '30x45',
          '40x60',
          // "40x80",
          // "50x75",
          '60x90',
          // "70x105",
          // "80x120",
          '90x135',
          // '100x150',
          // '120x180',
        ],
        default: '20x30',
      },
      land: {
        doc: 'CL',
        sizes: [
          '30x20',
          '45x30',
          '60x40',
          // "75x50",
          '90x60',
          // "80x40",
          // "105x70",
          // "120x80",
          '135x90',
          // '150x100',
          // '180x120',
        ],
        default: '30x20',
      },
      square: {
        doc: 'CS',
        sizes: ['30x30', '40x40', '60x60', '90x90'],
        default: '30x30',
      },
      freeformat: {
        doc: 'CF',
      },
    },

    // // --------------------- Forex ------------------------

    // forex: {
    //   port: {
    //     doc: 'CP',
    //     sizes: [
    //       '20x30',
    //       '30x45',
    //       '40x60',
    //       // "40x80",
    //       // "50x75",
    //       '60x90',
    //       // "70x105",
    //       // "80x120",
    //       '90x135',
    //       '100x150',
    //       '120x180',
    //     ],
    //     default: '20x30',
    //   },
    //   land: {
    //     doc: 'CL',
    //     sizes: [
    //       '30x20',
    //       '45x30',
    //       '60x40',
    //       // "75x50",
    //       '90x60',
    //       // "80x40",
    //       // "105x70",
    //       // "120x80",
    //       '135x90',
    //       '150x100',
    //       '180x120',
    //     ],
    //     default: '30x20',
    //   },
    //   square: {
    //     doc: 'CS',
    //     sizes: ['30x30', '40x40', '60x60', '90x90'],
    //     default: '30x30',
    //   },
    //   freeformat: {
    //     doc: 'CF',
    //   },
    // },

    // // --------------------- real aluminium ------------------------

    /* Removed real_aluminium at Nico's request 230103
    real_aluminium: {
      port: {
        doc: 'CP',
        sizes: [
          '20x30',
          '30x45',
          '40x60',
          // "40x80",
          // "50x75",
          '60x90',
          // "70x105",
          // "80x120",
          '90x135',
          '100x150',
          '120x180',
        ],
        default: '20x30',
      },
      land: {
        doc: 'CL',
        sizes: [
          '30x20',
          '45x30',
          '60x40',
          // "75x50",
          '90x60',
          // "80x40",
          // "105x70",
          // "120x80",
          '135x90',
          '150x100',
          '180x120',
        ],
        default: '30x20',
      },
      square: {
        doc: 'CS',
        sizes: ['30x30', '40x40', '60x60', '90x90'],
        default: '30x30',
      },
      freeformat: {
        doc: 'CF',
      },
    },
    */
  },

  kadapakFrameColors: {
    black: '#000000',
    silver: '#A6A6A6',
    gold: '#D1B181',
    red: '#8B0000',
    blue: '#123572',
  },

  // size of edge for canvas, in mm
  edgeSize: {
    default: 1,
    kadapak: 5,
    canvas: 20,
    wood: 50, // 45mm + 5mm for bend
  },

  producCodeModifier: {
    canvas: 0,
    // NOTE: 2024 update, wood not available anymore
    // wood: 6900,
    plexi: 7000,
    // kadapak: 7100,
    // aluminium: 7200,
    // forex: 7300,
    poster: 7400,
    // real_aluminium: 7500,
    aluminium_mounting: 7800, // 7600
  },

  // size of cut border
  cutBorder: {
    default: 25,
    canvas: 40,
    wood: 70,
  },

  upgrades: {},

  docs: {
    // // --------------------- PORTRAIT ------------------------

    CP: {
      codes: {
        '20x30': 510,
        '30x45': 511,
        '40x60': 512,
        '40x80': 519,
        '50x75': 515,
        '60x90': 513,
        '70x105': 516,
        '80x120': 517,
        '90x135': 514,
        // '100x150': 518,
        // '120x180': 530,
      },
      multiplier: 2.83464567,
      cutBorder: 40,
      layouts_default: [911],
      layouts_all: [911, 912, 913, 914, 915, 916, 965, 966, 967, 968, 955],
    },

    // // --------------------- LANDSCAPE ------------------------

    CL: {
      codes: {
        '30x20': 520,
        '45x30': 521,
        '60x40': 522,
        '75x50': 525,
        '90x60': 523,
        '80x40': 529,
        '105x70': 526,
        '120x80': 527,
        '135x90': 524,
        '150x100': 528,
        '180x120': 531,
      },
      multiplier: 2.83464567,
      cutBorder: 40,
      layouts_default: [921],
      layouts_all: [921, 922, 923, 924, 925, 926, 975, 976, 977, 978, 979, 960],
    },

    // // --------------------- SQUARE ------------------------

    CS: {
      codes: {
        '30x30': 501,
        '40x40': 502,
        '60x60': 503,
        '90x90': 504,
      },
      multiplier: 2.83464567,
      cutBorder: 40,
      layouts_default: [901],
      layouts_all: [
        901, 902, 903, 904, 905, 906, 907, 908, 985, 986, 987, 988, 951,
      ],
    },

    // // --------------------- Free format ------------------------

    CF: {
      code: 599,
      width: 200,
      height: 200,
      maxSize: '100x150', // in cm
      minSize: 20, // in cm
      multiplier: 2.83464567,
      cutBorder: 40,
      layouts_default: [901],
      layouts_all: [901, 902, 903, 904, 905, 906, 907, 908, 951],
    },
  },
};
