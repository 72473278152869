import { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
};

export const DragGhostImage = ({ children }: Props) => {
  const [currentDragPos, setPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setPos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('dragover', handleMouseMove);
    window.addEventListener('mouseover', handleMouseMove);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('drop', handleMouseMove);

    return () => {
      window.removeEventListener('dragover', handleMouseMove);
      window.removeEventListener('mouseover', handleMouseMove);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('drop', handleMouseMove);
    };
  }, []);

  return (
    <div
      className="absolute top-0 left-0 pointer-events-none z-50"
      style={{
        transform: `translate(${currentDragPos.x}px, ${currentDragPos.y}px)`,
      }}
    >
      {children}
    </div>
  );
};
