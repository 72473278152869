import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../data/config';
import { PROJECT_CLASS } from '../data/Constants';
import { GetText } from '../data/LanguageHelper';
import {
  getRealPageIndexForLayflat,
  isLayflat,
} from '../feature/layflat/layflatHelpers';
import { Project } from '../types/project';
import type { Frame, IPage } from '../types/types';
import { CalendarHelper } from './calendar/CalendarHelper';
import { CardHelper } from './card/CardHelper';
import { IsClassicCoverPage } from './cover/coverHelper';
import { GetDoc } from './ProductHelper';
import { GetUID } from './UID';

/**
 *
 * @param {number} pageIndex
 * @param {number} pageWidth
 * @param {number} pageHeight ()
 * @returns {IPage}
 */
export function CreatePage(
  pageIndex: number,
  pageWidth: number,
  pageHeight: number
): IPage {
  return {
    id: GetUID(),
    index: pageIndex,
    isCover: false, // TODO: this should not be used!! cover pages shoudl be calculated based on PageIndex and DOCID
    width: pageWidth,
    height: pageHeight,
    frames: [],
    coverClassicOptions: null,
    layoutType: null,
  };
}

export const pageContainsFrame = (page: IPage, frameId: string) =>
  page.frames.some((frame) => frame.id === frameId);

export const getPageOfFrame = (pageList: IPage[], frameID: string) => {
  return pageList.find((page) => pageContainsFrame(page, frameID));
};

/**
 * content pages repetition for ordering.
 * Actually only used in cards right now but system is ready
 */
export function GetPagesRepetition(project: Project): number {
  if (IsCardEditor())
    return CardHelper.GetNumRepetitionFromPack(project.cardPack);
  return 1;
}

export function GetPageFrameByID(page: IPage, frameID: string): Frame {
  for (const frame of page.frames) {
    if (frame.id === frameID) return frame;
  }
}

/**
 * Get page groups depending on the project type
 */
export function GetPageGroupList(docID: string, pageList: IPage[]): IPage[][] {
  const pageGroup = [];
  for (let index = 0; index < pageList.length; index += 1) {
    const group = getPageGroup(docID, index, pageList);
    pageGroup.push(group);
    index += group.length - 1;
  }
  return pageGroup;
}

/**
 * retrieve a correct diaplay name for the current group
 */
export function getPageGroupDisplayName(pageGroup: IPage[], docID: string) {
  let displayName = '';

  // -- cover --
  if (
    pageGroup.length === 1 &&
    pageGroup[0].index === 0 &&
    (IsCalendarEditor() || IsAlbumEditor())
  ) {
    displayName =
      IsAlbumEditor() && !IsClassicCoverPage(pageGroup[0])
        ? `${GetText('common.backCover')}   |   ${GetText('common.front')}`
        : GetText('common.cover');
  }

  // -- pages --
  else {
    pageGroup.forEach((page) => {
      if (page.index) {
        // inside pages for exampe do not have indexes, should not be displayed

        // ---- CALENDAR ----
        if (IsCalendarEditor()) {
          const monthIndex = CalendarHelper.GetPageCalendarIndex(page.index);
          const monthWithOffset = CalendarHelper.GetMonthIndexWithOffSet(
            monthIndex,
            true
          );

          // TODO: handle this for all calendars
          displayName = GetText(
            `calendar.month.${CalendarHelper.GetMonthNameByIndex(
              monthWithOffset
            )}`
          );
        }

        // ---- CARD ----
        else if (IsCardEditor()) {
          displayName = ''; // we do not display page group name for cards, we display "per page"

          // special case for merged pages (layflats)
        } else if (isLayflat(docID)) {
          const realIndex = getRealPageIndexForLayflat(page);
          const isDoublePage = pageGroup[0].merged;

          if (isDoublePage) {
            displayName = `${GetText('common.page')} ${realIndex} - ${GetText(
              'common.page'
            )} ${realIndex + 1} `;
          } else {
            displayName = `${GetText('common.page')} ${realIndex}`;
          }
        }

        // ---- ALBUMS and others ----
        else {
          if (displayName !== '') displayName += ' - ';
          displayName += `${GetText('common.page')} ${page.index}`;
        }
      }
    });
  }

  return displayName;
}

export function GetPageDisplayName(docID, pageIndex) {
  let displayName = '';
  const doc = GetDoc(docID);

  // ---- CARDS ----
  if (IsCardEditor()) {
    const displayNum = doc.pages_per_group;
    displayName =
      pageIndex % displayNum === 0
        ? GetText('common.front')
        : pageIndex % displayNum === displayNum - 1
          ? GetText('common.backCover')
          : GetText('common.inside');
  }

  return displayName;
}

/**
 * Retrieve the page group corresponding to a specific page index.
 */
export function getPageGroup(
  docID: string,
  pageIndex: number,
  pageList: IPage[]
): IPage[] {
  let pageGroup = [];
  const projectClass = PROJECT_CONST.project_class;
  const doc = GetDoc(docID);
  const pageAtIndex = pageList[pageIndex];
  const previousPage = pageIndex > 0 ? pageList[pageIndex - 1] : null;
  const nextPage =
    pageIndex < pageList.length - 1 ? pageList[pageIndex + 1] : null;

  // ---- ALBUMS ----
  if (projectClass === PROJECT_CLASS.ALBUM) {
    // case for only one page display
    if (
      pageIndex === 0 ||
      pageIndex === 1 ||
      pageIndex === pageList.length - 1 ||
      pageAtIndex.merged // merged page (layflat)
    ) {
      pageGroup.push(pageAtIndex);
      return pageGroup;
    }

    // case normal pages
    if (pageIndex % 2 === 0) {
      pageGroup.push(pageAtIndex);
      pageGroup.push(nextPage);
    } else {
      pageGroup.push(previousPage);
      pageGroup.push(pageAtIndex);
    }
    return pageGroup;
  }

  // ---- CALENDARS ----
  if (projectClass === PROJECT_CLASS.CALENDAR) {
    // case project calendar has cover
    if (pageIndex === 0) {
      pageGroup.push(pageList[pageIndex]);
      return pageGroup;
    }

    // case pages display is only 1
    if (doc.pages_per_group === 1) {
      pageGroup.push(pageList[pageIndex]);
      return pageGroup;
    }

    // case normal pages, check page display
    if (pageIndex % 2 === 1) {
      pageGroup.push(pageList[pageIndex]);
      pageGroup.push(pageList[pageIndex + 1]);
    } else {
      pageGroup.push(pageList[pageIndex - 1]);
      pageGroup.push(pageList[pageIndex]);
    }
    return pageGroup;
  }

  // ---- CARDS ----
  if (projectClass === PROJECT_CLASS.CARD) {
    const pagePerGroup = doc.pages_per_group;
    const indexInGroup = pageIndex % pagePerGroup;
    const startIndex = pageIndex - indexInGroup;
    for (let index = 0; index < pagePerGroup; index++) {
      pageGroup.push(pageList[startIndex + index]);
    }

    // if more than 2 pages per group, we want to change the display
    if (doc.pages_per_group > 2) {
      // if pages per group is bigger than 2 (for panormic card per example), we do not display the whole group.
      if (pageGroup[0].index === pageIndex) pageGroup = [pageGroup[0]];
      // new array with the first page
      else if (pageGroup[pageGroup.length - 1].index === pageIndex)
        pageGroup = [pageGroup[pageGroup.length - 1]];
      // new array with the last page
      else {
        // page group with all middle/inside pages
        pageGroup.pop();
        pageGroup.shift();
      }
    }

    return pageGroup;
  }

  // ---- CANVAS ----
  if (projectClass === PROJECT_CLASS.CANVAS) {
    pageGroup.push(pageList[pageIndex]);
    return pageGroup;
  }

  // TODO: we should never be here..
  return null;
}
