import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BgColorsOutlined,
  CopyOutlined,
  DiffOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FormOutlined,
  QrcodeOutlined,
  RedoOutlined,
  ScissorOutlined,
  TableOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Button, Col, Popover, Row, Tooltip } from 'antd';
import { IsAlbumEditor, IsCalendarEditor } from '../../../../data/config';
import { GetText } from '../../../../data/LanguageHelper';
import { editionActions } from '../../../../feature/edition/edition';
import { editionSelectors } from '../../../../feature/edition/edition.selector';
import { FRAME_TYPE } from '../../../../feature/frame/frame.types';
import {
  CreateFrame,
  CreateNewFrameText,
} from '../../../../feature/frame/frameHelper';
import { GRID_MODE, UIActions, UISelectors } from '../../../../feature/ui/ui';
import { usePageEditing } from '../../../../hooks/usePageEditing';
import { RandomRange } from '../../../../utils/MathUtils';
import ButtonGroup from 'antd/lib/button/button-group';
import Input from 'antd/lib/input/Input';
import { CalendarColorsModifierPopover } from '../CalendarColorsModifierPopover';
import { ProjectPageModifierPopup } from '../ProjectPageModifierPopup';

const TOOLTIP_DELAY = 1;

export const MainEditionToolbar = () => {
  const dispatch = useDispatch();
  const project = useSelector(editionSelectors.GetProjectSelector);
  const pageList = { project };
  const currentPage = useSelector(editionSelectors.GetSelectedPage);

  const gridMode = useSelector(UISelectors.GetCurrentGridMode);
  const allowGrid = useSelector(UISelectors.allowGrid);
  const allowUndo = useSelector(editionSelectors.allowUndo);
  const allowRedo = useSelector(editionSelectors.allowRedo);
  const allowCopy = useSelector(editionSelectors.allowCopy);
  const allowPaste = useSelector(editionSelectors.allowPaste);

  const pageEdit = usePageEditing();

  const [qrCodePopupOpen, setQRCodePopupOpen] = useState(false);
  const [QRCodeValue, setQRCodeValue] = useState<string>();

  // --------------------- methods ------------------------

  /**
   * Add empty frame to current page
   */
  const handleAddImageToPage = () => {
    pageEdit.start();
    pageEdit.modifyPage((pageCopy) => {
      const newFrame = CreateFrame({
        type: FRAME_TYPE.PHOTO,
        width: pageCopy.width * 0.3,
        height: pageCopy.height * 0.3,
        x: pageCopy.width * RandomRange(0.4, 0.6),
        y: pageCopy.height * RandomRange(0.4, 0.6),
      });
      // let newFrame = new Frame( FRAME_TYPE.PHOTO, editedPage.width*0.3, editedPage.height*0.3);
      // newFrame.x = editedPage.width*RandomRange(.4, .6);
      // newFrame.y = editedPage.height*RandomRange(.4, .6);
      pageCopy.frames.push(newFrame);
      return pageCopy;
    });
    pageEdit.end();
  };

  const handleAddTextToPage = () => {
    pageEdit.start();
    pageEdit.modifyPage((pageCopy) => {
      const newTextFrame = CreateNewFrameText(
        '',
        pageCopy.width * 0.3,
        pageCopy.height * 0.3
      );
      newTextFrame.x = pageCopy.width * RandomRange(0.4, 0.6);
      newTextFrame.y = pageCopy.height * RandomRange(0.4, 0.6);
      pageCopy.frames.push(newTextFrame);
      return pageCopy;
    });
    pageEdit.end();
  };

  const handleAddFrameQRToPage = () => {
    pageEdit.start();
    pageEdit.modifyPage((pageCopy) => {
      const newFrame = CreateFrame({
        type: FRAME_TYPE.QR_CODE,
        width: pageCopy.width * 0.4,
        height: pageCopy.width * 0.4,
        x: pageCopy.width * RandomRange(0.4, 0.6),
        y: pageCopy.height * RandomRange(0.4, 0.6),
        qrCode: {
          qrCode: QRCodeValue,
          color: '#000000',
        },
      });
      pageCopy.frames.push(newFrame);
      setQRCodeValue(undefined);
      return pageCopy;
    });
    pageEdit.end();
  };

  const handleChangeQRCodeValue = (e) => {
    setQRCodeValue(e.target.value);
  };

  const handeGridButtonClick = () => {
    const numGridMode = Object.keys(GRID_MODE).length / 2; // length of enum counts the keys and values, so we divide by 2
    const newGridMode: GRID_MODE = (gridMode + 1) % numGridMode;
    dispatch(UIActions.ChangeCurrentGridMode(newGridMode));
  };

  const handleUndo = () => {
    dispatch(editionActions.Undo());
  };

  const handleRedo = () => {
    dispatch(editionActions.Redo());
  };

  const handleFrameCopy = () => {
    dispatch(editionActions.CopyFrame());
  };

  const handleFrameCut = () => {
    dispatch(editionActions.CutFrame());
  };

  const handleFramePaste = () => {
    dispatch(editionActions.PasteFrame());
  };

  const handleChangePages = () => {};

  // --------------------- render ------------------------

  return (
    <div className="toolbar flex flex-wrap p-3 pr-40">
      {pageList && (
        <Row justify="start" align="middle" gutter={[8, 4]}>
          <Col>
            <ButtonGroup>
              <Button
                className="button"
                disabled={!allowUndo}
                shape="round"
                icon={<UndoOutlined />}
                size="small"
                onClick={() => {
                  handleUndo();
                }}
              >
                {GetText('tooltip.toolbar.button.undo')}
              </Button>
              <Button
                className="button"
                disabled={!allowRedo}
                shape="round"
                icon={<RedoOutlined />}
                size="small"
                onClick={() => {
                  handleRedo();
                }}
              >
                {GetText('tooltip.toolbar.button.redo')}
              </Button>
            </ButtonGroup>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                className="button"
                size="small"
                icon={<FileImageOutlined />}
                onClick={() => {
                  handleAddImageToPage();
                }}
              >
                {GetText('tooltip.toolbar.button.addPhoto')}
              </Button>
              <Button
                className="button"
                size="small"
                icon={<FontSizeOutlined />}
                onClick={() => {
                  handleAddTextToPage();
                }}
              >
                {GetText('tooltip.toolbar.button.addText')}
              </Button>

              {/** ------------------- ADD QRCODE ------------------- */}
              <Popover
                open={qrCodePopupOpen}
                onOpenChange={(open) => setQRCodePopupOpen(open)}
                content={
                  <div className="w-80">
                    <h3>{GetText('popup.qr.code.title')}</h3>
                    <p>{GetText('popup.qr.code.desc')}</p>
                    <Input
                      value={QRCodeValue}
                      onChange={handleChangeQRCodeValue}
                    ></Input>
                    <Button
                      className="w-full mt-2"
                      type="primary"
                      disabled={!QRCodeValue}
                      onClick={() => {
                        handleAddFrameQRToPage();
                        setQRCodePopupOpen(false);
                      }}
                    >
                      {GetText('common.confirm')}
                    </Button>
                  </div>
                }
                trigger="click"
              >
                <Button
                  className="button"
                  size="small"
                  icon={<QrcodeOutlined />}
                  onClick={() => setQRCodePopupOpen(true)}
                >
                  {GetText('tooltip.qr.code.title')}
                </Button>
              </Popover>
            </ButtonGroup>
          </Col>
          <Col>
            <ButtonGroup>
              <Tooltip
                mouseEnterDelay={TOOLTIP_DELAY}
                title={GetText('tooltip.toolbar.button.copy')}
              >
                <Button
                  className="button"
                  disabled={!allowCopy}
                  size="small"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    handleFrameCopy();
                  }}
                />
              </Tooltip>
              <Tooltip
                mouseEnterDelay={TOOLTIP_DELAY}
                title={GetText('tooltip.toolbar.button.cut')}
              >
                <Button
                  className="button"
                  disabled={!allowCopy}
                  size="small"
                  icon={<ScissorOutlined />}
                  onClick={() => {
                    handleFrameCut();
                  }}
                />
              </Tooltip>
              <Tooltip
                mouseEnterDelay={TOOLTIP_DELAY}
                title={GetText('tooltip.toolbar.button.paste')}
              >
                <Button
                  className="button"
                  disabled={!allowPaste}
                  size="small"
                  icon={<DiffOutlined />}
                  onClick={() => {
                    handleFramePaste();
                  }}
                />
              </Tooltip>
            </ButtonGroup>
          </Col>

          {
            // ---- ALBUMS ONLY ----
            IsAlbumEditor() && (
              <Col>
                <ProjectPageModifierPopup>
                  <Button
                    className="button"
                    size="small"
                    icon={<FormOutlined />}
                    onClick={() => {
                      handleChangePages();
                    }}
                  >
                    {GetText('tooltip.toolbar.button.addRemovePages')}
                  </Button>
                </ProjectPageModifierPopup>
              </Col>
            )
          }

          {
            // ---- CALENDAR ONLY ----
            IsCalendarEditor() && currentPage.isCalendar && (
              <Col>
                <CalendarColorsModifierPopover>
                  <Button
                    className="button"
                    size="small"
                    icon={<BgColorsOutlined />}
                    onClick={() => {
                      handleChangePages();
                    }}
                  >
                    {GetText('tooltip.toolbar.button.calendarColors')}
                  </Button>
                </CalendarColorsModifierPopover>
              </Col>
            )
          }

          {
            // ---- Grid ----
            allowGrid && (
              <Col>
                <Button
                  className="button"
                  size="small"
                  icon={<TableOutlined />}
                  onClick={() => {
                    handeGridButtonClick();
                  }}
                >
                  {GetText('tooltip.toolbar.button.grid')}
                </Button>
              </Col>
            )
          }
        </Row>
      )}
    </div>
  );
};
