import SvgTextMultiline from '../../../_components/SvgTextMultiline';
import { DATE_ACTION_ENUM } from '../../../feature/calendar/CalendarEnums';
import { Frame, ICalendarFrameOptions } from '../../../types/types';
import { CalendarHelper } from '../../../utils/calendar/CalendarHelper';

type Props = {
  frame: Frame;
  calendarOptions: ICalendarFrameOptions;
  pageIndex: number;
};

export const FrameMiniCalendar = ({
  frame,
  pageIndex,
  calendarOptions,
}: Props) => {
  let x;
  let y;
  let anchor;
  let baseline;
  let numLines;

  // console.log('calendarOptions', calendarOptions);

  // frame color
  const frameColor = CalendarHelper.GetFrameTextColor(pageIndex, frame);

  // month index
  const monthIndex: number = CalendarHelper.GetPageCalendarIndex(pageIndex); // for XXL and XL, calendar index is a month index

  const monthIndexOffset =
    calendarOptions.dateAction === DATE_ACTION_ENUM.MINICALENDAR_NEXT ? 1 : -1;

  const monthLabel = CalendarHelper.getMiniCalendarMonthWithOffSetYear(
    monthIndex,
    monthIndexOffset,
    true
  );
  // txt.text = CalendarManager.instance.getMiniCalendarMonthWithOffSetYear(monthIndex,monthIndexOffSet,true);
  // txt.setTextFormat(textFormat);
  // txt.x = frameVo.width - txt.width >>1;
  // subContent.addChild(txt);

  // posY = Math.ceil(txt.textHeight) + MeasureManager.PFLToPixel(3);

  // vertical weeks
  const weeks = [];
  for (let i = 0; i < 7; i++) {
    // txt = new TextField();
    // txt.width = frameVo.width/7;
    // txt.multiline = false;
    // txt.antiAliasType = AntiAliasType.ADVANCED;
    // txt.autoSize = TextFieldAutoSize.LEFT;
    // txt.selectable = false;
    // txt.wordWrap = false;
    // txt.embedFonts = true;
    // txt.x = 2 + (frameVo.width/7)*i; // 2 is new margin :: 92106 issue with cropped mini calendar on WCAL3
    // txt.y = posY;
    // var smallerSize:number = MeasureManager.getFontSize(Number(frameVo.calFontSizeRaw)-2,false);
    // textFormat.size = smallerSize;//frameVo.fontSize - 2;
    const verticalWeekIndex = `${monthIndex + 1}${String(i + 1)}`;
    weeks.push(
      CalendarHelper.getVerticalWeek(
        verticalWeekIndex,
        monthIndexOffset,
        1,
        true
      )
    );
    // txt.setTextFormat(textFormat);
    // subContent.addChild(txt);
  }

  // --------------------- RENDER ------------------------

  return (
    <svg
      width={frame.width}
      height={frame.height}
      textRendering="optimizeSpeed"
    >
      <text
        x={frame.width / 2}
        height={frame.height * 0.2}
        width={frame.width}
        className="unselectable"
        style={{
          // overflow:"hidden",
          textAnchor: 'middle', // halign
          dominantBaseline: 'hanging', // valign
          fontSize: calendarOptions.fontSize,
          fontWeight: calendarOptions.bold ? 'bold' : null,
          fontFamily: calendarOptions.fontFamily,
          fill: frameColor,
          // stroke:"black",
          // strokeWidth:2
        }}
      >
        {monthLabel}
      </text>
      <g>
        {weeks.map((week, weekIndex) => (
          <SvgTextMultiline
            key={weekIndex}
            lines={week.split('\n')}
            x={(frame.width / weeks.length) * weekIndex}
            y={frame.height * 0.15}
            width={frame.width / weeks.length}
            // height={frame.height*0.65}
            height={calendarOptions.fontSize * 7.5} // 7.5 after testing that it's correctly working on both XL and XXL
            fontSize={calendarOptions.fontSize - 1}
            fontFamily={calendarOptions.fontFamily}
            fontWeight={calendarOptions.bold ? 'bold' : null}
            fontColor={frameColor}
          />
        ))}
      </g>
    </svg>
  );
};
