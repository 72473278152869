import { Component } from 'react';
import { connect } from 'react-redux';
import { FormOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  InputNumber,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { log } from 'console';
import { PAPER_FINISH } from '../../../../data/Constants';
import { GetText } from '../../../../data/LanguageHelper';
import {
  ALL_MONTHS,
  WEEK_DAYS,
} from '../../../../feature/calendar/CalendarEnums';
import { editionActions } from '../../../../feature/edition/edition';
import { editionSelectors } from '../../../../feature/edition/edition.selector';
import { pricingSelectors } from '../../../../feature/pricing/pricing';
import { Project, ProjectOptions } from '../../../../types/project';
import { ICoverDocument } from '../../../../types/types';
import {
  GetDoc,
  GetPossibleUpgrades,
  GetProjectDocSizeCM,
} from '../../../../utils/ProductHelper';
import { GetProjectDisplayPage } from '../../../../utils/projectHelper';
import { CalendarProjectCreationView } from '../../projectCreation/CalendarProjectCreationView';

const { Paragraph } = Typography;
const { Option } = Select;

class CalendarProjectArea extends Component {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      upgradePopupVisible: false,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {}

  // --------------------- methods ------------------------

  handleProjectNameChange(newName) {
    this.props.dispatch(editionActions.UpdateProjectName(newName));
  }

  handleStartDayChange = (val) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.startDay = val;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  handleStartMonthChange = (val) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.startMonth = val;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  handleYearChange = (val) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    newProjectOptions.startYear = val;
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  handlePaperFinishChange = (paperFinish: string, flag) => {
    const newProjectOptions: ProjectOptions = { ...this.props.projectOptions };
    if (paperFinish === PAPER_FINISH.COATED) {
      newProjectOptions.coated = flag;
      // if(flag) newProjectOptions.matte = false; // be sure to force false to avoid having both options
    } else {
      // newProjectOptions.matte = flag;
      // if(flag) newProjectOptions.coated = false; // be sure to force false to avoid having both options
    }
    this.props.dispatch(editionActions.UpdateProjectOptions(newProjectOptions));
  };

  // --------------------- render ------------------------

  render() {
    const currentProject: Project = this.props.project;
    const numPages: number = currentProject
      ? GetProjectDisplayPage(currentProject)
      : null;
    const projectSizeInCM: string = currentProject
      ? GetProjectDocSizeCM(currentProject.docID)
      : null;

    let doc: ICoverDocument;
    let allowInserts: boolean;
    let pagePaperQualityOptions: Array;
    if (currentProject) doc = GetDoc(currentProject.docID);

    console.log('RENDER CALENDAR PROJECT AREA AREA');
    const previewURL = currentProject
      ? `/images/ui/menu/lefttabs/project/${currentProject.classname}/types_${currentProject.classname}_${currentProject.type}_${currentProject.docID}.jpg`
      : null;

    return (
      <div className="tabContent projectArea">
        {this.state.upgradePopupVisible && (
          <CalendarProjectCreationView
            isUpgrade="true"
            onCloseCallback={() => {
              this.setState({ upgradePopupVisible: false });
            }}
          />
        )}

        <h3>{GetText('lefttab.project')}</h3>

        {currentProject && (
          <div>
            <Paragraph
              editable={{
                onChange: (str) => {
                  this.handleProjectNameChange(str);
                },
              }}
            >
              {currentProject.name}
            </Paragraph>

            <h4>{GetText('calendar.type')}</h4>
            <ul style={{ paddingLeft: '20px', listStyleType: 'circle' }}>
              {/* <li>{GetText("calendar.type.types_calendars_"+currentProject.type)}</li> */}
              <li>{GetText(`calendar.prefix.${currentProject.docID}`)}</li>
              <li>{projectSizeInCM}</li>
              <li>
                {numPages} {GetText('common.pages')}
              </li>
            </ul>

            {
              // ALLOW UPGRADE?
              GetPossibleUpgrades(currentProject.docID).length > 0 && (
                <Button
                  type="primary"
                  size="default"
                  icon={<FormOutlined />}
                  onClick={() => {
                    this.setState({ upgradePopupVisible: true });
                  }}
                >
                  {GetText('common.modify')}
                </Button>
              )
            }

            {/* // --------------------- DAY, MONTH, YEAR SELECTION ------------------------ */}

            <div>
              {/* // ---- DAY SELECTION ---- */}

              <h5 style={{ paddingTop: '12px' }}>
                {GetText('calendar.configurator.starting.day.label')}
              </h5>
              <Select
                style={{ width: '100%' }}
                value={currentProject.startDay}
                size="small"
                onChange={(val) => this.handleStartDayChange(val)}
              >
                {WEEK_DAYS.map((val, index) => (
                  <Option key={val} value={index}>
                    {GetText(`calendar.weekday.${val}`)}
                  </Option>
                ))}
              </Select>

              {/* // ---- MONTH SELECTION ---- */}

              <h5 style={{ paddingTop: '12px' }}>
                {GetText('calendar.configurator.starting.month.label')}
              </h5>
              <Select
                style={{ width: '100%', display: 'inline-block' }}
                value={currentProject.startMonth}
                size="small"
                onChange={(val) => this.handleStartMonthChange(val)}
              >
                {ALL_MONTHS.map((val, index) => (
                  <Option key={val} value={index}>
                    {GetText(`calendar.month.${val}`)}
                  </Option>
                ))}
              </Select>

              {/* // ---- MONTH SELECTION ---- */}

              <h5 style={{ paddingTop: '12px' }}>
                {GetText('calendar.configurator.starting.year.label')}
              </h5>

              <InputNumber
                value={currentProject.startYear}
                size="small"
                min={0}
                max={9999}
                step={1}
                onChange={this.handleYearChange}
              />
            </div>

            {/* <div className="spacerV" /> */}
            <div className="spacerV" />
            <h4>{GetText('calendar.option')}</h4>

            {
              /* // ---- COATING ---- */
              !Number.isNaN(this.props.projectOptionsPrice.coated) && (
                <div>
                  <Tooltip
                    title={GetText('infos.lefttab.calendar.coating')}
                    placement="right"
                  >
                    <Checkbox
                      checked={currentProject.coated}
                      onChange={(e) =>
                        this.handlePaperFinishChange(
                          PAPER_FINISH.COATED,
                          e.target.checked
                        )
                      }
                    >
                      {GetText('lefttab.project.calendar.coated')}{' '}
                      {`(${this.props.projectOptionsPrice.coated}€)`}
                    </Checkbox>
                  </Tooltip>
                </div>
              )
            }

            {/* // --------------------- PREVIEW ------------------------ */}

            <Row className="preview">
              <img src={previewURL} />
            </Row>
          </div>
        )}
      </div>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, edition } = state;
  const { project } = edition;

  return {
    project,
    projectName: editionSelectors.GetProjectName(state),
    projectOptions: editionSelectors.GetProjectOptionsSelector(state),
    projectOptionsPrice: pricingSelectors.GetProjectOptionsPrice(state),
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(CalendarProjectArea);
export { reduxConnected as CalendarProjectArea };
