import { Photo } from '../../../types/types';

export const CreatePhoto = (id: string, name: string): Photo => ({
  ...CreateDefaultImageAsset(id, name),
  // dates
  creation_date: null,
  modification_date: null,
  // temp informations
  temp_url: null,
  temp: false,
  // md5 for dupplicate
  md5: null,
});

/**
 *
 * @param {string} id
 * @param {string} name
 * @returns {ImageAsset}
 */
export const CreateDefaultImageAsset = (id, name) => ({
  id,
  name,
  cat: null,

  // we recover width and height after initial image load
  width: 0,
  height: 0,

  // url informations
  thumb_url: null,
  working_url: null,
  full_url: null,

  // order suffix (for print frameDir)
  orderSuffix: null,
});

/**
 *
 * @param {String} photoID
 * @param {*} photoList
 * @return {Photo}
 */
export function GetPhotoByID(photoID, photoList) {
  let result = null;
  photoList.forEach((photoObj) => {
    if (photoObj.id === photoID) {
      result = photoObj;
    }
  });
  return result;
}
