import { DragEvent, SyntheticEvent, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CheckCircleTwoTone, CloudUploadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import { Dispatch } from 'redux';
import { PhotoDetailPopover } from '../../../_components/popover/PhotoDetailPopover';
import { photoListActions } from '../../../feature/photoList/photo.store';
import type { Photo } from '../../../types/types';
import { cn } from '../../../utils/theme/cn';

type Props = {
  photo: Photo;
  used: boolean; // is the photo used in current session (project)
  onDragStart?: (e: DragEvent<HTMLDivElement>, photo: Photo) => void;
  dispatch: Dispatch;

  // for better performance in lazy loading, pass the scroll position to component to avoid using listeners in the component
  scrollPosition?: { x: number; y: number };
  width?: number;
  height?: number;
  variant?: 'full' | 'withName';
};

const PhotoItem = ({
  photo,
  used,
  onDragStart,
  dispatch,
  width = 90,
  height = 90,
  variant = 'full',
}: Props) => {
  // --------------------- state ------------------------

  const [isLoading, setIsLoading] = useState(false);

  let imageUrl: string;
  if (photo.error) {
    imageUrl = undefined;
  } else if (photo.temp) {
    imageUrl = photo.temp_url;
  } else {
    imageUrl = photo.thumb_url;
  }

  const draggable = !!photo.width;
  // console.log('photoitem');

  // --------------------- Methods ------------------------

  const handleLoadStart = () => {
    setIsLoading(true);
  };

  const handleLoadComplete = (e: SyntheticEvent<HTMLImageElement>) => {
    setIsLoading(false);
    // case first time we load the image, we need to get the size
    if (!photo.width) {
      const tempPhoto = cloneDeep(photo);
      const target = e.target as HTMLImageElement;
      tempPhoto.width = target.naturalWidth;
      tempPhoto.height = target.naturalHeight;
      dispatch(photoListActions.UpdateTempPhotoSize(tempPhoto));
    }
  };

  // --------------------- render ------------------------
  return (
    <div
      className={cn(
        'relative bg-white border-1 border-white overflow-hidden cursor-pointer',
        'hover:border-primaryDark hover:bg-primaryLighter'
      )}
      style={{
        width: width,
        height: height,
      }}
      onDragStart={(e) => onDragStart(e, photo)}
      draggable={draggable}
    >
      <PhotoDetailPopover key={photo.id} photo={photo} isUsed={used}>
        {imageUrl && (
          // Documentation: https://www.npmjs.com/package/react-lazy-load-image-component
          <LazyLoadImage
            threshold={200}
            effect="opacity"
            height={variant === 'withName' ? width - 20 : width}
            delayMethod="debounce"
            delayTime={100}
            width="100%"
            placeholder={
              <div className="flex h-full w-full justify-center items-center">
                <Spin spinning={isLoading} />
              </div>
            }
            beforeLoad={handleLoadStart}
            style={{
              objectFit: 'contain',
            }}
            onLoad={handleLoadComplete}
            draggable={false}
            src={imageUrl}
          />
        )}
      </PhotoDetailPopover>

      {/** ------- label  -----------   */}

      {variant === 'withName' && (
        <div className="absolute text-center bottom-1 w-full text-xxs overflow-hidden whitespace-nowrap text-ellipsis px-1">
          {photo.name}
        </div>
      )}

      {/* ProgressBar for uploading */}
      {photo.temp && !isLoading && (
        <span className="absolute left-0 top-0 flex w-full h-full pointer-events-none justify-center items-center">
          <CloudUploadOutlined className="absolute text-2xl top-1 right-1 text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,0.6)]" />
        </span>
      )}

      {used && (
        <span className="absolute top-1 right-1 pointer-events-none">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </span>
      )}
    </div>
  );
};

export default PhotoItem;
