// https://medium.com/@matthew.holman/what-is-a-redux-selector-a517acee1fe8
import { createSelector } from '@reduxjs/toolkit';
import { cloneDeep, uniq } from 'lodash';
import { IsAlbumEditor } from '../../data/config';
import { StoreType } from '../../store/store.type';
import { Project, ProjectOptions } from '../../types/project';
import { Frame, IPage } from '../../types/types';
import { IsClassicCoverPage } from '../../utils/cover/coverHelper';
import { getPageGroup, GetPageGroupList } from '../../utils/pageHelper';
import { GetDoc } from '../../utils/ProductHelper';
import { GetProjectMaxPages } from '../../utils/projectHelper';
import { getFrameByID } from '../frame/_helpers/getFrameById';
import { FRAME_TYPE } from '../frame/frame.types';
import { photoListSelector } from '../photoList/photo.selector';
import { GetProjectOptions } from '../project/GetProjectOptions';

type RootState = StoreType;
const selfSelect = (state: RootState) => state.edition;
const GetProjectSelector = createSelector(selfSelect, (edition) =>
  edition.project ? edition.project : null
);
const GetPageListSelector = (state: RootState) =>
  state.edition.project?.pageList || null;

const GetProjectName = createSelector(selfSelect, (edition) =>
  edition.project ? edition.project.name : null
);
const GetProjectDocID = createSelector(selfSelect, (edition) =>
  edition.project ? edition.project.docID : null
);
const GetSelectedPageIndex = (state: RootState) => state.edition.selectedPage;

// TODO: this should probably be renameed as "editorClassname"
// const GetProjectClassName = state => state.edition.projectClassname;

const GetProjectCoverSelector = createSelector(
  GetPageListSelector,
  (pageList) => {
    if (IsAlbumEditor()) return pageList[0];
    return null;
  }
);

const GetProjectSizeRatioSelector = createSelector(
  (state) => (state.edition.project ? state.edition.project.width : 0),
  (state) => (state.edition.project ? state.edition.project.height : 0),
  (projectWidth: number, projectHeight: number) => projectWidth / projectHeight
  // return (Math.round(projectWidth) === Math.round(projectHeight))? "square" : (projectWidth>projectHeight)? "landscape" : "portrait";
);

const GetProjectCoverSizeRatioSelector = createSelector(
  GetProjectCoverSelector,
  (coverPage: IPage) => (coverPage ? coverPage.width / coverPage.height : 0)
);

const NeedSave = createSelector(
  (state) => state.edition.project,
  (state) => state.edition.lastSaveTime,
  (state) => state.edition.lastEditTime,
  (state) => photoListSelector.hasPhotosStillImporting(state),
  (project, lastSaveTime, lastEditTime, hasPhotosStillImporting) =>
    project && (lastSaveTime < lastEditTime || hasPhotosStillImporting)
  // BIG BUG Here, if we make an autofill with importing images, then we save the project,
  // -> then images are updated automatically (remap) but project do not seem to need a save..
  // -> client leave without warning, when opening again, still haveing "rc-upload" images..
  // -> WE NEED TO BE SURE ALL IMAGES ON THE LEFT ARE IN THE "project" LIST
  // [ EDIT ] we updatd the lastEditTime after a photo upload!
);

const GetProjectDocumentSelector = createSelector(
  (state) => (state.edition.project ? state.edition.project.docID : null),
  (docID) => (docID ? GetDoc(docID) : null)
);

const GetProjectOptionsSelector = createSelector(
  (state) => (state.edition.project ? state.edition.project : null),
  (project: Project) => {
    if (!project) return null;
    const options: ProjectOptions = GetProjectOptions(project);
    return options;
  }
);

const GetFrameListSelector = createSelector(GetPageListSelector, (pageList) => {
  const frameList: Frame[] = [];
  if (pageList) {
    pageList.forEach((page) => {
      page.frames.forEach((frame) => {
        frameList.push(frame);
      });
    });
  }
  return frameList;
});

const HasMaxPages = createSelector(
  GetProjectOptionsSelector,
  (options: ProjectOptions) => {
    const maxPages = GetProjectMaxPages(options);
    return options.numPages >= maxPages;
  }
);

const GetPhotosUsedSelector = createSelector(
  GetFrameListSelector,
  (frameList) => {
    return uniq(frameList?.map((frame) => frame.photo)) || [];
  }
);

// /**
//  * Retreive list of all unused photos
//  */
// const GetAllUnusedPhotosSelector = createSelector(
//   GetPhotosUsedSelector,
//   GetAllPhotoListSelector,
//   (photoUsed, allPhotosList) =>
//     allPhotosList.filter((item: Photo) => !photoUsed[item.id] && item.width) // if item has a width we are ok
// );

const GetPageGroupListSelector = createSelector(
  GetPageListSelector, // recover pagelist
  GetProjectDocID,
  (pageList, docID) => GetPageGroupList(docID, pageList)
);

const GetCurrentVisiblePages = createSelector(
  GetProjectDocID,
  (state) => state.edition.selectedPage,
  GetPageListSelector,
  (docID, selectedPageIndex, pageList) =>
    getPageGroup(docID, selectedPageIndex, pageList)
);

const GetAllVisibleFramesByID = createSelector(
  GetCurrentVisiblePages, // recover visible pages
  (visiblePageList) => {
    const visibleFrames = {};
    visiblePageList.forEach((page) => {
      page.frames.forEach((frame) => {
        visibleFrames[frame.id] = cloneDeep(frame);
      });
    });
    return visibleFrames;
  }
);

const GetSelectedPage = createSelector(
  GetSelectedPageIndex,
  GetPageListSelector,
  (selectedPageIndex, pageList) => {
    if (!pageList || pageList.length <= selectedPageIndex) return null;
    return pageList[selectedPageIndex];
  }
);

const GetSelectedFrame = createSelector(
  (state: RootState) => state.edition.selectedFrameID,
  GetPageListSelector,
  (selectedFrameID, pageList) => getFrameByID(selectedFrameID, pageList)
);

const allowUndo = createSelector(
  (state) => state.edition,
  (edition) =>
    edition.history &&
    edition.history.length > 1 &&
    edition.historyIndex < edition.history.length - 1
);

function allowRedo(state) {
  const { edition } = state;
  return edition.history && edition.historyIndex > 0;
}

const allowCopy = createSelector(
  GetSelectedFrame,
  // TODO: updat this rule!
  (selectedFrame: Frame) =>
    selectedFrame &&
    (selectedFrame.type === FRAME_TYPE.PHOTO ||
      selectedFrame.type === FRAME_TYPE.CLIPART ||
      selectedFrame.type === FRAME_TYPE.OVERLAYER ||
      selectedFrame.type === FRAME_TYPE.TEXT)
);

const allowPaste = createSelector(
  (state) => state.edition.clipboardFrame,
  GetSelectedPage,
  (clipboardFrame, selectedPage: IPage) => {
    // no frame to paste
    if (!clipboardFrame) return false;
    // do not allow to paste on a classic page
    if (!selectedPage || IsClassicCoverPage(selectedPage)) return false;
    // otherwise we are good..
    return true;
  }
);
// --------------------- export --------------------------------

export const editionSelectors = {
  GetProjectSelector,
  GetProjectName,
  GetProjectCoverSelector,
  GetProjectSizeRatioSelector,
  GetProjectCoverSizeRatioSelector,

  GetSelectedPageIndex, // page index
  GetSelectedPage, // page
  GetSelectedFrameID: (state: RootState) => state.edition.selectedFrameID,
  allowUndo,
  allowRedo,
  allowCopy,
  allowPaste,
  GetPhotosUsedSelector,

  HasMaxPages,
  NeedSave,
  GetAllVisibleFramesByID,

  GetPageGroupListSelector,
  GetProjectOptionsSelector,
};
