import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export const toast = ({
  title,
  ...otherProps
}: {
  title: string;
} & Omit<ArgsProps, 'message'>) => {
  notification.open({
    type: 'info',
    message: title,
    ...otherProps,
  });
};
