import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { SPECIAL_CATEGORIES } from '../../_components/PhotoManager/types';
import { GetText } from '../../data/LanguageHelper';
import { editionSelectors } from '../../feature/edition/edition.selector';
import { photoListSelector } from '../../feature/photoList/photo.selector';
import { tempImageFolderName } from '../../feature/photoList/photo.type';
import { projectListSelectors } from '../../feature/projectList/projectList';
import { Category } from '../../types/types';
import {
  isProjectCategory,
  sortCategories,
} from '../../utils/folder/folderHelper';

export const getProjectCategoryId = (projectId: string) =>
  projectId ? `@@${projectId}@@` : tempImageFolderName;

/**
 * retrieve the current project category ID
 */
export const getProjectCategoryIdSelector = createSelector(
  editionSelectors.GetProjectSelector,
  (project) => {
    return getProjectCategoryId(project?.id);
  }
);

/**
 * retrieve an array of all categories
 */
export const getPhotoCategoriesSelector = createSelector(
  photoListSelector.getAllPhotoList,
  projectListSelectors.getAllProjects,
  getProjectCategoryIdSelector,
  editionSelectors.GetProjectName,
  (photoList, projectList, projectCategoryId, projectName) => {
    const categories = uniq(photoList.map((photo) => photo.cat));
    // let's map the project ids to their names
    const projectIdNameMap = projectList?.map((p) => ({
      id: p.id,
      name: p.name,
    }));

    // we want to make sure we have a folder for the current project
    if (projectCategoryId) {
      if (!categories.includes(projectCategoryId))
        categories.unshift(projectCategoryId);
    }

    // return categories with project name mapped as label
    return (
      categories
        // we don't want to display "DELETED" category
        .filter((cat) => cat !== SPECIAL_CATEGORIES.DELETED)
        // we want to display project names instead of project ids
        .map((cat) => {
          let label =
            isProjectCategory(cat) && projectIdNameMap
              ? projectIdNameMap.find((project) => cat === `@@${project.id}@@`)
                  ?.name
              : cat;
          // rename editor 2 category
          if (cat === SPECIAL_CATEGORIES.EDITOR2)
            label = GetText('category.editor2.label');
          // rename current project category
          if (cat === projectCategoryId) label = projectName;
          return {
            id: cat,
            label: label || cat,
            isProject: isProjectCategory(cat),
          } as Category;
        })
        // then we sort by label
        .sort(sortCategories)
        // be sure to put the project first
        .sort((a, _b) => {
          if (a.id === projectCategoryId) return -1;
          return 0;
        })
    );
  }
);
