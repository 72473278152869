import { injectPhotoIntoFrame } from '../frame/_helpers/injectPhotoIntoFrame';
import { FRAME_TYPE } from '../frame/frame.types';
import { CreateFrame } from '../frame/frameHelper';
import { CreateDefaultImageAsset } from '../photoList/helpers/photoHelper';

/**
 *
 * @param {string} id
 * @param {width} width
 * @param {height} height
 * @returns {OverlayerItem}
 */
export const CreateOverlayerItem = (id, width, height) => ({
  ...CreateDefaultImageAsset(id, id),
  width: Number(width),
  height: Number(height),

  // rect use for internal image
  bLeft: 0, // padding left
  bTop: 0, // padding top
  bWidth: 0, // padding right
  bHeight: 0, // padding bottom

  proxy: null,
});

/**
 *
 * @param {IPage} page : the page that needs to be updated
 * @param {OverlayerItem} overlayerItem : the new layout to apply to this page
 */
function AddOverlayerToPage(page, overlayerItem, dropPoint) {
  const overlayerRatio = overlayerItem.width / overlayerItem.height;
  const frameHeight = page.height * 0.7;
  const frameWidth = frameHeight * overlayerRatio;

  if (!dropPoint) {
    dropPoint = {
      x: page.width / 2,
      y: page.height / 2,
    };
  }

  // overlayer content rect (is the content frame)
  const contentRect = GetOverLayerContentRect(
    overlayerItem,
    frameWidth,
    frameHeight
  );

  const overlayerFrame = CreateFrame({
    type: FRAME_TYPE.OVERLAYER,
    width: contentRect.width,
    height: contentRect.height,
    x: dropPoint.x,
    y: dropPoint.y,
    overlayer: overlayerItem.id,
  });

  page.frames.push(overlayerFrame);
}

/**
 *
 * @param {Frame} frame
 * @param {OverlayerItem} overlayerItem
 * @param {Photo} photoItem
 */
function AddOverlayerToFrame(frame, overlayerItem, photoItem) {
  // find correct frame ratio based on overlayer content ratio
  // let widthRatio = overlayerItem.width / frame.width;
  // let heightRatio = overlayerItem.height / frame.height;
  // let ratio = widthRatio < heightRatio ? widthRatio : heightRatio;
  frame.width = (frame.height * overlayerItem.width) / overlayerItem.height;

  // update frame to be an "overlayer"
  frame.overlayer = overlayerItem.id;
  frame.type = FRAME_TYPE.OVERLAYER;

  // if adding an overlayer, we remove border and radius
  frame.border = 0;
  frame.borderRadius = 0;

  // inject photo inside frame
  injectPhotoIntoFrame(frame, photoItem);
}

/**
Retrieve the rect for a frame inside an overlayer
 * @param {OverlayerItem} overlayer
 * @param {number} frameWidth
 * @param {number} frameHeight
 * @returns {Rectangle}
 */
function GetOverLayerContentRect(overlayer, frameWidth, frameHeight) {
  const scaleX = frameWidth / overlayer.width;
  const scaleY = frameHeight / overlayer.height;

  return {
    x: overlayer.bLeft * scaleX,
    y: overlayer.bTop * scaleY,
    width: (overlayer.width - overlayer.bLeft - overlayer.bWidth) * scaleX,
    height: (overlayer.height - overlayer.bTop - overlayer.bHeight) * scaleY,
  };
}

/**
Retrive the overlayer rect outside a frame
 * @param {OverlayerItem} overlayer
 * @param {Frame} frame
 * @returns
 */
function GetOverLayerOverRect(overlayer, frame) {
  const contentRect = GetOverLayerContentRect(
    overlayer,
    frame.width,
    frame.height
  );
  const scaleX = frame.width / contentRect.width;
  const scaleY = frame.height / contentRect.height;

  return {
    x: -contentRect.x * scaleX,
    y: -contentRect.y * scaleY,
    width: frame.width * scaleX,
    height: frame.height * scaleY,
  };
}

export const OverlayerHelper = {
  AddOverlayerToPage,
  AddOverlayerToFrame,
  GetOverLayerContentRect,
  GetOverLayerOverRect,
};
