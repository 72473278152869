import { useState } from 'react';

export const usePagination = (
  totalPages: number,
  initialPageIndex: number = 0
) => {
  const [pageIndex, setPageIndex] = useState<number>(initialPageIndex);
  return {
    pageIndex,
    setPageIndex,
    nextPage: () => {
      if (pageIndex < totalPages - 1) {
        setPageIndex(pageIndex + 1);
      }
    },
    previousPage: () => {
      if (pageIndex > 0) {
        setPageIndex(pageIndex - 1);
      }
    },
  };
};
