import { Frame, IPage } from '../../types/types';

/**
 * Returns all the snapping lines available for the current page group and edited frame
 * @param pageGroup
 * @param editedPage
 * @param editedFrame
 * @returns
 */
export const getSnappingLines = (
  pageGroup: IPage[],
  editedPage: IPage,
  editedFrame: Frame
): { v: number[]; h: number[] } => {
  // no snapping lines if nothing in edition
  if (!pageGroup || !editedPage || !editedFrame) return undefined;

  const snappingLines = {
    v: [],
    h: [],
  };

  // TODO: adapt for page with orientation vertical!! (test snapping lines in cards)
  // let verticalDisplay = (doc.pages_group_orientation === "vertical");
  // const pageIndex = 0;
  for (const page of pageGroup) {
    // let pageX:Number = verticalDisplay? 0 : pageIndex * page.width + pageSpacing;
    // let pageY:Number = verticalDisplay? pageIndex * page.height + pageSpacing : 0 ;

    // keep this security for special pages (linke insidepagearea) that do not have frames!
    if (page && page.frames) {
      for (const frame of page.frames) {
        if (editedPage.index === page.index) {
          snappingLines.v.push(frame.x);
          if (editedFrame.id !== frame.id) {
            // do not snap the same frame border lines, only center
            snappingLines.v.push(frame.x - frame.width / 2);
            snappingLines.v.push(frame.x + frame.width / 2);
          }
        }

        snappingLines.h.push(frame.y);
        if (editedFrame.id !== frame.id) {
          // do not snap the same frame border lines, only center
          snappingLines.h.push(frame.y - frame.height / 2);
          snappingLines.h.push(frame.y + frame.height / 2);
        }
      }
    }
  }
  return snappingLines;
};
