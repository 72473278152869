// category check
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { SPECIAL_CATEGORIES } from '../../../_components/PhotoManager/types';
import { getProjectCategoryIdSelector } from '../../../store/customSelectors/selectors';
import { Photo } from '../../../types/types';
import { notifyError } from '../../../utils/error/notifyError';
import { RcFile } from 'antd/lib/upload';
import { CreatePhoto } from './photoHelper';

export const useCreateTempPhoto = () => {
  // default category is current project category
  const defaultCategory = useSelector(getProjectCategoryIdSelector);

  // lets validte the category
  const validateCategory = (category: string): string => {
    // this should never happen, but just in case
    if (
      !category ||
      (
        [SPECIAL_CATEGORIES.DELETED, SPECIAL_CATEGORIES.EDITOR2] as string[]
      ).includes(category)
    ) {
      notifyError('Invalid category: ' + category);
      return defaultCategory;
    }
    return category;
  };

  // create a temporary photo object
  const CreateTempPhoto = (
    fileObj: RcFile,
    fileTempSrc: string,
    category: string
  ): Photo => {
    const tempPhoto = CreatePhoto(fileObj.uid, fileObj.name);
    tempPhoto.creation_date = `${fileObj.lastModified}`;
    tempPhoto.modification_date = `${fileObj.lastModified}`;
    tempPhoto.temp = true;
    tempPhoto.temp_url = fileTempSrc;
    tempPhoto.cat = category ? validateCategory(category) : defaultCategory;

    // md5 signature
    tempPhoto.md5 = CryptoJS.MD5(`${tempPhoto.name}${fileObj.size}`).toString();
    return tempPhoto;
  };

  return CreateTempPhoto;
};
