export const tempImageFolderName = '@@temp@@';

export enum ASSET_TYPE {
  PHOTO = 'photo',
  BACKGROUND = 'background',
  CLIPART = 'clipart',
}

export enum PHOTO_FILTER_OPTIONS {
  BY_DATE_ASC = 'BY_DATE_ASC',
  BY_DATE_DESC = 'BY_DATE_DESC',
  BY_IMPORT_DATE_ASC = 'BY_IMPORT_DATE_ASC',
  BY_IMPORT_DATE_DESC = 'BY_IMPORT_DATE_DESC',
  BY_NAME_ASC = 'BY_NAME_ASC',
  BY_NAME_DESC = 'BY_NAME_DESC',
}
