import { createReducer, createSelector } from '@reduxjs/toolkit';
import screenfull from 'screenfull';
import { TABS_ENUM } from '../../pages/homePage/leftArea/TABS_ENUM';
import type { IPage } from '../../types/types';
import { editionActions } from '../edition/edition';
import { editionSelectors } from '../edition/edition.selector';

/** **********************************
// ACTIONS TYPES
************************************ */

const CHANGE_MAIN_TAB = 'UI/CHANGE_MAIN_TAB';
const SET_PREVIEW_MODE = 'UI/SET_PREVIEW_MODE';
const UPDATE_MAIN_LOADING = 'UI/UPDATE_MAIN_LOADING';
const CHANGE_GRID_MODE = 'UI/CHANGE_GRID_MODE';
const TOGGLE_PHOTO_MANAGER = 'UI/TOGGLE_PHOTO_MANAGER';
const CHANGE_PHOTO_CATEGORY = 'UI/CHANGE_PHOTO_CATEGORY';

/** **********************************
// REDUCERS
************************************ */

export enum GRID_MODE {
  NONE = 0,
  SMALL = 1,
  MEDIUM = 2,
  BIG = 3,
}

type UIState = {
  selectedTab: string;
  tabsOpen: boolean;
  isPreview: boolean;
  gridMode: number;
  photoManagerOpen: boolean;
  photoCategory?: string;
  mainLoading: {
    title: string;
    percent: number;
    visibility: boolean;
  };
};
type RootState = {
  ui: UIState;
};

const initialState: UIState = {
  selectedTab: TABS_ENUM.PHOTO,
  tabsOpen: false,
  isPreview: false,
  gridMode: GRID_MODE.NONE,
  photoManagerOpen: false,

  // main loading
  mainLoading: {
    title: 'loading',
    percent: 10,
    visibility: false,
  },
};

const reducer = createReducer(initialState, {
  // --------------------- UNDO REDO ------------------------
  [CHANGE_MAIN_TAB]: (state, action) => {
    state.selectedTab = action.selectedTab
      ? action.selectedTab
      : state.selectedTab;
    state.tabsOpen = action.open ? action.open : state.tabsOpen;
  },

  [SET_PREVIEW_MODE]: (state, action) => {
    state.isPreview = action.isPreview;
  },

  [TOGGLE_PHOTO_MANAGER]: (state, { isOpen }) => {
    state.photoManagerOpen = isOpen;
  },

  [UPDATE_MAIN_LOADING]: (state, action) => {
    state.mainLoading = { ...action.loadingProps };
  },

  [CHANGE_GRID_MODE]: (state, action) => {
    state.gridMode = action.gridMode;
  },

  [CHANGE_PHOTO_CATEGORY]: (state, action) => {
    state.photoCategory = action.photoCategory;
  },
});
export default reducer;

/** **********************************
// SIMPLE ACTIONS (creator)
************************************ */

function changeMainTab(selectedTab, open) {
  return { type: CHANGE_MAIN_TAB, selectedTab, open };
}

function changePhotoCategory(photoCategory) {
  return { type: CHANGE_PHOTO_CATEGORY, photoCategory };
}

function SetPreviewMode(isPreview) {
  return (dispatch, getState) => {
    // put fullscreen
    // if(isPreview){
    //   if(IsSafari())
    //     document.body.webkitRequestFullScreen();
    //   else
    //     document.body.requestFullscreen();

    //   document.onfullscreenchange = (event)=>{
    //     if(!document.fullscreenElement){
    //       dispatch(SetPreviewMode(false));
    //       document.onfullscreenchange = null;
    //     }

    //   }
    // }
    // else if (document.fullscreenElement){
    //   document.exitFullscreen();
    //   document.onfullscreenchange = null;
    // }

    // handle fullscreen with special crossplatform library..
    if (isPreview) {
      // when going fullscreen, disable currently selected frame
      dispatch(editionActions.ChangeSelectedFrame(null));
      // request fullscreen
      screenfull.request();
      // listen to change to put back preview mode to false
      screenfull.onchange((event) => {
        if (!screenfull.isFullscreen) {
          dispatch(SetPreviewMode(false));
          screenfull.onchange(null);
        }
      });
    } else if (screenfull.isFullscreen) {
      screenfull.exit();
      screenfull.onchange(null);
    }

    dispatch({ type: SET_PREVIEW_MODE, isPreview });
  };
}

function UpdateMainLoading(visibility: boolean, title = '', percent = -1) {
  return {
    type: UPDATE_MAIN_LOADING,
    loadingProps: { visibility, title, percent },
  };
}

function ChangeCurrentGridMode(newGridMode: number) {
  return { type: CHANGE_GRID_MODE, gridMode: newGridMode };
}

/** **********************************
// COMPLEX ASYNC ACTIONS
************************************ */

const IsPreviewMode = createSelector(
  (state: RootState) => state.ui.isPreview,
  (isPreview) => isPreview
);

const GetMainLoadingProps = createSelector(
  (state) => state.ui.mainLoading,
  (mainLoadingProps) => mainLoadingProps
);

const GetCurrentGridMode = (state) => state.ui && state.ui.gridMode;

const allowGrid = createSelector(
  (state) => editionSelectors && editionSelectors.GetSelectedPage(state),
  (selectedPage: IPage) => {
    if (selectedPage && selectedPage.coverClassicOptions) return false;
    return true;
  }
);

/** **********************************
// EXPORT PUBLIC ACTIONS
************************************ */

export const UIActions = {
  changeMainTab,
  changePhotoCategory,
  SetPreviewMode,
  togglePhotoManager: (isOpen: boolean) => ({
    type: TOGGLE_PHOTO_MANAGER,
    isOpen,
  }),
  UpdateMainLoading,
  ChangeCurrentGridMode,

  // shortcuts
  ShowLoading: (title: string, percent: number) => (dispatch) =>
    dispatch(UIActions.UpdateMainLoading(true, title, percent)),
  HideLoading: () => (dispatch) => dispatch(UIActions.UpdateMainLoading(false)),
};

export const UISelectors = {
  getSelectedTab: (state: RootState) => state.ui.selectedTab,
  getPhotoCategory: (state: RootState) => state.ui.photoCategory,
  IsPreviewMode,
  photoManagerOpen: (state: RootState) => state.ui.photoManagerOpen,
  GetMainLoadingProps,
  allowGrid,
  GetCurrentGridMode,
};
