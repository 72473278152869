import { createRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import ImgIcon from '../../../_components/ImgIcon';
import { PageMiniature } from '../../../_components/PageMiniature/PageMiniature';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../../../data/config';
import { PROJECT_CLASS } from '../../../data/Constants';
import { GetText } from '../../../data/LanguageHelper';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { isLayflat } from '../../../feature/layflat/layflatHelpers';
import { photoListSelector } from '../../../feature/photoList/photo.selector';
import { useAssetsSelector } from '../../../hooks/useAssetsSelector';
import { EmbedIcons } from '../../../images/EmbedIcons';
import { Project } from '../../../types/project';
import type { IPage } from '../../../types/types';
import { CardHelper } from '../../../utils/card/CardHelper';
import { getPageGroupDisplayName } from '../../../utils/pageHelper';
import { cn } from '../../../utils/theme/cn';

export const PageNavigator = () => {
  // REDUX
  const dispatch = useDispatch();
  const photoList = useSelector(photoListSelector.getProjectPhotosList);
  const hasMaxPages = useSelector(editionSelectors.HasMaxPages);
  const pageGroups = useSelector(editionSelectors.GetPageGroupListSelector);
  const assetsSelector = useAssetsSelector();
  const project: Project = useSelector(editionSelectors.GetProjectSelector);
  const selectedPageIndex = useSelector(editionSelectors.GetSelectedPageIndex);

  const { docID, cardPack, id: projectId } = project;
  const isPhotoListLoaded = !!photoList;
  const [expanded, setExpanded] = useState(false);

  // state
  // TODO: maybe use a specific library like react-draggable for this
  const [state, setState] = useState({
    groupOver: null, // index of group currently "over"
    groupDragging: null, // index of group currently "dragge"
    groupTarget: null, // index of current group drop target

    pageOver: null, // index of page currently "over"
    pageDragging: null, // index of page currently "dragged"
    pageTarget: null, // index of current page drop target})
  });

  const selectedItemRef = createRef<HTMLDivElement>();
  let currentPageIndex = 0;

  // --------------------- methods ------------------------

  const checkSetState = (newState) => {
    let stateChanged = false;
    Object.keys(newState).forEach((key) => {
      if (state[key] !== newState[key]) stateChanged = true;
    });
    if (stateChanged) {
      setState({ ...state, ...newState });
    }
  };

  const scrollToCurrentPage = useCallback(() => {
    if (selectedItemRef && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedItemRef]);

  const handleAddPagesAfterGroup = (pageGroup: Array<IPage>) => {
    // alert("adding pages after page:" + pageGroup[pageGroup.length-1].index )
    dispatch(
      editionActions.AddPagesAtIndex(pageGroup[pageGroup.length - 1].index + 1)
    );
  };

  // _handleImageChange(e) {

  //     e.preventDefault();

  //     // save file data
  //     var files = e.target.files;
  //     setState({ files:files, urlList:[] }, ()=>{ checkRenderPreview(0); });
  //     //checkRenderPreview(0);

  //     /*
  //   let reader = new FileReader();
  //   let files = e.target.files;
  //   let imageUrls = []

  //   for (let index = 0; index < files.length; index++) {
  //       let f = files[index];
  //       reader.onloadend = () => {
  //             imageUrls.push(reader.result);
  //             setState({
  //                 files: files,
  //                 urlList: imageUrls
  //         });
  //       }
  //       reader.readAsDataURL(f)
  //   }
  //   */
  // }

  // checkRenderPreview( fileIndex )
  // {
  //     let {files, urlList} = state;

  //     // break
  //     if(fileIndex > files.length -1 )
  //         return;

  //     let reader = new FileReader();
  //     let f = files[fileIndex].originFileObj;
  //     reader.onloadend = () => {
  //         urlList.push(reader.result);
  //         setState({
  //             files: files,
  //             urlList: urlList
  //         }, ()=>{
  //             checkRenderPreview( fileIndex +1  );
  //         });
  //     }
  //     reader.readAsDataURL(f)
  // }

  // onImageDragStart( evt, id, src )
  // {
  //     console.log("dragstart: image id: ", id);
  //     evt.dataTransfer.setData("id", id);
  //     evt.dataTransfer.setData("src", src);
  // }

  const handlePageItemClick = (pageIndex) => {
    // notify store
    dispatch(editionActions.ChangePage(pageIndex));
  };

  const handleToggleOverview = () => {
    setExpanded(!expanded);
  };

  const handlePageDrop = (fromIndex: number, toIndex: number) => {
    dispatch(editionActions.SwapPages(fromIndex, toIndex));
  };

  //
  // Handle page drag/drop system
  //
  // handlePageOver = ( pageIndex )=> {
  //     checkSetState({pageIndex:pageIndex});
  // }
  // handlePageOut = ( pageIndex )=> {
  //     checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragOver = ( pageIndex )=> {
  //     checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragOut = ( pageIndex )=> {
  //     checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragStart = ( pageIndex )=> {
  //     checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDragEnd = ( pageIndex )=> {
  //     checkSetState({pageIndex:pageIndex});
  // }
  // handlePageDrop = ( pageIndex ) =>
  // {}

  //
  // Handle group drag/drop system
  //
  const handleGroupOver = (groupIndex) => {
    if (!state.groupDragging) checkSetState({ groupOver: groupIndex });
  };

  const handleGroupOut = (groupIndex) => {
    checkSetState({ groupOver: null });
  };

  const handleGroupDragOver = (groupIndex) => {
    if (
      state.groupDragging &&
      groupIndex !== 0 // cannot drag just after the cover
      // && state.groupDragging !==groupIndex
      // && state.groupDragging !==groupIndex+1)
    )
      checkSetState({ groupTarget: groupIndex });
  };

  const handleGroupDragOut = (groupIndex) => {
    checkSetState({ groupTarget: null });
  };

  const handleGroupDragStart = (groupIndex) => {
    checkSetState({ groupDragging: groupIndex });
  };

  const handleGroupDragEnd = (groupIndex) => {
    checkSetState({ groupDragging: null, groupTarget: null });
  };

  const handleGroupDrop = (groupIndex) => {
    if (state.groupDragging && state.groupTarget) {
      dispatch(editionActions.MovePageGroup(state.groupDragging, groupIndex));
    }
    checkSetState({ groupDragging: null, groupTarget: null });
  };

  // handleDraggerChange = ( info )=> {

  //     const status = info.file.status;
  //     message.info("File change: (" + info.file.name + "): " + status );

  //     const files = info.fileList;
  //     setState({
  //         files:files,
  //         urlList:[]
  //     },
  //     // on complete
  //     ()=>{
  //         checkRenderPreview(0);
  //     });

  //     /*

  //     const status = info.file.status;
  //     if (status !== 'uploading') {
  //         console.log(info.file, info.fileList);
  //     }
  //     if (status === 'done') {
  //         message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //         message.error(`${info.file.name} file upload failed.`);
  //     }
  //     */

  // }

  // --------------------- effects ------------------------

  useEffect(() => {
    scrollToCurrentPage();
    // NOTE: we disable this eslint rule as otherwise the scroll is done each time the ref change, and it's always the case..
    // TODO: try to understand why and fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageIndex]);

  // --------------------------- render ------------------------------

  return (
    <div
      className="pageNavigator"
      style={{
        position: expanded ? 'absolute' : 'relative',
        height: expanded ? '100%' : '50px',
        top: expanded ? '0' : undefined,
        zIndex: expanded ? 10 : undefined,
        left: expanded ? '0' : undefined,
        width: expanded ? '100%' : undefined,
        transition: 'height 0.5s',
      }}
    >
      <div
        key={projectId}
        style={
          expanded
            ? {
                display: 'flex',
                width: '100%',
                rowGap: '50px',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignContent: 'start',
                justifyContent: 'center',

                overflowX: 'hidden',
                overflowY: 'scroll',
                padding: '60px 30px 0px 30px',
                transition: 'width 0.5s',
              }
            : {
                whiteSpace: 'nowrap',
                overflowX: 'auto',
                overflowY: 'hidden',
                gap: '0px',
                padding: '16px 40px 0px 8px',
                transition: 'width 0.5s',
              }
        }
        className="h-full"
      >
        {isPhotoListLoaded &&
          pageGroups &&
          pageGroups.map((group, groupIndex) => {
            const isCover = IsAlbumEditor() && groupIndex === 0;
            // a group can be dragged only for albums and non cover
            let groupCanBeDragged = IsAlbumEditor() && groupIndex !== 0;
            // in case of layflat, we cannot drag the first and last page neither.
            if (isLayflat(docID)) {
              groupCanBeDragged =
                groupCanBeDragged &&
                groupIndex !== 1 &&
                groupIndex !== pageGroups.length - 1;
            }

            // ---- DISPLAY OR NOT PAGE REPETITION ----
            // show card rep only for cards and middle page for folded cards
            const cardRep = CardHelper.ShouldDisplayPageGroupRepetition(
              docID,
              groupIndex
            );

            return (
              <div
                key={groupIndex}
                role="button"
                tabIndex={0}
                className={
                  state.groupTarget === groupIndex
                    ? 'pageNavigatorGroup_dropTarget'
                    : 'pageNavigatorGroup'
                }
                // mouse hover
                onFocus={() => {}}
                onMouseOver={
                  groupCanBeDragged
                    ? (e) => {
                        handleGroupOver(groupIndex);
                      }
                    : null
                }
                onMouseLeave={(e) => {
                  handleGroupOut(groupIndex);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  handleGroupDragOver(groupIndex);
                }}
                onDrop={(e) => {
                  handleGroupDrop(groupIndex);
                }}
                style={{
                  width: isCover && expanded ? '100%' : 'auto',
                  margin: IsAlbumEditor()
                    ? '0px 30px 0px 25px'
                    : IsCardEditor()
                      ? CardHelper.GetNavigatorPageMargin(docID, groupIndex)
                      : IsCalendarEditor()
                        ? '0px 15px 0px 15px'
                        : '0px 15px 0px 15px',
                }}
              >
                <div className="flex items-center justify-center">
                  {group.map((page) => {
                    const pageIndex = currentPageIndex;
                    currentPageIndex += 1;
                    return (
                      // TODO: maybe reset the "visibility sensor fot better performances"
                      // <VisibilitySensor >
                      <PageMiniature
                        key={pageIndex}
                        docID={docID}
                        ref={
                          selectedPageIndex === pageIndex
                            ? selectedItemRef
                            : null
                        }
                        selected={selectedPageIndex === pageIndex}
                        page={page}
                        pageIndex={pageIndex}
                        assetsSelector={assetsSelector}
                        onClick={(e) => {
                          handlePageItemClick(pageIndex);
                        }}
                        onPageDrop={handlePageDrop}
                      />
                      // </VisibilitySensor>
                    );
                  })}

                  {state.groupOver === groupIndex && (
                    <Tooltip
                      title={GetText('edition.navigator.groupCTA')}
                      style={{ backgroundColor: 'red' }}
                    >
                      <div
                        className="navigatorGroupGrab"
                        draggable={groupCanBeDragged}
                        onDragStart={(e) => {
                          handleGroupDragStart(groupIndex);
                        }}
                        onDragEnd={(e) => {
                          handleGroupDragEnd(groupIndex);
                        }}
                      >
                        <div className="icon">
                          <ImgIcon
                            icon={EmbedIcons.GrabIcon}
                            style={{ width: '90%', height: '90%' }}
                          />
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>

                {/* Page Group display name  */}
                <div
                  className={cn(
                    'w-full pt-1 text-gray-600 text-xs text-center select-none',
                    expanded && 'text-md'
                  )}
                >
                  {getPageGroupDisplayName(group, docID)}
                </div>

                {
                  // ---- card repetition -----
                  cardRep && (
                    <div className="cardRep unselectable unclickable">
                      <span>
                        X{CardHelper.GetNumRepetitionFromPack(cardPack)}
                      </span>
                    </div>
                  )
                }

                {/* ADD Pages between  */}
                {PROJECT_CONST.project_class === PROJECT_CLASS.ALBUM &&
                  groupIndex !== 0 &&
                  // do not allow to add page at end for layflat
                  !(
                    isLayflat(docID) && groupIndex === pageGroups.length - 1
                  ) && (
                    <Tooltip
                      mouseEnterDelay={hasMaxPages ? 0.1 : 1.5}
                      title={
                        hasMaxPages
                          ? GetText('navigator.page.add.tooltip.max')
                          : GetText('navigator.page.add.tooltip')
                      }
                    >
                      <Popconfirm
                        title={GetText('navigator.page.add.title')}
                        okText={GetText('navigator.page.add.ok')}
                        cancelText={GetText('common.cancel')}
                        onConfirm={() => {
                          handleAddPagesAfterGroup(group);
                        }}
                        disabled={hasMaxPages}
                      >
                        {/* TODO: label */}
                        <Button
                          className="addPagesBetweenButton"
                          disabled={hasMaxPages}
                          type="dashed"
                          size="small"
                        >
                          +
                        </Button>
                      </Popconfirm>
                    </Tooltip>
                  )}

                {state.groupTarget === groupIndex && (
                  <div
                    className="dropTargetArea"
                    onDragLeave={() => {
                      handleGroupDragOut(groupIndex);
                    }}
                  />
                  // onDragOver={e=>{e.preventDefault()}}
                  // onDrop={(e)=>{handleGroupDrop(groupIndex)}} />(state.groupTarget === groupIndex)
                )}
              </div>
            );
          })}
      </div>
      <div
        className="right-0 z-10 -top-8"
        style={{
          position: expanded ? 'fixed' : 'absolute',
          top: expanded ? '4px' : '-24px',
          right: expanded ? '20px' : '0px',
          transition: 'all 0.5s',
        }}
      >
        <Button
          className="text-black"
          onClick={handleToggleOverview}
          size="small"
          icon={<ExpandOutlined />}
        >
          {expanded
            ? GetText('navigator.reduce.button')
            : GetText('navigator.expand.button')}
        </Button>
      </div>
    </div>
  );
};
