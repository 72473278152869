import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';
import { Button, InputNumber, Modal, Row, Select, Spin, Tooltip } from 'antd';
import { cloneDeep, isEqual } from 'lodash';
import {
  CatalogueAlbum,
  GetProductCatalogue,
} from '../../../data/catalogue/productCatalogue';
import { Colors } from '../../../data/Colors';
import { PROJECT_CONST } from '../../../data/config';
import { ALBUM_TYPES, COVER_TYPE } from '../../../data/Constants';
import { GetText } from '../../../data/LanguageHelper';
import { DebugFlags } from '../../../debug/DebugFlags';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { authSelectors } from '../../../feature/auth/authentification';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { isLayflat } from '../../../feature/layflat/layflatHelpers';
import { layoutListSelectors } from '../../../feature/layouts/layout.store';
import { useProjectPrice } from '../../../feature/pricing/useProjectPrice';
import { CreateProject } from '../../../feature/project/CreateProject';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { projectListSelectors } from '../../../feature/projectList/projectList';
import { IDocument } from '../../../types/IDocument';
import { Project, ProjectOptions } from '../../../types/project';
import type { RebrandingInfo } from '../../../types/types';
import { CreateClassicCoverOptions } from '../../../utils/cover/coverHelper';
import { getValidCoverType } from '../../../utils/cover/getValidCoverType';
import { history, ROUTE_CONST } from '../../../utils/history';
import { GetDoc, GetProjectDocSizeCM } from '../../../utils/ProductHelper';
import { getProjectPreviewUrl } from '../../../utils/project/getProjectPreviewUrl';
import {
  GetProjectDisplayPage,
  GetProjectMaxPages,
} from '../../../utils/projectHelper';

const { Option } = Select;

type Props = {
  isUpgrade?: boolean;
  onCloseCallback?: () => void;
};

export const AlbumProjectCreationView = ({
  isUpgrade,
  onCloseCallback,
}: Props) => {
  const projectClass = PROJECT_CONST.project_class;

  // --------------------- redux / state ------------------------

  const dispatch = useDispatch();
  const existingProject = useSelector(editionSelectors.GetProjectSelector);
  const projectListIsLoading = useSelector(projectListSelectors.loading);
  const rebranding: RebrandingInfo = useSelector(authSelectors.GetRebranding);
  const layouts = useSelector(layoutListSelectors.getLayoutStore);

  // state
  const [project, setProject] = useState<Project>();
  const [catalogue, setCatalogue] = useState<CatalogueAlbum>(
    GetProductCatalogue() as CatalogueAlbum
  );

  const productList = catalogue.products;
  const productOptionKeys = Object.keys(productList).map((key) => key);

  const [state, setState] = useState({
    product: productOptionKeys[0],
    productOptions: productOptionKeys,
    size: null,
    sizeOptions: null,
    pages: -1,
    coverType: undefined,
    minPages: 0,
    maxPages: 0,
    // projectName: null,
  });

  const docID = state.size;
  const doc = docID ? GetDoc(docID) : undefined;
  const previewURL = getProjectPreviewUrl({
    classname: projectClass,
    projectType: state.product,
    docID,
    coverType: state.coverType,
  });

  // TODO: check if this is still needed
  // For now, layflat products cannot be upgraded
  const productUpgradeDisabled =
    isUpgrade && existingProject && isLayflat(existingProject.docID);

  // Each time the state change, we construct the new project options
  const newProjectOptions = useMemo(() => {
    let options: ProjectOptions = project
      ? GetProjectOptions(project)
      : ({} as ProjectOptions);
    if (docID) {
      options = {
        ...options,
        ...{
          docID,
          type: state.product,
          numPages: state.pages,
          coverClassicOptions:
            state.coverType === COVER_TYPE.COVER_CLASSIC &&
            !options.coverClassicOptions
              ? CreateClassicCoverOptions()
              : options.coverClassicOptions,
          pagePaperQuality:
            options.pagePaperQuality === undefined
              ? doc.pagePaperQuality[doc.pagePaperQuality.length - 1]
              : options.pagePaperQuality, // highest quality as default
          coated:
            options.coated === undefined ? doc.coating_default : options.coated, // highest quality as default
        },
      };
      // TODO: find a way to put this in generic system as it is also in the "applyProjectOptions"
      // securities
      if (options.paper && !doc.insert) options.paper = false;
      if (!doc.pagePaperQuality.includes(options.pagePaperQuality))
        options.pagePaperQuality =
          doc.pagePaperQuality[doc.pagePaperQuality.length - 1]; // take highest quality
      if (!options.flyleaf || !doc.flyleaf) options.flyleaf = 'white';
      return options;
    }
  }, [state, project, docID, doc]);

  const projectPrice = useProjectPrice(newProjectOptions);

  // --------------------- methods ------------------------

  const updateState = (
    product?: string,
    size?: string,
    numPages?: number,
    coverType?: COVER_TYPE
  ) => {
    let currentProduct: string = product || state.product;
    let currentSize: string = size || state.size;
    let currentPages: number = numPages || state.pages;
    let currentCoverType: COVER_TYPE = coverType || state.coverType;

    // verify product is valid
    currentProduct = productOptionKeys.includes(currentProduct)
      ? currentProduct
      : productOptionKeys[0];

    // verify size is valid and update options
    const productNode = catalogue.products[currentProduct];
    const sizeOptions = productNode.docs;
    currentSize = sizeOptions.includes(currentSize)
      ? currentSize
      : productNode.default;

    // verify pages
    const newDocID = currentSize;
    const newDoc: IDocument = catalogue.docs[newDocID];
    const minPages = newDoc.minPages;
    const maxPages = GetProjectMaxPages({
      ...project,
      docID: currentSize,
      coverType: state.coverType,
      type: state.product,
    });

    // by default higher paper quality is always selected
    // const paperQuality =
    //   newDoc.pagePaperQuality[newDoc.pagePaperQuality.length - 1];
    // const maxPageIndex = paperQuality === PAPER_QUALITY.QUALITY_250 ? 1 : 0;
    // const maxPages = newDoc.maxPages[maxPageIndex];
    if (currentPages < minPages) currentPages = minPages;
    if (currentPages > maxPages) currentPages = maxPages;

    // coverType verification
    currentCoverType = getValidCoverType(
      currentProduct,
      currentSize,
      coverType
    );

    const newState = {
      product: currentProduct,
      productOptions: productOptionKeys,
      size: currentSize,
      sizeOptions,
      pages: currentPages,
      coverType: currentCoverType,
      minPages,
      maxPages,
    };

    if (!isEqual(newState, state)) setState(newState);
  };

  const handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }
    // invoke callback if there is one
    onCloseCallback?.();
  };

  const handleProductChange = (product: string) => {
    if (isUpgrade && state.product === product) return;
    updateState(product);
  };

  const handleSizeChange = (size: string) => {
    if (isUpgrade && state.size === size) return;
    updateState(undefined, size);
  };

  const handlePageChange = (numPages: number) => {
    if (isUpgrade && state.pages === numPages) return;
    updateState(
      undefined,
      undefined,
      // security, be sure that value is even for album projects
      numPages % 2 !== 0 ? numPages + 1 : numPages
    );
  };

  const handleCoverChange = (coverType: COVER_TYPE) => {
    if (isUpgrade && state.coverType === coverType) return;
    updateState(undefined, undefined, undefined, coverType);
  };

  const handleConfirm = () => {
    // --------------------- UPGRADE ------------------------
    if (isUpgrade) {
      let targetPages = state.pages;
      const currentProjectPages = GetProjectDisplayPage(project);
      // confirm function
      const onConfirm = () => {
        const updateParams = {
          type: state.product,
          docID,
          numPages: targetPages,
          options: {
            coverType: state.coverType,
          },
        };
        console.log('update params', updateParams);
        dispatch(editionActions.UpgradeCurrentProject(updateParams));
        handleClose();
      };

      // check min pages max pages

      if (targetPages < doc.minPages) {
        targetPages = doc.minPages;
      } else if (targetPages > state.maxPages) {
        targetPages = state.maxPages;
      }

      // if target pages is smaller than current pages, warn user before updating
      if (currentProjectPages > targetPages) {
        const numPagesDeleted = currentProjectPages - targetPages;
        popupHelper.showChangePageDeleteWarning(numPagesDeleted, onConfirm);
      } else {
        onConfirm();
      }
    }

    // ---------------------  NEW PROJECT  ------------------------
    else {
      // create new project
      const proj = CreateProject({
        classname: PROJECT_CONST.project_class,
        docID,
        type: state.product,
        projectName: 'new album project', //
        numPages: state.pages,
        layoutStore: layouts,
        options: {
          coverType: state.coverType,
          pagePaperQuality:
            doc.pagePaperQuality[doc.pagePaperQuality.length - 1], // highest quality as default
          coated: doc.coating_default, // highest quality as default
        },
      });

      // update state
      setProject(proj);
      // show rename popup
      popupHelper.showRenameProjectPopup(
        // success
        (newProjectName) => {
          proj.name = newProjectName;
          dispatch(editionActions.CreateProject(cloneDeep(proj)));
        }
      );
    }
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    if (isUpgrade && existingProject) {
      // TODO: filter catalogue for upgrades only
      // setCatalogue(filterCatalogueForUpgrades(catalogue, existingProject));
      updateState(
        existingProject.type,
        existingProject.docID,
        GetProjectDisplayPage(existingProject),
        existingProject.coverType
      );
      // update project
      setProject(existingProject);
    }
    // ------ initialize for NEW PROJECT -------
    else if (!isUpgrade && !existingProject && !project) {
      handleProductChange(state.productOptions[0]);
    }
  }, [isUpgrade, existingProject, project, catalogue]);

  // filter catalogue products based on rebranding
  useEffect(() => {
    if (rebranding && rebranding.subProductFilter?.albums) {
      const newCatalogue = GetProductCatalogue();
      setCatalogue({
        ...newCatalogue,
        products: rebranding.subProductFilter.albums,
      } as CatalogueAlbum);
    }
    // update product if catalogue change
    if (catalogue) handleProductChange(state.product);
  }, [catalogue, rebranding]);

  // TODO: later get rid of this,
  // TODO: we really need to handle this with UPGRADES node from the catalogue
  // we don't want product to be able to upgrade to "layflat" format for now
  useEffect(() => {
    if (isUpgrade && catalogue && existingProject) {
      // case existing project is layflat
      // we don't change the catalogue as the upgrades for layflat are fully disabled for now
      if (!isLayflat(existingProject.docID) && catalogue.products.layflat) {
        // if layflat is available, remove it from the catalogue
        // TODO: we really need to handle this with UPGRADES node from the catalogue
        const catalogueWithoutLayflats = cloneDeep(catalogue);
        delete catalogueWithoutLayflats.products.layflat;
        setCatalogue(catalogueWithoutLayflats);
      }
    }
  }, [isUpgrade, catalogue]);

  // --------------------- render ------------------------

  return (
    <Modal
      title={
        isUpgrade
          ? GetText('upgradeProject.title')
          : GetText('newProject.title')
      }
      open
      footer={null}
      // width="80%"
      width="600px"
      // onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleClose}
    >
      <Spin spinning={projectListIsLoading}>
        <h3>{GetText('newProject.album.desc')}</h3>

        <h4>{GetText('album.type')}</h4>
        <Select
          style={{ width: 400 }}
          value={state.product}
          placeholder="Select a Product"
          onChange={handleProductChange}
          disabled={productUpgradeDisabled}
        >
          {state.productOptions
            // simple hide layflats if needed by DebugFlags
            .filter(
              (p) => p !== ALBUM_TYPES.LAYFLAT || !DebugFlags.HIDE_LAYFLATS
            )
            .map((key) => (
              <Option key={key} value={key}>
                {GetText(`album.type.types_albums_${key}`)}
              </Option>
            ))}
        </Select>

        <div className="spacerV" />

        <h4>{GetText('album.size')}</h4>
        <Select
          disabled={!state.size || productUpgradeDisabled}
          value={state.size}
          style={{ width: 400 }}
          onChange={handleSizeChange}
        >
          {state.sizeOptions &&
            state.sizeOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`album.prefix.${key}`)} ({GetProjectDocSizeCM(key)})
              </Option>
            ))}
        </Select>

        <div className="spacerV" />

        {/* ------- type of cover ------- */}
        {
          // for now we only want this option for layflats.
          isLayflat(docID) && doc?.coverType && doc.coverType.length > 1 && (
            <>
              <h4>{GetText('album.coverType.label')}</h4>
              <Select
                value={state.coverType}
                style={{ width: 400 }}
                onChange={handleCoverChange}
              >
                {doc.coverType.map((key) => (
                  <Option key={key} value={key}>
                    {GetText(`album.coverType.option.${key}`)}
                  </Option>
                ))}
              </Select>

              <div className="spacerV" />
            </>
          )
        }

        {/* ------- number of pages ------- */}

        <h4>{GetText('page.number')}</h4>
        <InputNumber
          value={state.pages}
          min={state.minPages}
          max={state.maxPages}
          step={10}
          onChange={handlePageChange}
        />
        <div className="spacer" />
        <Tooltip
          title={
            isLayflat(docID)
              ? GetText('tooltip.pages.max.info.layflat')
              : GetText('tooltip.pages.max.info')
          }
        >
          <InfoCircleFilled style={{ fontSize: '16px', color: Colors.MAIN }} />
        </Tooltip>

        <Row>
          <img src={previewURL} />
        </Row>

        <Row justify="space-between">
          <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
            <Button type="link">
              {GetText('newProject.changeEditorClass.button')}
            </Button>
          </Link>
          <Button
            type="primary"
            disabled={projectPrice === 0}
            onClick={handleConfirm}
          >
            {GetText('common.continue')}
            {` (${projectPrice}€)`}
          </Button>
        </Row>
      </Spin>
    </Modal>
  );
};
