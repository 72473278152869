import { COVER_TYPE, PROJECT_CLASS } from '../../data/Constants';
import { isLayflat } from '../../feature/layflat/layflatHelpers';

type PreviewProjectOptions = {
  classname: PROJECT_CLASS;
  projectType: string;
  docID: string;
  coverType: COVER_TYPE;
};

export const getProjectPreviewUrl = ({
  classname,
  projectType,
  docID,
  coverType,
}: PreviewProjectOptions) => {
  const optionalCoverSuffix =
    isLayflat(docID) && coverType === COVER_TYPE.COVER_CLASSIC
      ? '_classic'
      : '';
  return `/images/ui/menu/lefttabs/project/${classname}/types_${classname}_${projectType}_${docID}${optionalCoverSuffix}.png`;
};
