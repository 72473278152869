import { useDispatch, useSelector } from 'react-redux';
import { CanvasEdge } from '../../../data/catalogue/canvasCatalogue.types';
import { IsCanvasEditor } from '../../../data/config';
import { GetText } from '../../../data/LanguageHelper';
import {
  getRealPageIndexForLayflat,
  isLayflat,
} from '../../../feature/layflat/layflatHelpers';
import { useCreateTempPhoto } from '../../../feature/photoList/helpers/useCreateTempPhoto';
import { GRID_MODE } from '../../../feature/ui/ui';
import { useAssetsSelector } from '../../../hooks/useAssetsSelector';
import { getProjectCategoryIdSelector } from '../../../store/customSelectors/selectors';
import type {
  Frame,
  ICalendarColorOptions,
  IPage,
  Photo,
} from '../../../types/types';
import { mmToPoint } from '../../../utils/MeasureUtils';
import { IsCalendar_WithSpiralHoles } from '../../../utils/ProductHelper';
import { FramePageNumber } from './components/FramePageNumber';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { CalendarHolesComp } from './CalendarHolesComp';
import { ClassicCoverArea } from './ClassicCoverArea';
import { FrameArea } from './FrameArea';

type Props = {
  id: string;
  pageIndex: number;
  page: IPage;
  selected: boolean;
  calendarColorOptions: ICalendarColorOptions;
  selectedFrameID: string;
  isPreviewMode: boolean;
  isEditing: boolean;
  editionScale: number;
  x: number;
  y: number;
  docID: string;
  gridMode: GRID_MODE;
  canvasEdge: CanvasEdge;
  showPageNumber: boolean;
  pageNumberColor: string;

  onFrameMouseDown: (pageIndex: number, frameIndex: number, e: Event) => void;
  onFrameDoubleClick: (frame: Frame) => void;
  onFrameItemDrop: (dropEvent, frame: Frame) => void;
  onFramePhotoImport: (photo: Photo, frame: Frame) => void;
  onFrameTextLimitReached: (
    frameID,
    limit: { width?: number; height?: number }
  ) => void;
  onFrameAddTextCtaClick: (frameId: string) => void;
};

export const PageArea = ({
  id,
  pageIndex,
  page,
  selected,
  calendarColorOptions,
  selectedFrameID,
  isPreviewMode,
  isEditing,
  editionScale,
  x,
  y,
  docID,
  gridMode,
  canvasEdge,
  showPageNumber,
  pageNumberColor,
  onFrameMouseDown,
  onFrameDoubleClick,
  onFrameItemDrop,
  onFramePhotoImport,
  onFrameTextLimitReached,
  onFrameAddTextCtaClick,
}: Props) => {
  const currentPage: IPage = page;
  const dispatch = useDispatch();

  const { getPhotosById } = useAssetsSelector();
  const assetSelector = useAssetsSelector();
  const projectCategoryId = useSelector(getProjectCategoryIdSelector);
  const createTempPhoto = useCreateTempPhoto();

  // const pageX = x;
  // const pageY = y;
  // const pageWidth = canvasEdge? currentPage.width + canvasEdge*2 : currentPage.width;
  // const pageHeight = canvasEdge? currentPage.height + canvasEdge*2 : currentPage.height;

  // allow to see content outside the page (for canvas we need this to see the edges!)
  const showCanvasEdge: boolean =
    IsCanvasEditor() && (!isPreviewMode || canvasEdge.isKadapak);

  const gridMM =
    gridMode === GRID_MODE.BIG ? 10 : gridMode === GRID_MODE.MEDIUM ? 5 : 1;

  const gridSize = Math.round(mmToPoint(gridMM) * 100) / 100;
  const gridBigSize = gridSize * 10;

  if (currentPage.calendarColorOptions)
    calendarColorOptions = currentPage.calendarColorOptions;

  // --------------------- methods ------------------------

  /**
   * Handle drag and drop
   */
  const handleDropOnFrame = (evt, frameIndex, frameObj) => {
    onFrameItemDrop(evt, frameObj); //
  };

  const handleDragOverFrame = (evt, frameIndex) => {
    evt.preventDefault(); // needed to allow the drop! see :https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
    // evt.currentTarget.addClass('hover'); // not working
  };

  const handleDragLeaveFrame = (evt, frameIndex) => {
    // evt.currentTarget.removeClass('hover'); // not working
  };

  const dummyUploadRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleUploaderChange = (
    info: UploadChangeParam<UploadFile<any>>,
    frame
  ) => {
    console.log('HandleDraggerChange:' + info.file.name);
    // if status is done, start the import process.. (done as we don't have a real upload )
    // Warning, this works as we don't have the upload process here!
    if (info.file.status === 'done') {
      const imageURL = window.URL.createObjectURL(info.file.originFileObj);
      const tempPhoto = createTempPhoto(
        info.file.originFileObj,
        imageURL,
        projectCategoryId
      );
      onFramePhotoImport(tempPhoto, frame);
    }
  };

  // --------------------- RENDER ------------------------

  return (
    <svg
      x={x}
      y={y}
      width={currentPage.width}
      height={currentPage.height}
      style={{
        overflow: showCanvasEdge ? 'visible' : 'hidden',
        clipPath: showCanvasEdge ? 'url(#clipPath)' : null,
      }}
    >
      {
        // --------------------- CANVAS Background ------------------------
        showCanvasEdge && (
          <g>
            <defs>
              <clipPath id="clipPath">
                <rect
                  x={-canvasEdge.size}
                  y={-canvasEdge.size}
                  width={page.width + canvasEdge.size * 2}
                  height={page.height + canvasEdge.size * 2}
                />
              </clipPath>
            </defs>
            <rect
              x={-canvasEdge.size}
              y={-canvasEdge.size}
              width={page.width + canvasEdge.size * 2}
              height={page.height + canvasEdge.size * 2}
              className="canvasEdgeBG"
            />
          </g>
        )
      }

      {
        /* // --------------------- CASE NORMAL PAGE ------------------------ */
        !currentPage.coverClassicOptions && getPhotosById && (
          <g>
            {/* // --------------------- background ------------------------ */}
            <rect
              id={id}
              x="0"
              y="0"
              width="100%"
              height="100%"
              className="pageArea"
            />

            {
              // --------------------- Display Frames ------------------------
              getPhotosById &&
                currentPage.frames.map((frame: Frame, i) => (
                  <FrameArea
                    key={frame.id}
                    frame={frame}
                    pageIndex={pageIndex}
                    frameIndex={i}
                    editionScale={editionScale}
                    isSelected={selectedFrameID === frame.id}
                    isEditing={selectedFrameID === frame.id && isEditing}
                    isPreviewMode={isPreviewMode}
                    calendarColorOptions={calendarColorOptions}
                    assetSelector={assetSelector}
                    onFrameMouseDown={(frameIndex, e) => {
                      onFrameMouseDown(pageIndex, frameIndex, e);
                    }}
                    onFrameDoubleClick={(f) => {
                      onFrameDoubleClick(f);
                    }}
                    onDragOverFrame={(e, i) => {
                      handleDragOverFrame(e, i);
                    }}
                    onDragLeaveFrame={(e, i) => {
                      handleDragLeaveFrame(e, i);
                    }}
                    onDropOnFrame={(e, i, frame) => {
                      handleDropOnFrame(e, i, frame);
                    }}
                    onDummyUploadRequest={dummyUploadRequest}
                    onUploaderChange={(info, frame) => {
                      handleUploaderChange(info, frame);
                    }}
                    onFrameTextLimitReached={(limit) =>
                      onFrameTextLimitReached(frame.id, limit)
                    }
                    onAddTextCtaClicked={() => onFrameAddTextCtaClick(frame.id)}
                  />
                ))
            }

            {
              // --------------------- Display page number frame ------------------------
              showPageNumber && (
                <FramePageNumber
                  page={page}
                  color={pageNumberColor}
                  displayPageIndex={
                    isLayflat(docID)
                      ? getRealPageIndexForLayflat(page)
                      : page.index
                  }
                />
              )
            }
          </g>
        )
      }

      {
        // --------------------- Selection stroke ------------------------
        selected && !isPreviewMode && !currentPage.coverClassicOptions && (
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            className="pageSelected"
          />
        )
      }

      {
        // --------------------- CANVAS EDGE ------------------------
        showCanvasEdge && (
          <g>
            {/* // EDGE */}
            <polyline
              className="canvasEdgeFront unselectable unclickable"
              points={`${canvasEdge.left} ${canvasEdge.top}, 
                                ${canvasEdge.right} ${canvasEdge.top}, 
                                ${canvasEdge.right} ${canvasEdge.bottom}, 
                                ${canvasEdge.left} ${canvasEdge.bottom}, 
                                ${canvasEdge.left} ${canvasEdge.top}`}
              style={canvasEdge.style}
            />

            {
              /* // Ege lines */
              !isPreviewMode && (
                <g>
                  <line
                    x1={-canvasEdge.size}
                    y1={0}
                    x2={page.width + canvasEdge.size}
                    y2={0}
                    className="edgeLine"
                  />
                  <line
                    x1={-canvasEdge.size}
                    y1={page.height}
                    x2={page.width + canvasEdge.size}
                    y2={page.height}
                    className="edgeLine"
                  />
                  <line
                    x1={0}
                    y1={-canvasEdge.size}
                    x2={0}
                    y2={page.height + canvasEdge.size}
                    className="edgeLine"
                  />
                  <line
                    x1={page.width}
                    y1={-canvasEdge.size}
                    x2={page.width}
                    y2={page.height + canvasEdge.size}
                    className="edgeLine"
                  />
                </g>
              )
            }

            {
              /* Edge texts */
              !isPreviewMode && (
                <g>
                  <text
                    x={page.width / 2}
                    y={-canvasEdge.size * 0.5}
                    style={{
                      fontSize: canvasEdge.size * 0.5,
                      textAnchor: 'middle',
                      dominantBaseline: 'middle',
                      fill: '#FFFFFF',
                      fillOpacity: 0.5,
                    }}
                  >
                    {GetText('canvas.edge')}
                  </text>
                  <text
                    x={page.width / 2}
                    y={page.height + canvasEdge.size * 0.5}
                    className="canvasEdgeText unselectable unclickable"
                    style={{
                      fontSize: canvasEdge.size * 0.5,
                    }}
                  >
                    {GetText('canvas.edge')}
                  </text>
                </g>
              )
            }
          </g>
        )
      }

      {
        // --------------------- CASE cover classic ------------------------
        currentPage.coverClassicOptions && (
          <ClassicCoverArea
            key="0"
            editionScale={editionScale}
            isPreviewMode={isPreviewMode}
            page={currentPage}
            options={currentPage.coverClassicOptions}
            dispatch={dispatch}
          />
        )
      }

      {
        // --------------------- CALENDAR HOLES ------------------------
        IsCalendar_WithSpiralHoles(docID) && (
          <CalendarHolesComp docID={docID} page={currentPage} />
        )
      }

      {
        // --------------------- GRID ------------------------
        !currentPage.coverClassicOptions && gridMode !== GRID_MODE.NONE && (
          <svg
            width="100%"
            height="100%"
            className="unselectable unclickable"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <pattern
                id="smallGrid"
                width={gridSize}
                height={gridSize}
                patternUnits="userSpaceOnUse"
              >
                <path
                  d={`M ${gridSize} 0 L 0 0 0 ${gridSize}`}
                  fill="none"
                  stroke="gray"
                  strokeWidth="0.5"
                />
              </pattern>
              <pattern
                id="grid"
                width={gridBigSize}
                height={gridBigSize}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  width={gridBigSize}
                  height={gridBigSize}
                  fill="url(#smallGrid)"
                />
                <path
                  d={`M ${gridBigSize} 0 L 0 0 0 ${gridBigSize}`}
                  fill="none"
                  stroke="gray"
                  strokeWidth="1"
                />
              </pattern>
            </defs>

            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        )
      }
    </svg>
  );
};
