import { DragEvent, forwardRef, MouseEventHandler, useState } from 'react';
import { Colors } from '../../data/Colors';
import { IsCardEditor } from '../../data/config';
import { isFrameEmpty } from '../../feature/frame/_helpers/isFrameEmpty';
import { FRAME_TYPE, IMAGE_QUALITY } from '../../feature/frame/frame.types';
import { GetImageQualityIndicator } from '../../feature/frame/frameHelper';
import { FrameQR } from '../../feature/frame/qrcode/FrameQR';
import { FrameText } from '../../feature/frame/text/FrameText';
import { OverlayerHelper } from '../../feature/overlayers/overlayerHelper';
import { AssetSelector } from '../../hooks/useAssetsSelector';
import { FramePostcardBG } from '../../pages/homePage/EditionArea/FramePostcardBG';
import { PageCanBeDragged } from '../../pages/homePage/pageNavigator/PageCanBeDragged';
import { IPage, OverlayerItem } from '../../types/types';
import { ComposeClassicCoverURL } from '../../utils/cover/coverHelper';
import { AddDragData, DRAG_TYPE, GetDragData } from '../../utils/DragUtils';
import { mmToPoint } from '../../utils/MeasureUtils';
// --------------------- TODO: REFACTOR due to circular dependencies!!! ---------------------
import { GetPageDisplayName } from '../../utils/pageHelper';

// TODO: ^important note: this is a copy of the function from coverHelper.ts
// But this is working in VisualTesting loki but the function in the coverHelper is not.
// This is due to ciruclar dependencies. We need to refactor this.
// import {
//   ComposeClassicCoverURL,
//   IsClassicCoverPage,
// } from '../../utils/cover/coverHelper';
const isClassicCoverPage = (page: IPage) =>
  !!(page.isCover && page.coverClassicOptions);

// ----------------------------------------------------------

type Props = {
  selected: boolean;
  page: IPage;
  docID: string;
  pageIndex: number;
  // assets
  assetsSelector: AssetSelector;
  // optional
  maxHeight?: number; // optional max height to change the size of the miniature
  // callbacks
  onClick: MouseEventHandler;
  onPageDrop(fromIndex: number, toIndex: number): void;
};

export const PageMiniature = forwardRef<HTMLDivElement, Props>(
  (
    {
      selected,
      assetsSelector,
      page,
      docID,
      pageIndex,
      onPageDrop,
      onClick,
      maxHeight = 75,
    },
    ref
  ) => {
    const [state, setState] = useState({ dragOver: false });
    const {
      getPhotosById,
      getBackgroundsById,
      getClipartsById,
      getOverlayersById,
    } = assetsSelector;

    const frameList = page.frames;
    const pageCanBeDragged = PageCanBeDragged(page, docID);

    const scaleRatio = maxHeight / page.height;
    const pageHeight = page.height * scaleRatio;
    const pageWidth = page.width * scaleRatio;

    // // case cover classic
    const classicCoverURL = page.coverClassicOptions
      ? ComposeClassicCoverURL(page.coverClassicOptions)
      : null;

    // --------------------- Render ------------------------

    return (
      // TODO:check to use antd Grid here with list item card
      <div
        ref={ref}
        id={`navigator_${page.index}`}
        className={`item${isClassicCoverPage(page) ? '_classic' : ''}${
          selected ? ' selected' : ''
        }${state.dragOver ? ' dragOver' : ''}`}
        key={pageIndex}
        role="button"
        tabIndex={0}
        onClick={onClick}
        style={{
          width: pageWidth,
          height: pageHeight,
          // backgroundColor: classicCoverURL ? 'rgb(100, 100, 100,0)' : 'inerit', // TODO:
          marginRight: IsCardEditor() ? 8 : 0,
        }}
        draggable={pageCanBeDragged}
        onDragStart={(e: DragEvent) => {
          AddDragData(e, DRAG_TYPE.NAVIGATOR_ITEM, page.index.toString());
        }}
        onDragOver={(e: DragEvent) => {
          // to allow the drop to work..
          if (pageCanBeDragged) {
            e.preventDefault();
            setState({ dragOver: true });
          }
        }}
        onDragLeave={() => {
          if (state.dragOver) setState({ dragOver: false });
        }}
        onDrop={(e: DragEvent) => {
          const pageIndexDrop = GetDragData(e, DRAG_TYPE.NAVIGATOR_ITEM);
          setState({ dragOver: false });
          if (
            pageCanBeDragged &&
            pageIndexDrop &&
            pageIndexDrop !== page.index.toString()
          )
            onPageDrop(Number.parseInt(pageIndexDrop, 10), page.index);
        }}
      >
        {
          // --------------------- case cover classic ------------------------

          classicCoverURL && (
            <img
              src={classicCoverURL}
              style={{
                width: pageWidth,
                height: pageHeight,
                position: 'absolute',
              }}
            />
          )
        }

        <svg width={pageWidth} height={pageHeight}>
          {
            /// ////////////////
            // DRAW FRAMES
            frameList.map((frame) => {
              // with frame from json
              const t = {
                x: (frame.x - frame.width / 2) * scaleRatio,
                y: (frame.y - frame.height / 2) * scaleRatio,
                width: frame.width * scaleRatio,
                height: frame.height * scaleRatio,
                angle: frame.rotation,
              };

              const photo = frame.photo ? getPhotosById(frame.photo) : null;
              const background = frame.background
                ? getBackgroundsById(frame.background)
                : null;

              const clipart = frame.clipart
                ? getClipartsById(frame.clipart)
                : null;
              const isEmpty = isFrameEmpty(frame);
              const borderInPixel = mmToPoint(frame.border) * scaleRatio;

              const overlayer: OverlayerItem = frame.overlayer
                ? getOverlayersById(frame.overlayer)
                : null;
              const overlayerRect = overlayer
                ? OverlayerHelper.GetOverLayerOverRect(overlayer, t)
                : null;

              const imgQuality = GetImageQualityIndicator(frame);

              // frame has error if we have a photo or background but no image data
              const hasError: boolean =
                (frame.photo || frame.background) && !photo && !background;

              const scaledBorderRadius = frame.borderRadius
                ? frame.borderRadius * scaleRatio
                : 0;

              // Draw frame
              return (
                <g
                  key={`${pageIndex}_${frame.id}`}
                  transform={`translate(${t.x},${t.y}) rotate(${t.angle},${
                    t.width / 2
                  },${t.height / 2})`}
                >
                  {scaledBorderRadius && (
                    <defs>
                      <clipPath id={`mask_${frame.id}_nav`}>
                        {/* <ellipse cx={frame.width/2} cy={frame.height/2} rx={frame.width/2} ry={frame.height/2} /> */}
                        <rect
                          x={0}
                          y={0}
                          ry={
                            scaledBorderRadius && frame.border
                              ? scaledBorderRadius + borderInPixel
                              : scaledBorderRadius
                          }
                          rx={
                            scaledBorderRadius
                              ? scaledBorderRadius + borderInPixel
                              : scaledBorderRadius
                          }
                          width={t.width}
                          height={t.height}
                        />
                      </clipPath>
                    </defs>
                  )}
                  <g>
                    {
                      // --------------------- empty ------------------------
                      isEmpty && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            stroke: '#cccccc',
                            strokeWidth: 1,
                            // strokeDasharray: "10 5",
                            fill:
                              frame.type === FRAME_TYPE.TEXT
                                ? Colors.MAIN_LIGHT
                                : '#cccccc',
                            fillOpacity: '0.4',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- fill ------------------------
                      frame.fillColor && frame.fillColor !== '#ffffff' && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            // stroke: "#000000",
                            // strokeWidth: 3,
                            // strokeDasharray: "10 5",
                            fill: frame.fillColor,
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- calendar ------------------------
                      frame.type === FRAME_TYPE.CALENDAR && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            // stroke: "#000000",
                            // strokeWidth: 3,
                            // strokeDasharray: "10 5",
                            // fill: frame.fillColor,

                            stroke: '#cccccc',
                            strokeWidth: '1px',
                            fillOpacity: '0.4',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- postcardBG ------------------------
                      frame.type === FRAME_TYPE.POSTCARD_BG && (
                        <FramePostcardBG />
                      )
                    }

                    {
                      // --------------------- Background -----------------------
                      background && (
                        <g clipPath={`url(#mask_${frame.id}_nav)`}>
                          <svg
                            width={t.width}
                            height={t.height}
                            viewBox={`${frame.cLeft * scaleRatio} ${
                              frame.cTop * scaleRatio
                            } ${t.width} ${t.height}`}
                          >
                            <image
                              key={background.id}
                              xlinkHref={background.thumb_url}
                              href={background.thumb_url}
                              width={background.width * frame.zoom * scaleRatio}
                              height={
                                background.height * frame.zoom * scaleRatio
                              }
                              preserveAspectRatio="none"
                            />
                          </svg>
                        </g>
                      )
                    }

                    {
                      // --------------------- Image -----------------------
                      photo && (
                        <g clipPath={`url(#mask_${frame.id}_nav)`}>
                          {/* // TODO: remove this, this is only for viewing image limit */}
                          <rect
                            x="0"
                            y="0"
                            width={t.width}
                            height={t.height}
                            style={{
                              // stroke: "#000000",
                              // strokeWidth: 3,
                              // strokeDasharray: "10 5",
                              // fill: frame.fillColor,
                              stroke: '#cccccc',
                              strokeWidth: '1px',
                              fillOpacity: '0.4',
                            }}
                          />
                          <svg
                            width={t.width}
                            height={t.height}
                            viewBox={`${frame.cLeft * scaleRatio} ${
                              frame.cTop * scaleRatio
                            } ${t.width} ${t.height}`}
                          >
                            <image
                              key={photo.id}
                              href={
                                photo.temp ? photo.temp_url : photo.thumb_url
                              }
                              width={photo.width * frame.zoom * scaleRatio}
                              height={photo.height * frame.zoom * scaleRatio}
                              preserveAspectRatio="none"
                            />
                          </svg>
                        </g>
                      )
                    }

                    {
                      // --------------------- Clipart ------------------------
                      clipart && (
                        <image // id={frame.id}
                          key={clipart.id}
                          // href={frame.content.image}
                          xlinkHref={clipart.thumb_url}
                          href={clipart.thumb_url}
                          width={frame.width * scaleRatio}
                          height={frame.height * scaleRatio}
                          preserveAspectRatio="none"
                        />
                      )
                    }

                    {
                      // --------------------- Text ------------------------
                      frame.text && (
                        <FrameText frame={frame} scaleRatio={scaleRatio} />
                      )
                    }

                    {
                      // --------------------- QR ------------------------
                      frame.qrCode && (
                        <FrameQR frame={frame} scaleRatio={scaleRatio} />
                      )
                    }

                    {
                      // --------------------- image quality ------------------------
                      imgQuality !== IMAGE_QUALITY.GOOD && (
                        <rect
                          x={t.width - 10}
                          y={t.height - 10}
                          width={8}
                          height={8}
                          style={{
                            fill:
                              imgQuality === IMAGE_QUALITY.BAD
                                ? Colors.RED_FLASH
                                : Colors.SELECTION,
                            fillOpacity: '1',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- has error ------------------------
                      hasError && (
                        <rect
                          x="0"
                          y="0"
                          width={t.width}
                          height={t.height}
                          style={{
                            fill: '#ff0000',
                            fillOpacity: '1',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- Frame border ------------------------
                      !isEmpty && frame.border !== 0 && (
                        <rect
                          rx={scaledBorderRadius}
                          ry={scaledBorderRadius}
                          x={borderInPixel / 2}
                          y={borderInPixel / 2}
                          width={t.width - borderInPixel}
                          height={t.height - borderInPixel}
                          style={{
                            pointerEvents: 'none',
                            stroke: frame.borderColor,
                            strokeWidth: borderInPixel,
                            fill: '#ff0000',
                            // fillOpacity: '0.4',
                            fillOpacity: '0',
                          }}
                        />
                      )
                    }

                    {
                      // --------------------- overlayer ------------------------
                      overlayer && (
                        <g key={overlayer.id}>
                          <image
                            href={overlayer.working_url}
                            xlinkHref={overlayer.working_url}
                            width={overlayerRect.width}
                            height={overlayerRect.height}
                            x={overlayerRect.x}
                            y={overlayerRect.y}
                            preserveAspectRatio="none"
                          />
                        </g>
                      )
                    }
                  </g>
                </g>
              );
            })
            // END DRA FRAMES
            /// ////////////////
          }
        </svg>

        {IsCardEditor() && (
          <div className="pageNavigatorItem_label unselectable">
            {GetPageDisplayName(docID, pageIndex)}
          </div>
        )}
      </div>
    );
  }
);
