import { IPage } from '../../../../types/types';

type Props = {
  page: IPage;
  displayPageIndex?: number; // override page index to display (useful for layflat)
  color: string;
};

const FrameNumber = ({
  pageWidth,
  pageHeight,
  pageIndex,
  color,
}: {
  pageWidth: number;
  pageHeight: number;
  pageIndex: number;
  color: string;
}) => {
  return (
    <text
      x={pageIndex % 2 === 0 ? 10 : pageWidth - 10}
      y={pageHeight - 15}
      width={30}
      height={30}
      style={{
        userSelect: 'none',
        pointerEvents: 'none',
        fontSize: 15,
        textAnchor: pageIndex % 2 === 0 ? 'start' : 'end',
        dominantBaseline: 'middle',
        background: 'yellow',
        fill: color || 'black',
      }}
    >
      {pageIndex}
    </text>
  );
};

export const FramePageNumber = ({ page, displayPageIndex, color }: Props) => {
  const pageIndex = displayPageIndex ?? page.index;
  const pageWidth = page.width;
  const pageHeight = page.height;
  return (
    <g>
      <FrameNumber
        pageWidth={pageWidth}
        pageHeight={pageHeight}
        pageIndex={pageIndex}
        color={color}
      />
      {page.merged && (
        <FrameNumber
          pageWidth={pageWidth}
          pageHeight={pageHeight}
          pageIndex={pageIndex + 1}
          color={color}
        />
      )}
    </g>
  );
};
