import { Colors } from '../../../data/Colors';
import { Frame } from '../../../types/types';
import { isFrameEmpty } from '../_helpers/isFrameEmpty';
import { getFrameTextVAlign } from './getFrameTextValign';

type Props = {
  frame: Frame;
  frameTextDivRef?: React.RefObject<HTMLDivElement> | undefined;
  scaleRatio?: number;
};

export const FrameText = ({
  frame,
  scaleRatio = 1,
  frameTextDivRef,
}: Props) => {
  const isEmpty = isFrameEmpty(frame);

  return (
    <foreignObject
      x="0"
      y="0"
      className="unselectable divReset"
      width={frame.width}
      height={frame.height}
      style={{
        transform: `scale(${scaleRatio})`,
      }}
    >
      <div
        className="divReset"
        style={{
          width: frame.width,
          height: frame.height,
          display: 'flex',
          justifyContent:
            frame.text.halign === 'right' ? 'flex-end' : frame.text.halign,
          alignItems: getFrameTextVAlign(frame),
        }}
      >
        <div
          ref={frameTextDivRef}
          className="divReset"
          style={{
            color: isEmpty ? Colors.GREY_DARK : frame.text.color,
            fontSize: frame.text.size,
            fontFamily: frame.text.family,
            fontStyle: frame.text.italic ? 'italic' : 'normal',
            fontWeight: frame.text.bold ? 'bold' : 'normal',
            whiteSpace: 'pre-wrap', //  to keep \n
            textAlign: frame.text.halign as any, // not found textalign type
            display: 'flex',
          }}
        >
          {frame.text.value}
        </div>
      </div>
    </foreignObject>
  );
};
