import { CanvasProduct, CatalogueCanvas } from './productCatalogue';

export type CanvasDoc = Omit<
  CatalogueCanvas['docs']['CF'],
  'maxSize' | 'minSize'
>;

export type CanvasEdge = {
  size: number;
  isKadapak: boolean;
  style: object;
  left: number;
  top: number;
  right: number;
  bottom: number;
};

export const CANVAS_TYPES: { [p: string]: CanvasProduct } = {
  CANVAS: 'canvas',
  // WOOD: 'wood',
  POSTER: 'poster',
  PLEXI: 'plexi',
  // KADAPAK: 'kadapak',
  // ALUMINIUM: 'aluminium',
  ALUMINIUM_MOUNTING: 'aluminium_mounting',
  // FOREX: 'forex',
  // REAL_ALUMINIMUM: 'real_aluminium',
};

export enum CANVAS_FORMAT {
  PORTRAIT = 'port',
  LANDSCAPE = 'land',
  SQUARE = 'square',
  FREE = 'freeformat',
}
