import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, Row, Select, Spin } from 'antd';
import { cloneDeep } from 'lodash';
import { GetProductCatalogue } from '../../../data/catalogue/productCatalogue';
import { PROJECT_CONST } from '../../../data/config';
import { GetText } from '../../../data/LanguageHelper';
import { popupHelper } from '../../../feature/alert/popupHelper';
import { editionActions } from '../../../feature/edition/edition';
import { pricingSelectors } from '../../../feature/pricing/pricing';
import { CreateProject } from '../../../feature/project/CreateProject';
import { GetProjectOptions } from '../../../feature/project/GetProjectOptions';
import { IDocument } from '../../../types/IDocument';
import { ProjectOptions } from '../../../types/project';
import { CalendarHelper } from '../../../utils/calendar/CalendarHelper';
import { history, ROUTE_CONST } from '../../../utils/history';
import {
  GetDoc,
  GetPossibleUpgrades,
  GetProjectDocSizeCM,
} from '../../../utils/ProductHelper';
import { GetProjectDisplayPage } from '../../../utils/projectHelper';

interface CalendarProjectCreationViewProps {
  isUpgrade: boolean;
  onCloseCallback: void;
}

class CalendarProjectCreationView extends Component<CalendarProjectCreationViewProps> {
  // --------------------- Constructor ------------------------

  constructor(props) {
    super(props);

    // bind functions
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    // retrieve project list
    let catalogue = GetProductCatalogue(); // make a copy, in case of
    // rebranding filter products
    if (
      !this.props.isUpgrade &&
      this.props.rebranding?.subProductFilter?.calendars
    ) {
      catalogue.products = this.props.rebranding.subProductFilter.calendars;
    }

    // ----  FILTER CATALOGUE FOR UPGRADES ----
    // in case of upgrade, we filter the catalogue
    if (this.props.project) {
      const filteredCatalogue = cloneDeep(catalogue);
      filteredCatalogue.products = {};
      const projectDocID = this.props.project.docID;
      const projectDoc = GetDoc(projectDocID);
      Object.keys(catalogue.products).forEach((productKey) => {
        const filteredDocs: Array = catalogue.products[productKey].docs.filter(
          (doc_id) => GetPossibleUpgrades(projectDocID).includes(doc_id)
        );
        if (filteredDocs.length > 0) {
          filteredCatalogue.products[productKey] = cloneDeep(
            catalogue.products[productKey]
          );
          filteredCatalogue.products[productKey].docs = cloneDeep(filteredDocs);
        }
      });
      catalogue = filteredCatalogue;
    }
    // ---- END OF CATALOGUE FILTER ----

    const productList = catalogue.products;
    const productOptionKeys = Object.keys(productList).map(
      (key, index, arr) => key
    );

    this.state = {
      // whole calendar catalogue
      catalogue,

      // = type :WALL, DESK, ETC...
      product: null, // Current product selected
      productOptions: productOptionKeys, // WALL, DESK, ETC...

      // = docID : WCAL, DCAL, DCAL2, et...
      size: null,
      sizeOptions: null,

      pages: -1,
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    // automatically set the first product
    if (this.props.isUpgrade) {
      const { project } = this.props;
      this.handleProductChange(project.type).then(() =>
        this.handleSizeChange(project.docID)
      );
      // .then(() => this.handlePageChange( GetProjectDisplayPage(project)) );
    } // default
    else {
      this.handleProductChange(this.state.productOptions[0]);
    }
  }

  // --------------------- methods ------------------------

  handleClose = () => {
    // if not upgrade be sure to be on HOME
    if (!this.props.isUpgrade) {
      history.push(ROUTE_CONST.HOME);
    }

    // invoke callback if there is one
    this.props.onCloseCallback?.();
  };

  handleChange(value) {
    console.log(`selected ${value}`);
  }

  handleProductChange(val): Promise {
    return new Promise((resolve, reject) => {
      const product = this.state.catalogue.products[val];
      const sizeOptions = product.docs;
      let { size } = this.state;

      // default value
      if (!sizeOptions.includes(size)) {
        if (sizeOptions.includes(product.default)) size = product.default;
        else {
          [size] = sizeOptions;
        }
        // else { size = sizeOptions[0]; }
      }

      this.setState(
        {
          product: val,
          sizeOptions,
          size,
        },
        () => {
          this.handleSizeChange(size).then(() => resolve());
        }
      ); // after setting the product, update also the size
    });
  }

  handleSizeChange(val): Promise {
    return new Promise((resolve, reject) => {
      // const doc:IDocument = this.state.catalogue.docs[this.state.size]
      const doc: IDocument = this.state.catalogue.docs[val];
      const pages = doc.numPages;

      this.setState(
        {
          size: val,
          pages,
        },
        () => {
          resolve();
        }
      );
    });
  }

  getProjectPrice() {
    let options: ProjectOptions = this.props.project
      ? GetProjectOptions(this.props.project)
      : {};
    const docID = this.state.size;
    if (docID) {
      const doc = GetDoc(docID);
      options = {
        ...options,
        ...{
          docID,
          type: this.state.product,
          numPages: this.state.pages,
          startDay: 1,
          startYear: CalendarHelper.GetNewCalendarYear(),
        },
      };

      // security
      // TODO: put this in a generic function
      if (options.coated && !doc.coating) options.coated = false;

      return pricingSelectors.GetProjectPrice(this.props.storeRef, options);
    }
    return 0;
  }

  handleConfirm() {
    // --------------------- UPGRADE ------------------------
    // case upgrade
    if (this.props.isUpgrade) {
      const onConfirm = () => {
        this.props.dispatch(
          editionActions.UpgradeCurrentProject({
            docID: this.state.size,
            type: this.state.product,
            numPages: this.state.pages,
            options: GetProjectOptions(this.props.project),
          })
        );
        this.handleClose();
      };
      const currentProjectPages = GetProjectDisplayPage(this.props.project);
      if (currentProjectPages > this.state.pages) {
        const numPagesDeleted = currentProjectPages - this.state.pages;
        popupHelper.showChangePageDeleteWarning(numPagesDeleted, onConfirm);
      } else {
        onConfirm();
      }
      return;
    }
    // ----- END OF UPGRADE ------

    // ---------------------  NEW PROJECT  ------------------------
    // create new project
    const proj = CreateProject({
      classname: PROJECT_CONST.project_class,
      docID: this.state.size,
      type: this.state.product,
      projectName: 'new calendar project', // TODO:
      numPages: this.state.pages,
      layoutStore: this.props.layouts,
      options: {
        startDay: 1,
        startYear: CalendarHelper.GetNewCalendarYear(),
      },
    });

    // update state
    this.setState({
      project: proj,
    });

    popupHelper.showRenameProjectPopup(
      // success
      (newProjectName) => {
        const { project } = this.state;
        project.name = newProjectName;
        this.props.dispatch(editionActions.CreateProject(cloneDeep(project)));
      },
      // cancel
      () => {
        // nothing
      }
    );
  }

  // --------------------- render ------------------------

  render() {
    const { user, projects } = this.props;
    const currentProject = this.props.project;
    const projectClass = PROJECT_CONST.project_class;
    const { Option } = Select;

    const previewURL = `/images/ui/menu/lefttabs/project/${projectClass}/types_${projectClass}_${this.state.product}_${this.state.size}.jpg`;
    const projectPrice = this.getProjectPrice();

    return (
      <Modal
        title={
          this.props.isUpgrade
            ? GetText('upgradeProject.title')
            : GetText('newProject.title')
        }
        visible
        footer={null}
        // width="80%"
        width="600px"
        // onOk={this.handleOk}
        // confirmLoading={confirmLoading}
        onCancel={this.handleClose}
      >
        <Spin spinning={projects.loading}>
          <h3>{GetText('newProject.calendar.desc')}</h3>

          <h4>{GetText('calendar.type')}</h4>
          <Select
            // defaultValue="lucy"
            style={{ width: 400 }}
            value={this.state.product}
            placeholder="Select a Product"
            onChange={this.handleProductChange}
          >
            {this.state.productOptions.map((key) => (
              <Option key={key} value={key}>
                {GetText(`calendar.type.types_calendars_${key}`)}
              </Option>
            ))}

            {/* <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                        Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option> */}
          </Select>

          <div className="spacerV" />

          <h4>{GetText('calendar.size')}</h4>
          <Select
            // defaultValue="lucy"
            disabled={!this.state.size}
            value={this.state.size}
            style={{ width: 400 }}
            onChange={this.handleSizeChange}
          >
            {this.state.sizeOptions &&
              this.state.sizeOptions.map((key) => (
                <Option key={key} value={key}>
                  {GetText(`calendar.prefix.${key}`)} (
                  {GetProjectDocSizeCM(key)})
                </Option>
              ))}
          </Select>

          <div className="spacerV" />

          <h4>
            {GetText('page.number')}
            {this.state.pages}
          </h4>

          <Row>
            <img src={previewURL} />
          </Row>

          <Row justify="space-between">
            <Link to={ROUTE_CONST.SELECT_PROJECT_CLASS}>
              <Button type="link">
                {GetText('newProject.changeEditorClass.button')}
              </Button>
            </Link>
            <Button
              type="primary"
              disabled={projectPrice === 0}
              onClick={this.handleConfirm}
            >
              {GetText('common.continue')}
              {` (${projectPrice}€)`}
            </Button>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

// Redux map
function mapStateToProps(state) {
  const { authentication, projects, edition, layouts } = state;
  const { user, rebranding } = authentication;
  const { project } = edition;

  // we need user and projects
  return {
    storeRef: state,
    user,
    rebranding,
    project,
    projects,
    layouts,
  };
}

// Redux connect
const reduxConnected = connect(mapStateToProps)(CalendarProjectCreationView);
export { reduxConnected as CalendarProjectCreationView };
