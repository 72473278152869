import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Colors } from '../../data/Colors';
import { PROJECT_CONST } from '../../data/config';
import { GetText } from '../../data/LanguageHelper';
import {
  ALL_MONTHS,
  DATE_ACTION_ENUM,
  DAYDATE_TYPE,
  WEEK_DAYS,
} from '../../feature/calendar/CalendarEnums';
import { FRAME_TYPE } from '../../feature/frame/frame.types';
import { GetProjectOptions } from '../../feature/project/GetProjectOptions';
import { Project, ProjectOptions } from '../../types/project';
import {
  Frame,
  ICalendarColorOptions,
  ICalendarFrameOptions,
} from '../../types/types';
import { mmToPoint } from '../MeasureUtils';
import {
  DOC_ID_ENUM,
  GetDoc,
  IsCalendar_Birthday,
  IsCalendar_magnetA5,
  IsCalendar_Organizer,
  IsCalendar_WallXL,
  IsCalendar_withMonthOverlap,
} from '../ProductHelper';

// --------------------- EXPORTS / PUBLIC -----------------------

// --------------------- helpers ------------------------

// ---- STORE HELPERS ----
let StoreRef;
function InitializeStoreRef(store) {
  StoreRef = store;
}
function getStore() {
  return StoreRef.getState();
}
const getProject: Project = () => getStore().edition.project;
const getOptions: ProjectOptions = () => GetProjectOptions(getProject());
const getPageCalendarColors: ICalendarColorOptions = (pageIndex) => {
  const page = getProject().pageList[pageIndex];
  if (page.calendarColorOptions) {
    return page.calendarColorOptions;
  }
  return getOptions().calendarColorOptions;
};
const getProjectStartYear: number = () => getOptions().startYear;
const getProjectStartMonth: number = () => getOptions().startMonth; // month wanted for starting the year january = 0, february = 1, etc..
const getProjectStartWeekDay: number = () => getOptions().startDay; // sunday = 0, monday = 1, .., saturday = 6

// --------------------- Methods ------------------------

function GetMonthNameByIndex(monthIndex: number): string {
  return ALL_MONTHS[monthIndex];
}

/**
 * Retrieve correct label for this pages
 */
function GetFrameText(pageIndex: number, frame: Frame) {
  const { calendarOptions } = frame;
  const { dateAction } = calendarOptions;
  const { dateIndex } = calendarOptions;
  const { docID } = getProject();

  // var monthIndex :number = getFrameMonthIndex( frameVo ); // Edit : monthIndex has been replaced by pageCalIndex (which can be a month OR a week for oganizer)
  // var pageCalIndex:number = GetPageCalendarIndex(frameVo);
  const pageCalIndex: number = GetPageCalendarIndex(pageIndex);
  const frameDate: Date = GetFrameDate(pageCalIndex, frame);
  const frameMonthIndex: number = frameDate.month;
  // var monthIndex:number = frameDate.month; // Edit: frame month index can be different than page month index! (as a page can contain frame from older months)

  let result: string;
  switch (dateAction) {
    case DATE_ACTION_ENUM.WEEKDAYFULL:
      result = getWeekDayFull(dateIndex - 1, false, true);
      break;

    case DATE_ACTION_ENUM.WEEKDAY:
      result = getWeekDayFull(dateIndex - 1, true, true);
      break;

    case DATE_ACTION_ENUM.MONTHYEAR:
      result = getMonthWithOffSetYear(pageCalIndex, true); // getMonthYear(monthIndex);
      break;

    case DATE_ACTION_ENUM.DAYDATE:
      // hack for birthday calendar and always display 29th
      if (
        IsCalendar_Birthday(docID) &&
        GetMonthIndexWithOffSet(pageCalIndex) === 1 &&
        frameMonthIndex === 2
      ) {
        result = `${29}`;
      } else result = `${frameDate.getDate()}`;
      break;

    case DATE_ACTION_ENUM.WEEKOFYEAR:
      result = getWeekOfYear(frame, pageCalIndex);
      break;

    // case DATE_ACTION_ENUM.MINICALENDAR_MONTH_PREVIOUS:
    // result = "";//getMonthYear(monthIndex-1);
    // break;

    // case DATE_ACTION_ENUM.MINICALENDAR_MONTH_NEXT:
    // result = "";//getMonthYear(monthIndex+1);
    // break;

    // case DATE_ACTION_ENUM.MINICALENDAR_WEEK_PREVIOUS:
    // result = "";// todo: How to align the data in the textfield to match weeks rows and day colums?
    // break;

    // case DATE_ACTION_ENUM.MINICALENDAR_WEEK_NEXT:
    // result = "";// todo: How to align the data in the textfield to match weeks rows and day colums?
    // break;

    // case DATE_ACTION_ENUM.MINICALENDAR_PREVIOUS:
    // result = "";// todo: How to align the data in the textfield to match weeks rows and day colums?
    // break;

    // case DATE_ACTION_ENUM.MINICALENDAR_NEXT:
    // result = "";// todo: How to align the data in the textfield to match weeks rows and day colums?
    // break;

    case DATE_ACTION_ENUM.NOTES:
      result = getNotesContent(); //
      break;

    case DATE_ACTION_ENUM.YEAR:
      // TODO: organizer
      // // organizer, based on frame date
      // if (ProductsCatalogue.IsCalendar_Organizer())
      // {
      // // internal page
      // if(frameVo.pageIndex !==0)
      // result = ""+ frameDate.fullYear;
      // // cover year
      // else
      // {
      // // if starting month is january, we only display the year, otherwise we also display the next year
      // if( getProjectStartMonth() === 0 )
      // result = "" + year;
      // else
      // result = "" + year + "-" + (year+1);
      // }
      // }

      // based on month index
      // else{
      // display current year and next year if starting month is not 0, but only on cover page
      result = getyearLabel(pageCalIndex, pageIndex === 0);
      // }

      break;

    // luxury calendar
    case DATE_ACTION_ENUM.DATENAME:
      // security added in 0.1.87
      // if dateIndex do not correspond to date, it mean the date do not exist in the current month
      // this check was failing because dateIndex is a string here
      if (Number(dateIndex) !== frameDate.getDate()) result = '';
      else
        result = getDateName(
          dateIndex,
          GetMonthIndexWithOffSet(pageCalIndex, false),
          true
        ); //
      break;

    case DATE_ACTION_ENUM.MONTH:
      if (IsCalendar_Organizer(docID))
        result = getMonthFull(frameDate.getMonth(), true);
      else result = getMonthWithOffSetYear(pageCalIndex, false);
      break;

    case DATE_ACTION_ENUM.MINICALENDAR_MONTH:
      result = getMonthWithOffSetYear(dateIndex - 1, true); // YeargetMonthYear(index-1);
      break;

    case DATE_ACTION_ENUM.MINICALENDAR_WEEK:
      result = getVerticalWeek(
        dateIndex,
        0,
        IsCalendar_magnetA5(docID) ? 2 : 3,
        true
      );
      break;

    // case DATE_ACTION_ENUM.HOURS:
    // result = ""+frameVo.dateIndex;
    // break;

    case DATE_ACTION_ENUM.URL:
      result = PROJECT_CONST.displayUrl; // "www.tictacphoto.com";
      break;

    default:
      result = '?';
      break;
  }

  // UPPER? TODO: organiser upper param
  // if(frameVo.upper)
  // return result.toUpperCase();
  // else
  // return result;

  return result;
}

// /***************************************************************
//  PROJECT : TicTac Photo
//  COPYRIGHT : jonathan@nguyen
//  YEAR: 2013
//  ****************************************************************/
// package manager
// {
// import com.codeazur.as3swf.timeline.Frame;
// import com.greensock.layout.AlignMode;

// import flash.geom.Point;
// import flash.sampler.getInvocationCount;
// import flash.sampler.startSampling;
// import flash.text.TextFormatAlign;

// import be.antho.data.DateUtil;
// import be.antho.data.ResourcesManager;

// import data.CalendarColors;
// import data.DateLinkageVo;
// import data.FrameVo;
// import data.Infos;
// import data.PageVo;
// import data.ProductsCatalogue;

// import flashx.textLayout.formats.TextAlign;
// import flashx.textLayout.formats.VerticalAlign;

// import utils.Colors;
// import utils.Debug;

// /**
//  * Calendar manager
//  */
// public class CalendarManager
// {

// /* ------------------------------------- PROPERTIES -------------------------------------*/

// public static const DATEACTION_NOTES:String = "[notes]";
// public static const DATEACTION_MINICALENDAR_MONTH_PREVIOUS:String = "[mcmpre]";// mini calendar of previous month
// public static const DATEACTION_MINICALENDAR_MONTH_NEXT:String = "[mcmpost]";  // mini calendar or next month
// public static const DATEACTION_MINICALENDAR_WEEK_PREVIOUS:String = "[mcwpre]";
// public static const DATEACTION_MINICALENDAR_WEEK_NEXT:String = "[mcwpost]";
// public static const DATEACTION_MINICALENDAR_PREVIOUS:String = "[mcpre]";
// public static const DATEACTION_MINICALENDAR_NEXT:String = "[mcpost]";
// public static const DATEACTION_MINICALENDAR_MONTH:String = "[mcmvar]";  // month + year for mini calendar (September 2017) -> magnet
// public static const DATEACTION_MINICALENDAR_WEEK:String = "[mcwvar]";  // vertical week for mini calender -> magnet
// public static const DATEACTION_MINICALENDAR_FULL:String = "[mcfull]";// mini calendar full (organizer first page)

// public static const DATEACTION_URL:String = "[url]"; // editor url (also used in cards)
// public static const DATEACTION_YEAR:String = "[year]";
// public static const DATEACTION_MONTH:String = "[month]";//january, february
// public static const DATEACTION_MONTHYEAR:String = "[monthyear]"; // january 2014, february 2014
// public static const DATEACTION_WEEKOFYEAR:String = "[weekofyear]"; // week number
// public static const DATEACTION_WEEKDAY:String = "[weekday]";//Mon, Tue, ect..
// public static const DATEACTION_WEEKDAYFULL:String = "[weekdayfull]"; //monday, tuesday etc...
// public static const DATEACTION_DATE:String = "[date]";
// public static const DATEACTION_DATENAME:String = "[datename]"; // Calendar Lux Rules:  Mon 1, Tue 2
// public static const DATEACTION_DAYDATE:String = "[daydate]"; // 1->31 simple number day date
// public static const DATEACTION_HOURS:String = "[hour]"; // hours in organizer
// public static const DAYDATE_TYPE.WEEKDAY:String = "daydateWeekDay";
// public static const DAYDATE_TYPE.WEEKEND:String = "daydateWeekEnd";
// public static const DAYDATE_TYPE.OFFSET:String = "daydateOffset";

// // public
// public var WEEK_DAYS:Vector.<String>;
// public var ALL_MONTHS:Vector.<String>;

// // private
// private var initiated:Boolean = false;
// private const monthLengths:Array = new Array (31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
// private var splittedMonth:Object = {};
// private var _year:number;

// /* ------------------------------------- CONSTRUCTOR -------------------------------------*/

// public function CalendarManager(sf:SingletonEnforcer)
// {
// if(!sf) throw new Error("CalendarsManager is a singleton, use instance");
// if(!initiated)
// init();
// }

// // singleton
// private static var _instance:CalendarManager;
// public static function get instance():CalendarManager
// {
// if (!_instance) _instance = new CalendarManager(new SingletonEnforcer())
// return _instance;
// }

// /* ------------------------------------- GET/SET -------------------------------------*/

// public function get year():number
// {
// return ProjectManager.instance.project.calendarYear;
// }

// public function set year(value:number):void
// {
// _year = value;
// }

// /* ------------------------------------- PUBLIC -------------------------------------*/

// /**
//  * Reset manager
//  * Set initiated to false to force init
//  */
// public function reset():void
// {
// init();
// }

// public function init():void
// {

// //DAYS ids list
// WEEK_DAYS = new Vector.<String>();
// var list:XMLList = ProjectManager.instance.menuXML..calendarWeekday.node.@id;
// for (var i:number = 0; i < list.length(); i++)
// {
// WEEK_DAYS.push(list[i]);
// }

// //MONTH ids list
// ALL_MONTHS = new Vector.<String>();
// list = ProjectManager.instance.menuXML..calendarMonth.node;
// for (i = 0; i < list.length(); i++)
// {
// ALL_MONTHS.push(list[i].@id);
// }

// //flag
// initiated = true;
// }

/**
 * get month index withOffset
 * return 0-11
 * return more if the keepWithinAYear flag is false
 */
function GetMonthIndexWithOffSet(
  monthIndex: number,
  keepWithinAYear = true
): number {
  const wantedStartMonthIndex: number = getProjectStartMonth();
  let newmonthIndex: number = wantedStartMonthIndex + monthIndex;

  if (newmonthIndex > 11 && keepWithinAYear) {
    newmonthIndex = Math.abs(12 - newmonthIndex);
  }
  return newmonthIndex;
}

/**
 * get year to use if month offset
 * return correct year to use
 */
function getYearToUseWithOffSet(monthIndex: number): number {
  const wantedStartMonthIndex: number = getProjectStartMonth();
  let newmonthIndex: number = wantedStartMonthIndex + monthIndex;

  const year = getProjectStartYear();
  let yearToUse: number = year;

  if (newmonthIndex > 11) {
    newmonthIndex = Math.abs(12 - newmonthIndex);
    yearToUse = year + 1;
  }
  return yearToUse;
}

// /**
//  * get number of day in month
//  * return 29 or 30 or 31
//  */
// public function getDaysInMonth(monthIndex:number):number
// {
// return daysInMonth(getYearToUseWithOffSet(monthIndex),GetMonthIndexWithOffSet(monthIndex));
// }

// /**
//  * Get Text by dateAction and dateIndex
//  * returns dd-mm
//  */
// public function getDateLinkageId(frameCalendarVo:FrameVo):DateLinkageVo
// {
// var obj:DateLinkageVo = new DateLinkageVo();
// //var monthIndex:number = getFrameCalendarIndex( frameCalendarVo );
// //var index:number = frameCalendarVo.dateIndex;
// //obj.startingDay = getProjectStartWeekDay();
// //obj.startingMonth = getProjectStartMonth();
// var frameDate:Date = GetFrameDate(frameCalendarVo);
// obj.dayDate = frameDate.date; // int(getDayDate(index,monthIndex,frameCalendarVo));
// obj.dayKind = whatKindofDayDateAmI(frameCalendarVo);//,String(obj.dayDate));
// obj.montIndexWithOffset = frameDate.month //GetMonthIndexWithOffSet(monthIndex);
// return obj;
// }

/**
 * Helper
 * Gives index of the given day index of the month (0->6) (Sunday:0 -> Saturday:6)
 * */
function whatKindofMcwvarAmI(dateIndex: number): string {
  const str = String(dateIndex);
  const dayIndex: number = Math.floor(str.substr(str.length - 1)) - 1;
  const wantedStartDayIndex: number = getProjectStartWeekDay();
  let offSetIndex: number = dayIndex + wantedStartDayIndex;
  offSetIndex =
    offSetIndex < WEEK_DAYS.length
      ? offSetIndex
      : offSetIndex - WEEK_DAYS.length;

  if (offSetIndex === 6 || offSetIndex === 0) return DAYDATE_TYPE.WEEKEND;
  return DAYDATE_TYPE.WEEKDAY;
}

/**
 * Helper
 * Gives index of the given day index of the month (0->6) (Sunday:0 -> Saturday:6)
 * */
function whatKindofDayNameAmI(
  dateIndex: number,
  monthIndex: number,
  yearToUse: number
): string {
  const dayIndex: number = getDayOfMonthIndex(dateIndex, monthIndex, yearToUse);
  if (dayIndex === 6 || dayIndex === 0) return DAYDATE_TYPE.WEEKEND;
  return DAYDATE_TYPE.WEEKDAY;
}

/**
 * Helper
 * Gives index of the given day index of the month (0->6) (Sunday:0 -> Saturday:6)
 * */
function whatKindofWeekDayAmI(weekDayIndex: number): string {
  const weekDay: string = getWeekDayFull(weekDayIndex - 1, false);
  if (weekDay === 'saturday' || weekDay === 'sunday')
    return DAYDATE_TYPE.WEEKEND;
  return DAYDATE_TYPE.WEEKDAY;
}

/**
 * Helper
 * Gives index of the given day index of the month (0->6) (Sunday:0 -> Saturday:6)
 * pageMonthIndex is used to compare framevo date month and calendar page month, it allows to find offset dates
 * */
function whatKindofDayDateAmI(pageCalIndex: number, frameVo: Frame): string {
  // , displayIndex:String):String
  // retrieve frame date
  const frameDate: Date = GetFrameDate(pageCalIndex, frameVo);
  const { docID } = getProject();

  // check for offset frames for calendars with full month pages
  if (!IsCalendar_Organizer(docID)) {
    const pageMonthIndex: number = GetMonthIndexWithOffSet(pageCalIndex);
    if (
      !Number.isNaN(pageMonthIndex) &&
      frameDate.getMonth() !== pageMonthIndex
    ) {
      return DAYDATE_TYPE.OFFSET;
    }
  }

  // check for week-end days
  if (frameDate.getDay() === 6 || frameDate.getDay() === 0)
    return DAYDATE_TYPE.WEEKEND;

  // default week day
  return DAYDATE_TYPE.WEEKDAY;
}

/**
 * Frame calendar index can be a month index or a week index.
 * all pages with calendar frames are counted
 * - for organizer calendar, this is the week index
 * - for other calendars, this is the month index
 */
function GetPageCalendarIndex(pageIndex: number): number {
  const doc = GetDoc(getProject().docID);

  // case pagegroup are months (not for organizers !)
  const monthIndex =
    doc.pages_per_group === 1 ? pageIndex - 1 : pageIndex / 2 - 1;

  // if( frameVo.type !==FrameVo.TYPE_CALENDAR ) return -1;

  // TODO: check what this was all about in previous editor!
  // return PagesManager.instance.getPageVoByFrameVo(frameVo).calendarIndex;

  return monthIndex;
}

// /**
//  * retrieve the calendar page week index from a frameVo
//  * - organizer calendar
//  * - month calendars
//  *
//  */
// public function GetPageWeekIndex( frameVo:FrameVo ) :number
// {
// var calIndex :number = GetPageCalendarIndex(frameVo);

// // for organizer, the calIndex is the week index
// if (ProductsCatalogue.IsCalendar_Organizer())
// {
// return Math.floor( calIndex - 1 )/2 ;// -1 because first calendar page on organizer is the year summary  - /2 because we have two pages per week
// }

// // otherwise return the week number from monthIndex
// var monthIndex :number = calIndex
// var yearToUse :number = getYearToUseWithOffSet(monthIndex);
// monthIndex = GetMonthIndexWithOffSet(monthIndex);

// return DateUtil.GetISOWeekNumber(new Date(yearToUse, monthIndex, 1));
// }

/** ------------------------------------- spiral position  -------------------------------------*/

export const AlignMode = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export function GetSpiralPosition(docID: string, pageIndex: number): string {
  // organizer
  if (IsCalendar_Organizer(docID)) {
    if (pageIndex === 0 || pageIndex === 1) return AlignMode.LEFT;
    if (pageIndex % 2 === 0) return AlignMode.RIGHT;
    return AlignMode.LEFT;
  }

  // wall xl
  if (IsCalendar_WallXL(docID)) {
    if (pageIndex === 0) return AlignMode.TOP;
    if (pageIndex % 2 === 1) return AlignMode.BOTTOM;
    return AlignMode.TOP;
  }

  // other calendar spirals are on top
  return AlignMode.TOP;
}

// /**
//  * Retrieve the associated date for this frame
//  */
// /*
// public function OLD_GetFrameDate( frameVo : FrameVo ):Date
// {
// // this works only for calendar frames
// if( frameVo.type !==FrameVo.TYPE_CALENDAR ) return null;

// var calIndex :number = getFrameCalendarIndex(frameVo);
// var dateIndex :number = frameVo.dateIndex;
// var frameDate : Date = new Date();

// // if we are in a organizer calendar, the frame date is based on weeks
// if(ProductsCatalogue.IsCalendar_Organizer())
// {
// var weekIndex:number = calIndex +1;
// frameDate = DateUtil.weeksToDate(year, weekIndex, dateIndex);
// }

// // other calendar does have
// else
// {
// frameDate.setFullYear(year, calIndex, dateIndex);
// }

// // return the date
// return frameDate;
// }
// */

/**
 * based on page cal index (month index or week index)
 * and frame date index (index of date in that month or week)
 * we calculate the final frame date (with month and start week day offset)
 */
const GetFrameDate = (pageCalIndex: number, frame: Frame): Date => {
  // Security
  if (frame.type !== FRAME_TYPE.CALENDAR) {
    return null;
  }

  // start day
  const wantedStartDayIndex: number = getProjectStartWeekDay(); // sunday = 0, monday = 1, .., saturday = 6
  const wantedStartMonthIndex: number = getProjectStartMonth(); // month wanted for starting the year

  // TODO: organizer
  // // ----------------
  // // for ORGANISER CALENDAR (days of year) frame date is found based on page index which is week index
  // if (ProductsCatalogue.IsCalendar_Organizer())
  // {
  // var startingWeekIndex:number = DateUtil.GetISOWeekNumber( new Date(year, wantedStartMonthIndex, 4 )) -1; // 4 because the 4th of january is always on the first week of the year ;  -1 because weeks starts at 1, not at 0. for example (week1 as starting week and week index at 1 must return week1 as result)
  // var weekIndex :number = startingWeekIndex + Math.floor( GetPageCalendarIndex(frameVo) -1 )/2 ;// -1 because first calendar page on organizer is the year summary ; /2 because we have two pages per week
  // //var dayIndex :number = frameVo.dateIndex - wantedStartDayIndex; // in layout.xml dateIndex '1' is 'monday'
  // var dayIndex:number = (wantedStartDayIndex-1) + frameVo.dateIndex;
  // return DateUtil.weeksToDate(year, weekIndex, dayIndex-1); //-1 because weeksToDate use monday=0 and normal date use monday=1
  // }
  // // ----------------

  const monthIndex: number = pageCalIndex; // GetPageCalendarIndex(frameVo);
  let yearToUse: number = getYearToUseWithOffSet(monthIndex);
  let newmonthIndex: number = GetMonthIndexWithOffSet(monthIndex);
  let date: number = frame.calendarOptions.dateIndex;

  // do we need to display dates from other months also in this page?
  // if so we need to check with the frame is not a frame from another month
  if (IsCalendar_withMonthOverlap(getProject().docID)) {
    // the date index is not the day of the month, it's the index of date in this page.
    // so index 0 can be in previous month if current month do not start on monday.

    const firstDayOfMonthIndex: number = getFirstDayOfMonthIndex(
      newmonthIndex,
      yearToUse
    ); // (Sunday:0 -> Saturday:6)
    const totalDaysinMonth: number = daysInMonth(yearToUse, newmonthIndex); // amount of days in that specific month
    let displayOffSet: number;
    let endOffset: number;
    let startOffset: number = Math.abs(
      wantedStartDayIndex - firstDayOfMonthIndex
    );
    startOffset =
      firstDayOfMonthIndex < wantedStartDayIndex
        ? 7 - startOffset
        : startOffset;
    const totalDaysinPrevMonth: number = daysInMonth(
      yearToUse,
      newmonthIndex - 1
    );
    let realDayToReturn: number;

    // small hack for caledar WCAL4 (birthday)
    /*
if(ProjectManager.instance.project.docPrefix === ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL)
startOffset = 0;
*/

    // Create the array that contains all the days to display
    // Can be 30, 31, 28, 29 or even 37 when offset the previous month or the next
    const dayDateArray: Array = [];
    for (let i = 1; i <= totalDaysinMonth; i++) {
      dayDateArray.push(i);
    }

    // Calculate the difference between total day in the given month and total day to display
    // check if offset the previous month or the next
    displayOffSet = daysToDisplay(frame) - totalDaysinMonth;
    if (displayOffSet !== 0) {
      // start offset (days of previous month)
      for (let j = 0; j < startOffset; j++) {
        dayDateArray.unshift(totalDaysinPrevMonth - j);
      }

      // end offset (days of next month)
      endOffset = displayOffSet - startOffset;
      for (let j = 1; j <= endOffset; j++) {
        dayDateArray.push(j);
      }
    }

    // return correct day in indexed page dates
    // realDayToReturn = dayDateArray[date-1];
    realDayToReturn = dayDateArray[date - 1];
    if (date < 7 && Math.floor(realDayToReturn) > 7) newmonthIndex--;
    // else if (realDayToReturn <= 7 && (date-startOffset) > 7)
    else if (date - startOffset > totalDaysinMonth) {
      // changed by andb on 92104 (problem with pano calendar offset "march 2017")
      newmonthIndex++;
    }

    if (newmonthIndex === -1) {
      // december
      newmonthIndex = 11;
      yearToUse--;
    } else if (newmonthIndex === 12) {
      newmonthIndex = 0;
      yearToUse++;
    }

    date = realDayToReturn;
  }

  // return final date for this frame
  const resultDate: Date = new Date(yearToUse, newmonthIndex, date);
  return resultDate;
};

// /**
//  * get horizontal align
//  */
// public function getFrameTextHAlign( frameVo:FrameVo ):String
// {
// if( frameVo.type !==FrameVo.TYPE_CALENDAR ) return TextFormatAlign.LEFT;
// switch(frameVo.dateAction)
// {
// case DATE_ACTION_ENUM.NOTES:
// return TextFormatAlign.LEFT;
// break;

// case DATE_ACTION_ENUM.MINICALENDAR_WEEK:
// return TextFormatAlign.CENTER;
// break;

// case DATE_ACTION_ENUM.MINICALENDAR_MONTH:
// return TextFormatAlign.CENTER;
// break;

// }
// return frameVo.hAlign;
// }

// /**
//  * get horizontal align
//  */
// public function getFrameTextVAlign( frameVo:FrameVo ):String
// {
// if( frameVo.type !==FrameVo.TYPE_CALENDAR ) return VerticalAlign.TOP
// switch(frameVo.dateAction)
// {
// case DATE_ACTION_ENUM.NOTES:
// return VerticalAlign.TOP
// break;

// case DATE_ACTION_ENUM.MINICALENDAR_WEEK:
// return VerticalAlign.TOP;
// break;

// case DATE_ACTION_ENUM.MINICALENDAR_MONTH:
// return VerticalAlign.TOP
// break;

// }
// return frameVo.vAlign
// }

// return the offset for the text in the frame
function GetFrameTextOffset(frame: Frame) {
  const offsetPoint = { x: 0, y: 0 };
  const options: ICalendarFrameOptions = frame.calendarOptions;

  if (options.stroke) {
    switch (options.dateAction) {
      case DATE_ACTION_ENUM.DAYDATE:
        offsetPoint.x =
          !options.vAlign || options.vAlign === 'top' ? mmToPoint(2) : 0; // 2mm
        offsetPoint.y =
          !options.hAlign || options.hAlign === 'left' ? mmToPoint(2) : 0; // 2mm
        break;
    }
  }
  return offsetPoint;
}

function IsFrameMultiline(frame: Frame): boolean {
  switch (frame.calendarOptions.dateAction) {
    case DATE_ACTION_ENUM.NOTES:
    case DATE_ACTION_ENUM.MINICALENDAR_WEEK: // magnet
    case DATE_ACTION_ENUM.DATENAME: // new add 08/04/2014 > keith remark // Luxury wall
      return true;
      break;
  }
  return false;
}

/**
 * retrieve calendar text color
 */
function GetFrameTextColor(pageIndex: number, frame: Frame): string {
  // if not frame calendar do nothing
  if (frame.type !== FRAME_TYPE.CALENDAR) {
    return Colors.BLACK;
  }

  // const docID = getProject().docID;
  const { calendarOptions } = frame;
  const pageCalIndex = GetPageCalendarIndex(pageIndex);

  const frameDate: Date = GetFrameDate(pageCalIndex, frame);
  const yearIndex: number = frameDate.getFullYear();
  const monthIndex: number = frameDate.getMonth();
  const dateIndex: number = frameDate.getDate();

  // var text : String = GetFrameText(frameVo);
  let dayType: string;
  // var color:number;
  if (calendarOptions.dateAction === DATE_ACTION_ENUM.DAYDATE) {
    // dayType = whatKindofDayDateAmI(frameVo, text); // old
    dayType = whatKindofDayDateAmI(pageCalIndex, frame); // ;, text);
  } else if (calendarOptions.dateAction === DATE_ACTION_ENUM.DATENAME) {
    // dayType = whatKindofDayNameAmI(frameVo.dateIndex,monthIndex,yearToUse); // old
    dayType = whatKindofDayNameAmI(
      calendarOptions.dateIndex,
      monthIndex,
      yearIndex
    ); // old
  } else if (
    calendarOptions.dateAction === DATE_ACTION_ENUM.MINICALENDAR_WEEK
  ) {
    dayType = whatKindofMcwvarAmI(calendarOptions.dateIndex); // old
  } else if (
    calendarOptions.dateAction === DATE_ACTION_ENUM.WEEKDAYFULL ||
    calendarOptions.dateAction === DATE_ACTION_ENUM.WEEKDAY
  ) {
    dayType = whatKindofWeekDayAmI(calendarOptions.dateIndex); // old
  } else {
    dayType = calendarOptions.dateAction;
  }

  return getColor(frame, pageIndex, dayType);
}

/**
 * Get color byt type of dayDate
 */
function getColor(frameVo: Frame, pageIndex, dayType: string): string {
  // TODO: recover colors per page and not for the full project!
  // var pageVo : PageVo = PagesManager.instance.getPageVoByFrameVo(frameVo);
  // var colors:ICanlendarColorOptions = (pageVo.customColors)? pageVo.customColors : new CalendarColors();
  const colors: ICalendarColorOptions = getPageCalendarColors(pageIndex);
  const { docID } = getProject();

  switch (dayType) {
    case DAYDATE_TYPE.OFFSET:
      return colors.prevNextMonthColor;
      break;

    case DAYDATE_TYPE.WEEKDAY:
      return colors.dayColor;
      break;

    case DAYDATE_TYPE.WEEKEND:
      if (!IsCalendar_Birthday(docID)) return colors.weekendColor;
      return colors.dayColor;
      break;

    case DATE_ACTION_ENUM.MONTH:
      return colors.monthAndYearColor;
      break;

    case DATE_ACTION_ENUM.YEAR:
      return colors.firstPageYearColor;
      break;

    case DATE_ACTION_ENUM.WEEKOFYEAR:
      if (IsCalendar_Organizer(docID)) return Colors.WHITE;
      break;

    case DATE_ACTION_ENUM.MINICALENDAR_MONTH:
    case DATE_ACTION_ENUM.MONTHYEAR:
      return colors.monthAndYearColor;
      break;

    case DATE_ACTION_ENUM.MINICALENDAR_NEXT:
    case DATE_ACTION_ENUM.MINICALENDAR_PREVIOUS:
      return colors.prevNextMonthColor;
      break;
  }

  return Colors.BLACK;
}

// /**------------------------------------ Print Helpers -------------------------------------*/

function getCalendarSubFramePhoto(frame: Frame) {
  frame = cloneDeep(frame);
  frame.type = FRAME_TYPE.PHOTO;
  frame.text = null;
  return frame;
}

function getCalendarSubFrameText(frame: Frame) {
  frame = cloneDeep(frame);
  frame.type = FRAME_TYPE.TEXT;
  frame.fillColor = null;
  frame.photo = null;
  return frame;
}

// /**
//  *  create a background frame at the same position of the calendar to render background when print "non-uploading" calendar frame
//  */
// public function GetCalendarBackgroundColorFrame( frameVo : FrameVo, backgroundColor :number ):FrameVo
// {
// // create a background frame for this calendar frame
// var bkg:FrameVo = new FrameVo();
// bkg.type = FrameVo.TYPE_BKG;
// bkg.x = frameVo.x;
// bkg.y = frameVo.y;
// bkg.width = frameVo.width;
// bkg.height = frameVo.height;
// bkg.fillAlpha = frameVo.fillAlpha;
// bkg.fillColor = backgroundColor;
// return bkg;
// }

/**
 * Return string with: Mon 1 7 14 21
 * index is // 11, 12, 13, 14, 15, 16, 17 // 21, 22, 23 etc...
 * 21 = month 2, day 1 (Sunday depending on the start day of week)
 * 12 = month 1, day 2 (monday depending on the start day of week)
 * ...
 */
function getVerticalWeek(
  index: number,
  monthIndexOffSet = 0,
  dayNameMaxChars = 3,
  localized = false,
  showDayName = true
): string {
  const str = String(index);
  const dayIndex: number = Math.floor(str.substr(str.length - 1)) - 1;
  const monthIndex: number = Math.floor(str.substr(0, str.length - 1)) - 1;
  const year = getProjectStartYear();
  let yearToUse: number = year;
  const wantedStartMonthIndex: number = getProjectStartMonth();
  let newmonthIndex: number =
    wantedStartMonthIndex + monthIndex + monthIndexOffSet;
  if (newmonthIndex > 11) {
    newmonthIndex = Math.abs(12 - newmonthIndex);
    yearToUse = year + 1;
  }

  // only for mini calendars
  if (newmonthIndex < 0) {
    newmonthIndex = 11;
    yearToUse = year - 1;
  }

  let endOffset: number;
  const wantedStartDayIndex: number = getProjectStartWeekDay();
  const firstDayOfMonthIndex: number = getFirstDayOfMonthIndex(
    newmonthIndex,
    yearToUse
  );
  const totalDaysinMonth: number = daysInMonth(yearToUse, newmonthIndex);
  let startOffset: number = Math.abs(
    wantedStartDayIndex - firstDayOfMonthIndex
  );
  startOffset =
    firstDayOfMonthIndex < wantedStartDayIndex ? 7 - startOffset : startOffset;

  // Parse all month
  // Group mondays with in monday's Array, Tuesdays in tuesday's array etc...
  // {"1": [1,8,15,22,29]} , {"2": [2,9,16,23,30]} etc...
  const repository = {};
  try {
    for (let i = 0; i < totalDaysinMonth; i++) {
      const dayArrName = String(
        getDayOfMonthIndex(i + 1, newmonthIndex, yearToUse)
      );

      if (!repository[`y${yearToUse}`]) repository[`y${yearToUse}`] = {};
      if (!repository[`y${yearToUse}`][`d${dayArrName}`])
        repository[`y${yearToUse}`][`d${dayArrName}`] = [];
      repository[`y${yearToUse}`][`d${dayArrName}`].push(i + 1);
    }
  } catch (e) {
    console.warn('Error in get vertical week', e);
  }

  let offSetIndex: number = dayIndex + wantedStartDayIndex;
  offSetIndex =
    offSetIndex < WEEK_DAYS.length
      ? offSetIndex
      : offSetIndex - WEEK_DAYS.length;

  if (dayIndex < startOffset)
    repository[`y${yearToUse}`][`d${offSetIndex}`].unshift(' ');

  // all days below each other
  let result: string =
    repository[`y${yearToUse}`][`d${offSetIndex}`].join('\n');

  // we always want 6 rows to display months
  const wantedLines = 6;
  const numLines = result.split('\n').length;
  if (numLines < wantedLines) {
    for (let i = numLines; i < wantedLines; i++) result += '\n ';
  }

  // display day of the week before
  if (showDayName) {
    let dayName: string = !localized
      ? WEEK_DAYS[offSetIndex].substr(0, dayNameMaxChars)
      : GetText(`calendar.weekday.${WEEK_DAYS[offSetIndex]}`).substr(
          0,
          dayNameMaxChars
        );
    dayName = dayNameMaxChars === 1 ? dayName.toUpperCase() : dayName;
    result = `${dayName}\n${result}`;
  }

  return result;
}

// // recover the day name by week day index (relative to the starting week day that could be changed by user)
// public function GetDayNameByWeekDayIndex(weekDayIndex:number, dayNameMaxChars:number = 3, localized:Boolean = true):String
// {
// var wantedStartDayIndex:number = getProjectStartWeekDay();
// var offSetIndex:number = weekDayIndex + wantedStartDayIndex;
// offSetIndex = (offSetIndex < WEEK_DAYS.length) ? offSetIndex : offSetIndex - WEEK_DAYS.length;

// var dayName:String = (!localized)?WEEK_DAYS[offSetIndex].substr(0,dayNameMaxChars):GetText("calendar.weekday."+WEEK_DAYS[offSetIndex]).substr(0,dayNameMaxChars);
// dayName = (dayNameMaxChars === 1)?dayName.toUpperCase():dayName;

// return dayName;
// }

// /* ------------------------------------- PRIVATE -------------------------------------*/

/**
 * Return notes:.........
 */
let noteLineContent = null;
function getNotesContent(): string {
  // TODO: Notes: THIS WHOLE THINKG IS A PERFORMANCE KILLER!!! for svg
  if (!noteLineContent) {
    let dots = '';
    const numLines = 5;
    for (let l = 0; l < numLines; l++) {
      for (let i = 0; i < 100; i++) {
        dots = `${dots} . `;
      }
      dots += l === numLines - 1 ? '' : '\n';
    }
    noteLineContent = dots;
  }

  return `${GetText('calendar.text.notes')}: ${noteLineContent}`;
}

/**
 * get the date name
 * Luxury calendars
 * return We 1, Tu 2, Fr 3...
 */
function getDateName(date: number, month: number, localized = false): string {
  /* OLD
var yearToUse:number = year;
var wantedStartMonthIndex:number = getProjectStartMonth();
var newmonthIndex:number =  wantedStartMonthIndex+monthIndex;
if(newmonthIndex>11)
{
newmonthIndex = Math.abs(12-newmonthIndex);
yearToUse = year+1;
}
var dayIndex:number = getDayOfMonthIndex(index,newmonthIndex, yearToUse);
var dayName:String = (!localized)?WEEK_DAYS[dayIndex].substr(0,2):GetText("calendar.weekday."+WEEK_DAYS[dayIndex]).substr(0,2);
return dayName+"\n"+index;
*/
  const year = getProjectStartYear();
  let yearToUse: number = year;
  if (month > 11) {
    month = Math.abs(12 - month);
    yearToUse = year + 1;
  }
  const dayIndex: number = getDayOfMonthIndex(date, month, yearToUse);
  const dayName: string = !localized
    ? WEEK_DAYS[dayIndex].substr(0, 2)
    : GetText(`calendar.weekday.${WEEK_DAYS[dayIndex]}`).substr(0, 2);
  return `${dayName}\n${date}`; // TODO:  \n or <br> not working in svg... need to find an alternative
}

/**
 * get the day Number
 * return the correct year depending on monthIndex
 */
function getyearLabel(monthIndex: number, withNextYear = false): string {
  const year = getProjectStartYear();
  let yearToUse: number = year;
  const wantedStartMonthIndex: number = getProjectStartMonth();
  let newmonthIndex: number = wantedStartMonthIndex + monthIndex;
  if (newmonthIndex > 11) {
    newmonthIndex = Math.abs(12 - newmonthIndex);
    yearToUse = year + 1;
  }
  if (withNextYear)
    return wantedStartMonthIndex > 0
      ? `${String(year)}-${String(year + 1)}`
      : String(year);
  return String(yearToUse);
}

// /**
//  * get the day Number
//  * return 1->31
//  */
// /*
// private function getDayDate(index:number, monthIndex:number, frameVo:FrameVo, returnFullDate:Boolean = false):String
// {
// var yearToUse:number = year;
// var wantedStartMonthIndex:number = getProjectStartMonth();
// var newmonthIndex:number =  wantedStartMonthIndex+monthIndex;

// if(newmonthIndex>11)
// {
// newmonthIndex = Math.abs(12-newmonthIndex);
// yearToUse = year+1;
// }

// var dayToReturn:number;
// var displayOffSet:number;
// var endOffset:number;
// var wantedStartDayIndex:number = getProjectStartWeekDay();
// var firstDayOfMonthIndex:number = getFirstDayOfMonthIndex(newmonthIndex, yearToUse);
// var totalDaysinMonth:number = daysInMonth(yearToUse,newmonthIndex);
// var startOffset:number = Math.abs(wantedStartDayIndex -firstDayOfMonthIndex);
// startOffset = (firstDayOfMonthIndex<wantedStartDayIndex)?7-startOffset:startOffset;
// var totalDaysinPrevMonth:number = daysInMonth(yearToUse,newmonthIndex-1);
// var dayNumberToReturn:String;

// //small hack for caledar WCAL4 (birthday)
// if(ProjectManager.instance.project.docCode === "WCAL4")
// startOffset = 0;

// //Create the array that contains all the days to display
// //Can be 30, 31, 28, 29 or even 37 when offset the previous month or the next
// var dayDateArray:Array = [];//(splittedMonth[monthIndex])?splittedMonth[monthIndex]:[];
// if(dayDateArray.length === 0)
// {
// for (var i:number = 1; i <= totalDaysinMonth; i++)
// {
// dayDateArray.push(i);
// }
// //Calculate the difference between total day in the given month and total day to display
// // check if offset the previous month or the next
// displayOffSet = daysToDisplay(frameVo) - totalDaysinMonth;
// if(displayOffSet === 0)
// {
// dayNumberToReturn = dayDateArray[index-1];
// if(!returnFullDate)
// return dayNumberToReturn;
// else
// return dayNumberToReturn+"/"+newmonthIndex+"/"+yearToUse;

// }
// else
// {
// //start offset
// for (var j:number = 0; j < startOffset; j++)
// {
// dayDateArray.unshift(totalDaysinPrevMonth-j);
// }
// //end offset
// endOffset = displayOffSet - startOffset;
// for (j = 1; j <= endOffset; j++)
// {
// dayDateArray.push(j);
// }
// }
// //splittedMonth[monthIndex] = dayDateArray;
// }
// dayNumberToReturn = dayDateArray[index-1];
// if(!returnFullDate)
// {
// return dayNumberToReturn;
// }
// else
// {
// if(index < 7 && int(dayNumberToReturn) > 7)
// newmonthIndex --;
// else if (int(dayNumberToReturn) < 7 && (index-startOffset) > 7)
// newmonthIndex ++;

// if(newmonthIndex === -1)//december
// {
// newmonthIndex =  11;
// yearToUse--;
// }
// else if(newmonthIndex === 12)
// {
// newmonthIndex =  0;
// yearToUse++;
// }

// return dayNumberToReturn+"/"+newmonthIndex+"/"+yearToUse;
// }
// }
// */

// /**
//  * get the difference of day between the month and what is displayed
//  *
//  */
// private function getOffSetDays(monthIndex:number, frameVo:FrameVo):number
// {
// var totalDaysInMonth:number = daysInMonth(year,monthIndex);
// var daysToDisplay:number = daysToDisplay(frameVo);
// return daysToDisplay - totalDaysInMonth;
// }

/**
 * get the number of day to display
 * find the amount of days to display from the layout, it is used to check the difference between the amount of days in a month and the amoun of days displayed.
 * We can then use a different color for days outside the month but displayed (offset)
 */
function daysToDisplay(frame: Frame): number {
  // var pageVo:PageVo = PagesManager.instance.getPageVoByFrameVo(frameVo);
  // var xml:XML = ProjectManager.instance.layoutsXML.layout.(@name === pageVo.layoutId)[0];
  // var list:XMLList = xml..frame.(attribute("dateaction") === DATE_ACTION_ENUM.DAYDATE);

  // //return LayoutManager.instance.totalDaysToDisplay;
  // return list.length();

  // TODO: this was way too complicated to go through the layout and try to get all daydate frames.
  // we will do easier by docID
  // const docID = getProject().docID;
  // // const doc = GetDoc(getProject().docID);
  // if(docID === "DCAL" || docID === "DCAL2")
  // return 37;

  return 50;
}

/**
 * get weekDay by index
 * return monday, tuesday etc...
 */
function getWeekDayFull(
  dayIndex: number,
  shortVersion = false,
  localized = false
): string {
  let maxChars = 3;
  const { docID } = getProject();

  // Some projects do only have 2 chars for weekDay
  if (
    docID === DOC_ID_ENUM.CAL_DESK_A5 ||
    docID === DOC_ID_ENUM.CAL_DESK_PORTRAIT_A5 ||
    docID === DOC_ID_ENUM.CAL_DESK_PORTRAIT_A5
  )
    maxChars = 2;

  // add start day index
  dayIndex += getProjectStartWeekDay();
  if (dayIndex > WEEK_DAYS.length - 1) {
    dayIndex -= WEEK_DAYS.length;
  }

  if (shortVersion) {
    if (!localized) return WEEK_DAYS[dayIndex].substr(0, maxChars);
    return GetText(`calendar.weekday.${WEEK_DAYS[dayIndex]}`).substr(
      0,
      maxChars
    );
  }

  if (!localized) return WEEK_DAYS[dayIndex];
  return GetText(`calendar.weekday.${WEEK_DAYS[dayIndex]}`);
}

/**
 * get week of year
 * return 1->52
 */
function getWeekOfYear(frame: Frame, pageCalIndex: number): string {
  /*
var index:number = frameVo.dateIndex;
var day:Date;
//var dateStringOfFirstFrameAfterWeekDayFrame:String = getDayDate(index,monthIndex,frameVo,true);
var dateStringOfFirstFrameAfterWeekDayFrame:String = DateUtil.toSimpleDateString(GetFrameDate(frameVo));
var splittedDate:Array = dateStringOfFirstFrameAfterWeekDayFrame.split("/");
var monthfordate:number = splittedDate[1];
var yearfordate:number = splittedDate[2];
var dayfordate:number = splittedDate[0];
var dateObj:Date = new Date(yearfordate,monthfordate,dayfordate);
var dayOfYear:number = getDayOfYear(dateObj); //1-365
var weekDay:number =  dateObj.getUTCDay()+1;// (Monday:1 -> Sunday:7)
var weekNumber:number = Math.floor((dayOfYear - weekDay + 10)/7);
//hack for january first week
if(monthfordate === 11 && index === 1)
{
/* OLD
if(weekNumber > 51)
weekNumber = 1;
* /
return "";

}
*/

  const date = GetFrameDate(pageCalIndex, frame);
  // console.log(`date:${date.toDateString()}`);
  // https://stackoverflow.com/questions/25953551/moment-js-get-the-week-number-based-on-a-specific-day-also-past-years
  // var weekNumber :number = moment(date).week();
  const weekNumber: number = moment(date).isoWeek();
  // console.log(`weekNumber:${weekNumber}`);

  // TODO:
  // return only week number for organizer (no prefix)
  // if(ProductsCatalogue.IsCalendar_Organizer())
  // return "" + weekNumber;

  return GetText('calendar.text.week.initital') + String(weekNumber);
}

// /**
//  * returns the amount of weeks in a month (not complete weeks, but different weeks)
//  */
// public function GetNumOfWeekInAMonth( monthIndex:number, yearToUse = -1) :number
// {
// // default year if not given
// if(yearToUse ===-1)
// yearToUse = year;

// if(monthIndex > 11)
// {
// yearToUse +=1;
// monthIndex = Math.abs(12-monthIndex);
// }

// // check all dates of the month
// var numDays :number = GetNumOfDaysInAMonth(monthIndex, yearToUse);
// var numWeeks :number = 1; // add already first week
// var date : Date;
// for (var i:number = 1; i < numDays; i++) // start at day +1 as we added already the first week
// {
// date = new Date(yearToUse, monthIndex, i+1 );
// // look for mondays
// if(date.getDay() === 1)
// numWeeks ++;
// }

// return numWeeks;
// }

/**
 * get month text
 * return january, february etc..
 */
function getMonthFull(monthIndex: number, localized = false): string {
  if (!localized) return ALL_MONTHS[monthIndex];
  return GetText(`calendar.month.${ALL_MONTHS[monthIndex]}`);
}

function GetNewCalendarYear(): number {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  return month > 5 ? year + 1 : year; // > june = new year
}

/**
 * get month with offset
 * return january 2014, february 2014 etc..
 */
function getMonthWithOffSetYear(monthIndex: number, withYear = false): string {
  const wantedStartMonthIndex: number = getProjectStartMonth();
  let newmonthIndex: number = wantedStartMonthIndex + monthIndex;
  const year = getProjectStartYear();
  let yearToUse: number = year;
  if (newmonthIndex > 11) {
    newmonthIndex = Math.abs(12 - newmonthIndex);
    yearToUse = year + 1;
  }
  if (withYear)
    return `${getMonthFull(newmonthIndex, true)} ${Number(yearToUse)}`;
  return getMonthFull(newmonthIndex, true);
}

/**
 * get month with offset
 * return january 2014, february 2014 etc..
 */
function getMiniCalendarMonthWithOffSetYear(
  monthIndex: number,
  monthIndexOffset: number,
  withYear = false
): string {
  const wantedStartMonthIndex: number = getProjectStartMonth();
  let newmonthIndex: number =
    wantedStartMonthIndex + monthIndex + monthIndexOffset;
  const year = getProjectStartYear();
  let yearToUse: number = year;
  if (newmonthIndex > 11) {
    newmonthIndex = Math.abs(12 - newmonthIndex);
    yearToUse = year + 1;
  }
  if (newmonthIndex < 0) {
    newmonthIndex = 11;
    yearToUse = year - 1;
  }

  if (withYear)
    return `${getMonthFull(newmonthIndex, true)} ${Number(yearToUse)}`;
  return getMonthFull(newmonthIndex, true);
}

// /**
//  * get month text
//  * return january 2014, february 2014 etc..
//  */
// private function getMonthYear(monthIndex:number):String
// {
// var yearOffSet:number = 0;
// if(monthIndex <0)
// {
// monthIndex = 11;
// yearOffSet--;
// }
// else if(monthIndex >11)
// {
// monthIndex = 0;
// yearOffSet++;
// }
// return getMonthFull(monthIndex, true) +" "+ Number(ProjectManager.instance.project.calendarYear+yearOffSet); //return getMonthFull(monthIndex, true) +" "+ Number(year+yearOffSet);
// }

/**
 * Helper
 * Gives the amount of day in a given month
 * */
function daysInMonth(year: number, month: number): number {
  month = month < 0 ? 12 - Math.abs(month) : month;
  let totalDays = 0;
  // create an array of month durations
  const monthLength: Array = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // check for leap year
  const isLeap: boolean =
    (year % 4 === 0 && year % 100 !== 0) ||
    (year % 100 === 0 && year % 400 === 0);

  if (month === 1) {
    if (IsCalendar_Birthday(getProject().docID) || isLeap) totalDays = 29;
    else totalDays = monthLength[month];
  } else {
    totalDays = monthLength[month];
  }
  return totalDays;
}

// /**
//  * Helper
//  * Gives index of the given day in a year (365)
//  * */
// private function getDayOfYear(date:Date):number
// {
// var dayInYear:number = 0;

// // get day of year up to month
// for (var i:number = 0; i < date.getMonth(); i++) {
// dayInYear += GetNumOfDaysInAMonth(i, date.fullYear );
// }

// // add day inside month
// dayInYear += date.getDate();

// // Start counting on 0 (optional)
// // dayInYear--;

// return dayInYear;
// }

// /**
//  * retrieve the amount of days in a month
//  */
// public function GetNumOfDaysInAMonth( monthIndex :number, yearIndex :number = -1) :number
// {
// // default year
// if(yearIndex === -1)
// yearIndex = year;

// // leap year on february
// // A leap year is divisable by 4, but not by 100 unless divisable by 400.
// if ( monthIndex === 1 && ((yearIndex % 4 === 0) && (yearIndex % 100 !==0)) || (yearIndex % 400 === 0)) {
// return 29;
// }

// return monthLengths[monthIndex];
// }

/**
 * Helper
 * Gives index of the first day of the month (0->6) (Sunday:0 -> Saturday:6)
 * */
function getFirstDayOfMonthIndex(month: number, year: number): number {
  const d: Date = new Date(year, month, 1);
  return d.getDay();
}

/**
 * Helper
 * Gives index of the given day index of the month (0->6) (Sunday:0 -> Saturday:6)
 * */
function getDayOfMonthIndex(day: number, month: number, year: number): number {
  const d: Date = new Date(year, month, day);
  return d.getDay();
}

export const CalendarHelper = {
  InitializeStoreRef,
  GetNewCalendarYear,
  GetMonthNameByIndex,
  GetFrameText,
  GetFrameTextColor,
  GetPageCalendarIndex, // retrieve the pageCalIndex based on pageIndex
  GetMonthIndexWithOffSet,
  GetFrameTextOffset,
  IsFrameMultiline,

  // print helper
  getCalendarSubFramePhoto,
  getCalendarSubFrameText,

  // mini calendar
  IsMiniCalendarFrame: (frame: Frame) =>
    frame?.calendarOptions &&
    (frame.calendarOptions.dateAction ===
      DATE_ACTION_ENUM.MINICALENDAR_PREVIOUS ||
      frame.calendarOptions.dateAction === DATE_ACTION_ENUM.MINICALENDAR_NEXT ||
      frame.calendarOptions.dateAction === DATE_ACTION_ENUM.MINICALENDAR_FULL),
  getMiniCalendarMonthWithOffSetYear,
  getVerticalWeek,
};
