import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { GetText } from '../../data/LanguageHelper';
import { ROUTE_CONST } from '../../utils/history';

export const RegisterForm = ({ registering, errorMessage, onSubmit }) => {
  // const { getFieldDecorator } = this.props.form;
  // const { registering, errorMessage } = this.props;
  const [internalError, setInternalError] = useState();
  const [form] = Form.useForm();

  // --------------------- CONSTRUCTOR ------------------------

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     internalError: null,
  //   };
  //   /*
  //   this.props.form.setFieldsValue({
  //       username: this.props.username,
  //       password: this.props.password
  //   });
  //   */
  //   // alert("username = " + this.props.username);
  // }

  // --------------------- REACT ------------------------

  // componentDidMount() {
  //   //   this.props.form.setFieldsValue({
  //   //     username: this.props.username,
  //   //     password: this.props.password
  //   // });
  // }

  // --------------------- REACT ------------------------

  const handleSubmit = ({ email, password, password2 }) => {
    setInternalError(undefined);
    if (password !== password2) {
      setInternalError(GetText('form.error.PASSWORD_MISMATCH'));
      return;
    }
    onSubmit(email, password);
    /*
    // I had to switch from onSubmit to onFinish because onSubmit was not working
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const mail = form.getFieldValue('email');
        // check that pass1 is same as pass2
        const pass1 = form.getFieldValue('password');
        const pass2 = form.getFieldValue('password2');
        if (pass1 !== pass2) {
          setInternalError(GetText('form.error.PASSWORD_MISMATCH'));
          return;
        }

        onSubmit(mail, pass1);
      }
    });
    */
  };

  // --------------------- RENDER ------------------------

  return (
    <Form onFinish={handleSubmit} className="register-form">
      <Form.Item
        name="email"
        rules={[
          { required: true, message: GetText('register.user.error') },
          { type: 'email', message: GetText('register.user.error') },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={GetText('register.user')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: GetText('register.pass2.error') }]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder={GetText('register.pass1')}
        />
      </Form.Item>

      <Form.Item
        name="password2"
        rules={[{ required: true, message: GetText('register.pass2.error') }]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder={GetText('register.pass2')}
        />
      </Form.Item>
      <Form.Item>
        {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
        {internalError && (
          <Alert message={internalError} type="error" showIcon />
        )}
        <Button
          type="primary"
          htmlType="submit"
          className="register-form-button"
          disabled={registering}
        >
          {registering
            ? GetText('common.continue')
            : GetText('register.continue.button')}
        </Button>
        Or{' '}
        <Link to={ROUTE_CONST.LOGIN}>{GetText('register.login.button')}</Link>
      </Form.Item>
    </Form>
  );
};
