/* eslint-disable no-bitwise */
// var convert = require('color-convert');

// export function getRGBColorStringFromRGB(name:String, hex:String):String {
//     // var red:number = (rgb >> 16) & 0xFF;
//     // var green:number = (rgb >> 8) & 0xFF;
//     // var blue:number = (rgb & 0xFF);
//     var rgb = convert.hex.rgb(hex);
//     var red = rgb[0];
//     var green = rgb[1];
//     var blue = rgb[2];

//     var str:String = "&"+name+"space=rgb&"+name+"="+(red / 255).toFixed(6)+","+(green / 255).toFixed(6)+","+(blue / 255).toFixed(6)+",0.000000";
//     return str;
// }

// export function getCMYKColorStringFromRGB(name:String, hex:String):String {
//     var str:String = "&"+name+"space=cmyk&"+name+"="+getCMYKStringFromRGB(hex);
//     return str;
// }

// export function getCMYKStringFromRGB(hex:String):String {
//     // var red:number = (rgb >> 16) & 0xFF;
//     // var green:number = (rgb >> 8) & 0xFF;
//     // var blue:number = (rgb & 0xFF);
//     // var C:number = 1.0 - (red / 255.0);
//     // var M:number = 1.0 - (green / 255.0);
//     // var Y:number = 1.0 - (blue / 255.0);
//     // var blk:number = 1.0;
//     // if (C < blk) blk = C;
//     // if (M < blk) blk = M;
//     // if (Y < blk) blk = Y;
//     // if (blk === 1.0) { // Kodak Black
//     //     C = 0.85;
//     //     M = 0.85;
//     //     Y = 0.85;
//     // }
//     // else {
//     //     C = (C - blk) / (1.0 - blk);
//     //     M = (M - blk) / (1.0 - blk);
//     //     Y = (Y - blk) / (1.0 - blk);
//     // }
//     // var K:number = blk;
//     var cmyk = convert.hex.cmyk.raw(hex);
//     var C = 1-(cmyk[0]/255);
//     var M = 1-(cmyk[1]/255);
//     var Y = 1-(cmyk[2]/255);
//     var K = 1-(cmyk[3]/255);

//     var cmyk:String = C.toFixed(6)+","+M.toFixed(6)+","+Y.toFixed(6)+","+K.toFixed(6);
//     return cmyk;
// }

export const PrintColorHelper = {
  getRGBColorString,
  getCMYKColorString,
};

function getRGBColorString(paramName: string, hexColor: string): string {
  return getRGBColorStringFromRGB(paramName, hexStringToRGB(hexColor));
}
function getCMYKColorString(paramName: string, hexColor: string): string {
  return getCMYKColorStringFromRGB(paramName, hexStringToRGB(hexColor));
}

/*
 Replace a hex string #fffff -> to rgb int
*/
function hexStringToRGB(hexString: string): number {
  const rrggbb = hexString.substring(1); // remove the #
  const bbggrr =
    rrggbb.substr(0, 2) + rrggbb.substr(2, 2) + rrggbb.substr(4, 2);
  const theColor: number = parseInt(bbggrr, 16);
  return theColor;
}

function getRGBColorStringFromRGB(name: string, rgb: number): string {
  const red: number = (rgb >> 16) & 0xff;
  const green: number = (rgb >> 8) & 0xff;
  const blue: number = rgb & 0xff;
  const str = `&${name}space=rgb&${name}=${(red / 255).toFixed(6)},${(
    green / 255
  ).toFixed(6)},${(blue / 255).toFixed(6)},0.000000`;
  return str;
}

function getCMYKColorStringFromRGB(name: string, rgb: number): string {
  const str = `&${name}space=cmyk&${name}=${getCMYKStringFromRGB(rgb)}`;
  return str;
}

/**
 * Retrieve color intensity (0-1)
 * 0 is black, 1 is white
 * @param color
 * @returns {number}
 */
export const getColorIntensity = (hexColor: string): number => {
  // Remove the '#' character if it exists
  let color = hexColor;
  if (color.startsWith('#')) {
    color = color.slice(1);
  }
  // Convert hex to RGB
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  // Calculate the intensity
  const intensity = (red + green + blue) / (255 * 3);
  return intensity;
};

function getCMYKStringFromRGB(rgb: number): string {
  const red: number = (rgb >> 16) & 0xff;
  const green: number = (rgb >> 8) & 0xff;
  const blue: number = rgb & 0xff;
  let C: number = 1.0 - red / 255.0;
  let M: number = 1.0 - green / 255.0;
  let Y: number = 1.0 - blue / 255.0;
  let blk = 1.0;
  if (C < blk) blk = C;
  if (M < blk) blk = M;
  if (Y < blk) blk = Y;
  if (blk === 1.0) {
    // Kodak Black
    C = 0.85;
    M = 0.85;
    Y = 0.85;
  } else {
    C = (C - blk) / (1.0 - blk);
    M = (M - blk) / (1.0 - blk);
    Y = (Y - blk) / (1.0 - blk);
  }
  const K: number = blk;
  const cmyk = `${C.toFixed(6)},${M.toFixed(6)},${Y.toFixed(6)},${K.toFixed(
    6
  )}`;
  return cmyk;
}
