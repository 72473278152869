import { ChangeEventHandler, Ref } from 'react';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BgColorsOutlined,
  BoldOutlined,
  DeleteOutlined,
  EditOutlined,
  ItalicOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import { Button, Popover, Select, Tooltip } from 'antd';
import clsx from 'clsx';
import ColorPicker, {
  ColorPickerObject,
} from '../../../../_components/ColorPicker';
import { Colors } from '../../../../data/Colors';
import { FONT_LIST_EMBED, FONT_SIZES_LIST } from '../../../../data/fonts';
import { GetText } from '../../../../data/LanguageHelper';
import {
  Frame,
  HorizontalTextAlign,
  VerticalTextAlign,
} from '../../../../types/types';
import ButtonGroup from 'antd/lib/button/button-group';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';

const { Option } = Select;

const tooltipDelay = 1;

type Props = {
  editedFrame: Frame;
  textInputRef: Ref<TextAreaRef>;

  onTextChange: ChangeEventHandler<HTMLTextAreaElement>;
  onChangeTextHAlign: (align: HorizontalTextAlign) => void;
  onChangeTextVAlign: (align: VerticalTextAlign) => void;
  onClearText: () => void;
  onTextFamilyChange: (value: string) => void;
  onTextSizeChange: (value: number) => void;
  onTextColorChange: (colorObj: ColorPickerObject) => void;
  onTextBGColorChange: (colorObj: ColorPickerObject) => void;
  onAddDefaultTextToFrame: () => void;
  onApplyCurrentTextOptionsToAll: () => void;

  onToggleTextItalic: () => void;
  onToggleTextBold: () => void;
};

const HAlignButtons = [
  { align: 'left', icon: AlignLeftOutlined },
  { align: 'center', icon: AlignCenterOutlined },
  { align: 'right', icon: AlignRightOutlined },
];
const VAlignButtons = [
  { align: 'top', icon: VerticalAlignTopOutlined },
  { align: 'middle', icon: VerticalAlignMiddleOutlined },
  { align: 'bottom', icon: VerticalAlignBottomOutlined },
];

export const TextEditionToolbar = ({
  editedFrame,
  textInputRef,
  onClearText,
  onTextChange,
  onTextColorChange,
  onTextBGColorChange,
  onChangeTextHAlign,
  onChangeTextVAlign,
  onTextSizeChange,
  onTextFamilyChange,
  onAddDefaultTextToFrame,
  onApplyCurrentTextOptionsToAll,

  onToggleTextItalic,
  onToggleTextBold,
}: Props) => {
  // at this point, we can have a frame that doens't yet have the text object
  // for example a frame calendar with not text, ca be edited to add text.
  const frameText = editedFrame.text;

  // get current HAlign
  const currentHAlignIndex =
    HAlignButtons.findIndex((val) => val.align === frameText?.halign) || 0;
  const currentHAlign = HAlignButtons[currentHAlignIndex];
  const nextHAlign = HAlignButtons[(currentHAlignIndex + 1) % 3];

  // get current VAlign
  const currentVAlignIndex =
    VAlignButtons.findIndex((val) => val.align === frameText?.valign) || 0;
  const currentVAlign = VAlignButtons[currentVAlignIndex];
  const nextVAlign = VAlignButtons[(currentVAlignIndex + 1) % 3];

  // ------------------------- render -------------------------
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        <div>
          {frameText && (
            <div key={editedFrame.id} className="textToolBar">
              <TextArea
                className={clsx('textArea', {
                  'text-center': frameText.halign === 'center',
                  'text-right': frameText.halign === 'right',
                  'text-left': frameText.halign === 'left',
                })}
                key={editedFrame.id}
                ref={textInputRef}
                autoFocus
                onChange={onTextChange}
                autoSize
                defaultValue={frameText.value}
              />

              {/* <Tooltip mouseEnterDelay={tooltipDelay}  title="Change Text" placement="top"><Button type="primary" icon="font-color" onClick={(e)=>{ this.handleFrameMoveDown(); }} /></Tooltip> */}

              <Select
                className="fontFamilySelector"
                // defaultValue="lucy"
                value={frameText.family}
                onChange={onTextFamilyChange}
              >
                {/* <Option key="font family key" value={"font family value"}>Font family</Option>      */}

                {FONT_LIST_EMBED.map((key) => (
                  <Option
                    key={key}
                    value={key}
                    className="fontFamilySelector_option"
                  >
                    <span style={{ fontFamily: key }}>{key}</span>
                  </Option>
                ))}
              </Select>
              <Select
                className="fontSizeSelector"
                // defaultValue="lucy"
                value={frameText.size}
                onChange={onTextSizeChange}
              >
                {/* <Option key="font family key" value={"font family value"}>Font family</Option>      */}

                {FONT_SIZES_LIST.map((key) => (
                  <Option
                    key={key}
                    className="fontSizeSelector_option"
                    value={key}
                  >
                    {key}
                  </Option>
                ))}
              </Select>

              <ButtonGroup style={{ margin: '2px 5px' }}>
                {/** FRAME TEXT COLOR */}
                <Popover
                  content={
                    <div style={{ width: 230 }}>
                      <p>{GetText('tooltip.transformtool.text.color')}</p>
                      <ColorPicker
                        spacing={5}
                        itemSize={15}
                        colors={Colors.displayColors}
                        selected={frameText.color}
                        onChange={onTextColorChange}
                      />
                    </div>
                  }
                  // title={GetText("tooltip.transformtool.text.color")}
                  trigger="focus"
                >
                  <Button
                    type="primary"
                    icon={<BgColorsOutlined />}
                    onClick={(e) => e.currentTarget.focus()}
                    style={{
                      // borderRadius:12,
                      color: frameText.color,
                      backgroundColor: Colors.GREY_LIGHT,
                      fontWeight: 'bolder',
                    }}
                  >
                    T
                  </Button>
                </Popover>
                {/* <Divider type="vertical" /> */}

                {/** FRAME BACKGROUND COLOR */}
                <Popover
                  content={
                    <div style={{ width: 200 }}>
                      <p>{GetText('tooltip.transformtool.textBackground')}</p>
                      <ColorPicker
                        spacing={2}
                        itemSize={15}
                        colors={Colors.displayColors}
                        selected={editedFrame.fillColor}
                        onChange={onTextBGColorChange}
                      />
                      {/* <Button type="primary" size="small" onClick={this.onClearBGColor} >{GetText("")}</Button> */}
                      {editedFrame.fillColor && (
                        <Button
                          danger
                          size="small"
                          block
                          onClick={(e) => {
                            onTextBGColorChange(null);
                          }}
                        >
                          {GetText('toolbar.text.backgroundcolor.remove')}
                        </Button>
                      )}
                    </div>
                  }
                  trigger="focus"
                >
                  <Button
                    type="primary"
                    onClick={(e) => e.currentTarget.focus()}
                    style={{
                      backgroundColor: Colors.GREY_LIGHT,
                    }}
                  >
                    <div
                      style={{
                        color: 'black',
                        width: '20px',
                        fontWeight: 'bolder',
                        height: '20px',
                        backgroundColor: editedFrame.fillColor
                          ? editedFrame.fillColor
                          : 'white',
                      }}
                    >
                      T
                    </div>
                  </Button>
                </Popover>
              </ButtonGroup>

              <ButtonGroup>
                <Tooltip
                  mouseEnterDelay={tooltipDelay}
                  title={GetText('toolbar.text.italic')}
                  placement="bottom"
                >
                  <Button
                    className={frameText.italic ? 'selected' : ''}
                    type="primary"
                    icon={<ItalicOutlined />}
                    onClick={(e) => {
                      onToggleTextItalic();
                    }}
                  />
                </Tooltip>
                <Tooltip
                  mouseEnterDelay={tooltipDelay}
                  title={GetText('toolbar.text.bold')}
                  placement="bottom"
                >
                  <Button
                    className={frameText.bold ? 'selected' : ''}
                    type="primary"
                    icon={<BoldOutlined />}
                    onClick={(e) => {
                      onToggleTextBold();
                    }}
                  />
                </Tooltip>

                {/* -------------------- align horizontal ------------------- */}

                <Tooltip
                  mouseEnterDelay={tooltipDelay}
                  title={GetText('toolbar.text.HAlign')}
                  placement="bottom"
                >
                  <Button
                    type="primary"
                    icon={<currentHAlign.icon />}
                    onClick={() => {
                      onChangeTextHAlign(
                        nextHAlign.align as HorizontalTextAlign
                      );
                    }}
                  />
                </Tooltip>

                {/* -------------------- align veritcal ------------------- */}

                <Tooltip
                  mouseEnterDelay={tooltipDelay}
                  title={GetText('toolbar.text.VAlign')}
                  placement="bottom"
                >
                  <Button
                    type="primary"
                    icon={<currentVAlign.icon />}
                    onClick={() => {
                      onChangeTextVAlign(nextVAlign.align as VerticalTextAlign);
                    }}
                  />
                </Tooltip>

                {/* -------------------- clear content ------------------- */}

                <Tooltip
                  mouseEnterDelay={tooltipDelay}
                  title={GetText('toolbar.text.clear')}
                  placement="bottom"
                >
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      onClearText();
                    }}
                  />
                </Tooltip>
              </ButtonGroup>

              <div className="spacer" />
              <Button
                type="primary"
                onClick={() => {
                  onApplyCurrentTextOptionsToAll();
                }}
              >
                {GetText('lefttab.backgrounds.applytoall')}
              </Button>
            </div>
          )}
        </div>
      }
    >
      <Tooltip
        mouseEnterDelay={tooltipDelay}
        title={GetText('tooltip.transformtool.text')}
        placement="top"
      >
        <Button
          id="toolbarTextEditButton"
          type="primary"
          block
          icon={<EditOutlined />}
          onClick={() => {
            if (!frameText) {
              onAddDefaultTextToFrame();
            }
          }}
        />
      </Tooltip>
    </Popover>
  );
};
