import { Modal } from 'antd';
import { isEqual } from 'lodash';
import { CANVAS_FORMAT } from '../../data/catalogue/canvasCatalogue.types';
import { Colors } from '../../data/Colors';
import {
  IsAlbumEditor,
  IsCalendarEditor,
  IsCardEditor,
} from '../../data/config';
import { PAPER_QUALITY, PROJECT_CLASS } from '../../data/Constants';
import { GetText } from '../../data/LanguageHelper';
import { IsDebug } from '../../debug/DebugFlags';
import { warnCustomer } from '../../feature/alert/warnCustomer';
import { backgroundSelectors } from '../../feature/backgrounds/background.store';
import {
  BackgroundHelper,
  getCoverBackgroundRect,
  injectBackgroundIntoFrame,
} from '../../feature/backgrounds/backgroundHelper';
import { clipartSelectors } from '../../feature/cliparts/cliparts';
import { FRAME_TYPE } from '../../feature/frame/frame.types';
import {
  AddDefaultTextToFrame,
  CleanAndVerifyFrameText,
  IsFrameText,
} from '../../feature/frame/frameHelper';
import { isLayflat } from '../../feature/layflat/layflatHelpers';
import { LAYOUT_TYPE } from '../../feature/layouts/layout.type';
import { photoListSelector } from '../../feature/photoList/photo.selector';
import { CreateProjectPages } from '../../feature/project/CreateProjectPages';
import { Project } from '../../types/project';
import { CreateCalendarColorOptions } from '../calendar/CreateCalendarColorOptions';
import { CardHelper } from '../card/CardHelper';
import {
  CreateClassicCoverPage,
  GetCoverSizeInPixel,
  GetProjectCover,
} from '../cover/coverHelper';
import { getValidCoverType } from '../cover/getValidCoverType';
import { notifyError } from '../error/notifyError';
import { API } from '../API';
import { pixelToCm } from '../MeasureUtils';
import { GetDoc } from '../ProductHelper';
import {
  IsClassicCoverProject,
  upgradeProjectCover,
  UpgradeProjectSize,
} from '../projectHelper';
import { CheckAndUpdateProjectSpineWidth } from '../spineHelper';
import { GetUID } from '../UID';

/**
 * Clean and verify a loaded project.
 */
export const CleanAndVerifyProject = (
  project: Project,
  storeRef: any
): Error | undefined => {
  const layoutStore = storeRef.layouts;
  const backgroundsByID = backgroundSelectors.getAllBackgroundsByID(storeRef);
  const allBackgroundList = backgroundSelectors.getAllBackgroundList(storeRef);
  const photosByID = photoListSelector.getAllBackendPhotosByID(storeRef);
  const clipartsByID = clipartSelectors.getAllClipartsByID(storeRef);
  const tempPhotoMapping =
    photoListSelector.getTempPhotoToNewPhotoMapping(storeRef);

  /// ///////////////////////////////////////
  if (!project.type) {
    console.warn(
      `TODO: Project has no type.. please fix: docID:${project.docID}`
    );
  }
  if (!project.docID) {
    return new Error(`Project with no DocID cannot be loaded`);
  }

  const doc = GetDoc(project.docID);
  if (!doc) {
    return new Error(
      `Project with DOCID:'${project.docID}' is not recognized by editor.`
    );
  }

  // photo corrupted message
  let photoCorrupted = null;

  // if( !project.docCode ){
  //  project.docCode = doc.code; // doc code (for backend)
  // }
  /// ///////////////////////////////////////

  /// /////////////////////////////////////////////////////
  // Temporary Fixes
  // issue before 0.0.38 with wrong size for albums
  // if( project.docCode !== doc.code )
  // {
  //  Modal.info({
  //   title: "Fixing album",
  //   content: "This project had wrong references: '"+project.docCode+"' but is in fact '"+doc.code+"'"
  //  })
  //  // alert("DEBUG: Old bug: Fixing project referenced as '"+project.docCode+"' but is in fact '"+doc.code+"'");
  //  UpgradeProjectSize( project, project.docID, layoutStore );
  // }

  // verify project size!
  if (
    project.canvasFormat !== CANVAS_FORMAT.FREE && // do not make checks for free format!
    (project.width !== doc.width * doc.multiplier ||
      project.height !== doc.height * doc.multiplier)
  ) {
    Modal.info({
      title: 'Fixing Project size',
      content: `This project size has been updated from ${pixelToCm(
        project.width
      ).toFixed(1)}x${pixelToCm(project.height).toFixed(1)}cm to ${pixelToCm(
        doc.width * doc.multiplier
      ).toFixed(1)}x${pixelToCm(doc.height * doc.multiplier).toFixed(1)}cm`,
    });
    // alert(`DEBUG: Fix previous album: this album size must be updated from ${project.width}x${project.height} to ${(doc.width*doc.multiplier)}x${(doc.height*doc.multiplier)}`);
    UpgradeProjectSize(
      project,
      doc.width * doc.multiplier,
      doc.height * doc.multiplier,
      layoutStore,
      backgroundsByID
    );
  }

  // common (possible undefined values)
  if (project.flyleaf === undefined) project.flyleaf = 'white';
  if (project.coated === undefined) project.coated = true;
  if (project.pagePaperQuality === undefined)
    project.pagePaperQuality = PAPER_QUALITY.QUALITY_170;

  // --------------------- ALBUM PATCH ------------------------

  if (project.classname === PROJECT_CLASS.ALBUM) {
    // be sure to have the correct cut border (was wrong before)
    project.cutBorder = doc.cutBorder; // Even if in the end it's taken from doc, so shoul even not be in the project..

    if (project.cover_matte === undefined) project.cover_matte = false;

    const cover = GetProjectCover(project);
    if (!cover) {
      notifyError(
        'No cover found for project with docID:${project.docID}, type:${project.type}'
      );
    }

    // november 2024: added the new "coverType" property to albums.
    project.coverType = getValidCoverType(
      project.type,
      project.docID,
      project.coverType
    );
    // if (project.coverType === undefined) {
    //   project.coverType =
    //     cover && cover.coverClassicOptions
    //       ? COVER_TYPE.COVER_CLASSIC
    //       : COVER_TYPE.COVER_CUSTOM;
    // }

    // verify project cover size, which seems to not be always correct neither... probably older projects issues..
    if (!IsClassicCoverProject(project)) {
      const coverSize = GetCoverSizeInPixel(project);

      const currentCoverSize = { width: cover.width, height: cover.height };

      // fixing cover size
      if (
        coverSize.width !== currentCoverSize.width ||
        coverSize.height !== currentCoverSize.height
      ) {
        Modal.info({
          title: 'Fixing album cover',
          content: `This project had wrong cover size: '${JSON.stringify(
            currentCoverSize
          )}' but should be '${JSON.stringify(coverSize)}'`,
        });
        upgradeProjectCover(project, layoutStore, backgroundsByID);
        // TODO: maybe later just use CleanAndVerifyCustomCover
        CheckAndUpdateProjectSpineWidth(project);
      }

      const backgroundFrame = cover.frames ? cover.frames[0] : null;
      if (backgroundFrame) {
        const currentBackgroundRect = {
          width: backgroundFrame.width,
          height: backgroundFrame.height,
          x: backgroundFrame.x,
          y: backgroundFrame.y,
        };
        const backgroundRect = getCoverBackgroundRect(project);
        // fixing cover background size!
        if (!isEqual(currentBackgroundRect, backgroundRect)) {
          Modal.info({
            title: 'Fixing album cover background bleed.',
            content: 'This project had wrong bleed for cover',
          });
          upgradeProjectCover(project, layoutStore, backgroundsByID);
        }
      }
    }

    // if album classic, verify we have a classic cover!
    if (IsClassicCoverProject(project)) {
      if (!project.pageList[0].coverClassicOptions) {
        project.pageList[0] = CreateClassicCoverPage(project);
      }
    }

    // verify that page list is not wrong (was an issue with previously created project)
    if (!isLayflat(project.docID)) {
      if (project.pageList.length % 2 === 0) {
        console.log(project);
        alert(
          'DEBUG: Due to a previous bug now fixed, this project has a wrong page amount, we fix this by adding a new page at the end'
        );
        project.pageList.concat(
          CreateProjectPages(
            project,
            1,
            project.pageList.length,
            layoutStore
          )[0]
        );
      }
    }
  }

  // --------------------- CALENDAR PATCH ------------------------

  if (IsCalendarEditor()) {
    if (!project.calendarColorOptions)
      project.calendarColorOptions = CreateCalendarColorOptions();
  }

  // --------------------- CARD PATCH ------------------------

  if (IsCardEditor()) {
    // fix for wrong cardpack!
    if (!Object.keys(doc.codes).includes(project.cardPack)) {
      const oldModelNum = CardHelper.GetNumModelFromPack(project.cardPack);
      const oldRepNum = CardHelper.GetNumRepetitionFromPack(project.cardPack);
      const packs = Object.keys(doc.codes);
      let closestPack = packs[0];
      for (const pack of packs) {
        const m = CardHelper.GetNumModelFromPack(pack);
        const r = CardHelper.GetNumRepetitionFromPack(pack);
        const cm = CardHelper.GetNumModelFromPack(closestPack);
        const cr = CardHelper.GetNumRepetitionFromPack(closestPack);
        if (Math.abs(oldModelNum - m) < Math.abs(oldModelNum - cm))
          closestPack = pack;
        else if (cm === m && Math.abs(oldRepNum - r) < Math.abs(oldRepNum - cr))
          closestPack = pack;
      }

      console.warn(
        `Fixing: wrong cardpack: from:'${project.cardPack}' to:'${closestPack}'`
      );
      project.cardPack = closestPack;
    }
    // fix for wrong amount of pages
    if (
      project.pageList.length !==
      CardHelper.GetTotalNumberOfPages(project.docID, project.cardPack)
    ) {
      const numPages = CardHelper.GetTotalNumberOfPages(
        project.docID,
        project.cardPack
      );
      console.warn(
        `Fixing: wrong card num of pages: from:'${project.pageList.length}' to:'${numPages}'`
      );
      const diff = numPages - project.pageList.length;
      // add
      if (diff > 0)
        project.pageList = project.pageList.concat(
          CreateProjectPages(
            project,
            diff,
            project.pageList.length,
            layoutStore
          )[0]
        );
      // remove
      else project.pageList.splice(project.pageList.length + diff, -diff);
    }
  }

  // --------------------- PAGES and frames VERIFICATIONS ------------------------

  // recover background lists
  const projectValidBackgrounds =
    BackgroundHelper.GetValidInternalBackgroundList(project, allBackgroundList);
  const coverValidBackgrounds = BackgroundHelper.GetValidCoverBackgroundList(
    project,
    allBackgroundList
  );

  // verify that all page index are correc
  project.pageList.forEach((page, index) => {
    // check page index
    if (page.index !== index) page.index = index;

    const displayPageIndex = IsAlbumEditor() ? page.index : page.index + 1;

    // for a better rendering system, pages should have uniq ID
    if (!page.id) page.id = GetUID();

    // verify frames
    const keptFrames = [];
    page.frames.forEach((frame, index, frameArr) => {
      let keepFrame = true;
      // check frame has an ID, otherwhise give one
      if (!frame.id) frame.id = GetUID();

      // check rotation
      frame.rotation = Number(frame.rotation);
      if (!frame.rotation || typeof frame.rotation !== 'number')
        frame.rotation = 0;

      // check frame border
      if (!frame.border) {
        frame.border = 0;
      }
      if (!frame.borderColor) {
        frame.borderColor = Colors.WHITE;
      }
      if (!frame.shadow) {
        frame.shadow = null;
      }

      // be sure frame calendars are in calendar layouts
      if (frame.type === FRAME_TYPE.CALENDAR && !page.isCalendar)
        page.isCalendar = true;

      // case an upload was not completed..
      if (frame.photo && !photosByID[frame.photo]) {
        if (!photoCorrupted) photoCorrupted = {};

        photoCorrupted[displayPageIndex] =
          tempPhotoMapping && tempPhotoMapping[`${frame.photo}_name`]
            ? `(${tempPhotoMapping[`${frame.photo}_name`]})`
            : IsDebug
              ? `(${frame.photo})`
              : '';

        frame.photo = null;
      }

      // fix postcard layouts
      if (
        frame.type === FRAME_TYPE.POSTCARD_BG &&
        page.layoutType !== LAYOUT_TYPE.POSTCARD_BG
      )
        page.layoutType = LAYOUT_TYPE.POSTCARD_BG;

      // bug reported for calendar frames having white space at the end
      // december 2020
      // EDIT: If we do this, this could break other projects... User are using line breaks to align vertically
      // if(frame.type === FRAME_TYPE.CALENDAR && frame.text && frame.text.value){
      //  frame.text.value = frame.text.value.trimEnd();
      // }

      // check that frames has default text
      if (IsFrameText(frame)) CleanAndVerifyFrameText(frame);

      // CLIPART verification
      if (frame.clipart) {
        const clipart = clipartsByID[frame.clipart];
        if (!clipart) {
          console.warn(`No Clipart found with ID: ${frame.clipart}`);
          warnCustomer(
            'Clipart error',
            `There is a clipart error (${frame.clipart}) on page ${page.index}, we removed it, please verify the page`
          );
          keepFrame = false;
        }
      }

      //   if( !frame.text )
      //   AddDefaultTextToFrame( frame );
      //  else
      //   CleanAndVerifyFrameText(frame);
      // }

      // // check that frames has default text
      // if( frame.type === FRAME_TYPE.SPINE_NUM && !frame.text ){
      //  AddDefaultTextToFrame( frame );
      //  frame.text.value = "09"; // TODO: upate this
      //  frame.text.size = 15;
      // }

      // fix for client that did add "none" overlayer to pages
      if (frame.overlayer === 'none') {
        frame.overlayer = null;
        frame.type = FRAME_TYPE.TEXT;
        AddDefaultTextToFrame(frame);
        // frame.text.value="coucou";
        // frame.fillColor = "#ff0000";
        console.warn(
          "Removed old 'none' overlayer frame and replaced by text frame empty"
        );
      }

      // // check that frames has default text
      // if( frame.type === FRAME_TYPE.SPINE && !frame.text ){
      //  AddDefaultTextToFrame( frame );
      //  frame.text.value = "this the spine"; // TODO: upate this
      //  frame.text.size = 15;
      // }

      // verify frame background!
      if (frame.background && !backgroundsByID[frame.background]) {
        console.warn(
          `Frame background is not found, try to find a match:${frame.background}`
        );
        // try to find a background that matches!
        const matchesFound = projectValidBackgrounds.filter((item) =>
          item.id.includes(frame.background)
        );

        // match found!
        if (matchesFound.length > 0) {
          console.log(
            `--> match found from ${frame.background} to ${matchesFound[0].id}`
          );
          injectBackgroundIntoFrame(frame, matchesFound[0]);
        } else {
          const message = `--> NO match found for background on page ${displayPageIndex} (ID:${frame.background})`;
          alert(message);
          API.sendDevMail(new Error(message));
          // frame.background = null; --> [EDIT] if we do this.. the backgrounds are removed... it's not ok..
        }
      }

      // keep frame
      if (keepFrame) {
        keptFrames.push(frame);
      }
    });
    page.frames = keptFrames;
  });
  // ---- END : Page and frame verifications ----
  /// ////////////////////////////////////////////////////////////////

  if (photoCorrupted) {
    let photoCorruptedError = GetText(
      'popup.projectcheck.image_corrupted.content'
    );
    Object.keys(photoCorrupted).forEach((pageIndex) => {
      photoCorruptedError += `page ${pageIndex} ${photoCorrupted[pageIndex]}, `;
    });

    // warn
    warnCustomer(
      GetText('popup.projectcheck.corrupted.title'),
      photoCorruptedError
    );
  }

  return undefined;
};
