import { ReactNode } from 'react';

/**
 * Escape html characters from a string
 * @param {string} unsafe
 */
export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export const GetUnsafeHTMLDiv = (content: string): ReactNode => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
);
