import { Component, useEffect, useRef } from 'react';
import { CirclePicker } from 'react-color';
import { connect } from 'react-redux';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Popover, Radio, Select } from 'antd';
import { cloneDeep } from 'lodash';
import ColorPicker from '../../../_components/ColorPicker';
import { ForeignObjectUnscaled } from '../../../_components/SvgForeignObjectUnscaled';
import { Colors } from '../../../data/Colors';
import {
  COVER_CLASSIC_COLOR_HEX,
  COVER_CLASSIC_CORNERS_TYPE,
  COVER_CLASSIC_FABRIC_OPTIONS,
  COVER_CLASSIC_FABRIC_TYPE,
} from '../../../data/Constants';
import { FONT_LIST_CLASSIC_COVER } from '../../../data/fonts';
import { GetText } from '../../../data/LanguageHelper';
import { editionActions } from '../../../feature/edition/edition';
import { editionSelectors } from '../../../feature/edition/edition.selector';
import { pricingSelectors } from '../../../feature/pricing/pricing';
import { EmbedAssets } from '../../../images/EmbedAssets';
import type { ClassicCoverOptions, IPage } from '../../../types/types';
import {
  ComposeClassicCoverURL,
  CoverLabelNameToCoverDisplayName,
} from '../../../utils/cover/coverHelper';

type TextEditionContetProps = {
  id: string;
  value: string;
  textPrice: string;
  maxLength: number;
  onChange: (e: Event) => void;
};

const TextEditionContent = ({
  id,
  value,
  textPrice,
  maxLength,
  onChange,
}: TextEditionContetProps) => {
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const onTextFocus = () => {
    inputRef.current.input.setSelectionRange(
      0,
      inputRef.current.input.value.length
    );
  };

  return (
    <div>
      <Input
        id={id}
        ref={inputRef}
        placeholder={value}
        onFocus={onTextFocus}
        allowClear
        maxLength={maxLength}
        onChange={onChange}
        style={{ width: '350px' }}
      />
      <div
        style={{
          fontSize: '80%',
          paddingTop: 10,
        }}
      >
        {textPrice}
      </div>
    </div>
  );
};

interface IClassicCoverAreaProps {
  isPreviewMode: boolean;
  page: IPage;
  classicCoverStock: any;
  options: ClassicCoverOptions;
  dispatch: () => void;
}

class ClassicCoverArea extends Component<IClassicCoverAreaProps> {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);
    this.state = {
      allCoverOptionsColors: {},
      linenColors: [],
      leatherColors: [],
      coverNameByColor: { leather: {}, linen: {} },
    };
  }

  // --------------------- react ------------------------

  componentDidMount() {
    this.remapColors();
  }

  componentDidUpdate(prevProps) {
    // do recalculate state based on props, should be done in mapstatetoprops
    if (prevProps !== this.props) {
      this.remapColors();
    }
  }

  remapColors = () => {
    const coverStock = this.props.classicCoverStock;
    const stateCopy = cloneDeep(this.state);
    stateCopy.leatherColors = [];
    stateCopy.linenColors = [];
    Object.keys(COVER_CLASSIC_FABRIC_OPTIONS).forEach(
      (fabricKey, index, arr) => {
        COVER_CLASSIC_FABRIC_OPTIONS[fabricKey].forEach((item) => {
          stateCopy.allCoverOptionsColors[item.name] = item.color;
          stateCopy.coverNameByColor[fabricKey][item.color] = item.name;

          // eslint-disable-next-line no-prototype-builtins
          if (
            Object.prototype.hasOwnProperty.call(coverStock, item.name) &&
            coverStock[item.name] === true
          ) {
            if (fabricKey === COVER_CLASSIC_FABRIC_TYPE.LEATHER) {
              stateCopy.leatherColors.push({
                id: item.name,
                hex: item.color,
                name: GetText(`cover.${fabricKey}.${item.name}.label`),
              });
            } else {
              stateCopy.linenColors.push({
                id: item.name,
                hex: item.color,
                name: GetText(`cover.${fabricKey}.${item.name}.label`),
              });
            }
          }
        });
      }
    );
    this.setState(stateCopy);
  };

  // --------------------- methods ------------------------

  handleTextChange = ({ target: { value, id } }) => {
    console.log(`text changed (${id}):${value}`);

    const options = cloneDeep(this.props.page.coverClassicOptions);
    options[id] = value;
    this.saveChange(options);
  };

  handleTextColorChange = (hexColor) => {
    const options = cloneDeep(this.props.page.coverClassicOptions);
    options.color = Object.keys(COVER_CLASSIC_COLOR_HEX).find(
      (key) => COVER_CLASSIC_COLOR_HEX[key] === hexColor
    ); // get id by hex color: ;
    this.saveChange(options);
  };

  handleTextFontChangeChange = (fontName) => {
    const options = cloneDeep(this.props.page.coverClassicOptions);
    options.fontName = fontName;
    this.saveChange(options);
  };

  handleFabricChange = (fabric, newColor) => {
    const coverLabelName = this.state.coverNameByColor[fabric][newColor];
    console.log(`color changed to (${newColor}) -->${coverLabelName}`);

    const options = cloneDeep(this.props.page.coverClassicOptions);

    // update correct element
    options.coverLabelName = coverLabelName;
    options.coverFabric = fabric;
    options.cover = CoverLabelNameToCoverDisplayName(coverLabelName);

    this.saveChange(options);
  };

  handleCornerChange = (cornerID) => {
    const options = cloneDeep(this.props.page.coverClassicOptions);
    options.corners = cornerID;
    this.saveChange(options);
  };

  saveChange = (newOptions) => {
    const currentPage: IPage = cloneDeep(this.props.page);
    currentPage.coverClassicOptions = newOptions;
    // save the page on store
    this.props.dispatch(editionActions.UpdatePage(currentPage));
    // editing over, add undoable action
    this.props.dispatch(editionActions.AddUndoableAction());
  };

  // --------------------- RENDER ------------------------
  render() {
    const COVER_WIDTH = 528;
    const COVER_HEIGHT = 366;

    const { options, page, projectName } = this.props;
    const {
      allCoverOptionsColors,
      linenColors,
      leatherColors,
      coverNameByColor,
    } = this.state;
    const currentPage: IPage = page;
    const currentOptions: ClassicCoverOptions = options;
    const currentCoverName = currentOptions.coverLabelName;

    // --- COMPOSE URL
    const coverURL = ComposeClassicCoverURL(currentOptions, false);

    // display text
    const { isPreviewMode } = this.props;
    const line1Text = !currentOptions.line1
      ? isPreviewMode
        ? ''
        : GetText('edition.cover.classic.editor.panel.text.watermark.line1')
      : currentOptions.line1;
    const line2Text = !currentOptions.line2
      ? isPreviewMode
        ? ''
        : GetText('edition.cover.classic.editor.panel.text.watermark.line2')
      : currentOptions.line2;
    const spineText = !currentOptions.spine
      ? isPreviewMode
        ? ''
        : GetText('edition.cover.classic.editor.panel.text.watermark.spine')
      : currentOptions.spine;
    const editionText = !currentOptions.edition
      ? isPreviewMode
        ? ''
        : '...'
      : currentOptions.edition;

    const notFilledOpacity = 0.7;
    const notFilledColor = Colors.WHITE;

    const textColor = COVER_CLASSIC_COLOR_HEX[currentOptions.color];

    const cornerPrice = ` (${parseFloat(
      this.props.priceOptions.cover_corner_price
    ).toFixed(2)}€)`;
    const textPrice = `* ${GetText(
      'edition.cover.classic.editor.panel.text.title'
    )}: ${parseFloat(this.props.priceOptions.cover_text_price).toFixed(2)}€`;

    return (
      <svg
        x={0}
        y={0}
        width="100%"
        height="100%"
        viewBox={`0 0 ${COVER_WIDTH} ${COVER_HEIGHT}`}
      >
        {/* // --------------------- background ------------------------ */}

        <image // id={frame.id}
          key={currentPage.index}
          width="100%"
          height="100%"
          href={coverURL}
        />

        {/* // --------------------- Line1 ------------------------ */}
        <Popover
          content={
            <TextEditionContent
              id="line1"
              value={line1Text}
              textPrice={textPrice}
              maxLength={29}
              onChange={(e) => this.handleTextChange(e)}
            />
          }
          trigger="click"
          placement="top"
        >
          <g transform="translate(184,150)">
            <svg width="239px" height="19px">
              {!currentOptions.line1 && !isPreviewMode && (
                <rect
                  fill="#ffffff22"
                  width="100%"
                  height="100%"
                  rx="5"
                  ry="5"
                />
              )}

              <text
                x="50%"
                y="50%"
                className="unselectable"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                  fill: currentOptions.line1 ? textColor : notFilledColor,
                  opacity: currentOptions.line1 ? 1 : notFilledOpacity,
                  // fontSize:(currentOptions.line1)?13:11,
                  fontSize: 13,
                  fontFamily:
                    currentOptions.line1 && currentOptions.fontName
                      ? currentOptions.fontName
                      : 'Arial',
                }}
              >
                {line1Text}
              </text>
            </svg>
          </g>
        </Popover>

        {/* // --------------------- Line2 ------------------------ */}
        <Popover
          content={
            <TextEditionContent
              id="line2"
              value={line2Text}
              textPrice={textPrice}
              maxLength={50}
              onChange={(e) => this.handleTextChange(e)}
            />
          }
          trigger="click"
          placement="bottom"
        >
          <g transform="translate(173,178)">
            <svg width="261" height="14">
              {!currentOptions.line2 && !isPreviewMode && (
                <rect
                  fill="#ffffff22"
                  width="100%"
                  height="100%"
                  rx="5"
                  ry="5"
                />
              )}

              <text
                x="50%"
                y="50%"
                className="unselectable"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                  fill: currentOptions.line2 ? textColor : notFilledColor,
                  opacity: currentOptions.line2 ? 1 : notFilledOpacity,
                  fontSize: 9,
                  fontFamily:
                    currentOptions.line2 && currentOptions.fontName
                      ? currentOptions.fontName
                      : 'Arial',
                }}
              >
                {line2Text}
              </text>
            </svg>
          </g>
        </Popover>

        {/* // --------------------- Spine ------------------------ */}

        <Popover
          content={
            <TextEditionContent
              id="spine"
              maxLength={30}
              textPrice={textPrice}
              value={spineText}
              onChange={(e) => this.handleTextChange(e)}
            />
          }
          trigger="click"
          placement="rightBottom"
        >
          <g transform="translate(31,290)rotate(270)">
            {/* // --------------------- Spine ------------------------ */}

            <svg width="228" height="14">
              {!currentOptions.spine && !isPreviewMode && (
                <rect
                  fill="#ffffff22"
                  width="100%"
                  height="100%"
                  rx="5"
                  ry="5"
                />
              )}

              <text
                x={currentOptions.spine ? '0%' : '50%'}
                y="50%"
                dominantBaseline="middle"
                textAnchor={currentOptions.spine ? 'left' : 'middle'}
                className="unselectable"
                style={{
                  fill: currentOptions.spine ? textColor : notFilledColor,
                  opacity: currentOptions.spine ? 1 : notFilledOpacity,
                  fontSize: currentOptions.spine ? 9 : 8,
                  fontFamily:
                    currentOptions.spine && currentOptions.fontName
                      ? currentOptions.fontName
                      : 'Arial',
                }}
              >
                {spineText}
              </text>
            </svg>
          </g>
        </Popover>

        {/* // --------------------- Edition ------------------------ */}
        <Popover
          content={
            <TextEditionContent
              id="edition"
              value={editionText}
              maxLength={2}
              textPrice={textPrice}
              onChange={(e) => this.handleTextChange(e)}
            />
          }
          trigger="click"
          placement="rightBottom"
        >
          <g transform="translate(22,302)">
            <svg width="31" height="19">
              {!currentOptions.edition && !isPreviewMode && (
                <rect
                  fill="#ffffff22"
                  width="100%"
                  height="100%"
                  rx="5"
                  ry="5"
                />
              )}

              <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                  fill: currentOptions.edition ? textColor : notFilledColor,
                  opacity: currentOptions.edition ? 1 : notFilledOpacity,
                  fontSize: currentOptions.edition ? 13 : 12,
                  fontFamily:
                    currentOptions.edition && currentOptions.fontName
                      ? currentOptions.fontName
                      : 'Arial',
                }}
              >
                {editionText}
              </text>
            </svg>
          </g>
        </Popover>

        {/* // --------------------- corners edition ------------------@------ */}

        {
          /* // --------------------- color edition ------------------------ */
          !isPreviewMode && (
            // <Popover
            //     content={<TextEditionContent id="edition" value={editionText} maxLength={3} onChange={(e)=>this.handleTextChange(e)}/>}
            //     trigger="click"
            //     placement="rightBottom"
            // >
            <ForeignObjectUnscaled
              editionScale={1} // classic cover area should not be unscaled..
              x={280}
              y={230}
              width={50}
              height={50}
            >
              <Popover
                // trigger="focus"
                placement="left"
                // title={GetText("edition.cover.classic.editor.panel.options.title1")}
                content={
                  <div className="classicCoverAreaEditionPanel">
                    {
                      // --------------------- PANEL EDITION ------------------------
                      <div>
                        {/* <h3>{GetText("edition.cover.classic.editor.panel.options.title1")}</h3> */}
                        {/* <Divider style={{width:"100%"}}>{GetText("edition.cover.leather")}</Divider> */}
                        <Divider style={{ width: '100%' }}>
                          {GetText(
                            'edition.cover.classic.editor.panel.options.title1'
                          )}
                        </Divider>
                        <h4>{GetText('edition.cover.leather')}</h4>

                        <ColorPicker
                          key="colorpicker_leather"
                          colors={leatherColors}
                          itemSize={25}
                          spacing={10}
                          selected={currentCoverName}
                          borderRadius={15}
                          onChange={(color) =>
                            this.handleFabricChange(
                              COVER_CLASSIC_FABRIC_TYPE.LEATHER,
                              color.hex
                            )
                          }
                        />

                        <h4>{GetText('edition.cover.fabric')}</h4>
                        <ColorPicker
                          key="colorpicker_linen"
                          colors={linenColors}
                          itemSize={25}
                          spacing={10}
                          selected={currentCoverName}
                          borderRadius={15}
                          onChange={(color) =>
                            this.handleFabricChange(
                              COVER_CLASSIC_FABRIC_TYPE.LINEN,
                              color.hex
                            )
                          }
                        />
                      </div>
                    }

                    {
                      // --------------------- Corner ------------------------
                      <div>
                        <Divider style={{ width: '100%' }}>
                          {GetText(
                            'edition.cover.classic.editor.panel.options.title2'
                          ) + cornerPrice}
                        </Divider>
                        {/* <h4>{GetText("edition.cover.leather")}</h5> */}
                        <Radio.Group
                          key="cornerOptions"
                          // options={ Object.keys(COVER_CLASSIC_CORNERS_TYPE).map(key=>{
                          //     return { label: key, value: COVER_CLASSIC_CORNERS_TYPE[key] };
                          // })}
                          className="cornerRadioGroup"
                          onChange={(event) =>
                            this.handleCornerChange(event.target.value)
                          }
                          value={this.props.page.coverClassicOptions.corners}
                        >
                          {Object.keys(COVER_CLASSIC_CORNERS_TYPE).map(
                            (key) => {
                              const val = COVER_CLASSIC_CORNERS_TYPE[key];
                              return (
                                <Radio
                                  key={val}
                                  value={val}
                                  className="cornerRadioButton"
                                >
                                  <img
                                    src={EmbedAssets.ClassicCoverCorner(val)}
                                  />
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      </div>
                    }

                    {
                      // --------------------- TEXT options ------------------------
                      <div>
                        <Divider style={{ width: '100%' }}>
                          {GetText('tooltip.transformtool.text.color')}
                        </Divider>
                        {/* <h4>{GetText("edition.cover.leather")}</h5> */}
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Select
                            // className="fontFamilySelector"
                            style={{
                              fontSize: 12,
                              width: '60%',
                            }}
                            // defaultValue="lucy"
                            value={this.props.page.coverClassicOptions.fontName}
                            onChange={(font) =>
                              this.handleTextFontChangeChange(font)
                            }
                          >
                            {/* <Option key="font family key" value={"font family value"}>Font family</Option>      */}

                            {FONT_LIST_CLASSIC_COVER.map((key) => (
                              <Select.Option
                                key={key}
                                value={key}
                                className="fontFamilySelector_option"
                              >
                                <span style={{ fontFamily: key }}>{key}</span>
                              </Select.Option>
                            ))}
                          </Select>
                          <CirclePicker
                            className="classicPanelCirclePicker"
                            width="30%"
                            styles={{ display: 'inline-flex' }}
                            key="colorpicker_text"
                            circleSpacing={10}
                            circleSize={25}
                            colors={Object.values(COVER_CLASSIC_COLOR_HEX)}
                            // color={currentBackgroundColor}
                            onChange={(color) =>
                              this.handleTextColorChange(color.hex)
                            }
                          />
                        </div>
                      </div>
                    }
                  </div>
                }
              >
                {/* <Tooltip title={GetText("edition.cover.classic.editor.panel.options.title1")}> */}
                <Button
                  type="default"
                  icon={<SettingOutlined />}
                  // type="primary"
                  className="button"
                  shape="circle-outline"
                  onClick={(e) => e.target.focus()}
                  style={{
                    color: allCoverOptionsColors[currentOptions.coverLabelName],
                    backgroundColor: '#ffffff88',
                  }}
                />
                {/* </Tooltip> */}
              </Popover>
            </ForeignObjectUnscaled>
          )
          // </Popover>
        }
      </svg>
    );
  }
}

// --------------------- redux ------------------------

// Redux map
function mapStateToProps(state) {
  return {
    project: editionSelectors.GetProjectSelector(state),
    classicCoverStock: pricingSelectors.GetClassicCoverStock(state),
    priceOptions: pricingSelectors.GetProjectOptionsPrice(state),
  };
}

// --------------------- export ------------------------

const reduxConnected = connect(mapStateToProps)(ClassicCoverArea);
export { reduxConnected as ClassicCoverArea };
