import { createRoot } from 'react-dom/client';
import { isObject } from 'lodash';
import { GetText } from '../../data/LanguageHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import { AssetSelector } from '../../hooks/useAssetsSelector';
import { FrameArea } from '../../pages/homePage/EditionArea/FrameArea';
import { StoreType } from '../../store/store.type';
import { Project } from '../../types/project';
import type {
  Frame,
  ICalendarColorOptions,
  IPage,
  IUploadItem,
} from '../../types/types';
import { API } from '../../utils/API';
import { CalendarHelper } from '../../utils/calendar/CalendarHelper';
import { notifyError, notifyMessageToDev } from '../../utils/error/notifyError';
import { domElementToPng, downloadDataURL } from '../../utils/HtmlUtils';
import { getPerformanceLevel } from '../../utils/perf/getPerformanceLevel';
import { backgroundSelectors } from '../backgrounds/background.store';
import { clipartSelectors } from '../cliparts/cliparts';
import { isFrameEmpty } from '../frame/_helpers/isFrameEmpty';
import { FRAME_TYPE } from '../frame/frame.types';
import { NeedFrameUpload } from '../frame/frameHelper';
import { FrameShadow } from '../frame/shadow/FrameShadow';
import { FRAME_SHADOW_SIZE } from '../frame/shadow/shadowHelper';
import { overlayerSelectors } from '../overlayers/overlayers';
import { photoListSelector } from '../photoList/photo.selector';
import { UIActions } from '../ui/ui';
import { manifestHelper } from './manifestHelper';
import { orderSelector } from './order';
import { checkIfPageMustBePrinted, getOrderFileLocation } from './orderHelper';

// public exports
export const FrameExporter = {
  InitializeStoreRef,
  ExportProjectFrames,
  // generateFrameForUpload, // TODO: for testing later, try to allow generation of frames from outside
};

/// /////////////////////////////////////////////////////////////
/// --------------------- Helpers ------------------------
// here, once the order starts, we keep a reference to the readonly Store, otherwise too complex to inject in each function..
// let StoreRef;

const PRINT_SCALE = 3; // amount of scaling done on the frame when printing

// TODO: this should not be done like this...
let StoreRef;
function InitializeStoreRef(store) {
  StoreRef = store;
}

let dispatchRef;

// helpers to get the store and project
// TODO: all this is kind of a hack, we need to integrate this inside a correct hook system
const getStore: () => StoreType = () => StoreRef.getState() as StoreType;
const getProject = () => getStore().edition.project;
const getCalendarOptions = () => getProject().calendarColorOptions;
const getAssetSelector = () => {
  return {
    getPhotosById: (id: string) =>
      photoListSelector.getAllPhotosByID(getStore())[id],
    getBackgroundsById: (id: string) =>
      backgroundSelectors.getAllBackgroundsByID(getStore())[id],
    getClipartsById: (id: string) =>
      clipartSelectors.getAllClipartsByID(getStore())[id],
    getOverlayersById: (id: string) =>
      overlayerSelectors.getAllOverlayersByID(getStore())[id],
  } as AssetSelector;
};
const getJobID = () => orderSelector.getJobIDSelector(getStore());

/// /////////////////////////////////////////////////////////////

let performanceLevel;
let startTime;
let totalFrames;
let estimatedTimeLeft: number;
let currentExportedFrame: number; // num of frames already exported
let currentUploadedFrame: number; // num of frames already exported

async function ExportProjectFrames(dispatch): Promise<void> {
  dispatchRef = dispatch;
  console.log('FrameExporter.ExportProjectFrames');
  dispatchRef(
    UIActions.ShowLoading(GetText('loading.order.generate.progress'), 0)
  );

  const uploadItems: Array<IUploadItem> = [];
  const uploadNames: Array<string> = [];

  // small helper that adds the upload item only if it's filename doesn't already exist!
  const checkAddUploadItem = (uploadItem: IUploadItem) => {
    if (!uploadNames.includes(uploadItem.fileName)) {
      uploadItems.push(uploadItem);
      uploadNames.push(uploadItem.fileName);
    }
  };

  // Create all upload items
  const project = getProject();

  // go through all pages, and all frames
  project.pageList.forEach((page: IPage, pageIndex) => {
    if (checkIfPageMustBePrinted(pageIndex)) {
      page.frames.forEach((frame: Frame, frameIndex) => {
        // ---- Classic export of frame needing a frame upload ----
        if (NeedFrameUpload(frame)) {
          checkAddUploadItem({
            fileName: getOrderFileLocation(frame, pageIndex, frameIndex),
            frame,
            pageIndex,
            frameIndex,
          });
        }

        // ---- Border ----
        else if (frame.border > 0) {
          checkAddUploadItem({
            fileName: getOrderFileLocation(null, 0, 0, frame.borderColor),
            hexColor: frame.borderColor,
          });
        }

        // ---- shadow ----
        if (frame.shadow && isObject(frame.shadow) && frame.shadow.enabled) {
          checkAddUploadItem({
            fileName: getOrderFileLocation(frame, 0, 0, null, frame.shadow),
            frame, // we add the frame so we can handle border radius
            shadow: frame.shadow,
          });
        }

        // // ---- CALENDAR fillColor ----
        // if( frame.type === FRAME_TYPE.CALENDAR && frame.fillColor )
        // {
        //  const color = frame.fillColor;
        //  checkAddUploadItem({
        //   fileName:getOrderFileLocation(null,0,0,color),
        //   hexColor:color
        //  })
        // }

        // ---- CALENDAR Sub frames ----
        if (frame.type === FRAME_TYPE.CALENDAR) {
          // fill color
          if (frame.fillColor) {
            checkAddUploadItem({
              fileName: getOrderFileLocation(null, 0, 0, frame.fillColor),
              hexColor: frame.fillColor,
            });
          }

          // sub frame text
          if (frame.text) {
            const subFrameText = CalendarHelper.getCalendarSubFrameText(frame);
            if (!isFrameEmpty(subFrameText)) {
              checkAddUploadItem({
                fileName: getOrderFileLocation(
                  subFrameText,
                  pageIndex,
                  frameIndex
                ),
                frame: subFrameText,
                pageIndex,
                frameIndex,
              });
            }
          }
        }
      });
    }
  });

  console.log(
    `FrameExporter: ${
      uploadItems.length
    } frame(s) will be exported:${JSON.stringify(uploadItems)}`
  );
  totalFrames = uploadItems.length;
  currentExportedFrame = 0;
  currentUploadedFrame = 0;
  estimatedTimeLeft = undefined;
  startTime = new Date();

  // find current browser performance level
  const level: number = await getPerformanceLevel();
  performanceLevel = level; // TODO: remove this magic number
  console.log('Performance Level:', performanceLevel);

  // Time to generate and upload all upload items
  return new Promise<void>((resolve, reject) => {
    // Adjust this number based on performance testing, this is the "performance" magic number, the amount of image that are processed in parallel
    const BATCH_SIZE = performanceLevel;
    const chunks = [];

    // Split uploadItems into chunks
    for (let i = 0; i < uploadItems.length; i += BATCH_SIZE) {
      chunks.push(uploadItems.slice(i, i + BATCH_SIZE));
    }

    // Process chunks sequentially, but items within chunks in parallel
    let promise = Promise.resolve();
    chunks.forEach((chunk) => {
      promise = promise.then(() =>
        Promise.all(chunk.map((item) => handleNextUploadItem(item))).then(
          () => {}
        )
      );
    });

    promise
      .then(() => {
        if (project.pageList.length > 1) {
          return exportAndUploadProjectPreviewPage(project);
        }
      })
      .then(() => {
        resolve();
      })
      .catch((reason) => {
        reject(`Upload sequence error:${reason}`);
      });
  });
}

/**
 * Handle the next upload item
 * creates a frame png and upload it
 */
const handleNextUploadItem = async (item: IUploadItem) => {
  currentExportedFrame += 1;
  updateFrameExporterLoadingPercentage();

  // ---- shadow ----
  if (item.shadow) return exportAndUploadShadowFrame(item.frame);

  // ---- Color (border) ----
  if (item.hexColor) return exportAndUploadColorFrame(item.hexColor);

  // ---- Classic frame rendering export ( text, mask )----
  if (item.frame)
    return exportAndUploadFrame(item.frame, item.pageIndex, item.frameIndex);

  // handle error
  return Promise.reject(
    new Error(`Next upload item cannot be exported: ${JSON.stringify(item)}`)
  );
};

/**
 * Update the frame exporter loading percentage
 */
function updateFrameExporterLoadingPercentage() {
  const elapsedTime = new Date().getTime() - startTime.getTime();
  const total = totalFrames * 2; // frames must be encoded then uploaded
  const current = currentExportedFrame + currentUploadedFrame;
  const percent = Math.round((current / total) * 100);

  // get estimated time left in format 00min 00sec
  const newEstimatedTimeLeft =
    ((elapsedTime / percent) * (100 - percent)) / 1000;
  if (
    !estimatedTimeLeft || // if no estimated time left, set it
    estimatedTimeLeft < 5 || // if estimated time left is too small, set it as it could be stuck on small value
    newEstimatedTimeLeft < estimatedTimeLeft || // if the new estimated time left is less than the old one and the difference is less than 5 seconds, set it
    Math.abs(newEstimatedTimeLeft - estimatedTimeLeft) > 3 // if the new estimated time left is less than the old one and the difference is less than 5 seconds, set it
  ) {
    estimatedTimeLeft = newEstimatedTimeLeft;
  }

  console.log('percent update:', {
    percent,
    estimatedTimeLeft,
    newEstimatedTimeLeft,
    elapsedTime,
    total,
    current,
    totalFrames,
    currentExportedFrame,
    currentUploadedFrame,
  });

  // get a display string
  const minLefts = Math.floor(estimatedTimeLeft / 60);
  const secLefts = Math.floor(estimatedTimeLeft % 60);
  const estimatedTimeLeftString =
    minLefts > 0 ? `${minLefts}min ${secLefts}sec` : `${secLefts} sec`;
  dispatchRef(
    UIActions.ShowLoading(
      (percent < 60
        ? GetText('loading.order.generate.encoding')
        : GetText('loading.order.generate.sending')) +
        ` - ${estimatedTimeLeftString}`,
      percent
    )
  );
}
// function updateFrameUploaderLoadingPercentage() {
//   dispatchRef(
//     UIActions.ShowLoading(
//       GetText('loading.order.generate.sending'),
//       Math.round((currentExportedFrame / totalFrames) * 100)
//     )
//   );
// }

// --------------------- Private ------------------------

//------------------------------
// Export Frame
//------------------------------
const exportAndUploadFrame = async (
  frame: Frame,
  pageIndex: number,
  frameIndex: number
) => {
  // try {
  // generate frame
  const pngFile = await generateFrameForUpload(
    frame,
    pageIndex,
    frameIndex,
    getAssetSelector(),
    getCalendarOptions()
  );

  // upload it
  const uploadResponse = await uploadFrame(
    frame,
    pageIndex,
    frameIndex,
    pngFile
  );
  return uploadResponse;
  // } catch (e) {
  //   `Export upload frame error: ${reason} in frame: ${JSON.stringify(frame)}`;
  // }
};

/**
 * Promise that should deliver a png file, or null if nothing to do with this frame.
 */
async function generateFrameForUpload(
  frame: Frame,
  pageIndex: number,
  frameIndex: number,
  assetSelector: AssetSelector,
  calendarColorOptions?: ICalendarColorOptions
): Promise<string> {
  // if no frame uploaded needed, return nothing
  if (!NeedFrameUpload(frame)) return null;
  console.log('need frame upload on page', pageIndex, 'frame:', frame);

  return renderFrameToPng(
    // FRAME
    <svg width={frame.width} height={frame.height}>
      <FrameArea
        key={`print_${pageIndex}_${frameIndex}`}
        frame={frame}
        pageIndex={pageIndex}
        calendarColorOptions={calendarColorOptions}
        frameIndex={frameIndex}
        isForPrint={true}
        assetSelector={assetSelector}
        isPreviewMode={false}
        editionScale={1}
        isSelected={false}
        isEditing={false}
        onUploaderChange={() => {}}
        onFrameDoubleClick={() => {}}
        onFrameMouseDown={() => {}}
        onDragOverFrame={() => {}}
        onDragLeaveFrame={() => {}} // Add this prop
        onDropOnFrame={() => {}} // Add this prop
        onDummyUploadRequest={() => {}} // Add this prop
        onAddTextCtaClicked={() => {}} // Add this prop
      />
    </svg>,
    {
      name: `frameExport_${pageIndex}_${frameIndex}__${frame.id}`,
      textValue: frame.text?.value,
      scale: PRINT_SCALE,
      width: frame.width,
      height: frame.height,
    }
  );
}

/**
 * upload classic  frame
 */
const uploadFrame = async (
  frame: Frame,
  pageIndex,
  frameIndex,
  pngFile: string
) => {
  const fileName = getOrderFileLocation(frame, pageIndex, frameIndex);
  // update percent
  updateFrameExporterLoadingPercentage();
  // upload image for order
  const uploadResponse = await API.uploadImageForOrder(
    fileName,
    pngFile,
    getJobID()
  );
  // update percentage after
  currentUploadedFrame += 1;
  updateFrameExporterLoadingPercentage();
  return uploadResponse;
};

/**
 * export and upload color frame
 * @param hexColor
 * @returns
 */
const exportAndUploadColorFrame = async (hexColor: string) => {
  const frameSize = 50; // NOTE: IMPORTANT, there is a minimum size for a correct generation.. do not go below this
  // generate
  const pngUrl = await renderFrameToPng(
    <svg width={frameSize} height={frameSize}>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        style={{
          fill: hexColor,
        }}
      />
    </svg>,
    {
      name: `frameColor_${hexColor}`,
      scale: PRINT_SCALE,
      width: frameSize,
      height: frameSize,
    }
  );

  // upload
  const fileName = getOrderFileLocation(null, 0, 0, hexColor);
  return (
    API.uploadImageForOrder(fileName, pngUrl, getJobID())
      // catch possible error
      .catch((reason) =>
        Promise.reject(new Error(`Export color frame error: ${reason}`))
      )
      .finally(() => {
        currentUploadedFrame += 1;
        updateFrameExporterLoadingPercentage();
      })
  );
};

/**
 * export and upload shadow frame
 * @param frame
 * @returns
 */
const exportAndUploadShadowFrame = async (frame: Frame) => {
  const frameShadow = frame.shadow;
  const pngUrl = await renderFrameToPng(
    <FrameShadow frame={frame} isForPrint />,
    {
      name: `shadow_${frameShadow.hexColor}`,
      scale: PRINT_SCALE,
      width: FRAME_SHADOW_SIZE,
      height: FRAME_SHADOW_SIZE,
    }
  );

  // upload
  const fileName = getOrderFileLocation(frame, 0, 0, null, frameShadow);
  return (
    API.uploadImageForOrder(fileName, pngUrl, getJobID())
      // catch possible error
      .catch((reason) =>
        Promise.reject(new Error(`Export shadow frame error: ${reason}`))
      )
      .finally(() => {
        currentUploadedFrame += 1;
        updateFrameExporterLoadingPercentage();
      })
  );
};

/**
 * For albums, we also want to export a manifest page
 * @param project
 * @returns
 */
const exportAndUploadProjectPreviewPage = async (project: Project) => {
  const previewPageIndex = manifestHelper.getPreviewPageIndex(project);
  // let's try to find the corresponding navigator item
  const itemID = `navigator_${previewPageIndex}`;
  const printArea = document.getElementById(itemID);
  // if we don't find it we abort
  if (!printArea) {
    throw new Error('FrameExporter: Navigator preview item not found!');
  }

  // generate based on page navigator item visible on screen
  const pngUrl = await domElementToPng(printArea, 3, true);

  // upload
  const fileName = getOrderFileLocation(
    null,
    0,
    0,
    false,
    false,
    previewPageIndex
  );
  return API.uploadImageForOrder(fileName, pngUrl, getJobID());
};

// --------------------- helpers ------------------------

/**
 * Generate a wrapper to generate div with dom to image
 * @param {string} id
 * @param {number} width
 * @param {number} height
 * @returns
 */
const createFrameWrapperDiv = (
  id: string,
  width: number,
  height: number
): HTMLDivElement => {
  // create the frame in background to allow a PNG generation of it
  const rendererDiv = document.createElement('div');
  rendererDiv.id = id;
  rendererDiv.style.width = `${width}px`;
  rendererDiv.style.height = `${height}px`;
  rendererDiv.style.padding = '0px';
  rendererDiv.style.margin = '0px';
  // hide it
  rendererDiv.style.position = 'absolute';
  rendererDiv.style.left = '0px';
  rendererDiv.style.top = '0px';
  rendererDiv.style.zIndex = '-1000';
  // rendererDiv.style.backgroundColor = 'red';
  return rendererDiv;
};

/**
 * Render a frame to a PNG file string (base64)
 * @param frame
 * @param wrapper
 * @param scale
 * @returns
 */
const renderFrameToPng = async (
  frame: JSX.Element,
  options: {
    name: string;
    scale: number;
    width: number;
    height: number;
    textValue?: string;
    useCopy?: boolean;
    retryCount?: number;
  }
) => {
  // create wrapper
  const wrapper = createFrameWrapperDiv(
    options.name,
    options.width,
    options.height
  );
  // add wrapper to body
  document.body.appendChild(wrapper);
  // create root
  const root = createRoot(wrapper);
  // render frame into wrapper
  root.render(frame);

  // get PNG from it
  const pngURL = await new Promise<string>((resolve, reject) => {
    // Wait for next frame to ensure rendering is complete
    requestAnimationFrame(() => {
      // STEP 3 generate PNG
      domElementToPng(wrapper, options.scale, options.useCopy)
        .then((pngDataUrl) => {
          if (DebugFlags.DOWNLOAD_EXPORTED_FRAMES) {
            console.log('downloading frame', pngDataUrl);
            downloadDataURL(
              pngDataUrl,
              `tictacExport/print_${options.name}.png`
            );
          }
          resolve(pngDataUrl);
        })
        .catch((reason) =>
          reject(
            new Error(
              `Render dom to png for frame '${options.name}', error: ${reason}`
            )
          )
        )
        .finally(() => {
          // png is generated, cleanup
          root.unmount();
          document.body.removeChild(wrapper);
        });
    });
  });

  // here we log image possible issue as the size of image is too low for it's width and height
  // for example, if the image size is below 10ko and the image width and height is 1000x500 it means the image is probably transparent.
  // get the size of the image in ko (the image is base64 encoded)
  const size = getSizeInKo(pngURL);
  // An estimation for minimum expected file size based on dimensions
  // PNG compression is variable, but we can estimate a minimum expected size
  // based on dimensions and a conservative bytes-per-pixel estimate
  const pixelCount = options.width * PRINT_SCALE * options.height * PRINT_SCALE;
  const bytesPerPixel = 0.03; // this magic number is a conservative estimate for compressed PNG with many transparency, after multiple test
  const estimatedMinWeight = (pixelCount * bytesPerPixel) / 1000; // Convert to KB
  if (options.name.includes('frameExport') && size < estimatedMinWeight) {
    const warningMessage = `image ${options.name} size ${size}ko (${options.width} * ${options.height}) should be above ${estimatedMinWeight}ko`;
    console.warn(`---> ${warningMessage}`);
    if (options.textValue) {
      console.warn(`---> text value: ${options.textValue}`);
    }
    notifyMessageToDev(`FrameExport issue: possible blank image`, {
      detail: warningMessage,
      options,
      printScale: PRINT_SCALE,
      performanceLevel,
      textValue: options.textValue,
    });

    // let's retry as we have a low size issue
    options.retryCount = (options.retryCount || 0) + 1;
    if (options.retryCount < 3) {
      // if we fail, we wait 250ms and retry
      await new Promise((resolve) => setTimeout(resolve, 250));
      return renderFrameToPng(frame, options);
    } else {
      const message = `FrameExport issue (${options.name}):  possible blank image fail after 3 retries`;
      console.warn(message);
      notifyError(
        `FrameExport issue: possible blank image fail after 3 retries`,
        {
          detail: warningMessage,
          options,
          printScale: PRINT_SCALE,
          performanceLevel,
          textValue: options.textValue,
        }
      );
    }
  }

  return pngURL;
};

// helper to get the size of the base64 encoded image
const getSizeInKo = (base64String: string) => {
  var stringLength = base64String.length - 'data:image/png;base64,'.length;
  var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  var sizeInKb = sizeInBytes / 1000;
  return sizeInKb;
};
