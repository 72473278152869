import { Input, Modal } from 'antd';
import { isObject } from 'lodash';
import { ImageEditor } from '../../_components/ImageEditor';
import { MultiLineText } from '../../_components/MultiLineText';
import { GetOldFlashEditorDownloadURL, PROJECT_CONST } from '../../data/config';
import { GetText } from '../../data/LanguageHelper';
import { DebugFlags } from '../../debug/DebugFlags';
import type { Photo, ProjectListVo } from '../../types/types';
import { DateUtils } from '../../utils/DateUtils';
import isNullOrUndefined from '../../utils/isNullOrUndefined';

export const popupHelper = {
  showOldEditorPopup,

  showErrorPopup,

  // project
  showRenameProjectPopup,
  showImageEditor,

  // warnings
  showChangePageDeleteWarning,
  ShowLeaveWarningPopup,
  showProjectNumPageChange,

  // debug
  AskSomething,
};

function showImageEditor(photo: Photo): Promise<string> {
  return new Promise((resolve, reject) => {
    // let userFeedback = null;
    // let title = optionalTitle ? optionalTitle : GetText("popup.feedback.title");
    // let desc = optionalDescription ? optionalDescription : GetText("popup.feedback.description", true);

    // open modal
    Modal.confirm({
      title: null, // (<span><FlagOutlined style={{color:Colors.YELLOW, fontSize:"180%", paddingRight:"10px"}} />this is a title!</span>),
      style: {
        top: '5%',
        display: 'flex',
        height: '95%',
        flexFlow: 'column',
      },
      icon: null,
      width: '90%',
      // height:"0%",
      content: <ImageEditor photo={photo} />,
      okCancel: false,
      footer: [],
      // cancelText: GetText("common.cancel"),
      // okText: GetText("topbar.project.saveAs.btn.label"),
      // onOk: ()=>{
      //   // // API.sendDevMail( new Error( "simulated error" ), "User Feedback:" + userFeedback ).then(()=>{
      //   // if(userFeedback && userFeedback !=="")
      //   // {
      //   //   API.sendUserFeedbackToDev( userFeedback )
      //   //   .then( API.sendUserFeedbackToSupport( userFeedback) )
      //   //   .then( openPopupThankYou )
      //   //   .then( ()=>{ resolve() } )
      //   // }
      //   // else resolve()
      //   resolve();

      // },
      // onCancel:()=>{
      //   resolve();
      // }
    });
  });
}

function showErrorPopup(errorMessage: string): Promise<void> {
  // security to avoid break if we pass error object to popup
  // eslint-disable-next-line prefer-destructuring
  if (isObject(errorMessage)) errorMessage = (errorMessage as any).errorMessage;

  return new Promise((resolve, reject) => {
    Modal.error({
      title: GetText('popup.error.title'),
      content: (
        <span>
          {GetText('popup.error.description', true)} :
          <br />
          <br />
          <i>{errorMessage}</i>
        </span>
      ),
      cancelText: GetText('common.cancel'),
      okText: GetText('common.ok'),
      onOk: () => {
        resolve();
      },
      onCancel: () => {
        resolve();
      },
    });
  });
}

function ShowLeaveWarningPopup(stillUploading: boolean) {
  Modal.warn({
    // title: ,
    title: stillUploading
      ? GetText('popup.browser.leave.uploading')
      : GetText('popup.browser.leave'),
    width: 600,
  });
}

/**
 *
 * @param {Function<String>} onSuccess
 * @param {*} onCancel
 * @param {string} defaultProjectName
 */
function showRenameProjectPopup(
  onSuccess: (arg0: string | null) => void,
  onCancel?: any,
  defaultProjectName = null
) {
  let projectName =
    defaultProjectName ||
    (DebugFlags.IS_LOCALHOST
      ? `DEBUG ${PROJECT_CONST.project_class} ${DateUtils.ToYYYYMMDD(
          Date.now()
        )}`
      : null);

  // on change
  const handleChange = (e: { target: { value: string | null } }) => {
    projectName = e.target.value;
  };

  // on confirm
  const handleConfirm = () => {
    if (isNullOrUndefined(projectName) || projectName === '') {
      // reopen popup as we don't allow an empty name!
      this.showRenameProjectPopup(onSuccess, onCancel);
      Modal.warn({ title: GetText('newProject.popup.name.error') });
    } else {
      onSuccess(projectName);
    }
  };

  // create input field component
  const inputField = (
    <Input
      size="large"
      placeholder={GetText('newProject.popup.name.input')}
      // onChange={this.handleNameChange}
      defaultValue={projectName}
      onChange={handleChange}
      autoFocus // not working
      onFocus={(e) => e.currentTarget.select()}
    />
  );

  // show modal
  Modal.confirm({
    width: 600,
    title: GetText('newProject.popup.name.title'),
    content: inputField,
    okText: GetText('common.continue'),
    cancelText: GetText('common.cancel'),
    onCancel,
    onOk: handleConfirm,
  });
}

function showChangePageDeleteWarning(
  numPagesDeleted: number,
  onConfirm?: () => void,
  onCancel?: () => void
) {
  Modal.confirm({
    title: GetText('popup.pageModifier.confirmDelete.title'), // TODO:language
    content: GetText('popup.pageModifier.confirmDelete.desc')
      .split('{NUM}')
      .join(`${numPagesDeleted}`),
    okText: GetText('common.confirm'),
    cancelText: GetText('common.cancel'),
    onOk() {
      onConfirm?.();
    },
    onCancel() {
      onCancel?.();
    },
  });
}

function showProjectNumPageChange(
  oldPageNum: number,
  newPageNum: number,
  onContinue: () => void
) {
  Modal.confirm({
    title: GetText('popup.pageChange.title'),
    content: GetText('popup.pageChange.desc')
      .replace('{OLD}', '' + oldPageNum)
      .replace('{NEW}', '' + newPageNum),
    okText: GetText('common.confirm'),
    // cancelText:GetText("common.cancel"),
    onOk() {
      onContinue?.();
    },
  });
}

function showOldEditorPopup(oldProject: ProjectListVo) {
  Modal.confirm({
    width: '500px',
    title: oldProject.name,
    // title:GetText("popup.oldEditor.redirect.title"), // TODO:language
    // content:GetText("popup.oldEditor.redirect.desc").replace("{NUM}", numPagesDeleted),
    content: <MultiLineText text={GetText('popup.oldEditor.redirect.desc')} />,
    okText: GetText('common.continue'),
    cancelText: GetText('common.cancel'),
    onOk() {
      window.open(GetOldFlashEditorDownloadURL(), '_blank');
    },
    // onCancel(){
    //   //onCancel && onCancel()
    // }
  });
}

function AskSomething(
  title: string,
  onSuccess: () => string,
  onCancel: any,
  defaultResponse: string = null
) {
  let userResponse = defaultResponse;

  // on change
  const handleChange = (e: { target: { value: string } }) => {
    userResponse = e.target.value;
  };

  // on confirm
  const handleConfirm = () => {
    if (isNullOrUndefined(userResponse) || userResponse === '') {
      // reopen popup as we don't allow an empty name!
      AskSomething(title, onSuccess, onCancel, defaultResponse);
      Modal.warn({ title: 'You need to enter something..' });
    } else {
      onSuccess(userResponse);
    }
  };

  // create input field component
  const inputField = (
    <Input
      size="large"
      placeholder={userResponse}
      // onChange={this.handleNameChange}
      defaultValue={userResponse}
      onChange={handleChange}
      autoFocus // not working
      onFocus={(e) => e.currentTarget.select()}
    />
  );

  // show modal
  Modal.confirm({
    width: 600,
    title,
    content: inputField,
    okText: GetText('common.continue'),
    cancelText: GetText('common.cancel'),
    onCancel,
    onOk: handleConfirm,
  });
}
