import { Component } from 'react';
import { connect } from 'react-redux';
import { GetText } from '../data/LanguageHelper';
import { DebugFlags } from '../debug/DebugFlags';
import { alertActions } from '../feature/alert/alert';
import { authActions, authSelectors } from '../feature/auth/authentification';
import { LoginForm } from './loginPage/LoginForm';

class LoginPage extends Component {
  // --------------------- constructor ------------------------

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      // submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Debugging
    if (
      DebugFlags.DEBUGGING &&
      DebugFlags.AUTO_FILL_LOGIN &&
      !this.props.loggedIn
    ) {
      setTimeout(() => {
        this.handleSubmit(
          DebugFlags.AUTO_FILL_LOGIN.user,
          DebugFlags.AUTO_FILL_LOGIN.pass
        );
      }, 100);
    } else {
      // if we have a session stored, we may still have the email of user to use
      try {
        const sessionString = localStorage.getItem('session');
        const session = JSON.parse(sessionString);
        this.state.username = session.email;
      } catch (e) {
        // do nothing
      }
    }
  }

  // --------------------- methods ------------------------

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(username, password) {
    // security
    // if not logged out, we logout before calling login! // TODO:session should be cleared by Backend to avoid this
    if (this.props.loggedIn) {
      this.props.dispatch(authActions.logout());
      setTimeout(() => {
        this.handleSubmit(username, password);
      }, 500);
      return;
    }

    // clear alerts
    this.props.dispatch(alertActions.clear());

    // dispatch
    if (username && password) {
      this.props.dispatch(authActions.login(username, password));
    }
  }

  // --------------------- react ------------------------

  // --------------------- render ------------------------

  render() {
    const { loggingIn, error, rebranding } = this.props;
    const { username, password } = this.state;
    return (
      // <Col span={8}>
      <div className="mainCenteringContainer">
        <div className="loginPage">
          <h2>
            {rebranding?.title
              ? GetText('login.title.rebranding').replace(
                  '{NAME}',
                  rebranding.title
                )
              : GetText('login.title')}
          </h2>
          <h4>{GetText('login.description')}</h4>

          <LoginForm
            onSubmit={(user, pass) => {
              this.handleSubmit(user, pass);
            }}
            username={username}
            password={password}
            loggingIn={loggingIn}
            errorMessage={error}
          />

          {/*
                <form name="form" onSubmit={this.handleSubmit}>

                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">Login</button>
                        {loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA===" />
                        }
                        <Link to="/register" className="btn btn-link">Register</Link>
                    </div>
                </form>
                */}

          {/* </Col> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn, error, rebranding } = state.authentication;

  return {
    loggingIn: loggingIn || authSelectors.ResourcesAreLoading(state),
    loggedIn,
    rebranding,
    error,
  };
}

// connect with redux
const connectedLoginPage = connect(mapStateToProps)(LoginPage);
// export
export { connectedLoginPage as LoginPage };
