import { Colors } from '../../../data/Colors';
import type { IPage } from '../../../types/types';
import {
  AlignMode,
  GetSpiralPosition,
} from '../../../utils/calendar/CalendarHelper';
import { mmToPoint } from '../../../utils/MeasureUtils';

interface ICustomProps {
  page: IPage; // name of icon in image folder
  docID: string; // name of icon in image folder
}

export const CalendarHolesComp = (props: ICustomProps) => {
  const { page, docID } = props;
  // if(!calendarSpiralHoles)
  // {
  //   calendarSpiralHoles = new Sprite();
  //   calendarSpiralHoles.mouseChildren = false;
  //   calendarSpiralHoles.mouseEnabled = false;
  //   calendarSpiralHoles.filters = [new DropShadowFilter(2,45,0,0.5,4,4,1,1,true)];
  // }

  // show spiral holes
  // calendarSpiralHoles.visible = true;

  // var pagesBounds:Rectangle = pageVo.bounds;
  // var g:Graphics = calendarSpiralHoles.graphics;
  // g.clear();

  // draw background
  const spiralPosition: string = GetSpiralPosition(docID, page.index);
  const rotated: boolean =
    spiralPosition === AlignMode.LEFT || spiralPosition === AlignMode.RIGHT;

  const totalSize: number = rotated ? page.height : page.width;

  // draw holes
  const holeSize: number = mmToPoint(4); // holes are 4mm wide (radius 2mm)
  const holesMargin: number = mmToPoint(4.5); // 1cm between holes
  const positionFromEdge: number = mmToPoint(4); // "center" of the hole is 5mm from edge
  const numHoles: number = totalSize / (holeSize + holesMargin); // +1 for security

  const spiralSize: number = mmToPoint(1); // spiral thickness
  const spiralMargin: number = mmToPoint(1); // space between spirals
  // var sideMargin:number = (totalSize - numHoles*(holeSize+holesMargin) - holesMargin)/2;
  let holeCenterPos: number;

  let posX: number;
  let posY: number;
  let rotation: number;

  if (spiralPosition === AlignMode.TOP) {
    // posX = (totalSize-calendarSpiralHoles.width)/4;
    posX = 0;
    posY = 0;
  } else if (spiralPosition === AlignMode.BOTTOM) {
    // case right of page
    // calendarSpiralHoles.scaleY = -1;
    // posX = (totalSize-calendarSpiralHoles.width)/4;
    posX = 0;
    posY = page.height - positionFromEdge;
  } else if (spiralPosition === AlignMode.LEFT) {
    // case left of page
    posX = 0;
    // posY = totalSize - (totalSize-calendarSpiralHoles.width)/4;
    posY = page.height;
    rotation = -90;
  } else if (spiralPosition === AlignMode.RIGHT) {
    // case right of page
    // calendarSpiralHoles.scaleY = -1;
    rotation = -90;
    posX = page.width; // pagesBounds.width;//-calendarSpiralHoles.height;
    // posY = totalSize - (totalSize-calendarSpiralHoles.height)/4;
    posY = page.height;
  }

  // for (var i:number = 0; i < numHoles; i++)
  // {
  //   // // rule for calendar XXL (25 holes on left, 25 holes on right, no more!)
  //   // if( ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL_XXL)
  //   //   && i>24 && i<numHoles-25)
  //   //   continue;

  //   // // wall and birthday calendar does have a "coup de pouce" and 12 holes on each side
  //   // if( (ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL) || ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL))
  //   //   && i>11 && i<numHoles-12)
  //   //   continue;

  //   //holeCenterPos = sideMargin + (holeSize+holesMargin)*i;
  //   holeCenterPos = holesMargin + (holeSize+holesMargin)*i;

  //   // draw the hole
  //   g.beginFill(Colors.BACKGROUND_COLOR, 1);
  //   g.drawCircle(holeCenterPos, positionFromEdge, holeSize/2);
  //   // draw the spirals

  //   g.beginFill(Colors.BLACK,0.8);
  //   //g.beginGradientFill("linear",[Colors.RED, Colors.BLACK], [0.8, 0.5], [0,255],);
  //   g.drawRect(holeCenterPos -spiralMargin*.5 -spiralSize, 0, spiralSize,positionFromEdge);
  //   g.drawRect(holeCenterPos +spiralMargin*.5, 0, spiralSize,positionFromEdge);
  // }

  // // ------
  // // draw "coup de pouce" for wall and birthday cal
  // if( ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL)
  //   || ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL) )
  // {
  //   g.beginFill(Colors.BACKGROUND_COLOR, 1);
  //   g.drawCircle((calendarSpiralHoles.width + holesMargin)/2, 0, MeasureManager.millimeterToPixel(9));
  // }
  // // ------

  // // add it to the top
  // addChild(calendarSpiralHoles);

  // // spiral holes positions
  // if(spiralPosition === AlignMode.TOP)
  // {
  //   posX = (totalSize-calendarSpiralHoles.width)/4;
  // }
  // else if(spiralPosition === AlignMode.BOTTOM)
  // {
  //   // case right of page
  //   calendarSpiralHoles.scaleY = -1;
  //   posX = (totalSize-calendarSpiralHoles.width)/4;
  //   posY = pagesBounds.height;
  // }
  // else if(spiralPosition === AlignMode.LEFT)
  // {
  //   // case left of page
  //   //posX = positionFromEdge + holeSize;
  //   posY = totalSize - (totalSize-calendarSpiralHoles.width)/4;
  //   rotation = -90;
  // }
  // else if(spiralPosition === AlignMode.RIGHT)
  // {
  //   // case right of page
  //   calendarSpiralHoles.scaleY = -1;
  //   rotation = -90;
  //   posX = pagesBounds.width;; //pagesBounds.width;//-calendarSpiralHoles.height;
  //   posY = totalSize - (totalSize-calendarSpiralHoles.height)/4;
  //   //rotation = 90; // do not use 90, use -90 and scaleX -1 to have symetric effect on organizer calendar.

  // }

  return (
    <svg x={posX} y={posY}>
      <g>
        {/* // --------------------- background ------------------------ */}
        {/* <rect id={props.id} x="0" y="0" width="100%" height={positionFromEdge+holeSize} fill="#ff0000" /> */}

        {Array.from({ length: numHoles }).map((value, index) => {
          const holeCenterPos = holesMargin + (holeSize + holesMargin) * index;
          return (
            <g key={`hole_${index}`}>
              <circle
                fill={Colors.BACKGROUND_COLOR}
                cx={holeCenterPos}
                cy={positionFromEdge}
                r={holeSize / 2}
              />

              <rect
                fill={Colors.BLACK}
                fillOpacity={0.8}
                x={holeCenterPos - spiralMargin * 0.5 - spiralSize}
                y={0}
                width={spiralSize}
                height={positionFromEdge}
              />
              <rect
                fill={Colors.BLACK}
                fillOpacity={0.8}
                x={holeCenterPos - spiralMargin * 0.5}
                y={0}
                width={spiralSize}
                height={positionFromEdge}
              />
            </g>
          );
        })}
        {/* for (var i:number = 0; i < numHoles; i++)
  {
    // // rule for calendar XXL (25 holes on left, 25 holes on right, no more!)
    // if( ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL_XXL)
    //   && i>24 && i<numHoles-25)
    //   continue;

    // // wall and birthday calendar does have a "coup de pouce" and 12 holes on each side
    // if( (ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL) || ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL))
    //   && i>11 && i<numHoles-12)
    //   continue;

    //holeCenterPos = sideMargin + (holeSize+holesMargin)*i;
    holeCenterPos = holesMargin + (holeSize+holesMargin)*i;

    // draw the hole
    g.beginFill(Colors.BACKGROUND_COLOR, 1);
    g.drawCircle(holeCenterPos, positionFromEdge, holeSize/2);
    // draw the spirals

    g.beginFill(Colors.BLACK,0.8);
    //g.beginGradientFill("linear",[Colors.RED, Colors.BLACK], [0.8, 0.5], [0,255],);
    g.drawRect(holeCenterPos -spiralMargin*.5 -spiralSize, 0, spiralSize,positionFromEdge);
    g.drawRect(holeCenterPos +spiralMargin*.5, 0, spiralSize,positionFromEdge);
  }

      <text>THIS IS A TEST COMP:{testpropsModified}</text> */}
      </g>
    </svg>
  );
};

// interface ImgIconProps {
//   iconName:String; // name of icon in image folder
// }
// class ImgIcon extends Component<ImgIconProps>
// {
//   render()
//   {
//     let iconName = this.props.iconName;
//     let className = this.props.className;
//     if(!iconName.includes("."))
//       iconName += ".png"; // default extension
//     return (
//       <img src={require("../images/icons/"+iconName)} draggable={false}
//         className={className? className : "imgIcon"} style={this.props.style} />
//     )
//   }
// }

// export default ImgIcon;

// const CustomIcon = () => (
//   <img
//     style={{ width: 15, padding: 1 }} // some custom style to look good
//     src="https://image.flaticon.com/icons/svg/109/109688.svg" // use your imported .png or .jpg file instead
//     alt="Custom Icon"
//   />
// );

// /**----------------------------------- Draw spiral holes for some calendars ----------------------------------- **/
// TODO : later we should store png for one hole and its spiral and draw bitmap instead of fill.

// private function drawSpiralHoles():void
// {
//  if(!calendarSpiralHoles)
//  {
//   calendarSpiralHoles = new Sprite();
//   calendarSpiralHoles.mouseChildren = false;
//   calendarSpiralHoles.mouseEnabled = false;
//   calendarSpiralHoles.filters = [new DropShadowFilter(2,45,0,0.5,4,4,1,1,true)];
//  }

//  // show spiral holes
//  calendarSpiralHoles.visible = true;

//  var pagesBounds:Rectangle = pageVo.bounds;
//  var g:Graphics = calendarSpiralHoles.graphics;
//  g.clear();

//  // draw background
//  /*
//  g.beginFill(Colors.RED_FLASH, 0.2);
//  g.drawRect(0,0, pagesBounds.width, MeasureManager.millimeterToPixel(10)) ;
//  */
//  var spiralPosition : String = CalendarManager.instance.GetSpiralPosition(pageVo.index);
//  var rotated:Boolean = (spiralPosition === AlignMode.LEFT || spiralPosition === AlignMode.RIGHT);

//  var totalSize:number = (rotated)? pagesBounds.height : pagesBounds.width;

//  // draw holes
//  var holeSize:number = MeasureManager.millimeterToPixel(4) ; // holes are 4mm wide (radius 2mm)
//  var holesMargin :number = MeasureManager.millimeterToPixel(4.5) ; // 1cm between holes
//  var positionFromEdge :number = MeasureManager.millimeterToPixel(4) ; // "center" of the hole is 5mm from edge
//  var numHoles :number = totalSize / (holeSize + holesMargin); // +1 for security

//  var spiralSize:number = MeasureManager.millimeterToPixel(1) ; // spiral thickness
//  var spiralMargin :number = MeasureManager.millimeterToPixel(1) ; // space between spirals
//  //var sideMargin:number = (totalSize - numHoles*(holeSize+holesMargin) - holesMargin)/2;
//  var holeCenterPos:number;
//  for (var i:number = 0; i < numHoles; i++)
//  {
//   // rule for calendar XXL (25 holes on left, 25 holes on right, no more!)
//   if( ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL_XXL)
//    && i>24 && i<numHoles-25)
//    continue;

//   // wall and birthday calendar does have a "coup de pouce" and 12 holes on each side
//   if( (ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL) || ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL))
//    && i>11 && i<numHoles-12)
//    continue;

//   //holeCenterPos = sideMargin + (holeSize+holesMargin)*i;
//   holeCenterPos = holesMargin + (holeSize+holesMargin)*i;
//   // draw the hole
//   g.beginFill(Colors.BACKGROUND_COLOR, 1);
//   g.drawCircle(holeCenterPos, positionFromEdge, holeSize/2);
//   // draw the spirals

//   g.beginFill(Colors.BLACK,0.8);
//   //g.beginGradientFill("linear",[Colors.RED, Colors.BLACK], [0.8, 0.5], [0,255],);
//   g.drawRect(holeCenterPos -spiralMargin*.5 -spiralSize, 0, spiralSize,positionFromEdge);
//   g.drawRect(holeCenterPos +spiralMargin*.5, 0, spiralSize,positionFromEdge);
//  }

//  // ------
//  // draw "coup de pouce" for wall and birthday cal
//  if( ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_WALL)
//   || ProductsCatalogue.IsProjectWithPrefix(ProductsCatalogue.PREFIX_CAL_BIRTHDAY_WALL) )
//  {
//   g.beginFill(Colors.BACKGROUND_COLOR, 1);
//   g.drawCircle((calendarSpiralHoles.width + holesMargin)/2, 0, MeasureManager.millimeterToPixel(9));
//  }
//  // ------

//  // add it to the top
//  addChild(calendarSpiralHoles);

//  // spiral holes positions
//  if(spiralPosition === AlignMode.TOP)
//  {
//   posX = (totalSize-calendarSpiralHoles.width)/4;
//  }
//  else if(spiralPosition === AlignMode.BOTTOM)
//  {
//   // case right of page
//   calendarSpiralHoles.scaleY = -1;
//   posX = (totalSize-calendarSpiralHoles.width)/4;
//   posY = pagesBounds.height;
//  }
//  else if(spiralPosition === AlignMode.LEFT)
//  {
//   // case left of page
//   //posX = positionFromEdge + holeSize;
//   posY = totalSize - (totalSize-calendarSpiralHoles.width)/4;
//   rotation = -90;
//  }
//  else if(spiralPosition === AlignMode.RIGHT)
//  {
//   // case right of page
//   calendarSpiralHoles.scaleY = -1;
//   rotation = -90;
//   posX = pagesBounds.width;; //pagesBounds.width;//-calendarSpiralHoles.height;
//   posY = totalSize - (totalSize-calendarSpiralHoles.height)/4;
//   //rotation = 90; // do not use 90, use -90 and scaleX -1 to have symetric effect on organizer calendar.

//  }
// }
