import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Drawer, List, Spin, Tag } from 'antd';
import { GetText } from '../../data/LanguageHelper';
import { popupHelper } from '../../feature/alert/popupHelper';
import { editionSelectors } from '../../feature/edition/edition.selector';
import {
  projectListActions,
  projectListSelectors,
} from '../../feature/projectList/projectList';
import { EmbedAssets } from '../../images/EmbedAssets';
import type { ProjectListVo } from '../../types/types';
import { history, ROUTE_CONST } from '../../utils/history';

export const ProjectListView = () => {
  // --------------------- state / redux ------------------------

  const dispatch = useDispatch();
  const project = useSelector(editionSelectors.GetProjectSelector);
  const projectList = useSelector(projectListSelectors.getAllProjects);
  const projectsListLoading = useSelector(projectListSelectors.loading);

  // --------------------- methods ------------------------

  const handleClose = () => {
    history.push(ROUTE_CONST.HOME);
  };

  const handleProjectClick = (item: ProjectListVo) => {
    const { classname, id, isFlash } = item;

    // case old editor project, display popup and allow redirect!
    if (isFlash) {
      popupHelper.showOldEditorPopup(item);
      return;
    }

    // open in other editor type, reload with correct editor class
    // force load via GET to fix issues with projects not loading correct
    // if (classname !== PROJECT_CONST.project_class) {
    //   window.location = `/${classname}?proj=${id}`;
    //   return;
    // }
    handleClose();

    // update window location
    // TODO: update location
    window.location.href = `/${classname}?proj=${id}`;
    // old:
    // window.location = `/${classname}?proj=${id}`;
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    // retrieve project list
    dispatch(projectListActions.getAll());
  }, [dispatch]);

  // --------------------- render ------------------------

  return (
    <Spin spinning={projectsListLoading}>
      <Drawer
        title={GetText('projectList.title')}
        width={600}
        onClose={(e) => {
          handleClose();
        }}
        open
        bodyStyle={{ paddingBottom: 80 }}
      >
        {projectsListLoading && <em>Loading projects...</em>}
        {/* {projects.error && (
          <span className="text-danger">
            ERROR:
            {projects.error}
          </span>
        )} */}
        {projectList && (
          <div>
            <List
              itemLayout="horizontal"
              className="projectList"
              dataSource={projectList as ProjectListVo[]}
              renderItem={(item) => {
                const selected = project && item.id === project.id;
                const avatarSrc = EmbedAssets.classNameImage(item.classname);

                return (
                  <List.Item
                    // actions={[<a key="list-loadmore-edit">load</a>, <a key="list-loadmore-more">delete</a>]}
                    onClick={
                      !selected
                        ? () => {
                            handleProjectClick(item);
                          }
                        : null
                    }
                    className={`${selected ? 'selected' : ''} item`}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={avatarSrc}
                          shape="circle"
                          size={80}
                          style={
                            {
                              // color: '#f56a00',
                              // backgroundColor: `${(selected)? "#0169a1": "#cccccc"}`
                            }
                          }
                        />
                      }
                      // title={<a href="#" onClick={ ()=> {handleProjectLoad(item.id)}} >{item.name}</a>}
                      title={
                        <span>
                          {item.isFlash && <Tag color="orange">Flash</Tag>}
                          {item.name}
                        </span>
                      }
                      description={
                        <div>
                          {/* <div>Description:{JSON.stringify(item)}</div> */}
                          <div>
                            <b>{GetText('projectList.item.desc')}:</b>{' '}
                            {item.description}{' '}
                          </div>
                          <div>
                            <b>{GetText('projectList.item.date')}:</b>{' '}
                            {item.last_modified}
                          </div>
                          {item.isFlash && (
                            <div style={{ color: 'orange' }}>
                              <i>{GetText('projectlist.oldEditor.desc')}</i>
                            </div>
                          )}

                          {/* <div><Button size="small" type="primary" onClick={ ()=> {handleProjectLoad(item.id)}}>Open</Button></div> */}
                        </div>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          </div>
        )}
      </Drawer>
    </Spin>
  );
};
