import { CANVAS_FORMAT } from '../../data/catalogue/canvasCatalogue.types';
import {
  IsCalendarEditor,
  IsCanvasEditor,
  IsCardEditor,
  PROJECT_CONST,
} from '../../data/config';
import {
  ALBUM_TYPES,
  PAPER_QUALITY,
  PROJECT_CLASS,
} from '../../data/Constants';
import { Project, ProjectCreationParams } from '../../types/project';
import { CreateCalendarColorOptions } from '../../utils/calendar/CreateCalendarColorOptions';
import {
  CreateClassicCoverPage,
  CreateProjectCustomCover,
} from '../../utils/cover/coverHelper';
import { cmToPixel } from '../../utils/MeasureUtils';
import { GetDoc } from '../../utils/ProductHelper';
import {
  ApplyOptionsToProject,
  cleanAndVerifyCustomCover,
  InjectPagesAtIndex,
  IsClassicCoverProject,
} from '../../utils/projectHelper';
import { LAYOUT_TYPE } from '../layouts/layout.type';
import { CreateProjectPages } from './CreateProjectPages';

export function CreateProject(creationParams: ProjectCreationParams) {
  const {
    classname,
    type,
    docID,
    projectName,
    numPages,
    layoutStore,
    options,
    canvasFreeWidth,
    canvasFreeHeight,
  } = creationParams;

  // recover doc ref
  const doc = GetDoc(docID);

  // create project object
  const proj: Project = {
    id: null, // ID is defined by by backend after the first save.
    name: projectName, // name is defined after the project has been saved once
    classname: classname,
    build_created: PROJECT_CONST.build_start, // TODO: get the build of the current version
    build_saved: PROJECT_CONST.build_start,

    // document
    type, // classic, contemporary, regular, etc... = ALBUM_TYPES
    docID, // document ID (from product cataglogue)

    width: doc.width * doc.multiplier, // the width in pixel
    height: doc.height * doc.multiplier, // the height in pixel
    cutBorder: doc.cutBorder,

    // common
    coated: false, // (VARNISH) Use or not coated/varnish pages. TRUE is the default value because all older ablums were coated
    pageList: [],

    // album only
    paper: false, // Use or not paper between pages
    pagePaperQuality: PAPER_QUALITY.QUALITY_250, // Use 200gr or 250 type of paper for pages. 250 is the default value because all older ablums were coated
    pageNumber: false, // show or hide page number
    pageNumberCentered: false, // are page numbers centered
    flyleaf: 'white', // colors used to draw flyeaf in editionArea. Defautl white
    matte: false, // matte paper
    cover_matte: false, // matte cover finish

    // calendar only
    ...(IsCalendarEditor() && {
      startYear: new Date().getFullYear(),
      startMonth: 0,
      startDay: 0,
      calendarColorOptions: CreateCalendarColorOptions(),
    }),

    // card only
    ...(IsCardEditor() && {
      cardPack: '1X1',
      envelope: 'none',
    }),

    // canvas only
    ...(IsCanvasEditor() && {
      canvasFrameColor: null, // some canvas (kadapak) can have a frame color
      canvasFormat: null,
    }),
  };

  if (options) ApplyOptionsToProject(proj, options);

  // --- CASE ALBUM ---
  if (classname === PROJECT_CLASS.ALBUM) {
    // create cover
    if (IsClassicCoverProject(proj))
      proj.pageList.unshift(
        CreateClassicCoverPage(
          proj,
          creationParams.coverName,
          creationParams.coverCorners
        )
      );
    else
      CreateProjectCustomCover(
        proj,
        layoutStore.filtered[LAYOUT_TYPE.COVER].all,
        layoutStore.byID
      );

    // inject pages
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);

    // now that pages are injected, cover must be updated with cover options and page size
    if (!IsClassicCoverProject(proj)) cleanAndVerifyCustomCover(proj);
  }

  // ---- CASE CALENDARS ----
  else if (classname === PROJECT_CLASS.CALENDAR) {
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);

    // calendars have default values for coating
    proj.coated = doc.coating && doc.coating_default;
  }

  // ---- CASE CARDS ----
  else if (classname === PROJECT_CLASS.CARD) {
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);
  }

  // ----  CASE CANVAS ----
  else if (classname === PROJECT_CLASS.CANVAS) {
    // free format
    if (proj.canvasFormat === CANVAS_FORMAT.FREE) {
      proj.width = cmToPixel(canvasFreeWidth);
      proj.height = cmToPixel(canvasFreeHeight);
    }
    const pages = CreateProjectPages(
      proj,
      numPages,
      proj.pageList.length,
      layoutStore
    );
    InjectPagesAtIndex(proj.pageList, pages, proj.pageList.length);
  }

  // This is mandatory!!!! Otherwise the class instance was set and nothing was updating... what a mess..
  // TODO: find a way to avoid this kind of issues... -> by using interface instead of class.. should be re-written
  return JSON.parse(JSON.stringify(proj));
}
