export const ENVIRONEMENT = {
  DEV: 'dev',
  STAGING: 'staging',
  PROD: 'prod',
};

// --------------------- PROJECT ------------------------

export enum PROJECT_CLASS {
  ALBUM = 'albums',
  CALENDAR = 'calendars',
  CARD = 'cards',
  CANVAS = 'canvas',
}

export enum ALBUM_TYPES {
  CLASSIC = 'classic',
  CONTEMPORARY = 'contemporary',
  TRENDY = 'trendy',
  CASUAL = 'casual',
  REGULAR = 'regular',
  LAYFLAT = 'layflat',
}

export enum PAPER_QUALITY {
  QUALITY_170 = '170',
  // QUALITY_200 : "200", // not used
  QUALITY_250 = '250',
}

export const PAPER_FINISH = {
  COATED: 'coated',
  MATTE: 'matte',
};

// --------------------- COVER ------------------------

/**
 * cover type constants
 */
export enum COVER_TYPE {
  COVER_CUSTOM = 'custom', //'Custom Cover',
  COVER_CLASSIC = 'classic', //'Leather Black',
}

export const COVER_CLASSIC_FABRIC_TYPE = {
  LEATHER: 'leather',
  LINEN: 'linen',
};

export const COVER_CLASSIC_FABRIC_OPTIONS = {
  leather: [
    { name: 'leatherblack', color: '#000000' },
    { name: 'leatherblue', color: '#203a4d' },
    { name: 'leatherbrown', color: '#513e3a' },
    { name: 'leathergreen', color: '#1e423e' },
    { name: 'leatherred', color: '#c12a36' },
    { name: 'leatherwhite', color: '#e2e9ec' },
  ],

  linen: [
    { name: 'linenbeige', color: '#dbce9e' },
    { name: 'linenblack', color: '#000000' },
    { name: 'linenblue', color: '#13396e' },
    { name: 'linenbrown', color: '#2b2b28' },
    { name: 'linengray', color: '#5f6a74' },
    { name: 'linenolive', color: '#97b34d' },
    { name: 'linenpink', color: '#e75fa5' },
    { name: 'linenred', color: '#d00722' },
  ],
};

export const COVER_CLASSIC_CORNERS_TYPE = {
  NONE: 'none',
  ROUND_GOLD: 'roundgold',
  ROUND_SILVER: 'roundsilver',
  SQUARE_GOLD: 'squaregold',
  SQUARE_SILVER: 'squaresilver',
};

export const COVER_CLASSIC_COLORS = {
  GOLD: 'gold',
  SILVER: 'silver',
};

export const COVER_CLASSIC_COLOR_HEX = {
  gold: '#ffd700', // note: this must be in lowercase
  silver: '#c0c0c0', // note: this must be in lowercase
};
