import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { App } from './App/App';
import { getAppVersion } from './data/getAppVersion';
import { getEnvFromUrl, IsLocalhost } from './debug/DebugFlags';
import { sentryBeforeBreadCrumb } from './debug/SentryReportingConsole';
import { store } from './store/store';
// CSS
// import './reset.css'; TODO: this doesn't actually work as once built, the css files are merged.
// This is a real issue and must be fixed with async loading or javascript css (or sass)
import 'antd/dist/antd.css';
import './font_embed.css';
import './index.css';

// app monitoring using Sentry
// check this out to add source map: https://medium.com/@vshab/create-react-app-and-sentry-cde1f15cbaa
!IsLocalhost &&
  Sentry.init({
    dsn: 'https://8ee1d4733342442a991b073cb73517f9@o879385.ingest.sentry.io/5832020',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error', 'warn'] }),
      Sentry.replayIntegration(),
    ],
    environment: getEnvFromUrl(), //IsLocalhost ? 'local' : import.meta.env.MODE,
    release: getAppVersion(),
    beforeBreadcrumb(breadcrumb) {
      return sentryBeforeBreadCrumb(breadcrumb);
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracesSampleRate: 1,
  });

// reporting console
// SetupReportingConsole();

// render in DOM
render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
