import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { UploadFile } from 'antd';
import { PROJECT_CONST } from '../../data/config';
import { useCreateTempPhoto } from '../photoList/helpers/useCreateTempPhoto';
import { photoListActions } from '../photoList/photo.store';
import { DraggerProps, UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { usePhotoUploader } from './PhotoUploaderProvider';

// CUSTOM DRAGGER
export const CustomDragger = forwardRef((props: DraggerProps, ref) => {
  const { currentCategory } = usePhotoUploader();
  const dispatch = useDispatch();
  const createTempPhoto = useCreateTempPhoto();

  // --------------------- methods ------------------------

  const importFile = (file: UploadFile<any>) => {
    const imageURL = window.URL.createObjectURL(file.originFileObj);
    const tempPhoto = createTempPhoto(
      file.originFileObj,
      imageURL,
      currentCategory
    );
    dispatch(photoListActions.importPhotos(tempPhoto));
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'done') {
      importFile(info.file);
    }
  };

  const dummyRequest = (e) => {
    setTimeout(() => {
      e.onSuccess();
    }, 10); // the more time we add here, the more delay we have before starting the import process (multiplied by the number of photos..) 2*500 photos => 1second to start the upload
  };

  // --------------------- render ------------------------

  return (
    <Dragger
      ref={ref}
      name="file-upload"
      multiple
      accept={PROJECT_CONST.acceptedImageFileFormats}
      customRequest={dummyRequest}
      onChange={handleChange}
      showUploadList={false}
      {...props}
    />
  );
});
