import { PROJECT_CONST } from '../config';
import { PROJECT_CLASS } from '../Constants';
import { CATALOGUE_ALBUM } from './albumCatalogue';
import { CATALOGUE_CALENDAR } from './calendarCatalogue';
import { CATALOGUE_CANVAS } from './canvasCatalogue';
import { CATALOGUE_CARD } from './cardCatalogue';

export type CatalogueAlbum = typeof CATALOGUE_ALBUM;
export type CatalogueCalendar = typeof CATALOGUE_CALENDAR;
export type CatalogueCard = typeof CATALOGUE_CARD;
export type CatalogueCanvas = typeof CATALOGUE_CANVAS;

export type CanvasProduct = keyof CatalogueCanvas['products'];
export type AlbumProduct = keyof CatalogueAlbum['products'];
export type CardProduct = keyof CatalogueCard['products'];
export type CalendarProduct = keyof CatalogueCalendar['products'];

export function GetProductCatalogue(optionalClassname?: PROJECT_CLASS) {
  const classname: PROJECT_CLASS =
    optionalClassname || PROJECT_CONST.project_class;

  if (classname === PROJECT_CLASS.ALBUM) return CATALOGUE_ALBUM;
  if (classname === PROJECT_CLASS.CALENDAR) return CATALOGUE_CALENDAR;
  if (classname === PROJECT_CLASS.CARD) return CATALOGUE_CARD;
  if (classname === PROJECT_CLASS.CANVAS) return CATALOGUE_CANVAS;

  throw new Error(`Get Catalogue for product type <${classname}> is not valid`);
}
