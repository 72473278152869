import { PropsWithChildren, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Popover } from 'antd';
import { GetText } from '../../data/LanguageHelper';
import { IsLocalhost } from '../../debug/DebugFlags';
import { Photo } from '../../types/types';
import { DateUtils } from '../../utils/DateUtils';
import { isSpecialCategory } from '../../utils/folder/folderHelper';

type Props = {
  photo: Photo;
  isUsed?: boolean;
  // isOpen: boolean;
  // onOpenChange?: (value: boolean) => void;
};

const POPOVER_WIDTH = 350;

export const PhotoDetailPopover = ({
  photo,
  isUsed,
  children,
}: PropsWithChildren<Props>) => {
  const [popOverVisible, setPopOverVisible] = useState(false);
  const highURL = photo.temp ? photo.temp_url : photo.working_url;
  const lowUrl = photo.temp ? photo.temp_url : photo.thumb_url;

  return (
    <Popover
      key={photo.id}
      content={
        <div
          role="button"
          tabIndex={0}
          className="flex flex-col break-words w-full"
          onMouseOver={() => {
            setPopOverVisible(false);
          }}
          onDragOver={() => {
            setPopOverVisible(false);
          }}
        >
          {/** ----- img ------ */}
          {popOverVisible && (
            <div className="border border-gray-300">
              <LazyLoadImage
                width={POPOVER_WIDTH}
                height={(POPOVER_WIDTH * photo.height) / photo.width}
                src={highURL}
                alt={photo.name}
                placeholderSrc={lowUrl}
              />
            </div>
          )}

          {/** ----- text detail ------ */}
          <div
            className={`flex-col py-2 text-xs`}
            style={{ width: POPOVER_WIDTH }}
          >
            <div className="flex text-md flex-wrap w-full grow-0">
              <b>{photo.name}</b>
              {IsLocalhost && (
                <span className="text-xs ml-2">(ID: {photo.id})</span>
              )}
            </div>
            <div>
              <i>Size: </i>
              {`${photo.width}x${photo.height}px`}
            </div>
            <div>
              <i>Creation date: </i> {/* TODO: translate */}
              {DateUtils.ToReadableDate(new Date(Number(photo.creation_date)))}
            </div>
            <div>
              <i>Import date: </i>
              {DateUtils.ToReadableDate(
                new Date(Number(photo.modification_date))
              )}
            </div>
            {!isSpecialCategory(photo.cat) && ( // TODO: if we want to display project categories here, we will need to retrieve label from categories
              <div>
                <i>Folder</i>: {photo.cat}
              </div>
            )}
          </div>

          {/** ----- is used info ------ */}
          {isUsed && (
            <h4 className="text-[#52c41a]">
              <CheckCircleTwoTone twoToneColor="#52c41a" />{' '}
              {GetText('photo.used.tooltip')}
            </h4>
          )}
        </div>
      }
      open={popOverVisible}
      placement="right"
      trigger="hover"
      mouseEnterDelay={0.5}
      onOpenChange={setPopOverVisible}
    >
      {children}
    </Popover>
  );
};
