import { CATALOGUE_ALBUM } from '../../data/catalogue/albumCatalogue';
import { IPage } from '../../types/types';
import { notifyError } from '../../utils/error/notifyError';
import { GetPageBleedInPixel } from '../backgrounds/backgroundHelper';
import { FRAME_TYPE } from '../frame/frame.types';
import { LAYOUT_TYPE } from '../layouts/layout.type';

const layflatDocIds = CATALOGUE_ALBUM.products.layflat.docs;

export const isLayflat = (docID: string) => {
  return layflatDocIds.includes(docID);
};

/** return real page index for a layflat page */
export const getRealPageIndexForLayflat = (page: IPage): number => {
  let realIndex = page.index * 2 - 2;
  if (page.index === 1) realIndex = 1;
  return realIndex;
};

/**
 * we merge two pages into one
 * the new page will have the width of the two pages and the height of the first page
 * the frames of the second page will be appended to the frames of the first page
 * the background frame of the first page will have the width updated to the new width
 * the background of the second page will be ignored
 */
export const mergePages = (
  page1: IPage,
  page2: IPage,
  oneMergedBackground: boolean = false
): IPage => {
  let page2Frames = page2.frames;
  // case we want one big merged background
  if (oneMergedBackground) {
    // create double background
    const bkg = page1.frames.find((f) => f.type === FRAME_TYPE.BKG);
    bkg.width = page1.width + page2.width;
    bkg.x = bkg.width / 2;
    // remove background from page 2
    page2Frames = page2.frames.filter((f) => f.type !== FRAME_TYPE.BKG);
  }
  // move page 2 frames to the right
  page2Frames.forEach((f) => {
    f.x += page1.width;
  });

  // for merged pages, we need to be sure the bleed of the background doens't overlap the other page
  // so we need to update the background bounds
  if (!oneMergedBackground) {
    const page1Bkg = page1.frames.find((f) => f.type === FRAME_TYPE.BKG);
    const page2Bkg = page2.frames.find((f) => f.type === FRAME_TYPE.BKG);
    if (!page1Bkg || !page2Bkg) {
      notifyError(
        "we merge pages but one of the pages doesn't have a background frame"
      );
    } else {
      const bleed = GetPageBleedInPixel();
      page1Bkg.width = page1.width + bleed;
      page1Bkg.x = page1.width / 2 - bleed / 2;
      page2Bkg.width = page2.width + bleed;
      page2Bkg.x = page1.width + page2.width / 2 + bleed / 2;
    }
  }

  // be sure background frames are first in the list of frames
  const newFrames = [...page1.frames, ...page2Frames];
  newFrames.sort((a, b) => {
    if (a.type === FRAME_TYPE.BKG) return -1;
    if (b.type === FRAME_TYPE.BKG) return 1;
    return 0;
  });

  // merge pages
  const newPage: IPage = {
    ...page1,
    width: page1.width + page2.width,
    frames: newFrames,
    merged: true,
    layoutType: LAYOUT_TYPE.MERGED,
  };
  return newPage;
};

/**
 * we merge two pages into one
 * the new page will have the width of the two pages and the height of the first page
 * the frames of the second page will be appended to the frames of the first page
 * the background frame of the first page will have the width updated to the new width
 * the background of the second page will be ignored
 */
export const splitMergedPage = (page: IPage): IPage[] => {
  if (!page.merged) throw new Error('Page is not merged, we cannot split it');

  // create two pages
  const pageLeft: IPage = {
    ...page,
    width: page.width / 2,
    frames: page.frames.filter((f) => f.x < page.width / 2),
    merged: false,
    layoutType: LAYOUT_TYPE.DEFAULT,
  };
  const pageRight: IPage = {
    ...page,
    width: page.width / 2,
    frames: page.frames.filter((f) => f.x >= page.width / 2),
    merged: false,
    layoutType: LAYOUT_TYPE.DEFAULT,
  };
  // update frames position
  pageRight.frames.forEach((f) => {
    f.x -= page.width / 2;
  });

  // TODO: handle backgrounds

  console.log('pageLeft', pageLeft);
  console.log('pageRight', pageRight);

  // return pages
  return [pageLeft, pageRight];
};

// for layflat project each page will be merged with the next one, but the first page and the last page.
// the page size will be doubled and the background frame will be updated
// we return the new page list
export const mergeLayflatPageList = (
  pageList: IPage[],
  doNotMergeFirstAndLastPage: boolean
): IPage[] => {
  const newPageList = [];
  for (let i = 0; i < pageList.length; i += 1) {
    const page = pageList[i];
    if (doNotMergeFirstAndLastPage && (i === 0 || i === pageList.length - 1)) {
      newPageList.push(page);
    } else {
      const nextPage = pageList[i + 1];
      const newPage = mergePages(page, nextPage);
      newPageList.push(newPage);
      i += 1;
    }
  }
  return newPageList;
};
