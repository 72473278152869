import { IsAlbumEditor } from '../../data/config';
import { ALBUM_TYPES } from '../../data/Constants';
import { Project } from '../../types/project';
import { Background, Frame, IPage, Rectangle } from '../../types/types';
import { GetCoverSizeInPixel } from '../../utils/cover/coverHelper';
import { mmToPoint } from '../../utils/MeasureUtils';
import { IsClassicCoverProject } from '../../utils/projectHelper';
import { injectPhotoIntoFrame } from '../frame/_helpers/injectPhotoIntoFrame';
import { FRAME_TYPE } from '../frame/frame.types';
import { CreateDefaultImageAsset } from '../photoList/helpers/photoHelper';

export const BackgroundHelper = {
  GetValidBackgroundListForSizeRatio,
  GetValidInternalBackgroundList,
  GetValidCoverBackgroundList,
};

/**
 *
 * @param {string} id
 * @param {number} width
 * @param {number} height
 * @returns {Background}
 */
export const CreateBackroundItem = (id, width, height) => ({
  ...CreateDefaultImageAsset(id, id),
  width: Number(width),
  height: Number(height),
  realWidth: null,
  realHeight: null,
  proxy: null,
});

export function applyBackgroundToPage(
  page: IPage,
  background: Background,
  mergedPageArea: 'left' | 'right' = 'left'
) {
  // get the frame to update
  let backgroundFrame = page.frames.find(
    (frame) => frame.type === FRAME_TYPE.BKG
  );

  // in case of merged page, we need to find the right background
  if (page.merged) {
    backgroundFrame = page.frames.find(
      (frame) =>
        (frame.type === FRAME_TYPE.BKG &&
          frame.x > page.width / 2 &&
          mergedPageArea === 'right') ||
        (frame.x < page.width / 2 && mergedPageArea === 'left')
    );
  }

  // case no background!
  if (!backgroundFrame) {
    console.warn('no frame found to apply background to page', page);
    backgroundFrame = page.frames[0];
  }

  injectBackgroundIntoFrame(backgroundFrame, background);
}

export function injectBackgroundIntoFrame(
  frame: Frame,
  background: Background
) {
  // security
  if (frame.type === FRAME_TYPE.BKG) {
    // as background has the same params as photo, we can safely inject background as a photo
    injectPhotoIntoFrame(frame, background);

    // but we need to be sure this is handled as background
    frame.background = background.id;
    frame.photo = null;
  } else alert(`This frame is not a background!!!: ${JSON.stringify(frame)}`);
}

export function ApplyBackgroundFillToPage(
  page: IPage,
  fillColor: string,
  mergedPageArea: 'left' | 'right' = 'left'
) {
  let pageBackground = page.frames.find(
    (frame) => frame.type === FRAME_TYPE.BKG
  );
  if (page.merged) {
    pageBackground = page.frames.find(
      (frame) =>
        (frame.type === FRAME_TYPE.BKG &&
          frame.x > page.width / 2 &&
          mergedPageArea === 'right') ||
        (frame.x < page.width / 2 && mergedPageArea === 'left')
    );
  }

  // security
  if (pageBackground.type === FRAME_TYPE.BKG) {
    // but we need to be sure this is handled as background
    pageBackground.cTop = 0;
    pageBackground.cLeft = 0;
    pageBackground.zoom = 1;
    pageBackground.background = null;
    pageBackground.photo = null;
    pageBackground.fillColor = fillColor;
  } else
    alert(
      `This frame is not a background!!!: ${JSON.stringify(pageBackground)}`
    );
}

// --------------------- Background size ------------------------

/**
 * upgrade a new background frame to new bakground bounds
 */
export const upgradeFrameBackground = (
  frame: Frame,
  backgroundBounds: Rectangle,
  backgroundsByID: Record<string, Background>
) => {
  const scaleX = backgroundBounds.width / frame.width;
  const scaleY = backgroundBounds.height / frame.height;
  const zoomScale = scaleX > scaleY ? scaleX : scaleY; // use biggest zoom to be sure we are still correctly linked in frame

  frame.width = backgroundBounds.width;
  frame.height = backgroundBounds.height;
  frame.x = backgroundBounds.x;
  frame.y = backgroundBounds.y;

  // re-inject background with optimal zoom and size!
  if (
    frame.background &&
    backgroundsByID &&
    // eslint-disable-next-line no-prototype-builtins
    backgroundsByID.hasOwnProperty(frame.background)
  ) {
    const bkg = backgroundsByID[frame.background];
    injectBackgroundIntoFrame(frame, bkg);
  }
  // simply be sure the zoom is kept
  else if (frame.photo || frame.background) {
    frame.cLeft *= zoomScale;
    frame.cTop *= zoomScale;
    frame.zoom *= zoomScale;
  }
};

/**
 * Retrieve the page background bounds (in pixel)
 */
export function getPageBackgroundRect(
  project: Project,
  isDoublePage: boolean = false
): Rectangle {
  const bleed = GetPageBleedInPixel(project, false);
  const pageWidth = isDoublePage ? project.width * 2 : project.width;
  return {
    width: pageWidth + bleed * 2,
    height: project.height + bleed * 2,
    x: pageWidth * 0.5,
    y: project.height * 0.5,
  };
}

/**
 * retrieve the cover background bounds (it has specific bleed of 10mm) and take the spine into calculation
 * @param {Project} project
 * @returns {Rectangle}
 */
export function getCoverBackgroundRect(project) {
  const bleed = GetPageBleedInPixel(project, true);
  const coverSize = GetCoverSizeInPixel(project);
  return {
    width: coverSize.width + bleed * 2,
    height: coverSize.height + bleed * 2,
    x: coverSize.width * 0.5,
    y: coverSize.height * 0.5,
  };
}

/**
 * @param {Project} project
 * @param {Boolean} forCover
 * @returns {number}
 */
export function GetPageBleedInPixel(
  project?: Project,
  forCover?: boolean
): number {
  // TODO: handle for canvas : var pageBleed :number = (Infos.isCanvas)? CanvasManager.instance.edgeSize : MeasureManager.millimeterToPixel(4);
  let bleedMM = 4; // always 4 mm for internal pages
  if (project && forCover) {
    if (project.type === ALBUM_TYPES.CASUAL) bleedMM = 4;
    // 4mm for casuals
    else if (project.type === ALBUM_TYPES.TRENDY) bleedMM = 7;
    // 7mm for trendy
    else bleedMM = 10; // 10mm by default for covers
  }

  // return in value in pixel
  return mmToPoint(bleedMM);
}

/**
 * @param {Project} project
 * @param {Array<Background>} backgroundList
 * @returns
 */
function GetValidInternalBackgroundList(project, backgroundList) {
  let pageRatio = 1;
  if (IsAlbumEditor())
    pageRatio = project.pageList[1].width / project.pageList[1].height;
  else pageRatio = project.pageList[0].width / project.pageList[0].height;

  return GetValidBackgroundListForSizeRatio(pageRatio, backgroundList);
}

function GetValidCoverBackgroundList(project, backgroundList) {
  if (IsAlbumEditor() && !IsClassicCoverProject(project)) {
    const pageRatio = project.pageList[0].width / project.pageList[0].height;
    return GetValidBackgroundListForSizeRatio(pageRatio, backgroundList);
  }
  return [];
}

function GetValidBackgroundListForSizeRatio(
  sizeRatio: number,
  backgroundList: Background[]
) {
  // return background having almost the same ratio (20% margin)
  const result = backgroundList?.filter(
    (bg) => Math.abs(1 - bg.width / bg.height / sizeRatio) < 0.2
  );

  // then remove duplicates
  return removeDuplicateBackgrounds(result);
}

/* 
  Remove background duplication
  backgrounds have Id's starting with a size context.
   the part after the first underscore is the same for all sizes.
   so we compare the part after the first underscore to remove duplicates.
   if we already have the background in the list, and the new background has better resolution, we use the new one.
  */
const removeDuplicateBackgrounds = (backgrounds: Background[]) => {
  // return backgrounds;
  const uniqueBackgrounds = [];
  const uniqueBackgroundNames = [];
  backgrounds.forEach((background) => {
    const name = background.name;
    if (!uniqueBackgroundNames.includes(name)) {
      uniqueBackgrounds.push(background);
      uniqueBackgroundNames.push(name);
    } else {
      const index = uniqueBackgroundNames.indexOf(name);
      const existingBackground = uniqueBackgrounds[index];
      if (background.width > existingBackground.width) {
        uniqueBackgrounds[index] = background;
      }
    }
  });

  return uniqueBackgrounds;
};
