import { Tooltip } from 'antd';
import { isString } from 'lodash';

export type ColorPickerObject = {
  hex: string;
  name: string;
};

interface ColorPickerProps {
  colors: Array<ColorPickerObject>; // [{ hex:"#fff000", name:"my color name"}]
  itemSize: number;
  spacing: number;
  borderRadius?: number;
  selected: string; // selected hex color
  onChange: (color: ColorPickerObject) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const itemSize = props.itemSize ? props.itemSize : 20;
  let colorList = props.colors;
  if (colorList && colorList[0] && isString(colorList[0])) {
    colorList = colorList.map((colorString) => ({
      hex: colorString,
      name: null,
    }));
  }

  return (
    <div
      className="colorPicker"
      style={{
        gap: props.spacing,
      }}
    >
      {colorList.map((color, index) => (
        <Tooltip key={`color_${index}`} title={color.name}>
          <div
            role="button"
            tabIndex={0}
            aria-label="color item"
            className={`item${
              props.selected &&
              (color.hex === props.selected ||
                color.name === props.selected ||
                color.id === props.selected)
                ? ' selected'
                : ''
            }`}
            style={{
              background: color.hex,
              width: itemSize,
              height: itemSize,
              borderRadius: props.borderRadius,
            }}
            onClick={() => {
              props.onChange(color);
            }}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default ColorPicker;
